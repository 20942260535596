import { atom } from "recoil";

import { Project, User } from "types/models";
import { PaginationInfo } from "types/client";

/** -------- Projects -------- */
export const projectListState = atom<Project[]>({
  key: "projectListState",
  default: [],
});

export const projectSelectedState = atom<Project | null>({
  key: "projectSelectedState",
  default: null,
});

export const projectRequestorsState = atom<User[]>({
  key: "projectRequestorsState",
  default: [],
});

export const editingProjectState = atom<boolean>({
  key: "editingProjectState",
  default: false,
});

// pagination, sorting, filters
export const totalProjectsPagesState = atom<PaginationInfo>({
  key: "totalProjectsPagesState",
  default: {
    prevLink: null,
    nextLink: null,
    numOfItems: 1,
    currentPage: 1,
  },
});

/** -------- Requests -------- */
export const requestListState = atom<Project[]>({
  key: "requestListState",
  default: [],
});

export const requestSelectedState = atom<Project | null>({
  key: "requestSelectedState",
  default: null,
});

export const requestRequestorsState = atom<User[]>({
  key: "requestRequestorsState",
  default: [],
});

// pagination, sorting, filters
export const totalRequestsPagesState = atom<PaginationInfo>({
  key: "totalRequestsPagesState",
  default: {
    prevLink: null,
    nextLink: null,
    numOfItems: 1,
    currentPage: 1,
  },
});

export const totalRequestsState = atom<number>({
  key: "totalRequestsState",
  default: 0,
});
