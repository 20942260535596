import { FC } from "react";
import { AiFillDelete as DeleteIcon } from "react-icons/ai";

import { removePaymentMethod } from "api/payments";
import { Notification } from "hooks/use-notification-dispatcher";

type IconProps = {
  className: string;
};
interface Action {
  Icon: FC<IconProps>;
  onClick(id: string): () => Promise<void>;
}

export const createActions = (
  setPerformingAction: (flag: boolean) => void,
  updateCustomer: () => void,
  notificationDispatcher: (notification: Notification) => void
): Action[] => [
  {
    Icon: DeleteIcon,
    onClick: (id: string) => async () => {
      setPerformingAction(true);
      await removePaymentMethod(id);
      await updateCustomer();
      setPerformingAction(false);
      notificationDispatcher({
        type: "success",
        description: "Successfully removed payment method",
      });
    },
  },
];
