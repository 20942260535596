/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, FC } from "react";
import { Alert, Button, Form } from "antd";
import NumberFormat from "react-number-format";
import { Heading, Text } from "rebass";

import { verifyBankAccount } from "api/payments";
import theme from "theme/theme.main";
import { Source } from "types/models";

interface Props {
  source: Source;
  onSuccess(source: Source): void;
}

const VerifyAccountForm: FC<Props> = ({ source, onSuccess }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = (values: any) => {
    setLoading(true);
    const amounts = [
      Number(values.amount1.replace(/[^\d]/g, "")),
      Number(values.amount2.replace(/[^\d]/g, "")),
    ];
    verifyBankAccount(source.id, amounts)
      .then((bankSource) => {
        onSuccess(bankSource);
      })
      .catch((err) => {
        if (err.response?.data?.error) {
          setError(err.response.data?.error);
        } else {
          setError(
            "The amounts provided do not match the amounts that were sent to the bank account."
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Heading
        fontSize="4"
        color="primary"
        textAlign="center"
        width="100%"
        fontWeight="body"
        p="27px 0px"
      >
        Verify Bank Account
      </Heading>
      <Text mb={4} color="chamomile06" textAlign="center" fontSize={1}>
        Please check your bank statement and enter the two micro deposits shown
        in the order that they were made.
      </Text>
      {/* {showError && <Alert message={errMessage} type="error" style={{marginBottom: '1rem'}} />} */}
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={handleSubmit}
        style={{
          width: "100%",
        }}
      >
        <Form.Item
          name="amount1"
          label="Micro deposit 1"
          rules={[
            {
              required: true,
              message: "Please enter the deposit amount",
            },
          ]}
        >
          <NumberFormat
            name="amount"
            style={{
              width: "120px",
              height: "40px",
              border: `1px solid ${theme.colors.tertiary}`,
            }}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            placeholder="$0.34"
            prefix="$"
            onValueChange={(values) => {
              if (
                values.floatValue &&
                (values.floatValue >= 10000 || values.floatValue < 0)
              )
                return;
              form.setFieldsValue({ amount1: values.value });
            }}
          />
        </Form.Item>
        <Form.Item
          name="amount2"
          label="Micro deposit 2"
          rules={[
            {
              required: true,
              message: "Please enter the deposit amount",
            },
          ]}
        >
          <NumberFormat
            name="amount"
            style={{
              width: "120px",
              height: "40px",
              border: `1px solid ${theme.colors.tertiary}`,
            }}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            placeholder="$0.42"
            prefix="$"
            onValueChange={(values) => {
              if (
                values.floatValue &&
                (values.floatValue >= 10000 || values.floatValue < 0)
              )
                return;
              form.setFieldsValue({ amount2: values.value });
            }}
          />
        </Form.Item>
        <Button
          type="primary"
          onClick={() => {
            form.validateFields().then((values) => {
              handleSubmit(values);
            });
          }}
          style={{ width: "100%", marginTop: "20px" }}
          loading={loading}
          size="large"
        >
          Confirm
        </Button>
        {error && (
          <Alert
            message={error}
            type="error"
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          />
        )}
      </Form>
    </>
  );
};

export default VerifyAccountForm;
