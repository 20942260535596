import React, { FC, useEffect } from "react";

import Form from "components/Form";
import Button from "components/Button";

import useAsyncState from "hooks/use-async-state";
import { adminUpdateUser, fetchActiveUsers } from "api/users";
import LoadingComponent from "components/LoadingComponent";
import { UserRole } from "types/models";

import { phoneNumberInput, required, validatePhoneNumber } from "utils/form";
import useAsync from "hooks/use-async";

import { updateHome } from "api/homes";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import useHomes from "hooks/use-homes";
import styles from "./styles.module.scss";

type FormValues = {
  title: string;
  description: string;
  wifi_credentials: string;
  wifi_network: string;
  wifi_password: string;
  access_details: string;
  homeowner_email: string;
  homeowner_phone: string;
  watcher: string;
  coordinator: string;
};

type Props = {
  onSubmit(): void;
  updateData(): void;
};

const HomeForm: FC<Props> = ({ onSubmit, updateData }) => {
  const notificationDispatcher = useNotificationDispatcher();

  const { homeDetail: home, updateHomeDetail } = useHomes();

  const {
    uid,
    title,
    home_address: homeAddress,
    users,
    description,
    wifi_credentials: wifiCredentials,
    wifi_network: wifiNetwork,
    wifi_password: wifiPassword,
    access_details: accessDetails,
  } = home || {};

  const { raw: address } = homeAddress || {};

  const homeOwner = (users?.filter(({ role }) => role === UserRole.HomeOwner) ||
    [])[0];

  const homeWatcher = (users?.filter(
    ({ role }) => role === UserRole.HomeWatcher
  ) || [])[0];

  const homeCoordinator = (users?.filter(
    ({ role }) => role === UserRole.HummingManager
  ) || [])[0];

  const {
    data: coordinators,
    inProgress: fetchingCoordinators,
    refresh: fetchCoordinators,
  } = useAsyncState(
    [],
    () =>
      fetchActiveUsers({
        role: UserRole.HomeCoordinator,
        page_size: "100",
      }).then(({ results }) => results),
    { defaultLoading: true }
  );

  const {
    data: watchers,
    inProgress: fetchingWatchers,
    refresh: fetchWatchers,
  } = useAsyncState(
    [],
    () =>
      fetchActiveUsers({
        role: UserRole.HomeWatcher,
        page_size: "100",
      }).then(({ results }) => results),
    { defaultLoading: true }
  );

  useEffect(() => {
    fetchWatchers();
    fetchCoordinators();
  }, []);

  const [submit, submitting] = useAsync(async (values: FormValues) => {
    if (!uid || !address || !title)
      notificationDispatcher({
        type: "error",
        description: "No home selected",
      });
    else {
      const {
        watcher,
        coordinator,
        homeowner_email: homeownerEmail,
        homeowner_phone: homeownerPhone,
        ...homeInformation
      } = values;

      let newUsers = [...(users || [])];

      if (watcher) {
        newUsers = newUsers.filter(({ role }) => role !== UserRole.HomeWatcher);

        const watcherToAdd = watchers.find(
          ({ uid: watcherUID }) => watcherUID === watcher
        );

        if (watcherToAdd)
          newUsers.push({
            user: watcherToAdd,
            role: UserRole.HomeWatcher,
          });
      }

      if (coordinator) {
        newUsers = newUsers.filter(
          ({ role }) => role !== UserRole.HummingManager
        );

        const coordinatorToAdd = coordinators.find(
          ({ uid: cooridnatorUID }) => cooridnatorUID === coordinator
        );

        if (coordinatorToAdd)
          newUsers.push({
            user: coordinatorToAdd,
            role: UserRole.HummingManager,
          });
      }

      await adminUpdateUser(
        { email: homeownerEmail, phone_number: homeownerPhone },
        homeOwner.user.uid
      );

      await updateHome({
        users: newUsers,
        ...homeInformation,
        uid,
        title,
        address,
      });

      updateHomeDetail(home?.uid);

      updateData();
      onSubmit();
    }
  });

  return (
    <LoadingComponent
      className={styles.container}
      loading={fetchingCoordinators || fetchingWatchers}
    >
      <Form
        initialValues={{
          description,
          wifi_credentials: wifiCredentials,
          wifi_network: wifiNetwork,
          wifi_password: wifiPassword,
          access_details: accessDetails,
          homeowner_email: homeOwner?.user?.email,
          homeowner_phone: homeOwner?.user?.phone_number,
          watcher: homeWatcher?.user?.uid,
          coordinator: homeCoordinator?.user?.uid,
        }}
        disabled={submitting}
        className={styles.form}
        onSubmit={submit}
        fields={[
          {
            type: "label",
            id: "home_label",
            placeholder: "Home information",
          },
          {
            title: "Home watcher",
            type: "select",
            placeholder: "Home watcher",
            id: "watcher",
            options: watchers.map(({ uid: watcherUID, name }) => ({
              label: name,
              value: watcherUID,
              key: watcherUID,
            })),
          },
          {
            title: "Access details",
            type: "text",
            placeholder: "Access details",
            id: "access_details",
          },
          {
            title: "Wifi network",
            type: "text",
            placeholder: "Wifi network",
            id: "wifi_network",
          },
          {
            title: "Wifi password",
            type: "text",
            placeholder: "Wifi password",
            id: "wifi_password",
          },
          {
            type: "label",
            id: "owner_label",
            placeholder: "Homeowner information",
          },
          {
            title: "Homeowner email",
            type: "text",
            id: "homeowner_email",
            placeholder: "Homeowner email",
            disabled: true,
          },
          {
            title: "Homeowner phone",
            type: "text",
            id: "homeowner_phone",
            placeholder: "Homeowner phone",
            normalize: [phoneNumberInput],
            validate: [
              required("This field is required"),
              validatePhoneNumber("Enter a correct phone number"),
            ],
          },
        ]}
      >
        {({ handleSubmit, invalid, resetToInitialValues }) => (
          <div className={styles.formActions}>
            <Button
              disabled={submitting}
              variant="secondary"
              onClick={resetToInitialValues}
            >
              Reset to initial values
            </Button>
            <Button
              loading={submitting}
              disabled={invalid}
              onClick={handleSubmit}
            >
              Save changes
            </Button>
          </div>
        )}
      </Form>
    </LoadingComponent>
  );
};

export default HomeForm;
