import React, { FC } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  FaFileInvoiceDollar as InvoiceIcon,
  FaPencilRuler as ProjectsIcon,
  FaCreditCard as PaymentPreferencesIcon,
} from "react-icons/fa";

import Sidebar from "components/Sidebar";

const Navigation: FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <Sidebar
      fixed
      externalSelectedOption={pathname}
      options={[
        {
          label: "Invoices",
          key: "/portal/invoices",
          onClick: () => history.push("/portal/invoices"),
          Icon: InvoiceIcon,
        },
        {
          label: "Projects",
          key: "/portal/projects",
          onClick: () => history.push("/portal/projects"),
          Icon: ProjectsIcon,
        },
        {
          label: "Preferences",
          key: "/portal/preferences",
          onClick: () => history.push("/portal/preferences"),
          Icon: PaymentPreferencesIcon,
        },
      ]}
    />
  );
};

export default Navigation;
