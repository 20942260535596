import Spinner from "components/Spinner";
import React, { FC } from "react";

import styles from "./styles.module.scss";

const Loading: FC = () => (
  <div className={styles.container}>
    <Spinner />
  </div>
);

export default Loading;
