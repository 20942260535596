import React, { FC } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";

import Button from "components/Button";
import Form from "components/Form";
import { createAppointmentFromJob } from "api/events";
import { updateJob } from "api/jobs";
import { jobSelectedState } from "recoil/atoms";
import { JobAppointmentClient } from "types/client";
import { Job, UserRole, Vendor } from "types/models";
import { required } from "utils/form";

import useAsync from "hooks/use-async";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import moment from "moment";
import useProjects from "hooks/use-projects";
import styles from "./styles.module.scss";

type Props = {
  selectedVendor: Vendor | null;
};

type FormValues = {
  due_date: [string, string];
  homeowners_to_invite: string[] | undefined;
  vendors_to_invite: string[] | undefined;
  include_encrypted_data: boolean | undefined;
};

const ScheduleAppointment: FC<Props> = ({ selectedVendor }) => {
  const history = useHistory();
  const loc = useLocation();
  const { projectID, jobID } = useParams<{ [key: string]: string }>();
  const { updateProjectJobs, selectedProject } = useProjects();
  const [selectedJob, setSelectedJob] = useRecoilState(jobSelectedState);

  const notificationDispatcher = useNotificationDispatcher();

  const homeowners =
    selectedProject?.home_detail?.users?.filter(
      (user) =>
        user.role === UserRole.HomeOwner || user.role === UserRole.HomeMember
    ) ?? [];

  const formatFormValues = (values: FormValues): JobAppointmentClient => {
    const {
      due_date: dueDate,
      homeowners_to_invite: homeownersToInvite = [],
      vendors_to_invite: vendorsToInvite = [],
      include_encrypted_data: encryptedData,
    } = values;

    const [startTime, endTime] = dueDate;

    return {
      start_timestamp: moment(startTime).unix(),
      end_timestamp: moment(endTime).unix(),
      participants: [...homeownersToInvite, ...vendorsToInvite],
      job: jobID,
      include_encrypted_data: encryptedData ?? false,
      description: selectedJob?.details ?? selectedJob?.uid,
    };
  };

  const [schedule, scheduling] = useAsync(async (values: FormValues) => {
    if (selectedJob && selectedVendor && !selectedJob.selected_vendor) {
      const updatedJob = await updateJob(
        { selected_vendor: selectedVendor.uid } as Job,
        jobID
      );
      setSelectedJob(updatedJob);
    }

    await createAppointmentFromJob(formatFormValues(values))
      .then(() => {
        notificationDispatcher({
          type: "success",
          description: "Appointment successfully created",
        });
        updateProjectJobs();
        history.push(`/admin/projects/${projectID}/jobs/${jobID}${loc.search}`);
      })
      .catch(() => {
        notificationDispatcher({
          type: "error",
          description: "Appointment could not be created",
        });
      });
  });

  return (
    <Form
      className={styles.form}
      onSubmit={schedule}
      name="schedule-appointment"
      disabled={scheduling}
      fields={[
        {
          type: "range",
          id: "due_date",
          title: "Choose time",
          validate: [required("Please select a time for the appointment.")],
        },
        {
          type: "checklist",
          id: "homeowners_to_invite",
          title: "Home owners",
          options: homeowners.map(({ user }) => ({
            label: `${user.name} - ${user.email}`,
            value: user.email,
          })),
        },
        {
          type: "checklist",
          id: "vendors_to_invite",
          title: "Vendors",
          options: [
            {
              label: `${selectedVendor?.vendor_name} - ${selectedVendor?.email}`,
              value: selectedVendor?.email,
            },
          ],
        },
        {
          type: "toggle",
          id: "include_encrypted_data",
          title: "Include homeowner's Encrypted Data",
          parents: [{ id: "homeowners_to_invite" }],
        },
      ]}
    >
      {({ invalid, handleSubmit }) => (
        <Button
          className={styles.submit}
          disabled={invalid}
          onClick={handleSubmit}
          loading={scheduling}
        >
          Schedule
        </Button>
      )}
    </Form>
  );
};

export default ScheduleAppointment;
