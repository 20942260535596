import React, { FC, useEffect, useState } from "react";
import { HiChevronDown as CollapsedIcon } from "react-icons/hi";

import InvoiceStatusTag from "components/InvoiceStatusTag";
import { Invoice, Bill } from "types/models";
import { currencyFormatter } from "utils/currency";
import Collapsable from "components/Collapsable";

import Label from "components/Label";
import { useParams } from "react-router-dom";
import InvoiceDetail from "./components/InvoiceDetail";
import styles from "./styles.module.scss";

type PanelProps = {
  invoice: Invoice;
};

const PanelHeader = ({ invoice }: PanelProps) => {
  return (
    <div className={styles.invoiceHeader}>
      <div className={styles.invoiceHeader}>
        <Label ellipsis className={styles.headerLabel}>
          {`Invoice - ${currencyFormatter.format(Number(invoice.total))}`}
        </Label>
        {invoice.number && <Label>{invoice.number}</Label>}
        {invoice.vendor_invoice_number && (
          <Label>{invoice.vendor_invoice_number}</Label>
        )}
        <InvoiceStatusTag invoice={invoice} />
      </div>
      <CollapsedIcon className={styles.collapsedIcon} />
    </div>
  );
};

type InvoicesProps = {
  invoices: Invoice[];
  bills: Bill[];
  jobID: string;
};

const Invoices: FC<InvoicesProps> = ({
  invoices,
  bills,
  jobID,
}: InvoicesProps) => {
  const { invoiceID } = useParams<{ [key: string]: string }>();

  const [selectedInvoice, setSelectedInvoice] = useState(invoiceID);

  useEffect(() => {
    setSelectedInvoice(invoiceID);
  }, [invoiceID]);

  return (
    <div>
      {invoices.map((invoice) => (
        <Collapsable
          unmountOnCollapse
          open={
            invoiceID === undefined ? undefined : selectedInvoice === invoice.id
          }
          onSelect={(id: string) => {
            if (selectedInvoice === id) setSelectedInvoice("");
            else setSelectedInvoice(id);
          }}
          id={invoice.id}
          key={invoice.id}
          className={styles.collapsable}
          header={<PanelHeader invoice={invoice} />}
        >
          <InvoiceDetail jobID={jobID} invoice={invoice} bills={bills} />
        </Collapsable>
      ))}
    </div>
  );
};

export default Invoices;
