import { AxiosError } from "axios";
import toast from "react-hot-toast";

export const getError = (
  errors: { [key: string]: string[] | string },
  defaultError: string
): string => {
  const firstErrorKey = Object.keys(errors)?.[0];

  return firstErrorKey
    ? Array.isArray(errors[firstErrorKey])
      ? errors[firstErrorKey][0]
      : (errors[firstErrorKey] as string)
    : defaultError;
};

export const displayErrorFromAxiosException =
  (defaultError: string) => (exception: AxiosError) => {
    const errors = exception.response?.data;

    toast.error(getError(errors, defaultError), { position: "top-right" });
  };
