import { atom } from "recoil";
import { AppFilters } from "./types";

export const dummyState = atom<AppFilters>({
  key: "dummy-filters",
  default: { updatingFilters: false, assignedToMe: false },
});

export const appFiltersState = atom<AppFilters>({
  key: "app-global-filters",
  default: { updatingFilters: false, assignedToMe: false },
});
