import { Activity, ActivityDisplay } from "types/models";

export const ACTIVITY_DISPLAY: { [x: string]: ActivityDisplay } = {
  approved_estimate_to_homeowner: {
    text: "approved estimate to homeowner",
    destinationURL: () => "",
  },
  create_invoice: {
    text: "created an invoice",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.job}/invoices/${activity.object_id}`,
  },
  updated_note: {
    text: "updated note",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/notes/${activity.object_id}`,
  },
  update_invoice: {
    text: "updated an invoice",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.job}/invoices/${activity.object_id}`,
  },
  finalize_invoice: {
    text: "finalized an invoice",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.job}/invoices/${activity.object_id}`,
  },
  pay_invoice: {
    text: "paid an invoice",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.job}/invoices/${activity.object_id}`,
  },
  create_appointment: {
    text: "created an appointment",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.job}/`,
  },
  created_project: {
    text: "created a project",
    destinationURL: (_activity: Activity) => "",
  },
  completed_project: {
    text: "completed a project",
    destinationURL: (_activity: Activity) => "",
  },
  updated_project: {
    text: "updated a project",
    destinationURL: (_activity: Activity) => "",
  },
  approved_project: {
    text: "approved a project",
    destinationURL: (_activity: Activity) => "",
  },
  cancelled_project: {
    text: "cancelled a project",
    destinationURL: (_activity: Activity) => "",
  },
  restored_project: {
    text: "restorted a project",
    destinationURL: (_activity: Activity) => "",
  },
  created_note: {
    text: "created a note",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/notes/${activity.object_id}/`,
  },
  archived_note: {
    text: "archived a note",
    destinationURL: (_activity: Activity) => "",
  },
  created_job: {
    text: "created a job",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.object_id}/`,
  },
  completed_job: {
    text: "completed a job",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.object_id}/`,
  },
  uncompleted_job: {
    text: "uncompleted a job",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.object_id}/`,
  },
  restored_job: {
    text: "restored a job",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.object_id}/`,
  },
  archived_job: {
    text: "archived a job",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.object_id}/`,
  },
  send_service_request: {
    text: "sent a service request",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.job}/estimates/${activity.object_id}/`,
  },
  send_estimate_to_homeowner: {
    text: "sent a service estimate",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.job}/estimates/${activity.object_id}/`,
  },
  archive_service_estimate: {
    text: "archived a service estimate",
    destinationURL: (activity: Activity) => "",
  },
  restore_service_estimate: {
    text: "restored a service estimate",
    destinationURL: (activity: Activity) =>
      `/admin/projects/${activity.project}/jobs/${activity.job}/estimates/${activity.object_id}/`,
  },
  assigned_project: {
    text: "assigned this project to someone",
    destinationURL: () => "",
  },
  removed_assignment_on_project: {
    text: "removed an assignment",
    destinationURL: () => "",
  },
};
