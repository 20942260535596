import { atomFamily } from "recoil";

export const loadingState = atomFamily({
  key: "use-async-atom-loading",
  default: false,
});

export const loadedState = atomFamily({
  key: "use-async-atom-loaded",
  default: false,
});
