import Button from "components/Button";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Dropdown } from "antd";
import { IoFilterSharp as FilterIcon } from "react-icons/io5";

import { RecoilState, useRecoilValue } from "recoil";
import Label from "components/Label";
import { FilterOption } from "../../types";
import Filter from "./components/Filter";
import styles from "./styles.module.scss";

type Props = {
  options: FilterOption[];
  name: string;
  disabled: boolean;
  filtersState: RecoilState<{ [key: string]: string | undefined }>;
  orderOptions: boolean;
  width: number;
};

const SetFilter: FC<Props> = ({
  name,
  options,
  disabled,
  filtersState,
  orderOptions,
  width,
}) => {
  const [visible, setVisible] = useState(false);
  const tableFilters = useRecoilValue(filtersState);

  const filtersApplied = tableFilters[name]?.split(",").length;

  useEffect(() => {
    if (disabled) setVisible(false);
  }, [disabled]);

  const sortedOptions = useMemo(
    () =>
      options.sort(({ label: labelA }, { label: labelB }) => {
        if (labelA.toLowerCase() > labelB.toLowerCase()) return 1;
        if (labelA.toLowerCase() < labelB.toLowerCase()) return -1;
        return 0;
      }),
    [options]
  );

  return (
    <Dropdown
      destroyPopupOnHide
      placement="bottomRight"
      visible={visible}
      overlay={
        <Filter
          width={width}
          filtersState={filtersState}
          disabled={disabled}
          name={name}
          options={orderOptions ? sortedOptions : options}
          onClose={() => setVisible(false)}
        />
      }
    >
      <div className={styles.container}>
        <Button
          disabled={disabled}
          stopPropagation
          variant="secondary"
          onClick={() => setVisible(!visible)}
          Icon={<FilterIcon />}
        />
        {filtersApplied && (
          <Label color="white" type="centered" className={styles.badge}>
            {filtersApplied}
          </Label>
        )}
      </div>
    </Dropdown>
  );
};

export default SetFilter;
