/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, FC } from "react";
import { Form, Input, message } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Flex, Text } from "rebass";
import {
  AiFillDelete as DeleteIcon,
  AiFillEdit as EditIcon,
} from "react-icons/ai";

import { deleteNote, updateNote } from "api/notes";
import { Note } from "types/models";

import Greeting from "components/Greeting";
import Label from "components/Label";
import useProjects from "hooks/use-projects";
import useAsync from "hooks/use-async";
import Button from "components/Button";
import ConfirmAction from "./components/ConfirmAction";
import styles from "./styles.module.scss";

type NoteDetailProps = {
  note: Note;
  highlight?: boolean;
};

const NoteDetail: FC<NoteDetailProps> = ({ note, highlight }) => {
  const [editing, setEditing] = useState(false);
  const [newDescription, setNewDescription] = useState(note.description);
  const { projectID } = useParams<{ [key: string]: string }>();
  const { updateProjectNotes } = useProjects();

  const [showIcons, setShowIcons] = useState(false);

  const [handleArchiveNote, archivingNote] = useAsync(async () => {
    await deleteNote(note.uid)
      .then(() => {
        message.success("Deleted note");
        updateProjectNotes(projectID);
      })
      .catch(() => {
        message.error("Could not delete note");
      });
  });

  const [handleUpdateNote, updatingNote] = useAsync(async () => {
    if (!newDescription) {
      message.error("Please enter a note");
      return;
    }
    await updateNote(note.uid, {
      description: newDescription.trim(),
    } as Note).then(() => {
      message.success("Updated note");
      updateProjectNotes(projectID);
    });
  });

  useEffect(() => {
    if (archivingNote || updatingNote) setShowIcons(false);
  }, [archivingNote, updatingNote]);

  return (
    <div
      className={`${styles.container} ${highlight && styles.highlighted}`}
      onMouseEnter={() => setShowIcons(true)}
      onMouseLeave={() => setShowIcons(false)}
      onClick={() => {
        if (editing) {
          setEditing(false);
          if (note.description !== newDescription.trim()) handleUpdateNote();
        }
      }}
    >
      <Form
        initialValues={{ description: note.description }}
        style={{ height: "100%" }}
      >
        <div className={styles.formContent}>
          <div
            className={styles.descriptionContainer}
            onClick={(event) => event.stopPropagation()}
          >
            {editing ? (
              <Form.Item name="description" style={{ marginBottom: 0 }}>
                <Input.TextArea
                  name="description"
                  allowClear
                  rows={6}
                  className={styles.textArea}
                  onChange={(event) => setNewDescription(event.target.value)}
                />
              </Form.Item>
            ) : (
              <>
                {note.description.split("\n").map((part) => (
                  <Label className={styles.description} breakWord>
                    {part}
                  </Label>
                ))}
              </>
            )}
            {!editing && moment(note.created_at).isBefore(note.updated_at) && (
              <Text fontSize={0} fontStyle="italic" marginTop={4}>
                (edited)
              </Text>
            )}
          </div>
          <Flex
            justifyContent="space-between"
            flexDirection="column"
            alignItems="flex-end"
            css={{ textAlign: "right" }}
          >
            <Flex flexDirection="column" css={{ zIndex: 0 }}>
              <Text fontSize={0} fontStyle="italic">
                {moment(note.created_at).format("MM/DD/YYYY h:mma")}
              </Text>
              <Text fontSize={0} fontStyle="italic">
                by {note.created_by_name}
              </Text>
            </Flex>
            {!editing && (
              <div className={styles.noteDetail}>
                <div className={styles.actionsContainer}>
                  <Greeting unmountOn={!showIcons} className={styles.actions}>
                    <Button
                      variant="text"
                      loading={updatingNote}
                      disabled={archivingNote}
                      onClick={() => setEditing(true)}
                      Icon={<EditIcon className={styles.icon} />}
                    />
                    <ConfirmAction
                      title="Are you sure you want to delete this note?"
                      onConfirm={() => handleArchiveNote()}
                    >
                      <Button
                        variant="text"
                        loading={archivingNote}
                        disabled={updatingNote}
                        onClick={() => {}}
                        Icon={<DeleteIcon className={styles.icon} />}
                      />
                    </ConfirmAction>
                  </Greeting>
                </div>
              </div>
            )}
          </Flex>
        </div>
      </Form>
    </div>
  );
};

NoteDetail.defaultProps = {
  highlight: false,
};

export default NoteDetail;
