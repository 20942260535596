import { Attachment } from "components/HomeAttachmentsField";
import Label from "components/Label";
import React, { FC } from "react";

interface Props {
  attachment: Attachment;
}

const MiscPreview: FC<Props> = ({ attachment }) => {
  return (
    <div>
      <Label>{attachment.name}</Label>
    </div>
  );
};

export default MiscPreview;
