import { useEffect, useState } from "react";
import { notification as notificationCenter } from "antd";

const notifications = {
  success: "success",
  error: "error",
  info: "info",
};

export interface Notification {
  type?: "success" | "info" | "error";
  message?: string;
  description: string;
  duration?: number;
  key?: string;
}

const DEFAULT_TITLES = {
  [notifications.success]: "Success",
  [notifications.error]: "Something went wrong",
  [notifications.info]: "You should know",
};

const DEFAULT_DURATION = 4.5;
const DEFAULT_KEY = "notification";

const useNotificationDispatcher = (): ((value: Notification) => void) => {
  const [notification, setNotification] = useState<Notification>({
    message: "",
    description: "",
  });

  useEffect(() => {
    const { type } = notification;

    if (notification && type) {
      const {
        message = DEFAULT_TITLES[type],
        description,
        duration = DEFAULT_DURATION,
        key = DEFAULT_KEY,
      } = notification;

      notificationCenter[type]({
        description,
        message,
        duration,
        key,
      });
    }
  }, [notification]);

  return setNotification;
};

export default useNotificationDispatcher;
