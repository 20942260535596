import React, { FC } from "react";
import Button from "components/Button";
import Form from "components/Form";
import { Invoice, Source } from "types/models";
import { capitalize } from "lodash";
import { setDefaultPaymentMethod } from "api/payments";
import useAsync from "hooks/use-async";
import toast from "react-hot-toast";
import { payInvoice } from "api/invoices";
import { required } from "utils/form";
import { isEmpty } from "utils/array";

import Label from "components/Label";
import styles from "./styles.module.scss";

type Props = {
  paymentMethods: Source[];
  refreshInvoice(): void;
  onSuccess(): void;
  invoice: Invoice;
  canEditPreferences: boolean;
};

const ConfirmAndPay: FC<Props> = ({
  invoice,
  paymentMethods,
  refreshInvoice,
  onSuccess,
  canEditPreferences,
}) => {
  const defaultSource = paymentMethods.find(
    ({ default_source: isDefault }) => isDefault
  )?.id;

  const sourceName = ({ source_data: { last4, bank_name, funding } }: Source) =>
    funding
      ? `${capitalize(funding)} Card ending in ${last4}`
      : `${bank_name} bank account`;

  const [submit, submitting] = useAsync(
    async ({ newSource }: { newSource: string }) => {
      await setDefaultPaymentMethod(newSource);
      refreshInvoice();
    }
  );

  const [pay, paying] = useAsync(async (id: string) => {
    return payInvoice(id)
      .then(() => {
        toast.success("Successfully paid invoice!");
        onSuccess();
      })
      .catch(() => toast.error("Something went wrong, could not pay invoice"));
  });

  return (
    <>
      <Label className={styles.title} type="h5" color="primary">
        Payment Method
      </Label>
      <Form
        className={styles.sourceForm}
        initialValues={{ newSource: defaultSource }}
        onSubmit={submit}
        fields={[
          {
            placeholder: "Default Payment Method",
            id: "newSource",
            type: "select",
            validate: [
              required("A default payment method is needed to pay invoices"),
            ],
            options: paymentMethods.map((source) => ({
              value: source.id,
              label: sourceName(source),
            })),
          },
        ]}
      >
        {({ handleSubmit, invalid, hasChanged }) => (
          <div className={styles.methodActions}>
            <Button
              loading={submitting}
              disabled={!canEditPreferences || invalid || !hasChanged}
              onClick={handleSubmit}
            >
              Update Default Payment Method
            </Button>
            <Button
              variant="positive"
              disabled={isEmpty(paymentMethods) || !defaultSource}
              onClick={() => pay(invoice.id)}
              loading={paying}
            >
              Confirm & Pay
            </Button>
            {!defaultSource && (
              <Label className={styles.error} type="s2-negative">
                No Default Payment Method
              </Label>
            )}
          </div>
        )}
      </Form>
    </>
  );
};

export default ConfirmAndPay;
