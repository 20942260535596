import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";

import Label from "components/Label";
import Form from "components/Form";
import { roomListState } from "recoil/atoms";
import { createRoom, fetchRooms } from "api/homes";
import useAsyncAtom from "hooks/use-async-atom";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import { Values } from "components/FormConstructor/types";
import { required, onlyNumbers } from "utils/form";
import Panel from "components/Panel";

import styles from "./styles.module.scss";

type AddRoomProps = {
  handleClose: () => void;
  visible: boolean;
  initialValues?: { floor: string };
};

const AddRoom: FC<AddRoomProps> = ({ handleClose, visible, initialValues }) => {
  const notificationDispatcher = useNotificationDispatcher();

  const { homeID } = useParams<{ [key: string]: string }>();

  const { refresh } = useAsyncAtom(roomListState, () =>
    fetchRooms({ home: homeID, expand: "appliances" })
  );

  const handleAddRoom = async (newRoom: Values) => {
    const { name, floor } = newRoom;
    const response = await createRoom(homeID, name, floor);
    if (response) {
      notificationDispatcher({
        type: "success",
        message: "Success!",
        description: "Room created correctly",
      });
      refresh();
    } else
      notificationDispatcher({
        type: "error",
        message: "Something went wrong",
        description: "Couldn't create room",
      });

    handleClose();
  };

  return (
    <Panel
      unmountOnCollapse
      visible={visible}
      position="bottom"
      size={500}
      onClose={handleClose}
    >
      <div className={styles.container}>
        <Label type="h5" color="primary">
          New Room
        </Label>
        <div className={styles.form}>
          <Form
            initialValues={initialValues}
            name="add-room-form"
            fields={[
              {
                id: "name",
                type: "text",
                title: "Room's name",
                validate: [required("Field is required")],
              },
              {
                id: "floor",
                type: "text",
                title: "Room's floor",
                validate: [required("Field is required")],
                normalize: [onlyNumbers],
              },
            ]}
            onSubmit={handleAddRoom}
          >
            {({ handleSubmit }) => (
              <Button
                type="primary"
                className={styles.submit}
                onClick={handleSubmit}
              >
                Add Room
              </Button>
            )}
          </Form>
        </div>
      </div>
    </Panel>
  );
};

AddRoom.defaultProps = {
  initialValues: undefined,
};

export default AddRoom;
