// invoices
export const PAYMENT_TIMING = [
  {
    value: "pay_now",
    display: "Pay Now",
  },
  {
    value: "customer_paid",
    display: "Customer Payment Received",
  },
];

// maps to backend values
export const PAYMENT_TYPES = {
  CHECK: "Check",
  ACH: "ACH",
  EXTERNAL: "Paid Externally",
};

export const PAYMENT_OPTIONS = [
  {
    option: PAYMENT_TYPES.CHECK,
    display: "Paper Check",
  },
  {
    option: PAYMENT_TYPES.ACH,
    display: "ACH",
  },
  {
    option: PAYMENT_TYPES.EXTERNAL,
    display: "Paid Externally",
  },
];

export enum SourceStatus {
  CHARGEABLE = "chargeable",
  VERIFIED = "verified",
  PENDING = "pending",
  FAILED = "failed",
  CANCELED = "canceled",
  CONSUMED = "consumed",
}
