/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, FC } from "react";
import { Checkbox, Form } from "antd";
import theme from "theme/theme.main";

import { useUser } from "providers/UserProvider";
import { updateUser } from "api/users";

import Label from "components/Label";
import Button from "components/Button";
import { SharedFlowState } from "components/Flow/types";
import { useRecoilState } from "recoil";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import useAsync from "hooks/use-async";
import Agreement from "./components/Agreement";

import styles from "./styles.module.scss";

type Props = {
  nextStep(): void;
  sharedFlowState: SharedFlowState;
};

const Terms: FC<Props> = ({ nextStep, sharedFlowState }) => {
  const [form] = Form.useForm();
  const [readTOS, setReadTOS] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const { user, setUser } = useUser();
  const [state, setState] = useRecoilState(sharedFlowState);
  const { acceptedTermsOfService } = state;

  const notificationDispatcher = useNotificationDispatcher();

  const handleSubmit = async () => {
    if (user && acceptedTerms) {
      await updateUser({ accepted_terms_of_use: true }, user.uid)
        .then((resp) => {
          setUser(resp);
          notificationDispatcher({
            type: "success",
            description: "Accepted terms of service",
          });
        })
        .catch(() => {
          notificationDispatcher({
            type: "error",
            description: "An error occurred, please check for errors and retry",
          });
        });
    }
  };

  const [submit, submitting] = useAsync(async () => {
    await form
      .validateFields()
      .then(async () => {
        await handleSubmit();
        nextStep();
        setState({ ...state, acceptedTermsOfService: true });
      })
      .catch(() =>
        notificationDispatcher({
          type: "error",
          description: "Form could not submit",
        })
      );
  });

  const handleTermsScroll = (event: any) => {
    const SCROLL_PADDING = 200;
    const readTermsOfService =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + SCROLL_PADDING;
    if (readTermsOfService) setReadTOS(true);
  };

  return (
    <div className={styles.terms}>
      <Form
        form={form}
        initialValues={{
          termsOfService: false,
        }}
        style={{ display: "flex", flexDirection: "column" }}
        className={styles.form}
      >
        <div className={styles.agreement} onScroll={handleTermsScroll}>
          <Agreement />
        </div>
        <Label type="bold-italic">Please scroll to the bottom to accept</Label>
        <Form.Item
          name="termsOfService"
          style={{ marginTop: "8px", marginBottom: 0 }}
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Accept Terms of Service")),
            },
          ]}
        >
          <Checkbox
            name="termsOfService"
            disabled={!readTOS}
            style={{
              color: `${
                readTOS ? theme.colors.primary : theme.colors.chamomile05
              }`,
            }}
            onChange={(value) => {
              form.setFieldsValue({ termsOfService: value.target.checked });
              setAcceptedTerms(value.target.checked);
            }}
          >
            I accept and agree to the Service Provider Agreement
          </Checkbox>
        </Form.Item>
        <Button
          loading={submitting}
          disabled={acceptedTermsOfService}
          onClick={submit}
        >
          Accept terms
        </Button>
      </Form>
    </div>
  );
};

export default Terms;
