/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useRecoilState } from "recoil";

import Label from "components/Label";
import { fetchVendorInvoices } from "api/vendors";
import InvoiceStatusTag from "components/InvoiceStatusTag";
import { COL_WIDTH, VENDOR_INVOICE_PAGE_SIZE } from "constants/site";
import { totalVendorsPagesState } from "recoil/vendors";
import { Invoice } from "types/models";
import { InvoiceResponse } from "types/responses";
import { currencyFormatter } from "utils/currency";
import { updateStickyFilters } from "utils/projects";
import LoadingComponent from "components/LoadingComponent";

import { DownloadOutlined } from "@ant-design/icons";
import Content from "components/Content";

import styles from "./styles.module.scss";

const CSV_HEADERS: string[][] = [
  [
    "Invoice Number",
    "Initial Amount",
    "Transfer Amount",
    "Status",
    "Date Created",
  ],
];

const generateCSVFile = (invoices: Invoice[]) => {
  const values = invoices.map((inv) => {
    return [
      String(inv.number),
      inv.total,
      String((parseFloat(inv.total) - parseFloat(inv.tax || "0")).toFixed(2)) ||
        "",
      inv.status,
      moment(inv.due_date).format("MM/DD/YY h:mma"),
    ];
  });
  return CSV_HEADERS.concat(values);
};

const Invoices = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const loc = useLocation();
  const [vendorInvoices, setVendorInvoices] = useState<Invoice[] | null>(null);
  const [paginationInfo, setPaginationInfo] = useRecoilState(
    totalVendorsPagesState
  );
  const [loading, setLoading] = useState(true);
  const [tableUpdateLoading, setTableUpdateLoading] = useState(false);

  const [csvData, setCSVData] = useState<string[][]>(CSV_HEADERS);

  const existingFilters = Object.fromEntries(new URLSearchParams(loc.search));
  const [existingPageNumber, setExistingPageNumber] = useState<number>(
    paginationInfo.currentPage
  );

  useEffect(() => {
    setLoading(true);
    fetchVendorInvoices(
      { page_size: VENDOR_INVOICE_PAGE_SIZE },
      updateStickyFilters(loc.search)
    )
      .then((resp) => {
        setVendorInvoices(resp.results);
        setInvoicePagination(resp);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (existingFilters.page) {
      setExistingPageNumber(Number(existingFilters.page));
    }
  }, [existingFilters]);

  useEffect(() => {
    if (!vendorInvoices) return;
    const invoicesOnPage = generateCSVFile(vendorInvoices);
    setCSVData(invoicesOnPage);
  }, [vendorInvoices]);

  const handleTableChange = async () => {
    setTableUpdateLoading(true);
    const fetchData = async () => {
      const stickyParams = updateStickyFilters(loc.search);
      const invoicesResponse = await fetchVendorInvoices(
        { page_size: VENDOR_INVOICE_PAGE_SIZE },
        stickyParams
      );
      history.push(`${match.path}?${stickyParams}`);

      setVendorInvoices(invoicesResponse.results);
      setInvoicePagination(invoicesResponse, 1);
      setTableUpdateLoading(false);
    };

    fetchData();
  };

  const setInvoicePagination = (
    invoicesResponse: InvoiceResponse,
    currPageNum?: number
  ) => {
    const updatedPaginationInfo = {
      numOfItems: invoicesResponse.count,
      prevLink: invoicesResponse.previous || null,
      nextLink: invoicesResponse.next || null,
      currentPage: currPageNum || paginationInfo.currentPage,
    };
    setPaginationInfo(updatedPaginationInfo);
  };

  const handlePagination = (pageNum: number) => {
    setTableUpdateLoading(true);

    const fetchData = async () => {
      const stickyParams = updateStickyFilters(loc.search, {
        page: String(pageNum),
      });
      const invoiceResponse = await fetchVendorInvoices(
        {
          page_size: VENDOR_INVOICE_PAGE_SIZE,
        },
        stickyParams
      );
      history.push(`${match.path}?${stickyParams}`);

      setVendorInvoices(invoiceResponse.results);
      setInvoicePagination(invoiceResponse, pageNum);
      setTableUpdateLoading(false);
    };

    fetchData();
  };

  const formatData = (invoices: Invoice[]) =>
    invoices.map((invoice) => ({
      ...invoice,
      transfer_amount: String(
        parseFloat(invoice.total) - parseFloat(invoice.tax || "0")
      ),
    }));

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "number",
      key: "number",
      ellipsis: true,
      fixed: true,
      width: COL_WIDTH.medium,
      render: (invoice_number: string) => invoice_number,
    },
    {
      title: "Initial Amount",
      dataIndex: "total",
      key: "total",
      ellipsis: true,
      width: COL_WIDTH.large,
      render: (total: string) => currencyFormatter.format(Number(total)),
    },
    {
      title: "Transfer Amount",
      dataIndex: "transfer_amount",
      key: "transfer_amount",
      ellipsis: true,
      width: COL_WIDTH.large,
      render: (transfer_amount: any) =>
        currencyFormatter.format(transfer_amount),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: COL_WIDTH.medium,
      render: (_status: string, record: Invoice) => (
        <InvoiceStatusTag invoice={record} />
      ),
    },
    {
      title: "Date Created",
      dataIndex: "created",
      key: "created",
      ellipsis: true,
      width: COL_WIDTH.large,
      render: (created: string) => moment(created).format("MM/YY h:mma"),
    },
  ];

  return (
    <Content withSidebar>
      <LoadingComponent className={styles.loading} loading={loading}>
        <div className={styles.header}>
          <Label type="h5" color="primary">
            Invoices
          </Label>
          <CSVLink
            className={styles.csvLink}
            data={csvData}
            filename={`invoice-list-page-${paginationInfo.currentPage}`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DownloadOutlined />
            <Label>Download Invoices</Label>
          </CSVLink>
        </div>
        <LoadingComponent
          loading={tableUpdateLoading}
          className={styles.tableWrapper}
        >
          <Table
            className={styles.table}
            rowKey={({ id }) => id}
            dataSource={vendorInvoices ? formatData(vendorInvoices) : []}
            columns={columns}
            pagination={false}
            size="middle"
            scroll={{ x: 1000 }}
            onChange={handleTableChange}
            showSorterTooltip={false}
          />
          <Pagination
            defaultCurrent={1}
            showSizeChanger={false}
            defaultPageSize={Number(VENDOR_INVOICE_PAGE_SIZE)}
            total={paginationInfo.numOfItems}
            current={existingPageNumber || paginationInfo.currentPage}
            onChange={(page) => handlePagination(page)}
          />
        </LoadingComponent>
      </LoadingComponent>
    </Content>
  );
};

export default Invoices;
