/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, FC } from "react";
import { message, Tag } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";

import { updateProject } from "api/projects";
import NameAndAddress from "components/NameAndAddress";
import CustomBadge from "components/CustomBadge";
import SubSection from "components/SubSection";
import useProjects from "hooks/use-projects";
import {
  ProjectStatusOptions,
  ARCHIVE_PROJECT_TYPE,
  ProjectStatus,
} from "constants/statuses";
import { Project } from "types/models";
import theme from "theme/theme.main";
import { formattedText } from "utils/strings";
import Label from "components/Label";
import useAsync from "hooks/use-async";
import { isEmpty } from "utils/array";
import { projectOriginToLabel } from "constants/projects";
import AttachmentsPreview from "components/AttachmentsPreview";
import PlanTag from "components/PlanTag";
import Button from "components/Button";
import PauseProject from "./components/PauseProject";
import ArchiveProject from "./components/ArchiveProject";

import styles from "./styles.module.scss";
import CompleteProject from "./components/CompleteProject";

type Props = {
  setEditing: (x: boolean) => void;
  setCreatingJob(): void;
};

const ViewProject: FC<Props> = ({ setEditing, setCreatingJob }) => {
  const { projectID } = useParams<{ [key: string]: string }>();
  const {
    updateProjects,
    selectedProject,
    updateProjectDetails,
    projectCategories,
  } = useProjects();
  const [cancelling, setCancelling] = useState(false);
  const [completing, setCompleting] = useState(false);
  const [declining, setDeclining] = useState(false);
  const [pausing, setPausing] = useState(false);

  const handleCloseArchivingModal = () => {
    setCancelling(false);
    setDeclining(false);
  };

  const canEdit =
    selectedProject?.status !== ProjectStatus.COMPLETED &&
    selectedProject?.status !== ProjectStatus.ARCHIVED;

  const [handleUnpause, pausingProject] = useAsync(async () => {
    if (selectedProject) {
      await updateProject(projectID, { paused_until: null })
        .then((newProject: Project) => {
          updateProjects();
          message.success("Unpaused project");
          updateProjectDetails(newProject.uid);
        })
        .catch(() => message.error("Project could not be unpaused"));
    }
  });

  return (
    selectedProject && (
      <div id="project-details-panel" className={styles.container}>
        <NameAndAddress
          borderless
          showLinkToHome
          home={selectedProject?.home_detail}
        />
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            <Label type="h5" color="primary" className={styles.title}>
              {selectedProject?.name}
            </Label>
            <Button onClick={() => setEditing(true)}>Edit</Button>
          </div>
          {selectedProject?.annual_maintenance && (
            <Tag
              style={{
                borderRadius: 5,
                padding: 10,
                border: 0,
                margin: "15px 0 0",
                backgroundColor: `${theme.colors.semanticPositive}40`,
              }}
            >
              <Label type="positive-bold">Annual Maintenance</Label>
            </Tag>
          )}
          {selectedProject?.tenant_request && (
            <Tag
              style={{
                borderRadius: 5,
                padding: 10,
                border: 0,
                margin: "15px 0 0",
                backgroundColor: `${theme.colors.semanticCritical}40`,
              }}
              color={theme.colors.inProgress}
            >
              <Label type="critical-bold">Requested by Tenant</Label>
            </Tag>
          )}
          {selectedProject?.due_date && (
            <SubSection title="Due date">
              <Label>
                {`${moment(selectedProject.due_date).format(
                  "MM/DD/YY"
                )} - In ${moment
                  .duration(
                    moment(selectedProject.due_date).diff(moment(), "days"),
                    "days"
                  )
                  .humanize()}`}
              </Label>
            </SubSection>
          )}
          <SubSection
            id="project-status-badge"
            title="Status"
            flexDirection="column"
            marginTop={4}
          >
            <CustomBadge
              status={selectedProject?.status}
              options={ProjectStatusOptions}
            />
          </SubSection>
          {selectedProject?.plan_blueprint && (
            <SubSection title="Plan">
              <PlanTag {...selectedProject.plan_blueprint} />
            </SubSection>
          )}
          <SubSection
            title="Project created"
            flexDirection="column"
            marginTop={4}
          >
            {moment(selectedProject?.created_at).format("MM/DD")}
          </SubSection>
          <SubSection
            title="Service offering"
            flexDirection="column"
            marginTop={4}
          >
            {selectedProject.service_offering?.full_name}
          </SubSection>
          {selectedProject.service_offering?.product_line && (
            <SubSection
              title="Product Line"
              flexDirection="column"
              marginTop={4}
            >
              {selectedProject.service_offering.product_line}
            </SubSection>
          )}
          <SubSection title="Category" flexDirection="column" marginTop={4}>
            {
              projectCategories.find(
                (category) => category.id === selectedProject?.category
              )?.name
            }
          </SubSection>
          {selectedProject?.kustomer_conversation_url && (
            <SubSection
              title="Kustomer Conversation"
              flexDirection="column"
              marginTop={4}
            >
              <a
                href={selectedProject.kustomer_conversation_url}
                target="_blank"
                rel="noreferrer"
              >
                View
              </a>
            </SubSection>
          )}
          <SubSection title="Description" flexDirection="column" marginTop={4}>
            {formattedText(selectedProject?.details)}
          </SubSection>
          {selectedProject?.assigned_to.length > 0 && (
            <SubSection
              title="Assigned to"
              flexDirection="column"
              marginTop={4}
            >
              {selectedProject?.assigned_to.map((user) => user.name).join(", ")}
            </SubSection>
          )}
          {selectedProject?.project_origin && (
            <SubSection
              title="Project's origin"
              flexDirection="column"
              marginTop={4}
            >
              {projectOriginToLabel(selectedProject?.project_origin)}
            </SubSection>
          )}
          {selectedProject?.additional_notes && (
            <SubSection
              title="Internal Notes"
              flexDirection="column"
              marginTop={4}
            >
              {selectedProject?.additional_notes}
            </SubSection>
          )}
          {selectedProject?.paused_until && (
            <SubSection
              title="Paused Until"
              flexDirection="column"
              marginTop={4}
            >
              {moment(selectedProject?.paused_until).format("MM/DD/YYYY")}
            </SubSection>
          )}
          {!isEmpty(selectedProject.attachments) && (
            <SubSection
              title="Attachments"
              flexDirection="column"
              marginTop={4}
            >
              <AttachmentsPreview attachments={selectedProject.attachments} />
            </SubSection>
          )}
          {selectedProject?.approved_at && (
            <div className={styles.actions}>
              {canEdit && (
                <Button variant="secondary-outlined" onClick={setCreatingJob}>
                  Add Job
                </Button>
              )}
              {canEdit && (
                <Button
                  variant="negative-outlined"
                  onClick={() => {
                    if (selectedProject?.paused_until) handleUnpause();
                    else setPausing(true);
                  }}
                  loading={pausingProject}
                >
                  {`${
                    selectedProject?.paused_until ? "Unpause" : "Pause"
                  } Project`}
                </Button>
              )}
              {canEdit && (
                <Button variant="positive" onClick={() => setCompleting(true)}>
                  Complete Project
                </Button>
              )}
              {canEdit && (
                <Button variant="negative" onClick={() => setCancelling(true)}>
                  Archive Project
                </Button>
              )}
            </div>
          )}
          <PauseProject
            visible={pausing}
            handleClose={() => setPausing(false)}
          />
          <ArchiveProject
            visible={cancelling || declining}
            handleClose={handleCloseArchivingModal}
            type={
              cancelling
                ? ARCHIVE_PROJECT_TYPE.CANCEL
                : ARCHIVE_PROJECT_TYPE.DECLINE
            }
          />
          <CompleteProject
            visible={completing}
            handleClose={() => setCompleting(false)}
          />
        </div>
      </div>
    )
  );
};

export default ViewProject;
