import React, { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined as LoadingSpinner } from "@ant-design/icons";

import styles from "./styles.module.scss";

type Props = {
  className?: string;
  loading: boolean;
  id?: string;
};

const LoadingComponent: FC<Props> = ({ children, className, loading, id }) => {
  const antIcon = <LoadingSpinner spin />;

  return (
    <>
      {loading && (
        <div id={id} className={`${className} ${styles.spin}`}>
          <Spin size="large" indicator={antIcon} />
        </div>
      )}
      {!loading && (
        <div id={id} className={className}>
          {children}
        </div>
      )}
    </>
  );
};

LoadingComponent.defaultProps = {
  className: "",
  id: undefined,
};

export default LoadingComponent;
