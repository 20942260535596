import { Place } from "types/models";

export const getFromPlace = (
  attribute: string,
  place: Place,
  type: "long_name" | "short_name" = "long_name"
) =>
  place.address_components.find(({ types }) => types.includes(attribute))?.[
    type
  ] ?? "";
