/* eslint-disable react/no-array-index-key */
import React, { useEffect, FC, useMemo, useState } from "react";
import { Button } from "antd";
import { useParams } from "react-router-dom";

import LoadingComponent from "components/LoadingComponent";
import { fetchRooms } from "api/homes";
import { roomListState } from "recoil/atoms";

import { Room } from "types/models";

import useAsyncAtom from "hooks/use-async-atom";
import Label from "components/Label";
import useHomes from "hooks/use-homes";
import AddRoom from "./components/AddRoom";
import styles from "./styles.module.scss";
import Floor from "./components/Floor";

const RoomList: FC = () => {
  const { homeID } = useParams<{ [key: string]: string }>();
  const { homeDetail: home } = useHomes();
  const [currentFloor, setCurrentFloor] = useState<number>(1);
  const [addingRoom, setAddingRoom] = useState(false);

  const {
    inProgress: loadingRooms,
    data: rooms,
    refresh,
  } = useAsyncAtom(roomListState, () =>
    fetchRooms({
      home: homeID,
      expand: "appliances",
    })
  );

  useEffect(() => {
    if (home) refresh();
  }, [home, homeID]);

  const floors = useMemo(() => {
    const topFloor =
      rooms?.reduce((acc, { floor }) => (floor > acc ? floor : acc), 0) || 0;

    const newFloors = new Array<Room[]>(topFloor);

    rooms?.forEach(({ floor, ...item }) => {
      if (!newFloors[floor]) newFloors[floor] = [];
      newFloors[floor].push({ ...item, floor });
    });

    if (!newFloors[0]) newFloors[0] = [];

    return newFloors;
  }, [rooms]);

  const floorVariants = (index: number) => {
    const indexDif = index - (currentFloor ?? 0);
    const relativeIndex = indexDif >= 0 ? indexDif : floors.length + indexDif;
    const scaleFactor = (floors.length - relativeIndex) / floors.length;

    const commonStyles = {
      width: "80%",
      minWidth: 450,
      zIndex: floors.length - relativeIndex,
      opacity: scaleFactor + 0.1,
      top: "50%",
      left: "50%",
    };

    return {
      selected: {
        ...commonStyles,
        height: "90%",
        transform: "translate(-50%, -50%)",
      },
      unselected: {
        ...commonStyles,
        height: `${100 * scaleFactor}%`,
        transform: `translate(-${50 + (1 - scaleFactor) * 15}%, -50%)`,
      },
    };
  };

  const onFloorClick = (index: number) => () => setCurrentFloor(index);

  return (
    <LoadingComponent loading={loadingRooms} className={styles.container}>
      <AddRoom
        initialValues={{ floor: `${currentFloor}` }}
        visible={addingRoom}
        handleClose={() => setAddingRoom(false)}
      />
      {!!rooms?.length && (
        <div className={styles.pagination}>
          {new Array(floors.length).fill(0).map((_, index) => (
            <Button
              key={`Room ${index}`}
              className={styles.paginationButton}
              disabled={index === currentFloor}
              onClick={() => setCurrentFloor(index)}
            >
              {index}
            </Button>
          ))}
        </div>
      )}
      <div className={styles.floors}>
        {rooms?.length ? (
          floors.map((floor, index) => (
            <Floor
              key={`Floor ${index}`}
              index={index}
              floorVariants={floorVariants(index)}
              onClick={onFloorClick(index)}
              isSelected={index === currentFloor}
              addRoom={() => setAddingRoom(true)}
              floor={floor}
            />
          ))
        ) : (
          <Button
            onClick={() => {
              setAddingRoom(true);
            }}
            type="primary"
            className={styles.addRoomButton}
          >
            <Label color="white" type="button">
              Add room
            </Label>
          </Button>
        )}
      </div>
    </LoadingComponent>
  );
};

export default RoomList;
