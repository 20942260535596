import React, { useEffect, FC } from "react";
import {
  useLocation,
  RouteComponentProps,
  Redirect,
  useHistory,
} from "react-router-dom";

import { mapper } from "utils/mapping";
import Switch from "components/Switch";
import { useSessionValue } from "providers/SessionProvider";
import { useUser } from "providers/UserProvider";
import Admin from "views/Admin";
import Auth from "views/Auth";
import PageNotFound from "views/PageNotFound";
import Portal from "views/Portal";
import Vendor from "views/Vendor";
import { UserRole } from "types/models";

import styles from "./styles.module.scss";
import { PUBLIC_ROUTES } from "./constants";

const getRedirectRoute = (
  authenticated: boolean,
  role: string | undefined,
  userFlags: {
    hasOnboarded?: boolean;
    hasAcceptedTerms?: boolean;
  },
  route?: string
) => {
  if (PUBLIC_ROUTES.some((publicRoute) => route?.includes(publicRoute)))
    return route;

  const { hasAcceptedTerms, hasOnboarded } = userFlags;

  const isAuthRoute = route?.includes("/auth");
  const shouldRedirect = route && !isAuthRoute && route !== "/";

  if (route && PUBLIC_ROUTES.includes(route)) return route;

  if (authenticated && role) {
    if (shouldRedirect) return route;
    return mapper(
      {
        [UserRole.Admin]: "/admin",
        [UserRole.HomeCoordinator]: "/admin",
        [UserRole.Vendor]:
          hasOnboarded && hasAcceptedTerms ? "/vendor" : "/vendor/onboarding",
        [UserRole.HomeOwner]: "/portal",
      },
      "/auth/login"
    )(role);
  }

  return shouldRedirect
    ? `/auth/login/?redirectUrl=${route}`
    : isAuthRoute
    ? route
    : "/auth/login";
};

const HummingRouter: FC = () => {
  const { sessionData } = useSessionValue();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { user } = useUser();

  const authenticated = !!sessionData?.access_token;
  const hasOnboarded = !!user?.has_onboarded;
  const hasAcceptedTerms = !!user?.accepted_terms_of_use;

  useEffect(() => {
    history.push(
      getRedirectRoute(
        authenticated,
        user?.role,
        { hasOnboarded, hasAcceptedTerms },
        `${pathname}${search}`
      )
    );
  }, []);

  const routes = [
    { path: "/auth", Component: Auth },
    {
      isPrivate: true,
      roles: [UserRole.Admin, UserRole.HomeCoordinator],
      path: "/admin",
      Component: Admin,
    },
    {
      isPrivate: true,
      roles: [UserRole.HomeOwner, UserRole.HomeMember],
      path: "/portal",
      Component: Portal,
    },
    {
      path: "/vendor",
      Component: Vendor,
    },
    {
      exact: true,
      path: "/",
      render: ({ location }: RouteComponentProps): JSX.Element => (
        <Redirect
          to={{
            pathname: getRedirectRoute(authenticated, user?.role, {
              hasOnboarded,
              hasAcceptedTerms,
            }),
            state: { from: location },
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (window.analytics) window.analytics.page();
  }, [pathname]);

  return (
    <div className={styles.container}>
      <Switch
        authenticated={authenticated}
        routes={routes}
        FallbackComponent={PageNotFound}
      />
    </div>
  );
};

export default HummingRouter;
