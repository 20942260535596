/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import moment from "moment";
import { Tag } from "antd";
import { Invoice, InvoiceStatus } from "types/models";
import { useTheme } from "emotion-theming";
import { toTitleCase } from "utils/strings";

interface Props {
  invoice: Invoice;
  style?: object;
}

function InvoiceStatusTag({ invoice, style }: Props) {
  const theme: any = useTheme();
  const { status } = invoice;
  switch (status) {
    case InvoiceStatus.Draft:
      return (
        <Tag
          style={{ borderRadius: 5, height: "22px", ...style }}
          color={theme.colors.chamomile05}
        >
          Draft
        </Tag>
      );
    case InvoiceStatus.Open:
      return (
        <Tag
          style={{ borderRadius: 5, height: "22px", ...style }}
          color={theme.colors.primary}
        >
          {invoice.due_date
            ? `Due ${moment(invoice.due_date).format("M/D")}`
            : "Payment Due"}
        </Tag>
      );
    case InvoiceStatus.Processing:
      return (
        <Tag
          style={{ borderRadius: 5, height: "22px", ...style }}
          color={theme.colors.inProgress}
        >
          Processing
        </Tag>
      );
    case InvoiceStatus.Paid:
      return (
        <Tag
          style={{ borderRadius: 5, height: "22px", ...style }}
          color={theme.colors.success}
        >
          {toTitleCase(status)}
        </Tag>
      );
    default:
      return (
        <Tag
          style={{ borderRadius: 5, height: "22px", ...style }}
          color={theme.colors.error}
        >
          {toTitleCase(status)}
        </Tag>
      );
  }
}

InvoiceStatusTag.defaultProps = {
  style: {},
};

export default InvoiceStatusTag;
