import React, { FC } from "react";

import AddButton from "components/AddButton";
import Label from "components/Label";

import styles from "./styles.module.scss";

type DetailSectionProps = {
  title: string;
  handleClickAdd?: () => void;
  children: React.ReactNode;
  addButton?: boolean;
  buttonID?: string;
};

const JobDetailSection: FC<DetailSectionProps> = ({
  title,
  handleClickAdd,
  children,
  addButton = true,
  buttonID = "",
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Label type="bold">{title}</Label>{" "}
        {addButton && (
          <AddButton buttonID={buttonID} handleOnClick={handleClickAdd} />
        )}
      </div>

      <div className={styles.content}>{children}</div>
    </div>
  );
};

JobDetailSection.defaultProps = {
  handleClickAdd: () => {},
  addButton: true,
  buttonID: "",
};

export default JobDetailSection;
