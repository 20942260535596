import AddBank from "components/AddBank";
import AddCreditCard from "components/AddCard";
import { mapper } from "utils/mapping";

export const PAYMENT_METHODS = mapper(
  {
    card: AddCreditCard,
    bank: AddBank,
  },
  AddCreditCard
);
