import React, { FC } from "react";
import Button from "components/Button";
import Label from "components/Label";

import styles from "./styles.module.scss";
import Banner from "./assets/banner.png";

type Props = {
  nextStep(): void;
};

const GetStarted: FC<Props> = ({ nextStep }) => (
  <div className={styles.container}>
    <div className={styles.leftPanel}>
      <img src={Banner} alt="" className={styles.banner} />
    </div>
    <div className={styles.rightPanel}>
      <div className={styles.information}>
        <Label
          className={styles.topLabel}
          type="s3-bold-uppercase"
          color="primary"
        >
          Humming Homes
        </Label>
        <Label type="h3-semibold" color="primary">
          Making homeownership
        </Label>
        <Label type="h3-semibold" color="primary">
          easier and more enjoyable
        </Label>
        <Button
          className={styles.getStartedButton}
          size="xxlarge"
          variant="secondary-outlined"
          onClick={nextStep}
          labelVariant="uppercase-bold"
        >
          Get started
        </Button>
      </div>
    </div>
  </div>
);

export default GetStarted;
