import React, { FC } from "react";
import { IoAddCircleOutline as AddIcon } from "react-icons/io5";

import Button from "components/Button";

import { useUser } from "providers/UserProvider";
import { SourceType } from "types/models";

import PaymentSource from "components/PaymentSource";
import styles from "./styles.module.scss";

type ManagePaymentOptionsProps = {
  addNewPayment: (x: boolean) => void;
};

const ManagePaymentOptions: FC<ManagePaymentOptionsProps> = ({
  addNewPayment,
}: ManagePaymentOptionsProps) => {
  const { customer } = useUser();
  const { sources } = customer || {};

  return (
    <div className={styles.container}>
      <Button
        Icon={<AddIcon style={{ marginRight: 5, fontSize: 20 }} />}
        variant="primary"
        onClick={() => addNewPayment(true)}
      >
        Add new payment method
      </Button>
      <div className={styles.sourcesContainer}>
        {sources
          ?.filter(
            ({ type }) =>
              type === SourceType.BANK_ACCOUNT || type === SourceType.CARD
          )
          .map((source) => (
            <PaymentSource key={source.id} source={source} />
          ))}
      </div>
    </div>
  );
};

export default ManagePaymentOptions;
