import { atom } from "recoil";

import { User } from "types/models";

export const potentialAssigneeList = atom<User[]>({
  key: "potentialAssigneeList",
  default: [],
});

export const userFlagsState = atom<{
  hasBeenActivated?: boolean;
  hasAcceptedTerms?: boolean;
  hasOnboarded?: boolean;
  waitingForOps?: boolean;
}>({
  key: "userFlags",
  default: {},
});
