import React, { FC } from "react";
import { resetPassword } from "api/auth";
import Label from "components/Label";
import Form from "components/Form";
import Button from "components/Button";

import { required } from "utils/form";
import useAsync from "hooks/use-async";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import Content from "components/Content";
import styles from "./styles.module.scss";

const ResetPass: FC = () => {
  const notificationDispatcher = useNotificationDispatcher();
  const [submit, submitting] = useAsync(
    async ({ email }: { email: string }) => {
      await resetPassword(email)
        .then(() =>
          notificationDispatcher({
            type: "success",
            description:
              "If a user account exists, the associated email address will receive a password reset message shortly.",
          })
        )
        .catch((error) =>
          notificationDispatcher({
            type: "error",
            description: error.response.message,
          })
        );
    }
  );

  return (
    <Content className={styles.container}>
      <Label color="primary" type="h5">
        Reset Password
      </Label>
      <Form
        className={styles.form}
        name="reset-password"
        onSubmit={submit}
        fields={[
          {
            type: "text",
            id: "email",
            validate: [required("Please input your email")],
            placeholder: "Email address",
          },
        ]}
      >
        {({ handleSubmit, invalid }) => (
          <Button
            className={styles.submit}
            loading={submitting}
            disabled={invalid}
            onClick={handleSubmit}
          >
            Reset password
          </Button>
        )}
      </Form>
    </Content>
  );
};

export default ResetPass;
