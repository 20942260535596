/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useLayoutEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import Form from "components/Form";

import { getHomesList } from "api/homes";
import useProjects from "hooks/use-projects";
import { potentialAssigneeList } from "recoil/atoms";
import { Home } from "types/models";

import Label from "components/Label";
import { required } from "utils/form";
import useAsyncState from "hooks/use-async-state";
import LoadingComponent from "components/LoadingComponent";

import PlanTag from "components/PlanTag";
import Button from "components/Button";
import toast from "react-hot-toast";
import styles from "./styles.module.scss";
import BlankProject from "./components/BlankProject";
import MaintenancePlanProject from "./components/MaintenancePlanProject";

type Props = {
  onSubmit(): void;
};

const ProjectCreationAndModification: FC<Props> = ({ onSubmit }) => {
  const {
    selectedProject,
    loadingProjectCategories,
    loadingServiceCategories,
  } = useProjects();

  const assignees = useRecoilValue(potentialAssigneeList);
  const isEditing = !!selectedProject;

  const {
    data: homes,
    refresh: refreshHomes,
    inProgress: fetchingHomes,
  } = useAsyncState<Home[]>(
    [],
    () =>
      getHomesList({
        archived: "false",
      }),
    {
      failureCallback: () => toast.error("Failed to load homes"),
    }
  );

  useLayoutEffect(() => {
    refreshHomes();
  }, []);

  const [tab, setTab] = useState(selectedProject ? "project" : "none");

  return (
    <LoadingComponent
      className={styles.container}
      loading={
        fetchingHomes || loadingServiceCategories || loadingProjectCategories
      }
    >
      <div className={styles.heading}>
        <Label type="h5" color="primary">
          {isEditing ? "Edit Project" : "Create a Project"}
        </Label>
        {selectedProject?.plan_blueprint && (
          <PlanTag {...selectedProject?.plan_blueprint} />
        )}
      </div>
      <Form
        initialValues={{ home: selectedProject?.home }}
        className={styles.form}
        onChange={(values) => {
          if (!selectedProject && !values.home) {
            setTab("none");
          }
        }}
        renderExternally
        fields={[
          {
            required: true,
            disabled: !!selectedProject,
            type: "select",
            id: "home",
            placeholder: "Home",
            options: homes.map(({ title, uid }) => ({
              value: uid,
              key: String(uid),
              label: title,
            })),
            validate: [required("This field is required")],
          },
        ]}
      >
        {({ values, formToRender }) => (
          <div className={styles.innerForm}>
            <div className={styles.section}>
              <Label type="h6" color="primary">
                Select a Home
              </Label>
              {formToRender}
            </div>
            {!selectedProject && (
              <div className={styles.tabs}>
                <Button
                  variant="text"
                  className={`${styles.tab} ${
                    tab === "project" && styles.selectedTab
                  }`}
                  disabled={!values.home || !!selectedProject}
                  onClick={() => setTab("project")}
                >
                  Project
                </Button>
                <Button
                  variant="text"
                  className={`${styles.tab} ${
                    tab === "plan" && styles.selectedTab
                  }`}
                  disabled={!values.home || !!selectedProject}
                  onClick={() => setTab("plan")}
                >
                  Maintenance Plan
                </Button>
              </div>
            )}
            {tab === "plan" && (
              <MaintenancePlanProject
                home={values.home}
                onSubmit={onSubmit}
                assignees={assignees}
              />
            )}
            {tab === "project" && (
              <BlankProject
                project={selectedProject}
                home={values.home}
                onSubmit={onSubmit}
                assignees={assignees}
              />
            )}
            {tab === "none" && <div className={styles.prompt} />}
          </div>
        )}
      </Form>
    </LoadingComponent>
  );
};

export default ProjectCreationAndModification;
