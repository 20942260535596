import React from "react";
import moment from "moment";

import {
  BadgeOptions,
  ProjectStatus,
  ProjectStatusOptions,
} from "constants/statuses";
import {
  User,
  Project,
  Home,
  ProjectCategory,
  ServiceCategory,
  PlanBlueprint,
  CancellationReason,
} from "types/models";
import { ColumnDefinition } from "components/Table/types";
import PropertyCell from "views/Admin/components/PropertyCell";
import EmergencyTag from "components/EmergencyTag";
import PlanTag from "components/PlanTag";
import UpdateStatus from "./components/UpdateStatus";

type RowData = {
  property: Home;
  name: string;
  status?: { display: string };
  offering?: string;
  category?: string;
  created_at: string;
  assigned_to: string;
  due_date: string;
  is_emergency: boolean;
  maintenance_plan?: PlanBlueprint;
  id: string;
  uid: string;
  service_offering: string;
  product_line: string;
  last_status_update: string;
};

export const createDataFromProjects = (
  projects: Project[],
  categories: ProjectCategory[]
): RowData[] =>
  projects.map((project) => {
    const {
      is_emergency: isEmergency,
      category,
      home_detail: home,
      name,
      status,
      service_offering: serviceOffering,
      assigned_to: assignee,
      created_at: createdAt,
      uid,
      plan_blueprint: plan,
      due_date: dueDate,
      updated_at: updatedAt,
    } = project;

    return {
      is_emergency: isEmergency,
      maintenance_plan: plan,
      property: home,
      name,
      status: ProjectStatusOptions.find(
        ({ value: statusValue }) => statusValue === status
      ),
      service_offering: serviceOffering?.full_name || "",
      category: categories.find(({ id }) => id === category)?.name,
      assigned_to: assignee[0]?.name,
      created_at: moment(createdAt).format("MM/DD [at] h:mma"),
      due_date: dueDate ? moment(dueDate).format("MM/DD/YY") : "",
      uid,
      id: uid,
      product_line: serviceOffering?.product_line || "",
      last_status_update: moment(updatedAt).format("MM/DD [at] h:mma"),
    };
  });

export const createColumns = (
  service_categories: ServiceCategory[],
  assignees: User[],
  updateProjects: () => void,
  statuses: ProjectStatus[],
  cancellationReasons: CancellationReason[]
): ColumnDefinition<RowData>[] => [
  {
    Header: "Project Name",
    accessor: "name",
    fixed: "left",
    width: 250,
  },
  {
    Header: "Home Name",
    accessor: "property",
    fixed: "left",
    width: 250,
    Cell: ({ value }: { value: Home }) => <PropertyCell home={value} />,
    interactable: true,
  },
  {
    Header: "Product Line",
    accessor: "product_line",
    width: 200,
    filter: "set",
    options: [
      "Emergency",
      "Managed",
      "Marketplace",
      "Marketplace Internal",
    ].map((value) => ({ value, label: value, key: value })),
  },
  {
    interactable: true,
    Header: "Status",
    accessor: "status",
    Cell: ({
      row: {
        original: { id, status, assigned_to: assginee, name, property },
      },
    }: {
      row: {
        original: {
          id: string;
          status: BadgeOptions;
          assigned_to?: string;
          name: string;
          property: Home;
        };
      };
    }) => (
      <UpdateStatus
        cancellationReasons={cancellationReasons}
        project={`${name} for ${property.title}`}
        assignee={assginee}
        assignees={assignees.map(({ uid, name: aName }) => ({
          label: aName,
          key: String(uid),
          value: uid,
        }))}
        updateProjects={updateProjects}
        id={id}
        status={status}
      />
    ),
    width: 250,
    ...(statuses.length > 1
      ? {
          filter: ["set", "ordering"],
          options: ProjectStatusOptions.filter(({ value: statusValue }) =>
            statuses.includes(statusValue as ProjectStatus)
          ).map(({ display, value }) => ({
            label: display,
            value: String(value),
            key: String(value),
          })),
        }
      : {}),
  },
  {
    Header: "Assignee",
    accessor: "assigned_to",
    width: 200,
    filter: "set",
    orderOptions: true,
    options: assignees.map(({ uid, name }) => ({
      label: name,
      key: String(uid),
      value: uid,
    })),
  },
  {
    Header: "Created At",
    accessor: "created_at",
    width: 250,
    filter: ["date", "ordering"],
  },
  {
    Header: "Due date",
    accessor: "due_date",
    width: 350,
    filter: ["date", "ordering"],
  },
  {
    Header: "Service offerings",
    accessor: "service_offering",
    width: 550,
    filter: "set",
    options: service_categories
      .map(
        ({ children }) =>
          children
            ?.map(
              ({ service_offerings }) =>
                service_offerings
                  ?.map(({ id, full_name }) => ({
                    key: String(id),
                    label: full_name,
                    value: String(id),
                  }))
                  .flat() || []
            )
            .flat() || []
      )
      .flat(),
  },
  {
    Header: "Plan",
    accessor: "maintenance_plan",
    Cell: ({ value }: { value: PlanBlueprint }) =>
      value && <PlanTag {...value} />,
    width: 230,
    filter: "set",
    options: [{ key: "Fall 2022", label: "Fall 2022", value: "Fall 2022" }],
  },
  {
    Header: "Priority",
    accessor: "is_emergency",
    width: 180,
    filter: "ordering",
    Cell: ({ value }: { value: boolean }) => (
      <EmergencyTag variant={value ? "emergency" : "normal"} />
    ),
  },
  {
    Header: "Last Updated",
    accessor: "last_status_update",
    width: 200,
    fixed: "right",
    filter: ["date", "ordering"],
  },
];
