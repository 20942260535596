import styled from "styled-components";
import theme from "theme/theme.main";
import { Tabs } from "antd";

export const CustomerInvoiceTabs = styled(Tabs)`
  .ant-tabs-content-holder {
    position: relative;
  }
  .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-tabpane {
    overflow: scroll;
  }

  &.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    font-size: 24px;
    font-weight: 200;
    font-family: ${theme.fonts.heading};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${theme.colors.heading};
    font-weight: ${theme.fontWeights.light};
    font-family: ${theme.fonts.heading};
  }
`;
