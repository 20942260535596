import { getHomeNotes } from "api/homes";
import Button from "components/Button";
import LoadingComponent from "components/LoadingComponent";
import useAsyncState from "hooks/use-async-state";
import React, { FC, useLayoutEffect, useState } from "react";
import { HomeNote } from "types/models";
import NoteForm from "./components/AddNote";
import Note from "./components/Note";

import styles from "./styles.module.scss";

type Props = {
  homeID: string;
};

const Notes: FC<Props> = ({ homeID }) => {
  const [noteFormVisible, setNoteFormVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState<HomeNote | null>(null);

  const {
    data: homeNotes,
    inProgress: loadingHomeNotes,
    refresh: fetchHomeNotes,
  } = useAsyncState<HomeNote[]>([], () => getHomeNotes(homeID));

  useLayoutEffect(() => {
    fetchHomeNotes();
  }, []);

  return (
    <div className={styles.container}>
      <Button
        disabled={loadingHomeNotes}
        onClick={() => setNoteFormVisible(true)}
      >
        Add note
      </Button>
      <LoadingComponent className={styles.content} loading={loadingHomeNotes}>
        <NoteForm
          updateNotes={fetchHomeNotes}
          homeID={homeID}
          visible={noteFormVisible}
          note={selectedNote}
          handleClose={() => {
            setNoteFormVisible(false);
            setSelectedNote(null);
          }}
        />
        {homeNotes.map((note) => (
          <Note
            onEditNote={(noteID: string) => {
              const noteToEdit = homeNotes.find(({ uid }) => uid === noteID);
              if (noteToEdit) {
                setSelectedNote(noteToEdit);
                setNoteFormVisible(true);
              }
            }}
            updateHomeNotes={fetchHomeNotes}
            note={note}
          />
        ))}
      </LoadingComponent>
    </div>
  );
};

export default Notes;
