import React, { useEffect, useState, FC } from "react";
import { Select } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Flex, Heading } from "rebass";
import { useRecoilState, useRecoilValue } from "recoil";

import Panel from "components/Panel";
import SubSection from "components/SubSection";
import { JOB_ACTIONS } from "constants/site";
import { jobSelectedState, vendorsState, vendorState } from "recoil/atoms";
import { parameterize } from "utils/strings";
import { mapper } from "utils/mapping";

import { fetchVendor } from "api/vendors";
import { useTheme } from "theme/ThemeCreator";
import useAsyncAtom from "hooks/use-async-atom";
import useProjects from "hooks/use-projects";
import { isEmpty } from "utils/array";
import CreateInvoice from "./components/CreateInvoice";
import FinalizeInvoice from "./components/FinalizeInvoice";
import ScheduleAppt from "./components/ScheduleAppointment";

const JobAction: FC = () => {
  const history = useHistory();
  const loc = useLocation();
  const { projectID, jobID, jobAction } = useParams<{
    [key: string]: string;
  }>();
  const { selectedProject, selectedProjectJobs } = useProjects();
  const vendors = useRecoilValue(vendorsState);
  const [selectedJob, setSelectedJob] = useRecoilState(jobSelectedState);

  const [title, setTitle] = useState("");
  const {
    data: selectedVendor,
    refresh: getVendor,
    inProgress: gettingVendor,
  } = useAsyncAtom(vendorState, async () => {
    if (selectedJob) return fetchVendor(selectedJob.selected_vendor);
    return null;
  });
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
    switch (jobAction) {
      case parameterize(JOB_ACTIONS.CREATE_INVOICE):
        setTitle(JOB_ACTIONS.CREATE_INVOICE);
        break;
      case parameterize(JOB_ACTIONS.SCHEDULE_APPOINTMENT):
        setTitle(JOB_ACTIONS.SCHEDULE_APPOINTMENT);
        break;
      case parameterize(JOB_ACTIONS.FINALIZE_INVOICE):
        setTitle(JOB_ACTIONS.FINALIZE_INVOICE);
        break;
      default:
        setVisible(false);
    }
  }, [jobAction, selectedVendor, vendors]);

  useEffect(() => {
    if (selectedProjectJobs.length > 0) {
      const currJob = selectedProjectJobs.find((job) => job.uid === jobID);
      setSelectedJob(currJob || selectedProjectJobs[0]);
    }
  }, [selectedProjectJobs, jobID]);

  useEffect(() => {
    if (selectedJob && selectedJob.selected_vendor) getVendor();
  }, [selectedJob, vendors]);

  const handleClose = () => {
    setVisible(false);
    history.push(`/admin/projects/${projectID}/jobs/${jobID}${loc.search}`);
  };

  const { colors } = useTheme();

  const ActionRenderer = mapper(
    {
      [parameterize(JOB_ACTIONS.CREATE_INVOICE)]: () => (
        <CreateInvoice
          gettingVendor={gettingVendor}
          selectedVendor={selectedVendor}
        />
      ),
      [parameterize(JOB_ACTIONS.SCHEDULE_APPOINTMENT)]: () => (
        <ScheduleAppt selectedVendor={selectedVendor} />
      ),
      [parameterize(JOB_ACTIONS.FINALIZE_INVOICE)]: () => <FinalizeInvoice />,
    },
    null
  )(jobAction);

  return (
    <Panel
      initial
      position="right"
      size={850}
      visible={visible}
      onClose={handleClose}
      unmountOnCollapse
    >
      <Flex
        backgroundColor={colors.gray25}
        width="100%"
        flexDirection="column"
        padding={25}
      >
        <Heading style={{ marginBottom: 15 }}>{title}</Heading>
        <SubSection
          id="project-title"
          title="Project"
          flexDirection="column"
          marginTop={15}
        >
          {selectedProject?.name}
        </SubSection>
        <SubSection
          id="job-title"
          title="Job"
          flexDirection="column"
          marginTop={4}
        >
          {jobAction === parameterize(JOB_ACTIONS.FINALIZE_INVOICE) ? (
            selectedJob?.name
          ) : (
            <Select
              showSearch
              placeholder="The name of the job"
              optionFilterProp="children"
              defaultValue={selectedProjectJobs ? jobID : ""}
              filterOption
              onChange={(value) => {
                const jobSelected = selectedProjectJobs.find(
                  ({ uid }) => uid === value
                );
                setSelectedJob(jobSelected || null);
                history.push(
                  `/admin/projects/${projectID}/jobs/${jobSelected?.uid}/${jobAction}${loc.search}`
                );
              }}
            >
              {!isEmpty(selectedProjectJobs) &&
                selectedProjectJobs
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((job) => {
                    return (
                      <Select.Option key={job.uid} value={job.uid}>
                        {job.name}
                      </Select.Option>
                    );
                  })}
            </Select>
          )}
        </SubSection>
        <SubSection
          marginTop={15}
          title="Service Provider*"
          flexDirection="column"
        >
          {selectedVendor?.vendor_name ?? "Please assign a vendor"}
        </SubSection>
      </Flex>
      {selectedVendor && (
        <Flex width="100%" flexDirection="column" padding={25}>
          <ActionRenderer />
        </Flex>
      )}
    </Panel>
  );
};

export default JobAction;
