import React, { FC } from "react";

import Form from "components/Form";
import Button from "components/Button";
import { minLength, required } from "utils/form";
import Label from "components/Label";
import useAsync from "hooks/use-async";
import { createUser } from "api/auth";
import { UserRole } from "types/models";
import { useHistory } from "react-router-dom";
import { useUser } from "providers/UserProvider";
import { useSessionValue } from "providers/SessionProvider";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import { getError } from "utils/errors";

import styles from "./styles.module.scss";

const SignUp: FC = () => {
  const history = useHistory();
  const { setUser } = useUser();
  const { setSessionData } = useSessionValue();
  const notificationDispatcher = useNotificationDispatcher();

  const [submit, submitting] = useAsync(
    async (values: {
      email: string;
      password: string;
      first_name: string;
      last_name: string;
    }) => {
      const {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
      } = values;

      await createUser({
        email,
        password,
        name: `${firstName} ${lastName}`,
        role: UserRole.HomeOwner,
      })
        .then(async (resp) => {
          setUser(resp.user);
          setSessionData(resp);

          history.push("/");
        })
        .catch((requestError) => {
          notificationDispatcher({
            type: "error",
            description: getError(
              requestError.response.data,
              "Couldn't sign in"
            ),
            message: "Something went wrong!",
            duration: 10,
            key: "sign-up-error-notification",
          });
        });
    }
  );

  return (
    <Form
      className={styles.container}
      renderExternally
      name="sign-up"
      disabled={submitting}
      fields={[
        {
          id: "first_name",
          type: "text",
          validate: [required("Please enter your first name")],
          placeholder: "First name",
        },
        {
          id: "last_name",
          type: "text",
          validate: [required("Please enter your last name")],
          placeholder: "Last name",
        },
        {
          type: "text",
          id: "email",
          placeholder: "Email",
          validate: [required("Please enter your email")],
        },
        {
          type: "password",
          id: "password",
          placeholder: "Password",
          validate: [
            required("Please enter a new password"),
            minLength(8, "Passwords must contain at least 8 characters"),
          ],
        },
      ]}
      onSubmit={submit}
    >
      {({ handleSubmit, invalid, formToRender }) => (
        <div>
          <Label className={styles.title} type="centered-h5" color="primary">
            Sign up
          </Label>
          {formToRender}
          <Button
            className={styles.submit}
            disabled={invalid}
            onClick={handleSubmit}
            loading={submitting}
          >
            Sign up
          </Button>
        </div>
      )}
    </Form>
  );
};

export default SignUp;
