import React, { useEffect, FC } from "react";
import { useParams } from "react-router-dom";

import Button from "components/Button";
import { fetchCancellationReasons, updateProject } from "api/projects";
import { CancellationReason } from "types/models";
import { ARCHIVE_PROJECT_TYPE, ProjectStatus } from "constants/statuses";
import Modal from "components/Modal";
import useProjects from "hooks/use-projects";
import useAsync from "hooks/use-async";
import useAsyncState from "hooks/use-async-state";
import Label from "components/Label";
import { toast } from "react-hot-toast";
import Form from "components/Form";
import { required } from "utils/form";
import LoadingComponent from "components/LoadingComponent";

import styles from "./styles.module.scss";

type Props = {
  handleClose: () => void;
  type: string;
  visible: boolean;
};

interface FormValues {
  cancellation_reason: string;
}

const ArchiveProject: FC<Props> = ({ handleClose, type, visible }) => {
  const { projectID } = useParams<{ [key: string]: string }>();
  const { updateAllProjectsInformation } = useProjects();

  const {
    data: cancellationReasons,
    inProgress: fetchingCancellationReasons,
    refresh: getCancellationReasons,
  } = useAsyncState<CancellationReason[]>([], fetchCancellationReasons);

  useEffect(() => {
    getCancellationReasons();
  }, []);

  const [handleArchive, archiving] = useAsync(
    async ({ cancellation_reason }: FormValues) => {
      await updateProject(projectID, {
        cancellation_reason: Number(cancellation_reason),
        status: ProjectStatus.ARCHIVED,
      })
        .then(() => {
          updateAllProjectsInformation();
          toast.success("Canceled project");

          handleClose();
        })
        .catch(() =>
          toast.error("Project has already started -- could not be cancelled")
        );
    }
  );

  return (
    <Modal
      visible={visible}
      title={`${
        type === ARCHIVE_PROJECT_TYPE.CANCEL ? "Cancel" : "Decline"
      } Project`}
      handleClose={handleClose}
      contentClassName={styles.content}
    >
      <Label type="s1-negative-bold">
        Are you sure you want to update this project status to Archived? This
        action will close all active chats and actions with the customer related
        to this project.
      </Label>
      <LoadingComponent loading={fetchingCancellationReasons}>
        <Form
          className={styles.form}
          onSubmit={handleArchive}
          fields={[
            {
              required: true,
              validate: [required("Please indicate the cancellation reason")],
              type: "radio",
              id: "cancellation_reason",
              title: "Please select reason for cancelling project",
              options: cancellationReasons.map(({ id, reason }) => ({
                value: String(id),
                label: reason,
              })),
            },
          ]}
        >
          {({ handleSubmit, invalid }) => (
            <Button
              disabled={invalid}
              variant="negative"
              loading={archiving}
              onClick={handleSubmit}
            >
              {`${
                type === ARCHIVE_PROJECT_TYPE.CANCEL ? "Cancel" : "Decline"
              } Project`}
            </Button>
          )}
        </Form>
      </LoadingComponent>
    </Modal>
  );
};

export default ArchiveProject;
