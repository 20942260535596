import { fetchAction, fetchActions } from "api/actions";
import useAppFilters from "components/GlobalFilters/hooks/use-app-filters";
import useAsyncAtom from "hooks/use-async-atom";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Action } from "types/models";
import { updateStickyFilters } from "utils/projects";

import {
  actionsState,
  actionsPaginationState,
  selectedActionState,
} from "./atoms";
import { defaultRequestParams } from "./constants";

const useActions = () => {
  const [pagination, setPagination] = useRecoilState(actionsPaginationState);

  const { market } = useAppFilters();

  const { search } = useLocation();

  const {
    data: actions,
    inProgress: loadingActions,
    refresh: updateActions,
    overrideState: setActions,
  } = useAsyncAtom(actionsState, async (externalSearch?: string) =>
    fetchActions(
      {
        ...defaultRequestParams,
        market: market ? String(market) : "",
      },
      updateStickyFilters(externalSearch ?? search)
    )
      .then(({ results, count, previous, next }) => {
        const currentPage = new URLSearchParams(search).get("page");

        setPagination({
          numOfItems: count,
          prevLink: previous || null,
          nextLink: next || null,
          currentPage: Number(currentPage) || pagination.currentPage,
        });

        return results;
      })
      .catch(() => [])
  );

  const {
    data: selectedAction,
    refresh: updateActionDetail,
    inProgress: loadingActionDetail,
    overrideState: setSelectedAction,
  } = useAsyncAtom(
    selectedActionState,
    async (actionUID?: string): Promise<Action | null> => {
      const response = await fetchAction(
        actionUID ?? selectedAction?.uid ?? ""
      );
      if (response) return response;
      return null;
    }
  );

  const resetActions = () => setActions([]);

  const resetAction = () => setSelectedAction(null);

  return {
    actions,
    pagination,
    selectedAction,

    loadingActions,
    loadingActionDetail,

    updateActions,
    updateActionDetail,

    resetActions,
    resetAction,
  };
};

export default useActions;
