import React, { useEffect, FC } from "react";
import { useSetRecoilState } from "recoil";

import { pageTitleState } from "recoil/atoms";
import Content from "components/Content";
import { useSidebarInteraction } from "components/Sidebar";
import ActiveProjects from "./components/ActiveProjects";

const Projects: FC = () => {
  const setPageTitle = useSetRecoilState(pageTitleState);
  const { showSidebar } = useSidebarInteraction();

  useEffect(() => {
    showSidebar();
  }, []);

  useEffect(() => {
    setPageTitle("Active Projects");
  }, []);

  return (
    <Content withSidebar>
      <ActiveProjects />
    </Content>
  );
};

export default Projects;
