import { fetchHomeDetail } from "api/homes";
import useAsyncAtom from "hooks/use-async-atom";
import { selectedHomeState } from "./atoms";

const useHomes = () => {
  const {
    data: homeDetail,
    inProgress: loadingHomeDetail,
    refresh: updateHomeDetail,
    overrideState: setSelectedHome,
  } = useAsyncAtom(selectedHomeState, (homeID: string) =>
    fetchHomeDetail(homeID, {})
  );

  return {
    homeDetail,

    updateHomeDetail,

    loadingHomeDetail,

    clearHomeDetail: () => setSelectedHome(null),
  };
};

export default useHomes;
