import { atom } from "recoil";

import { Appliance, ApplianceModel, Home, Room } from "types/models";

export const homeSelectedState = atom<Home | null>({
  key: "homeSelectedState",
  default: null,
});

export const applianceModelsState = atom<ApplianceModel[]>({
  key: "applianceModels",
  default: [],
});

export const selectedHomeFilter = atom<string | null | undefined>({
  key: "selectedHomeFilter",
  default: null,
});

export const homesState = atom<Home[]>({
  key: "homesState",
  default: [],
});

export const homesList = atom<Home[]>({
  key: "homesList",
  default: [],
});

export const homesLoadingState = atom<boolean>({
  key: "homesLoadingState",
  default: true,
});

export const applianceListState = atom<Appliance[]>({
  key: "applianceListState",
  default: [],
});

export const roomListState = atom<Room[] | null>({
  key: "roomListState",
  default: null,
});
