import React, { useState, FC } from "react";
import { Button, Form, Input, message } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { Flex, Heading, Text } from "rebass";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { updateUser } from "api/users";
import { addBankAccount } from "api/vendors";
import { useUser } from "providers/UserProvider";
import { vendorUser } from "recoil/vendors";
import Background from "svg/BackgroundEllipse";
import theme from "theme/theme.main";
import { toast } from "react-hot-toast";

const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 0;
  background-color: white;
`;

type Props = {
  nextStep(): void;
};

const BankInfo: FC<Props> = ({ nextStep }: Props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { user, setUser } = useUser();
  const vendor = useRecoilValue(vendorUser);
  const [loading, setLoading] = useState(false);
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [routingNumber, setRoutingNumber] = useState<string>("");

  const completeUserOnboarding = () => {
    if (!(user && user.uid)) return;

    updateUser({ has_onboarded: true }, user.uid).then((respUser) => {
      setUser(respUser);
    });
  };

  const handleSkipStep = () => {
    completeUserOnboarding();
    if (user?.accepted_terms_of_use) history.push("/vendor/");
    else nextStep();
  };

  const addPaymentInfo = () => {
    if (!vendor || !vendor.bill_vendor_id || !vendor.uid) {
      toast.error(
        "Please compete the Bill.com account creation process through the invitation that was sent to your email"
      );
      return;
    }
    if (!accountNumber || !routingNumber) {
      message.error("Please fill in all fields");
      return;
    }
    if (vendor?.bank_account) {
      history.push("/vendor/success/");
      return;
    }

    setLoading(true);
    addBankAccount(vendor.uid, accountNumber, routingNumber)
      .then(() => {
        completeUserOnboarding();
        history.push("/vendor/success/");
      })
      .catch(() => {
        message.error("Could not add bank account");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Heading
        fontSize="32px"
        lineHeight="1.3"
        color={theme.colors.success}
        fontWeight={500}
        marginTop={6}
      >
        Step 4 of 5
      </Heading>
      <Text fontSize="16px" color="primary05" mb={3}>
        Please add your bank information
      </Text>
      <Flex
        margin={["0 auto", "0 auto", "24px auto 0"]}
        backgroundColor="white"
        padding={[4, 5]}
        style={{
          borderRadius: "8px",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        }}
        flexDirection="column"
        width={["85%", "65%"]}
        maxWidth="500px"
      >
        <Text fontSize="16px" color="chamomile05" mb={3}>
          Please input banking info to set up ACH payments.
        </Text>
        <Form
          form={form}
          initialValues={{
            account_number: vendor?.bank_account?.account_number,
            routing_number: vendor?.bank_account?.routing_number,
          }}
        >
          <Flex flexDirection="column">
            <Form.Item style={{ marginBottom: 16 }}>
              <Input
                type="text"
                name="account_number"
                bordered={false}
                placeholder="Account Number"
                onChange={(event) => {
                  const { value } = event.target;
                  form.setFieldsValue({ account_number: value });
                  setAccountNumber(value);
                }}
                style={{
                  padding: "16px",
                  height: "40px",
                  borderBottom: "1px solid rgba(108, 114, 128, 0.2)",
                }}
              />
            </Form.Item>
            <Form.Item>
              <Input
                type="text"
                name="routing_number"
                bordered={false}
                placeholder="Routing Number"
                onChange={(event) => {
                  const { value } = event.target;
                  form.setFieldsValue({ routing_number: value });
                  setRoutingNumber(value);
                }}
                style={{
                  padding: "16px",
                  height: "40px",
                  borderBottom: "1px solid rgba(108, 114, 128, 0.2)",
                }}
              />
            </Form.Item>

            <Button
              style={{ alignSelf: "center" }}
              loading={loading}
              onClick={addPaymentInfo}
            >
              {vendor?.bank_account
                ? "Confirm Banking Info"
                : "Add Payment Info"}
            </Button>
            <Button
              style={{ alignSelf: "center", marginTop: "0.5rem" }}
              loading={loading}
              onClick={handleSkipStep}
              type="link"
            >
              <Text css={{ textDecoration: "underline" }}>
                Skip to Dashboard
              </Text>
            </Button>
          </Flex>
        </Form>
      </Flex>
    </>
  );
};

const BankInfoPage: FC<Props> = ({ nextStep }: Props) => {
  const loc = useLocation();

  if (loc.pathname.includes("/vendor/onboaring/add-bank-info")) {
    return (
      <>
        <BackgroundContainer>
          <Background />
        </BackgroundContainer>
        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          style={{ zIndex: 1 }}
        >
          <BankInfo nextStep={nextStep} />
        </Flex>
      </>
    );
  }

  return <BankInfo nextStep={nextStep} />;
};

export default BankInfoPage;
