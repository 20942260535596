/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { FaUserCircle as UserIcon } from "react-icons/fa";
import { BiMenuAltLeft as MenuIcon } from "react-icons/bi";

import { useSessionValue } from "providers/SessionProvider";
import { useUser } from "providers/UserProvider";
import Label from "components/Label";

import Button from "components/Button";
import { useSidebarInteraction, useToggleSidebar } from "components/Sidebar";
import useScreenSize from "hooks/use-screen-size";
import styles from "./styles.module.scss";

const Navigation: FC = () => {
  const toggleSidebar = useToggleSidebar();
  const { unmounted } = useSidebarInteraction();
  const { isResponsive } = useScreenSize();

  const loc = useLocation();
  const { setUser, user } = useUser();
  const { setSessionData } = useSessionValue();
  const history = useHistory();
  const logOutUser = () => {
    setSessionData(null);
    setUser(null);
    history.push("/auth/login");
  };

  const overlay = (
    <Menu
      className={styles.menu}
      mode="horizontal"
      defaultSelectedKeys={[loc.pathname]}
    >
      <Menu.Item
        className={styles.menuItem}
        onClick={logOutUser}
        key="logout"
        style={{ height: "30px" }}
      >
        <Label color="primary">Logout</Label>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.container}>
      <Dropdown
        className={styles.dropdown}
        placement="bottomRight"
        overlay={overlay}
      >
        <Avatar
          className={styles.avatar}
          src={user?.profile_image || user?.avatar}
          size={30}
          icon={
            user?.profile_image ? null : (
              <UserIcon className={styles.userIcon} />
            )
          }
        />
      </Dropdown>
      {isResponsive && !unmounted && (
        <Button
          variant="icon"
          onClick={toggleSidebar}
          Icon={<MenuIcon className={styles.menuIcon} />}
        />
      )}
    </div>
  );
};

export default Navigation;
