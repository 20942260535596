import { Step } from "components/Flow/types";

import AddressInfo from "./views/AddressInfo";
import BankInfo from "./views/BankInfo";
import ContactInfo from "./views/ContactInfo";
import Terms from "../../../../components/Terms";
import Confirmation from "./views/Confirmation";

export const getSteps = (addPaymentMethod: boolean): Step[] => [
  ...(addPaymentMethod
    ? []
    : [
        {
          Component: AddressInfo,
        },
        {
          Component: ContactInfo,
        },
        {
          Component: Confirmation,
        },
      ]),
  {
    Component: BankInfo,
  },
  {
    Component: Terms,
  },
];
