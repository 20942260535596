import { AxiosError } from "axios";
import { RcFile } from "antd/lib/upload/interface";

import { Attachment } from "types/models";

import { AttachmentResponse } from "types/responses";
import { apiClient, ServerError } from ".";

const createAttachment = async (
  file: RcFile,
  homeID: string,
  projectID?: string,
  home_attachment?: boolean
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("home", homeID);
    formData.append("name", file.name);
    if (projectID) formData.append("project", projectID);
    if (home_attachment)
      formData.append("home_attachment", `${home_attachment}`);

    const response = await apiClient.post<Attachment>(
      "/attachments/",
      formData
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const addAttachmentsToProject = async (
  projectID: string,
  attachmentIDs: string[]
) => {
  try {
    const payload = {
      attachment_uids: attachmentIDs,
    };

    const response = await apiClient.post<Attachment>(
      `/projects/${projectID}/attach_files/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const removeAttachmentsFromProject = async (
  projectID: string,
  attachmentIDs: string[]
) => {
  try {
    const payload = {
      attachment_uids: attachmentIDs,
    };

    const response = await apiClient.post<Attachment>(
      `/projects/${projectID}/detach_files/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const addAttachmentsToJob = async (jobID: string, attachmentIDs: string[]) => {
  try {
    const payload = {
      attachment_uids: attachmentIDs,
    };

    const response = await apiClient.post<Attachment>(
      `/jobs/${jobID}/attach_files/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const removeAttachmentsFromJob = async (
  jobID: string,
  attachmentIDs: string[]
) => {
  try {
    const payload = {
      attachment_uids: attachmentIDs,
    };

    const response = await apiClient.post<Attachment>(
      `/jobs/${jobID}/detach_files/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const addAttachmentsToAppliance = async (
  applianceID: string,
  attachmentIDs: string[]
) => {
  try {
    const payload = {
      attachment_uids: attachmentIDs,
    };

    const response = await apiClient.post<Attachment>(
      `/appliances/${applianceID}/attach_files/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const removeAttachmentsFromAppliance = async (
  applianceID: string,
  attachmentIDs: string[]
) => {
  try {
    const payload = {
      attachment_uids: attachmentIDs,
    };

    const response = await apiClient.post<Attachment>(
      `/appliances/${applianceID}/detach_files/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const addAttachmentsToEstimate = async (
  estimateID: string,
  attachmentIDs: string[]
) => {
  try {
    const payload = {
      attachment_uids: attachmentIDs,
    };

    const response = await apiClient.post<Attachment>(
      `/jobs/service_estimates/${estimateID}/attach_files/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const removeAttachmentsFromEstimate = async (
  estimateID: string,
  attachmentIDs: string[]
) => {
  try {
    const payload = {
      attachment_uids: attachmentIDs,
    };
    const response = await apiClient.post<AttachmentResponse>(
      `/jobs/service_estimates/${estimateID}/detach_files/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export {
  createAttachment,
  addAttachmentsToProject,
  removeAttachmentsFromProject,
  addAttachmentsToJob,
  removeAttachmentsFromJob,
  addAttachmentsToAppliance,
  removeAttachmentsFromAppliance,
  addAttachmentsToEstimate,
  removeAttachmentsFromEstimate,
};
