import React, { FC } from "react";
import { motion } from "framer-motion";

import styles from "./styles.module.scss";

type Props = {
  count: number;
};

const NotificationsBadge: FC<Props> = ({ count }) => {
  const variants = {
    collapsed: {
      marginLeft: 5,
    },
    expanded: {
      marginLeft: 15,
    },
  };

  return (
    <motion.div variants={variants} className={styles.container}>
      {count}
    </motion.div>
  );
};

export default NotificationsBadge;
