import { adminUpdateUser, createAdmin } from "api/users";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import LoadingComponent from "components/LoadingComponent";
import Modal from "components/Modal";
import Tabs from "components/Tabs";
import { USER_ROLE_LABELS } from "constants/roles";
import useAsync from "hooks/use-async";
import React, { FC, useState } from "react";
import toast from "react-hot-toast";
import { User, UserRole } from "types/models";
import { minLength, required, sameInput, validEmail } from "utils/form";
import { purgeObject } from "utils/object";

import { targetRoles } from "../../constants";

import styles from "./styles.module.scss";

interface Props {
  visible: boolean;
  handleClose(): void;
  user: User | null;
  onModalClosed(): void;
  updateData(): void;
  loading: boolean;
}

interface FormValues {
  profile_image?: File[];
  bio?: string;
  active?: boolean;
  first_name?: string;
  last_name?: string;
  email?: string;
  role?: UserRole.Admin | UserRole.HomeCoordinator | UserRole.HomeExpert;
  hometown?: string;
}

const CreateOrEditUser: FC<Props> = ({
  visible,
  handleClose,
  user,
  onModalClosed,
  updateData,
  loading,
}) => {
  const isEditing = !!user;

  const [currentTab, setCurrentTab] = useState("edit");

  const [submit, submitting] = useAsync(
    async ({ profile_image, first_name, last_name, ...rest }: FormValues) => {
      if (isEditing)
        await adminUpdateUser(
          purgeObject({
            profile_image: profile_image?.[0],
            name: `${first_name} ${last_name}`,
            ...rest,
          }),
          user.uid
        ).then(() => {
          toast.success("User updated successfully");
          updateData();
        });
      else {
        await createAdmin({
          profile_image: profile_image?.[0],
          name: `${first_name} ${last_name}`,
          ...rest,
          username: rest.email,
          home_manager_profile: { hometown: rest.hometown },
        }).then(() => {
          toast.success("Successfully created user");
          updateData();
          handleClose();
        });
      }
    }
  );

  const nameSplit = user?.name.split(" ") || [];
  const firstName = nameSplit[0];
  const lastName = nameSplit?.slice(1, nameSplit.length).join(" ");

  return (
    <Modal
      onModalClosed={onModalClosed}
      title={isEditing ? `Editing: ${user.name}` : "New user"}
      visible={visible}
      handleClose={handleClose}
      contentClassName={styles.content}
    >
      {isEditing && (
        <Tabs
          currentValue={currentTab}
          tabs={[
            { name: "Edit", value: "edit" },
            { name: "Current Preview", value: "preview" },
          ]}
          onChange={setCurrentTab}
        />
      )}
      <LoadingComponent className={styles.loadingWrapper} loading={loading}>
        {currentTab === "edit" && (
          <Form
            initialValues={purgeObject({
              bio: user?.bio,
              profile_image: [],
              role: user?.role,
              first_name: firstName,
              last_name: lastName,
              is_active: isEditing ? user?.is_active : true,
            })}
            className={styles.form}
            onSubmit={submit}
            fields={[
              {
                title: "Email",
                id: "email",
                type: "text",
                validate: [
                  required("Email is required"),
                  validEmail("Please enter a valid email"),
                ],
                hide: isEditing,
              },
              {
                title: "Password",
                type: "password",
                id: "password",
                validate: [
                  required("This field is required."),
                  minLength(8, "Passwords must contain at least 8 characters"),
                ],
                hide: isEditing,
              },
              {
                type: "password",
                title: "Confirm Password",
                id: "confirm_password",
                validate: [required("This field is required.")],
                dependencies: [
                  {
                    id: "password",
                    validate: [sameInput("Passwords must match")],
                  },
                ],
                hide: isEditing,
              },
              {
                title: "First name",
                id: "first_name",
                type: "text",
                validate: [required("First name is required")],
              },
              {
                title: "Last name",
                id: "last_name",
                type: "text",
                validate: [required("Last name is required")],
              },
              {
                title: isEditing ? "New role" : "Role",
                id: "role",
                type: "select",
                validate: [required("Role is required")],
                options: targetRoles.map((role) => ({
                  value: role,
                  label: USER_ROLE_LABELS[role],
                })),
              },
              {
                title: "Bio",
                type: "area",
                id: "bio",
                validate: [required("Bio is required")],
                help: "Suggested length for bio is 150/250 characters",
                props: { withCount: true },
              },
              {
                id: "is_active",
                placeholder: "Active",
                type: "checkbox",
                disabled: !isEditing,
                help: "When deactivating a user they will no longer have permissions to work on admin",
              },
              {
                title: isEditing ? "Update profile picture" : "Profile picture",
                type: "attachments",
                id: "profile_image",
              },
            ]}
          >
            {({ handleSubmit, hasChanged, invalid }) => (
              <Button
                disabled={!hasChanged || invalid}
                className={styles.submit}
                loading={submitting}
                onClick={handleSubmit}
              >
                Save
              </Button>
            )}
          </Form>
        )}
        {currentTab === "preview" && isEditing && (
          <div className={styles.previewWrapper}>
            <img
              className={styles.previewImage}
              src={user.profile_image}
              alt=""
            />
            <div className={styles.previewInfo}>
              <Label color="primary" type="h4">
                {user.name}
              </Label>
              <Label color="primary" className={styles.role}>
                {USER_ROLE_LABELS[user.role]}
              </Label>
              <Label className={styles.bio} color="primary">
                {user.bio}
              </Label>
            </div>
          </div>
        )}
      </LoadingComponent>
    </Modal>
  );
};

export default CreateOrEditUser;
