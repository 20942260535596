/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import styled from "styled-components";
import { Flex, Text } from "rebass";
import theme from "theme/theme.main";
import { AiFillCreditCard as CreditCardIcon } from "react-icons/ai";

import styles from "./styles.module.scss";

const Container = styled(Flex)`
  cursor: pointer;
  border: 1px solid ${theme.colors.primary05};
  transition: opacity 0.5s ease-in-out;
  &:hover {
    opacity: 75%;
  }
`;

interface Props {
  style: any;
  onClick(): void;
}

function AddCreditCardButton({ onClick, style }: Props) {
  return (
    <Container
      backgroundColor="white"
      width="100%"
      maxWidth="350px"
      flexDirection="row"
      alignItems="center"
      justifyContent="start"
      padding="19px 23.5px"
      onClick={onClick}
      {...style}
    >
      <CreditCardIcon className={styles.icon} />
      <Flex
        paddingLeft="21.5px"
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
      >
        <Text fontSize={2} pb="-2px">
          Credit/Debit Card
        </Text>
        <Text fontSize={[0, 1, 1]} color="chamomile06">
          Add Debit or Credit Card
        </Text>
      </Flex>
    </Container>
  );
}

export default AddCreditCardButton;
