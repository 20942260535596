import React from "react";
import theme from "theme/theme.main";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={theme.colors.primary05}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.5 23.25h21M1.5 20.25h21M3 9.75v7.5M6 9.75v7.5M10.5 9.75v7.5M13.5 9.75v7.5M18 9.75v7.5M21 9.75v7.5M22.5 6.75h-21L11.189.988a1.5 1.5 0 011.622 0L22.5 6.75z"
      />
    </svg>
  );
}

export default Icon;
