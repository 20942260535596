import { mapper } from "utils/mapping";

export const tagVariants = mapper(
  {
    inactive: {
      label: "Inactive",
      labelProps: { type: "negative" },
    },
    active: {
      label: "Active",
      labelProps: { type: "positive" },
    },
  },
  {}
);
