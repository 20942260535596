import Flow from "components/Flow";
import React, { FC } from "react";

import { steps } from "./constants";
import styles from "./styles.module.scss";

const GetStarted: FC = () => (
  <Flow
    className={styles.flow}
    withoutNextStepButton
    withoutPreviousStepButton
    steps={steps}
  />
);
export default GetStarted;
