/* eslint-disable @typescript-eslint/no-explicit-any */
import { Value } from "components/FormConstructor/types";
import { match, __ } from "ts-pattern";
import { isEmpty } from "./array";
import { mapper } from "./mapping";

export const isDefined = (value: Value): boolean => value !== undefined;

export const hasPostalCode =
  (error: string) =>
  (place: Value): string | false => {
    return (
      match(place)
        .with(undefined, () => error)
        .with(
          {
            address_components: [{ types: [__.string], long_name: __.string }],
          },
          (value) => {
            const postalCode =
              value.address_components.find(({ types }) =>
                types.includes("postal_code")
              )?.long_name ?? "";

            return postalCode ? false : error;
          }
        )
        .run() ?? error
    );
  };

export const required =
  (error: string) =>
  (value: Value): string | false =>
    (!value || (Array.isArray(value) && isEmpty(value))) &&
    value !== false &&
    value !== 0
      ? error
      : false;

export const validEmail =
  (error: string) =>
  (value: Value): string | false =>
    typeof value === "string" &&
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
      ? false
      : error;

export const minLength =
  (length: number, error: string) =>
  (value: Value): string | false =>
    typeof value === "string" && value.length >= length ? false : error;

export const maxLength =
  (length: number, error: string) =>
  (value: Value): string | false =>
    typeof value === "string" && value.length > length ? error : false;

export const onlyNumbers = (value: string): string => value.replace(/\D/g, "");

export const onlyLetters = (value: string): string =>
  value.replace(/[^a-zA-Z]/g, "");

export const phoneNumberInput = (value: string): string =>
  value.replace(/[^0-9+()-.]/g, "");

export const validateWebsite =
  (error: string) =>
  (value: Value): string | false =>
    typeof value === "string" &&
    /[w]{0,3}[.]{0,1}[\w\d]+\.[a-zA-Z]{3}/.test(value)
      ? false
      : error;

export const validatePhoneNumber =
  (error: string) =>
  (value: Value): string | false =>
    typeof value === "string" &&
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
      value
    )
      ? false
      : error;

export const nonZeroFloat =
  (error: string) =>
  (value: Value): string | false =>
    typeof value === "string" && parseFloat(value) !== 0 ? false : error;

export const maxFloat =
  (error: string, max: number) =>
  (value: Value): string | false =>
    typeof value === "string" && parseFloat(value) <= max ? false : error;

export const minFloat =
  (error: string, min: number) =>
  (value: Value): string | false =>
    typeof value === "string" && parseFloat(value) >= min ? false : error;

const stringCompare = (valueA: string, valueB: string): boolean =>
  !valueA.localeCompare(valueB);

const equals = mapper(
  {
    string: stringCompare,
  },
  stringCompare
);

export const sameInput =
  (error: string) =>
  (valueA: Value, valueB: Value): string | false =>
    valueA && valueB && equals(typeof valueA)(valueA, valueB) ? false : error;
