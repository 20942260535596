import React, { FC } from "react";

import Form from "components/Form";
import Modal from "components/Modal";
import { ApplianceModel } from "types/models";
import { required } from "utils/form";
import LoadingComponent from "components/LoadingComponent";
import useAsync from "hooks/use-async";
import { createModelAppliance } from "api/homes";

import Button from "components/Button";
import styles from "./styles.module.scss";

type Props = {
  onClose(): void;
  visible: boolean;
  refreshModels(): void;
};

const NewModel: FC<Props> = ({ onClose, visible, refreshModels }) => {
  const [submit, submitting] = useAsync(async (values: ApplianceModel) => {
    await createModelAppliance(values);

    refreshModels();
    onClose();
  });

  return visible ? (
    <Form
      onSubmit={submit}
      name="new-appliance-model-form"
      renderExternally
      fields={[
        {
          id: "manufacturer",
          type: "text",
          title: "Manufacturer",
          placeholder: "Manufacturer's name",
          validate: [required("This field is required.")],
        },
        {
          id: "model_id",
          type: "text",
          title: "Model Number",
          placeholder: "Model Number",
          validate: [required("This field is required.")],
        },
        {
          id: "label",
          type: "text",
          title: "Appliance's label",
          placeholder: "Please label the appliance",
          validate: [required("This field is required.")],
        },
        {
          id: "documentation_url",
          type: "text",
          title: "Documentation's URL",
          placeholder: "Please enter the documentation's URL",
        },
      ]}
    >
      {({ formToRender, handleSubmit, invalid }) => (
        <Modal
          visible={visible}
          title="Add an Appliance Model"
          handleClose={onClose}
        >
          <LoadingComponent loading={submitting} className={styles.form}>
            {formToRender}
          </LoadingComponent>
          <Button
            className={styles.submit}
            disabled={invalid}
            onClick={handleSubmit}
          >
            Add model
          </Button>
        </Modal>
      )}
    </Form>
  ) : null;
};

export default NewModel;
