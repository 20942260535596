import React, { FC } from "react";

import { Project } from "types/models";

import Collapsable from "components/Collapsable";
import ProjectCollapseDetail from "./components/ProjectCollapseDetail";
import Header from "./components/Header";

import styles from "./styles.module.scss";

type Props = {
  projects: Project[];
};

const ProjectColumn: FC<Props> = ({ projects }: Props) => {
  return (
    <>
      {projects.map((project) => (
        <Collapsable
          withIcon
          className={styles.item}
          header={<Header project={project} />}
          key={project.uid}
        >
          <ProjectCollapseDetail project={project} />
        </Collapsable>
      ))}
    </>
  );
};

export default ProjectColumn;
