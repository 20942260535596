import { atom } from "recoil";
import { PaginationInfo } from "types/client";
import { Action } from "types/models";

export const actionsState = atom<Action[]>({
  key: "hook-actionsState",
  default: [],
});

export const selectedActionState = atom<Action | null>({
  key: "hook-selectedActionState",
  default: null,
});

export const actionsPaginationState = atom<PaginationInfo>({
  key: "hook-actionsPaginationState",
  default: {
    prevLink: null,
    nextLink: null,
    numOfItems: 0,
    currentPage: 1,
  },
});
