import React, { FC } from "react";
import moment from "moment";

import { pluralize } from "utils/strings";
import Label from "components/Label";

type Props = {
  dueDate: Date;
  invoiceCountdown: number;
};

const InvoiceDate: FC<Props> = ({ dueDate, invoiceCountdown }) => {
  return dueDate ? (
    invoiceCountdown < 0 ? (
      <Label>{`Past Due ${moment(dueDate).format("MM/DD/YYYY")}`}</Label>
    ) : (
      <Label>
        {`${pluralize(Math.ceil(invoiceCountdown), "day")} (${moment(
          dueDate
        ).format("MM/DD/YYYY")})`}
      </Label>
    )
  ) : (
    <Label>Invalid Due Date</Label>
  );
};

export default InvoiceDate;
