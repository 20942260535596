import React, { FC } from "react";

import { BadgeOptions } from "constants/statuses";
import { CustomTag } from "elements/text";
import Label from "components/Label";

type Props = {
  status: string;
  options: BadgeOptions[];
  className?: string;
  overrideLabel?: (display: string) => string;
};

const CustomBadge: FC<Props> = ({
  status,
  options,
  className,
  overrideLabel,
}) => {
  const option = options.find(({ value }) => String(value) === status);
  if (option) {
    return (
      <CustomTag
        className={className}
        style={{
          padding: "5px 15px",
          alignItems: "center",
          display: "flex",
          color: option.color,
          height: 25,
          marginRight: 0,
          width: "fit-content",
          border: 0,
          borderRadius: 5,
          backgroundColor: `${option.color}30`,
        }}
        color={option.color}
      >
        {overrideLabel?.(option.display) || option.display}
      </CustomTag>
    );
  }
  return <Label>{status}</Label>;
};

CustomBadge.defaultProps = {
  className: undefined,
  overrideLabel: undefined,
};

export default CustomBadge;
