import { fetchUsers } from "api/users";
import Label from "components/Label";
import useAsyncState from "hooks/use-async-state";
import React, { FC, useCallback, useEffect, useState } from "react";
import { MdOutlineEdit as EditIcon } from "react-icons/md";

import { User } from "types/models";

import Table from "components/Table";
import { useLocation } from "react-router-dom";
import Button from "components/Button";
import { debounce } from "lodash";
import { purgeObject } from "utils/object";
import styles from "./styles.module.scss";
import { createColumns, createData, RowData, targetRoles } from "./constants";
import BatchEdit from "./components/BatchEdit";
import CreateOrEditUser from "./components/CreateOrEditUser";

const UserManagement: FC = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState<RowData[]>([]);
  const [updatingUsersRole, setUpdatingUsersRole] = useState(false);

  const [editingUser, setEditingUser] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const {
    data: users,
    inProgress: fetchingUsers,
    refresh: refreshUsers,
  } = useAsyncState(
    [],
    (params) =>
      fetchUsers(params).then(({ results, count }) => {
        setTotalUsers(count);
        return results;
      }),
    { defaultLoading: true }
  );

  const updateOnSearch = useCallback(
    debounce((params: string) => {
      const paramsObj = new URLSearchParams(params);

      refreshUsers(
        purgeObject({
          search: paramsObj.get("search") || "",
          page: paramsObj.get("page") || "1",
          page_size: "10",
          role: paramsObj.get("role") || targetRoles.join(","),
        })
      );
    }, 300),
    []
  );

  useEffect(() => {
    refreshUsers();
  }, []);

  useEffect(() => {
    updateOnSearch(search);
  }, [search]);

  useEffect(() => {
    if (userToEdit) {
      const user = users.find(({ uid: userId }) => userId === userToEdit.uid);
      if (user) setUserToEdit(user);
    }
  }, [users]);

  return (
    <>
      <CreateOrEditUser
        loading={fetchingUsers}
        updateData={refreshUsers}
        onModalClosed={() => setUserToEdit(null)}
        visible={editingUser}
        user={userToEdit}
        handleClose={() => setEditingUser(false)}
      />
      <BatchEdit
        updateData={refreshUsers}
        visible={updatingUsersRole}
        handleClose={() => setUpdatingUsersRole(false)}
        usersToUpdate={selectedUsers.map(({ role, ...rest }) => ({
          ...rest,
          role,
        }))}
      />
      <div className={styles.tableWrapper}>
        <Table
          searchPrompt="Search by name:"
          withSearch
          onRowClick={({ original: { id } }) => {
            const user = users.find(({ uid: userId }) => userId === id);

            if (user) {
              setUserToEdit(user);
              setEditingUser(true);
            }
          }}
          headerActions={
            <div className={styles.actions}>
              <Button onClick={() => setEditingUser(true)}>Create User</Button>
              <Button
                disabled={selectedUsers.length === 0}
                onClick={() => setUpdatingUsersRole(true)}
              >
                Batch Update
              </Button>
            </div>
          }
          reloadData={refreshUsers}
          pagination={{
            count: totalUsers,
            page: Number(searchParams.get("page")) || 1,
            pageSize: 10,
          }}
          loading={fetchingUsers}
          data={createData(users)}
          columns={createColumns()}
          selectable
          setSelectedRows={setSelectedUsers}
          selectedRows={selectedUsers}
          rowClickDescription={
            <div className={styles.onClickDescription}>
              <EditIcon />
              <Label>Edit</Label>
            </div>
          }
        />
      </div>
    </>
  );
};

export default UserManagement;
