import React, { useState, FC } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, message } from "antd";
import { createNote } from "api/notes";

import Button from "components/Button";
import useProjects from "hooks/use-projects";
import useAsync from "hooks/use-async";
import styles from "./styles.module.scss";

const NewNote: FC = () => {
  const [form] = Form.useForm();
  const [noteText, setNote] = useState("");
  const { projectID } = useParams<{ [key: string]: string }>();
  const { selectedProject, updateProjectNotes } = useProjects();

  const [handleCreateNote, creatingNote] = useAsync(async () => {
    if (!noteText) {
      message.error("Please add a note");
      return;
    }
    if (!selectedProject) {
      message.error("Note is not attached to project");
      return;
    }
    await createNote(projectID, noteText)
      .then(async () => {
        message.success("Created note");
        updateProjectNotes(projectID);
        form.resetFields();
        setNote("");
      })
      .catch(() => {
        message.error("Could not create note");
      });
  });

  return (
    <Form form={form} className={styles.container}>
      <Form.Item name="note" className={styles.textAreaContainer}>
        <Input.TextArea
          name="note"
          autoSize={{ minRows: 3, maxRows: 6 }}
          className={styles.textArea}
          placeholder="Add new note"
          allowClear
          onChange={(event) => setNote(event.target.value)}
        />
      </Form.Item>
      <div className={styles.createNoteContainer}>
        <Button
          disabled={!noteText}
          loading={creatingNote}
          className={styles.createNote}
          onClick={handleCreateNote}
        >
          Create Note
        </Button>
      </div>
    </Form>
  );
};
export default NewNote;
