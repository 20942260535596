import React, { FC } from "react";
import moment from "moment";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { CgClose as CloseIcon } from "react-icons/cg";

import { fetchPayableInvoice } from "api/invoices";
import { fetchHomePaymentMethods } from "api/payments";
import InvoiceStatusTag from "components/InvoiceStatusTag";
import { Invoice, InvoiceStatus, Source } from "types/models";
import { currencyFormatter } from "utils/currency";
import Panel from "components/Panel";
import useScreenSize from "hooks/use-screen-size";
import toast from "react-hot-toast";
import { getError } from "utils/errors";
import AsyncInject from "components/AsyncInject";
import { AxiosError } from "axios";
import Label from "components/Label";

import Button from "components/Button";
import { mapper } from "utils/mapping";
import { fetchHomeDetail } from "api/homes";
import { useUser } from "providers/UserProvider";
import { SourceStatus } from "constants/payments";
import styles from "./styles.module.scss";
import ConfirmAndPay from "./components/ConfirmAndPay";

interface Props {
  onPay(): void;
}

const InvoiceDetail: FC<Props> = ({ onPay }) => {
  const { user } = useUser();
  const { homeID, invoiceID } = useParams<{
    invoiceID: string;
    homeID: string;
  }>();
  const loc = useLocation();
  const history = useHistory();

  const { isResponsive } = useScreenSize();

  const closeDrawer = () => {
    history.push(`/portal/invoices${loc.search && `${loc.search}`}`);
  };

  const statusActions = (
    invoice: Invoice,
    paymentMethods: Source[],
    refresh: () => void,
    canEditPreferences: boolean
  ) =>
    mapper(
      {
        [InvoiceStatus.Unpaid]: () => (
          <ConfirmAndPay
            onSuccess={() => {
              closeDrawer();
              onPay();
            }}
            invoice={invoice}
            refreshInvoice={refresh}
            paymentMethods={paymentMethods}
            canEditPreferences={canEditPreferences}
          />
        ),
        [InvoiceStatus.Processing]: () => (
          <Label className={styles.processing} type="s1-positive-centered">
            Processing payment
          </Label>
        ),
      },
      () => (
        <>
          <Button
            variant="positive"
            disabled={!invoice.charge || !invoice.charge.receipt_url}
            onClick={() => window.open(invoice.charge.receipt_url, "_blank")}
          >
            View Receipt
          </Button>
          {(!invoice.charge || !invoice.charge.receipt_url) && (
            <Label className={styles.noReceipt} type="negative-s1-centered">
              No receipt attached
            </Label>
          )}
        </>
      )
    );

  return (
    <Panel
      unmountOnCollapse
      size={isResponsive ? "100%" : 600}
      position="right"
      onClose={closeDrawer}
      visible={!!invoiceID}
    >
      <div className={styles.header}>
        <Button variant="text" onClick={closeDrawer} Icon={<CloseIcon />} />
      </div>
      <AsyncInject
        className={styles.container}
        dependencies={[invoiceID, homeID]}
        asyncRequest={async () => {
          const payableInvoice = await fetchPayableInvoice(invoiceID);
          const paymentMethods = await fetchHomePaymentMethods(homeID);
          const homeDetail = await fetchHomeDetail(homeID);

          return {
            payableInvoice,
            paymentMethods: [
              ...paymentMethods.sources,
              ...paymentMethods.bank_account,
            ],
            homeDetail,
          };
        }}
        BadData={() => (
          <Label color="primary" type="h5-centered">
            Something went wrong, please reload
          </Label>
        )}
        onFailure={(error: AxiosError) => {
          toast.error(
            getError(error.response?.data, "Failed to fetch payable invoice")
          );
          closeDrawer();
        }}
      >
        {({
          data: { payableInvoice, paymentMethods, homeDetail },
          refresh,
        }) => {
          const {
            created,
            vendor,
            invoiceitems,
            description,
            tax,
            pre_payment_credit_notes_amount: prePaymentCreditNotesAmount,
            amount_due: amountDue,
            status,
          } = payableInvoice;

          const canEditPreferences = homeDetail.owner?.uid === user?.uid;

          const isUnpaid = status === InvoiceStatus.Unpaid;

          const StatusAction = statusActions(
            payableInvoice,
            paymentMethods.filter(
              ({ status: sourceStatus }) =>
                sourceStatus !== SourceStatus.PENDING
            ),
            refresh,
            canEditPreferences
          )(status);

          return (
            <>
              <div className={styles.section}>
                <Label type="h5" color="primary">
                  Invoice Details
                </Label>
                <div className={styles.invoiceInfo}>
                  <Label type="s1" useMarkdown>{`__Date__: ${moment(
                    created
                  ).format("MM/DD/YYYY")}`}</Label>
                  <Label
                    type="s1"
                    useMarkdown
                  >{`__Service Provider__: ${vendor}`}</Label>
                  <Label
                    type="s1"
                    useMarkdown
                  >{`__Description__: ${description}`}</Label>
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.detailHeading}>
                  <Label type="h5" color="primary">
                    Invoice Breakdown
                  </Label>
                  <InvoiceStatusTag invoice={payableInvoice} />
                </div>
                <div className={styles.invoiceItems}>
                  {invoiceitems
                    .sort((itemA, itemB) => itemA.id.localeCompare(itemB.id))
                    .map((item) => (
                      <div className={styles.invoiceItem}>
                        <Label
                          color="primary"
                          type="s1-bold-italic-capitalized"
                        >
                          {item.description}
                        </Label>
                        <Label color="primary" type="s1">
                          {currencyFormatter.format(Number(item.amount))}
                        </Label>
                      </div>
                    ))}
                  {tax && (
                    <div className={styles.invoiceItem}>
                      <Label color="primary" type="s1-bold-italic-capitalized">
                        Tax
                      </Label>
                      <Label color="primary" type="s1">
                        {tax}
                      </Label>
                    </div>
                  )}
                  {prePaymentCreditNotesAmount > 0 && (
                    <div className={styles.invoiceItem}>
                      <Label type="s1-bold-italic-capitalized-positive">
                        Credit
                      </Label>
                      <Label type="s1-positive">
                        -$
                        {(prePaymentCreditNotesAmount / 100).toFixed(2)}
                      </Label>
                    </div>
                  )}
                  <div className={styles.invoiceItem}>
                    <Label color="primary" type="s1-bold-italic-capitalized">
                      Total
                    </Label>
                    <Label color="primary" type="s1">
                      {currencyFormatter.format(Number(amountDue))}
                    </Label>
                  </div>
                </div>
              </div>
              <div className={styles.actions}>
                <StatusAction />
              </div>
              {canEditPreferences && isUnpaid && (
                <Button
                  variant="text"
                  onClick={() => {
                    history.push("/portal/preferences");
                  }}
                >
                  Manage Payment Preferences
                </Button>
              )}
            </>
          );
        }}
      </AsyncInject>
    </Panel>
  );
};

export default InvoiceDetail;
