/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Input } from "antd";

import ErrorBar from "components/ErrorBar";

import Label from "components/Label";
import styles from "./styles.module.scss";

const { TextArea: Area } = Input;

const TextArea: FC<{
  onChange: (value: string | undefined) => void;
  error?: string;
  placeholder?: string;
  id?: string;
  value: string;
  disabled?: boolean;
  withCount?: boolean;
  showErrors?: boolean;
}> = ({
  onChange,
  error,
  placeholder,
  id,
  value,
  disabled,
  withCount,
  showErrors = true,
}) => {
  return (
    <div className={styles.container}>
      <Area
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        rows={4}
        className={styles.input}
        value={value}
        onChange={(event: any) => {
          const { value: newValue } = event.target;
          onChange(newValue ?? undefined);
        }}
      />
      {withCount && (
        <Label className={styles.count} type="positive">{`Characters count: ${
          value?.length || 0
        }`}</Label>
      )}
      {showErrors && <ErrorBar id={id} error={error} />}
    </div>
  );
};

TextArea.defaultProps = {
  error: undefined,
  placeholder: "",
  id: undefined,
  disabled: false,
  withCount: false,
  showErrors: true,
};

export default TextArea;
