const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const currencyToNumber = (currency: string) => {
  return Number(currency.replace(/[$,]*/g, ""));
};

const formatCurrency = (amount: number) =>
  `$${amount.toFixed(2).replace(".00", "")}`;

export { currencyFormatter, formatCurrency, currencyToNumber };
