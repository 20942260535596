import Label from "components/Label";
import React, { FC } from "react";

import { tagVariants } from "./contants";
import styles from "./styles.module.scss";

interface Props {
  variant: "inactive" | "active";
  customTagVariants?: { active: string; inactive: string };
}

const ActiveTag: FC<Props> = ({ variant, customTagVariants }) => {
  const { label, labelProps } = tagVariants(variant);

  return (
    <div className={`${styles.container} ${styles[variant]}`}>
      <Label {...labelProps}>{customTagVariants?.[variant] || label}</Label>
    </div>
  );
};

ActiveTag.defaultProps = {
  customTagVariants: undefined,
};

export default ActiveTag;
