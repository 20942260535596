import { createServiceOffering } from "api/projects";
import Button from "components/Button";
import Form from "components/Form";
import Modal from "components/Modal";
import useAsync from "hooks/use-async";
import React, { FC } from "react";
import { ServiceCategory } from "types/models";
import { displayErrorFromAxiosException } from "utils/errors";
import { required } from "utils/form";

import styles from "./styles.module.scss";

interface Props {
  visible: boolean;
  handleClose(): void;
  onModalClosed(): void;
  parent: ServiceCategory;
  onSuccess(): void;
}

interface FormValues {
  name: string;
}

const CreateServiceOffering: FC<Props> = ({
  visible,
  handleClose,
  onModalClosed,
  parent,
  onSuccess,
}) => {
  const [submit, submitting] = useAsync((formValues: FormValues) => {
    return createServiceOffering(parent.id, formValues)
      .then(() => {
        onSuccess();
        handleClose();
      })
      .catch(
        displayErrorFromAxiosException("Failed to create service offering")
      );
  });

  return (
    <Modal
      onModalClosed={onModalClosed}
      handleClose={handleClose}
      visible={visible}
      title={`Creating service offering for: ${parent.name}`}
    >
      <Form
        className={styles.form}
        onSubmit={submit}
        fields={[
          {
            type: "text",
            title: "Name",
            id: "name",
            validate: [required("Name is required for creation")],
          },
        ]}
      >
        {({ handleSubmit, invalid, hasChanged }) => (
          <Button
            className={styles.submit}
            disabled={!hasChanged || invalid}
            loading={submitting}
            onClick={handleSubmit}
          >
            Create
          </Button>
        )}
      </Form>
    </Modal>
  );
};

export default CreateServiceOffering;
