import Label from "components/Label";
import React, { FC } from "react";
import { PlanBlueprint } from "types/models";
import { icons } from "./constants";

import styles from "./styles.module.scss";

interface Props extends PlanBlueprint {
  className?: string;
}

const PlanTag: FC<Props> = ({ className, color, name, icon_name }) => {
  const Icon = icons(icon_name);

  return (
    <div
      style={{ backgroundColor: `${color}25` }}
      className={`${styles.container} ${className}`}
    >
      <Icon className={styles.icon} />
      <Label type="s3" style={{ color }}>
        {name}
      </Label>
    </div>
  );
};

PlanTag.defaultProps = {
  className: undefined,
};

export default PlanTag;
