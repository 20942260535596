import React, { FC } from "react";
import * as Sentry from "@sentry/react";

import Switch from "components/Switch";
import { Redirect, RouteComponentProps, useRouteMatch } from "react-router-dom";
import Topbar from "components/Topbar";

import ErrorBoundaryFallback from "components/ErrorBoundaryFallback";
import Login from "./views/Login";
import ResetPassword from "./views/ResetPassword";
import SetNewPassword from "./views/SetNewPassword";
import SignUp from "./views/SignUp";

import styles from "./styles.module.scss";

const Auth: FC = () => {
  const match = useRouteMatch();

  return (
    <div className={styles.container}>
      <Topbar variant="secondary" />
      <Sentry.ErrorBoundary
        fallback={
          <ErrorBoundaryFallback message="Whoops! Something went wrong, we'll work on fixing it!" />
        }
      >
        <Switch
          routes={[
            {
              path: `${match.path}/login`,
              Component: Login,
            },
            {
              path: `${match.path}/sign_up`,
              Component: SignUp,
            },
            {
              exact: true,
              path: `${match.path}/reset/confirm/:uid/:token`,
              Component: SetNewPassword,
            },
            { path: `${match.path}/reset`, Component: ResetPassword },
            {
              path: "*",
              render: ({ location }: RouteComponentProps) => (
                <Redirect
                  to={{
                    pathname: `${match.path}/login`,
                    state: { from: location },
                  }}
                />
              ),
            },
          ]}
        />
      </Sentry.ErrorBoundary>
    </div>
  );
};

export default Auth;
