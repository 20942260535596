import { createContext } from "react";
import { User, StripeCustomer } from "types/models";

interface UserContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  customer: StripeCustomer | null;
  setCustomer: (customer: StripeCustomer) => void;
}

// Context related to connection details
const userContext = createContext<UserContextType>({
  user: null,
  setUser: (_data) => {},
  customer: null,
  setCustomer: (_data) => {},
});

export default userContext;
