import React from "react";

function Background() {
  return (
    <svg
      width="100%"
      height="380"
      viewBox="0 0 1440 472"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="720" cy="110.5" rx="1140" ry="361.5" fill="#F9F7F5" />
    </svg>
  );
}

export default Background;
