import React, { FC } from "react";
import Markdown from "react-markdown";

import { Theme } from "types/components";

import { useTheme } from "theme/ThemeCreator";
import useScreenSize from "hooks/use-screen-size";
import styles from "./styles.module.scss";

type Props = {
  id?: string;
  type?: string;
  children: string | string[] | number | undefined;
  color?: string;
  ellipsis?: boolean;
  className?: string;
  breakWord?: boolean;
  style?: { [key: string]: string | number };
  useMarkdown?: boolean;
};

const Label: FC<Props> = ({
  type = "s2",
  children,
  color = "white",
  ellipsis = false,
  className = "",
  breakWord = false,
  style = {},
  id,
  useMarkdown,
}) => {
  const theme = useTheme();
  const { isResponsive } = useScreenSize();

  const { colors } = theme.label;

  const getTypeStyles = (_theme: Theme, _type: string) => {
    const allTypes = _type.split("-");
    let typeStyles = {};

    allTypes.forEach((subType) => {
      const styleToAdd =
        theme.label[`${subType}${isResponsive ? "Responsive" : ""}`] ??
        theme.label[subType];
      typeStyles = { ...typeStyles, ...(styleToAdd ?? {}) };
    });

    return typeStyles;
  };

  const typeStyles = getTypeStyles(theme, type);

  return (
    <div
      id={id}
      className={`${className} ${ellipsis && styles.ellipsis} ${
        breakWord && styles.breakWord
      }`}
      style={{ color: colors[color], ...typeStyles, ...style }}
    >
      {children !== undefined ? (
        useMarkdown ? (
          <Markdown>{String(children)}</Markdown>
        ) : (
          children
        )
      ) : (
        ""
      )}
    </div>
  );
};

Label.defaultProps = {
  id: undefined,
  color: "black",
  type: "s2",
  ellipsis: false,
  className: "",
  breakWord: false,
  style: {},
  useMarkdown: false,
};

export default Label;
