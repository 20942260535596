import { deleteAppointment } from "api/admin";
import Button from "components/Button";
import Label from "components/Label";
import Modal from "components/Modal";
import useAsync from "hooks/use-async";
import useProjects from "hooks/use-projects";
import moment from "moment";
import React, { FC, useState } from "react";
import { Event } from "types/models";
import { formattedTime } from "utils/datetime";

import styles from "./styles.module.scss";

interface Props {
  appointment: Event;
}

const Appointment: FC<Props> = ({ appointment }) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const { updateProjectJobs } = useProjects();

  const [removeAppointment, removingAppointment] = useAsync(async () => {
    await deleteAppointment(appointment.id).then(updateProjectJobs);
  });

  const { start_time: startTime, end_time: endTime } = appointment.when;

  return startTime && endTime ? (
    <>
      <Modal
        visible={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
      >
        <div className={styles.modal}>
          <Label type="negative-s1-bold">
            Are you sure you want to delete the appointment?
          </Label>
          <Button
            loading={removingAppointment}
            onClick={removeAppointment}
            variant="negative"
          >
            Delete
          </Button>
        </div>
      </Modal>
      <div className={styles.appointment}>
        <Label type="bold-italic">{`${moment(startTime * 1000).format(
          "MM/DD"
        )} between ${formattedTime(
          moment(startTime * 1000),
          moment(endTime * 1000)
        )}`}</Label>
        <Button
          onClick={() => setOpenConfirmation(true)}
          variant="negative"
          size="medium"
        >
          Delete
        </Button>
      </div>
    </>
  ) : null;
};

export default Appointment;
