import React, { MouseEvent, useEffect, useState } from "react";
import { message, Switch } from "antd";

import Button from "components/Button";
import { archiveHome, restoreHome } from "api/homes";
import Modal from "components/Modal";
import Label from "components/Label";

import useHomes from "hooks/use-homes";
import Form from "components/Form";
import { archiveReasons } from "constants/homes";
import styles from "./styles.module.scss";

const ActiveHomeToggle = () => {
  const { homeDetail: home, updateHomeDetail } = useHomes();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(false);

  useEffect(() => {
    if (!home) return;
    setToggleStatus(!home.archived_at);
  }, [home]);

  const handleArchiveHome = ({
    archived_reason,
  }: {
    archived_reason: string;
  }) => {
    if (!home) return;
    setToggleStatus(false);
    archiveHome(home.uid, archived_reason)
      .then((newHome) => {
        updateHomeDetail(newHome.uid);
        message.success("Home is now inactive");
      })
      .catch(() => message.error("Home could not be marked inactive"));
  };

  const handleRestoreHome = () => {
    if (!home?.archived_at) return;
    restoreHome(home.uid)
      .then((newHome) => {
        updateHomeDetail(newHome.uid);
        message.success("Home successfully restored");
      })
      .catch(() => message.error("Home could not be restored"));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Label type="bold-italic">Listing Status</Label>
        <div className={styles.switch}>
          <Label>Inactive</Label>
          <Switch
            style={{
              margin: "0 5px",
            }}
            onClick={(checked: boolean, e: MouseEvent<HTMLButtonElement>) => {
              if (checked) {
                handleRestoreHome();
                setToggleStatus(true);
                return;
              }

              setShowConfirmationModal(true);
            }}
            checked={toggleStatus}
            size="small"
          />
          <Label type="positive">Active</Label>
        </div>
        <Modal
          handleClose={() => setShowConfirmationModal(false)}
          title="Are you sure home is inactive?"
          visible={showConfirmationModal}
        >
          <Form
            onSubmit={handleArchiveHome}
            initialValues={{ archived_reason: "No reason provided" }}
            fields={[
              {
                title: "Archive reason",
                type: "select",
                id: "archived_reason",
                options: archiveReasons.map((reason) => ({
                  label: reason,
                  value: reason,
                })),
              },
            ]}
          >
            {({ handleSubmit, invalid }) => (
              <div className={styles.buttons}>
                <Button
                  variant="positive"
                  onClick={() => setShowConfirmationModal(false)}
                >
                  Keep Home
                </Button>
                <Button
                  disabled={invalid}
                  variant="negative"
                  onClick={() => {
                    handleSubmit();
                    setShowConfirmationModal(false);
                  }}
                >
                  Archive Home
                </Button>
              </div>
            )}
          </Form>
        </Modal>
      </div>
      {!toggleStatus && home?.archived_reason && (
        <Label
          className={styles.reason}
          type="italic-bold-negative-centered"
        >{`Archived reason: ${home?.archived_reason}`}</Label>
      )}
    </div>
  );
};

export default ActiveHomeToggle;
