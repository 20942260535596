import AmountField from "components/AmountField";
import FormConstructor from "components/FormConstructor";
import Select from "components/Select";
import TextArea from "components/TextArea";
import TextInput from "components/TextInput";
import React, { FC, useRef, useState } from "react";
import { mapper } from "utils/mapping";
import {
  AiFillInfoCircle as InfoCircle,
  AiFillCheckCircle as CheckIcon,
  AiFillDelete as DeleteIcon,
} from "react-icons/ai";
import { BsThreeDotsVertical as MenuIcon } from "react-icons/bs";

import { nonZeroFloat, required } from "utils/form";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { getError } from "utils/errors";
import Button from "components/Button";
import styles from "./styles.module.scss";

import { LineItem, Valid } from "../../types";

interface Props {
  item: LineItem & Valid;
  onDelete(): void;
  onValid(item: LineItem): void;
  onInvalid(item: LineItem): void;
}

export const invoiceItemTypes = [
  {
    label: "General",
    value: "general",
  },
  { label: "House Watching", value: "house_watch" },
  {
    label: "Labor",
    value: "labor",
  },
  {
    label: "Emergency labor",
    value: "emergency_labor",
  },
  { label: "Deposit", value: "deposit" },
  { label: "Material", value: "material" },
  { label: "Other", value: "other" },
  { label: "Discount", value: "discount" },
];

const fieldMapper = mapper(
  {
    area: { Component: TextArea },
    select: { Component: Select },
    text: { Component: TextInput },
    amount: { Component: AmountField },
  },
  { Component: TextArea }
);

const Item: FC<Props> = ({ item, onDelete, onValid, onInvalid }) => {
  const [openOptions, setOpenOptions] = useState(false);

  const menuRef = useRef<HTMLDivElement | null>(null);

  return (
    <FormConstructor
      onSubmit={(values) => {
        onValid({ ...values, id: item.id } as LineItem);
      }}
      showErrors={false}
      renderExternally
      submitOnChange
      onValidityChange={(invalidFlag, values) => {
        if (invalidFlag) onInvalid({ ...values, id: item.id } as LineItem);
      }}
      fieldMapper={fieldMapper}
      fields={[
        [
          {
            id: "type",
            options: invoiceItemTypes,
            type: "select",
            placeholder: "Type",
            validate: [required("Type is required")],
          },
          {
            id: "amount",
            type: "amount",
            placeholder: "Amount",
            validate: [
              required("Amount is required"),
              nonZeroFloat("Amount cannot be $0.00"),
            ],
          },
          {
            id: "description",
            type: "text",
            placeholder: "Description",
            validate: [required("Description is required")],
          },
        ],
      ]}
      initialValues={{ ...item }}
    >
      {({ formToRender, formErrors }) => {
        return (
          <div className={styles.field}>
            <div
              className={`${styles.form} ${
                item.valid ? styles.valid : styles.invalid
              }`}
            >
              {formToRender}
            </div>
            {!item.valid ? (
              <Tooltip
                arrow
                title={getError(formErrors, "Field contains errors")}
              >
                <div className={styles.infoContainer}>
                  <InfoCircle className={styles.infoIcon} />
                </div>
              </Tooltip>
            ) : (
              <CheckIcon className={styles.checkIcon} />
            )}
            <div ref={menuRef}>
              <Button
                variant="text"
                className={styles.menuIcon}
                onClick={() => setOpenOptions(true)}
                Icon={<MenuIcon />}
              />
            </div>
            <Menu
              anchorEl={menuRef.current}
              open={openOptions}
              onClose={() => setOpenOptions(false)}
            >
              <MenuItem onClick={onDelete} className={styles.menuItem}>
                <DeleteIcon />
                Delete
              </MenuItem>
            </Menu>
          </div>
        );
      }}
    </FormConstructor>
  );
};

export default Item;
