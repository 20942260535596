/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";

import Label from "components/Label";
import { useTheme } from "theme/ThemeCreator";
import useScreenSize from "hooks/use-screen-size";
import { Step } from "../../types";

import styles from "./styles.module.scss";

type Props = {
  steps: Step[];
  currentStep: number;
  config: { backgroundColor?: string };
  setCurrentStep(newStep: number): void;
};

const Progress: FC<Props> = ({
  steps,
  currentStep,
  config = {},
  setCurrentStep,
}) => {
  const { isResponsive } = useScreenSize();
  const { colors } = useTheme();

  const { backgroundColor = colors.white } = config;

  const variants = {
    selected: {
      width: "100%",
    },
    notSelected: {
      width: 0,
    },
  };

  const [visited, setVisited] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    setVisited({ ...visited, [currentStep]: true });
  }, [currentStep]);

  return (
    <div className={styles.container}>
      <div className={styles.items}>
        {steps.map(({ progressLabel }, index) => (
          <div
            key={progressLabel}
            className={styles.itemWrapper}
            onClick={
              index < currentStep || visited[index]
                ? () => setCurrentStep(index)
                : () => {}
            }
            style={{
              transition: "all 2s ease-in-out",
              backgroundColor: visited[index]
                ? colors.primary40
                : colors.gray50,
              width: `${100 / steps.length}%`,
              cursor:
                index < currentStep || visited[index] ? "pointer" : "default",
            }}
          >
            <motion.div
              initial={false}
              animate={index <= currentStep ? "selected" : "notSelected"}
              variants={variants}
              className={styles.itemBackground}
              transition={{ ease: "easeOut" }}
            />
            <Label
              style={{ backgroundColor }}
              className={styles.text}
              type="s3-centered-bold"
            >
              {index + 1}
            </Label>
            {!isResponsive && (
              <Label
                style={{ backgroundColor }}
                className={styles.text}
                type="s3-uppercase-centered-bold"
              >
                {progressLabel}
              </Label>
            )}
            <div className={styles.progressBar} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Progress;
