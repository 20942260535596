import Button from "components/Button";
import ErrorBar from "components/ErrorBar";
import { debounce } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { AiOutlinePlus as PlusIcon } from "react-icons/ai";
import { replace } from "utils/array";
import { v4 as uuid } from "uuid";

import Item from "./components/LineItem";
import styles from "./styles.module.scss";
import { LineItem, Valid } from "./types";

interface Props {
  onChange(values: LineItem[]): void;
  initialValue?: LineItem[];
  error?: string;
  setError(error: string): void;
  clearError(): void;
}

const NewLineItemField: FC<Props> = ({
  onChange,
  initialValue,
  error,
  setError,
  clearError,
}) => {
  const generateNewItem = (): LineItem & Valid => ({
    id: uuid(),
    description: "",
    amount: undefined,
    type: undefined,
    valid: false,
  });

  const [lineItems, setLineItems] = useState<(LineItem & Valid)[]>(
    initialValue?.map(({ amount, description, id, type }) => ({
      amount,
      description,
      id,
      type,
      valid: true,
    })) || [generateNewItem()]
  );

  useEffect(() => {
    onChange(
      lineItems.filter(({ valid }) => valid).map(({ valid, ...item }) => item)
    );

    const hasInvalidItem = lineItems.some(({ valid }) => !valid);

    if (hasInvalidItem)
      setError("Review all invalid line items before submitting");
    else clearError();
  }, [lineItems]);

  return (
    <div className={`${styles.container} ${error && styles.error}`}>
      <Button
        variant="text"
        onClick={() => {
          setLineItems([...lineItems, generateNewItem()]);
        }}
        Icon={<PlusIcon className={styles.addIcon} />}
      >
        Add line item
      </Button>
      {lineItems.map((item) => (
        <Item
          onInvalid={debounce((el) => {
            setLineItems(
              replace(
                lineItems,
                ({ id }) => id === item.id,
                () => ({ ...el, valid: false, id: item.id })
              )
            );
          }, 500)}
          onValid={(el) => {
            setLineItems(
              replace(
                lineItems,
                ({ id }) => id === item.id,
                () => ({ ...el, valid: true, id: item.id })
              )
            );
          }}
          onDelete={() => {
            setLineItems(lineItems.filter(({ id }) => id !== item.id));
          }}
          key={item.id}
          item={item}
        />
      ))}
      <ErrorBar error={error} />
    </div>
  );
};

NewLineItemField.defaultProps = {
  initialValue: undefined,
  error: undefined,
};

export default NewLineItemField;
