import React, { FC, useState, useEffect } from "react";

import Panel from "components/Panel";
import NewApplianceForm from "components/NewApplianceForm";
import Label from "components/Label";
import useScreenSize from "hooks/use-screen-size";

import LoadingComponent from "components/LoadingComponent";
import { Appliance } from "types/models";
import { fetchAppliance } from "api/homes";
import useAsync from "hooks/use-async";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

const ApplianceDetail: FC<{
  visible: boolean;
  appliance: Appliance;
  onClose(): void;
}> = ({ visible, appliance, onClose }) => {
  const { id, label } = appliance;
  const { screenHeight } = useScreenSize();
  const [detail, setDetail] = useState<Appliance | null>(null);

  const [getApplianceDetail, fetchingDetail] = useAsync(async () => {
    const data = await fetchAppliance(id, {
      expand: "room_details,model_details,attachments",
    });

    setDetail(data);
  });

  useEffect(() => {
    getApplianceDetail();
  }, []);

  const { model_details: modelDetails } = detail || {};
  const { documentation_url: documentationURL } = modelDetails || {};

  return (
    <Panel
      size={screenHeight * 0.8}
      onClose={onClose}
      position="bottom"
      visible={visible}
      unmountOnCollapse
      containerClassName={styles.container}
    >
      <LoadingComponent className={styles.loading} loading={fetchingDetail}>
        <Label
          className={styles.title}
          type="h5"
          color="primary"
        >{`Appliance detail - ${label}`}</Label>
        {detail && (
          <NewApplianceForm
            applianceId={detail.id}
            editing
            onSubmit={onClose}
            initialValues={{
              ...detail,
              attachments: detail.attachments.map(({ file, ...item }) => ({
                url: file,
                ...item,
              })),
            }}
          />
        )}
        {documentationURL && (
          <>
            <Label className={styles.documentationTitle}>
              Documentation URL
            </Label>
            <div className={styles.documentation}>
              <Link to={documentationURL} target="_blank">
                {documentationURL}
              </Link>
            </div>
          </>
        )}
      </LoadingComponent>
    </Panel>
  );
};

export default ApplianceDetail;
