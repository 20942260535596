import { AxiosError } from "axios";

import { Market } from "types/models";

import { apiClient, ServerError } from ".";

const fetchMarkets = async () => {
  try {
    const response = await apiClient.get<Market[]>("/admin/markets/");
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const getMarketsByZipCode = async (zip: string) => {
  try {
    const response = await apiClient.get<Market[]>(
      `/markets/get_markets_by_zip_code/?zip_code=${zip}`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export { fetchMarkets, getMarketsByZipCode };
