/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { getAllServiceCategories } from "api/projects";
import ActiveTag from "components/ActiveTag";
import Button from "components/Button";
import Label from "components/Label";
import useAsyncState from "hooks/use-async-state";
import React, { FC, useLayoutEffect, useState } from "react";
import { ServiceCategory, ServiceOfferingSnippet } from "types/models";
import { MdOutlineAdd as AddIcon } from "react-icons/md";

import LoadingComponent from "components/LoadingComponent";
import CreateServiceOffering from "./components/CreateServiceOffering";
import CreateServiceSubCategory from "./components/CreateServiceCategory";
import EditServiceOffering from "./components/EditServiceOffering";
import EditServiceCategory from "./components/EditServiceCategory";
import ItemHeader from "./components/ItemHeader";

import styles from "./styles.module.scss";

const ServiceOfferings: FC = () => {
  const {
    data: serviceCategories,
    refresh: updateServiceCategories,
    inProgress: loadingServiceCategories,
  } = useAsyncState<ServiceCategory[]>([], getAllServiceCategories);

  const [editServiceOffering, setEditServiceOffering] = useState(false);
  const [serviceOfferingToEdit, setServiceOfferingToEdit] =
    useState<ServiceOfferingSnippet | null>(null);

  const [editServiceSubCategory, setEditServiceSubCategory] = useState(false);
  const [serviceCategoryToEdit, setServiceCategoryToEdit] =
    useState<ServiceCategory | null>(null);

  const [createServiceOffering, setCreateServiceOffering] = useState(false);
  const [serviceOfferingParent, setServiceOfferingParent] =
    useState<ServiceCategory | null>(null);

  const [createServiceCategory, setCreateServiceCategory] = useState(false);
  const [serviceSubCategoryParent, setServiceSubCategoryParent] =
    useState<ServiceCategory | null>(null);

  useLayoutEffect(() => {
    updateServiceCategories();
  }, []);

  return (
    <>
      <CreateServiceSubCategory
        onSuccess={updateServiceCategories}
        onModalClosed={() => setServiceSubCategoryParent(null)}
        handleClose={() => setCreateServiceCategory(false)}
        parent={serviceSubCategoryParent}
        visible={createServiceCategory}
      />
      {serviceCategoryToEdit && (
        <EditServiceCategory
          onSuccess={updateServiceCategories}
          serviceCategory={serviceCategoryToEdit}
          visible={editServiceSubCategory}
          handleClose={() => setEditServiceSubCategory(false)}
          onModalClosed={() => setServiceCategoryToEdit(null)}
        />
      )}
      {serviceOfferingToEdit && (
        <EditServiceOffering
          onSuccess={updateServiceCategories}
          serviceOffering={serviceOfferingToEdit}
          visible={editServiceOffering}
          handleClose={() => setEditServiceOffering(false)}
          onModalClosed={() => setServiceOfferingToEdit(null)}
        />
      )}
      {serviceOfferingParent && (
        <CreateServiceOffering
          onSuccess={updateServiceCategories}
          parent={serviceOfferingParent}
          visible={createServiceOffering}
          handleClose={() => setCreateServiceOffering(false)}
          onModalClosed={() => setServiceOfferingParent(null)}
        />
      )}
      <LoadingComponent loading={loadingServiceCategories}>
        <Button
          className={styles.button}
          variant="icon"
          stopPropagation
          onClick={() => {
            setCreateServiceCategory(true);
          }}
          Icon={<AddIcon className={styles.icon} />}
        >
          Create Service Category
        </Button>
        {serviceCategories.map((category) => (
          <div className={styles.category}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setEditServiceSubCategory(true);
                setServiceCategoryToEdit(category);
              }}
            >
              <ItemHeader
                className={styles.categoryHeader}
                handleAdd={() => {
                  setCreateServiceCategory(true);
                  setServiceSubCategoryParent(category);
                }}
                title={category.name}
              />
            </div>
            <div className={styles.subCategories}>
              {category.children.map((subCategory) => (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditServiceSubCategory(true);
                    setServiceCategoryToEdit(subCategory);
                  }}
                  className={styles.subCategory}
                >
                  <ItemHeader
                    title={subCategory.name}
                    handleAdd={() => {
                      setCreateServiceOffering(true);
                      setServiceOfferingParent(subCategory);
                    }}
                  />
                  <div className={styles.offerings}>
                    {subCategory.service_offerings.map((offering) => (
                      <div
                        className={`${styles.offering} ${
                          !offering.active && styles.inactiveOffering
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditServiceOffering(true);
                          setServiceOfferingToEdit(offering);
                        }}
                      >
                        <ActiveTag
                          variant={offering.active ? "active" : "inactive"}
                        />
                        <Label>{offering.name}</Label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </LoadingComponent>
    </>
  );
};

export default ServiceOfferings;
