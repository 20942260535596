/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from "react";
import { useTable } from "react-table";

import {
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Table as RootTable,
  TableHead,
  Tooltip,
} from "@mui/material";
import { isEmpty } from "utils/array";
import { RecoilState } from "recoil";
import styles from "./styles.module.scss";
import {
  ColumnDefinition,
  CustomColumnInstance,
  MinimumRow,
} from "../../types";
import { filterMapper } from "../../constants";

type Props<T> = {
  data: any[];
  columns: ColumnDefinition<T>[];
  fixed?: "left" | "right";
  loading: boolean;
  filtersState: RecoilState<{ [key: string]: string | undefined }>;
  tableId: string;
  onRowClick?(rowData: { original: T }): void;
  rowClickDescription?: NonNullable<ReactNode>;
};

const FixedTable = <T extends MinimumRow>({
  data,
  columns,
  fixed,
  onRowClick,
  loading,
  filtersState,
  tableId,
  rowClickDescription = "",
}: Props<T>) => {
  const { getTableProps, headers, getTableBodyProps, rows, prepareRow } =
    useTable({ data, columns });

  return !isEmpty(columns) ? (
    <TableContainer
      className={`${styles.container} ${styles[`${fixed}Container`]}`}
    >
      <RootTable className={styles.table} {...getTableProps()}>
        <TableHead
          className={`${styles.header} ${fixed && styles.fixedHeader}`}
        >
          <TableRow className={`${styles.row} ${fixed && styles.fixedRow}`}>
            {headers.map((column) => {
              const {
                getHeaderProps,
                render: headerRender,
                width,
                filter,
                options,
                id,
                orderOptions,
              } = column as CustomColumnInstance;
              const Filters = Array.isArray(filter)
                ? filter.map((fil) => filterMapper(fil))
                : filterMapper(filter ?? "");

              return (
                <TableCell
                  style={{ padding: 0, height: 60 }}
                  {...getHeaderProps()}
                >
                  <div className={styles.cell} style={{ width }}>
                    <span className={styles.label}>
                      {headerRender("Header")}
                    </span>
                    {Filters &&
                      (Array.isArray(Filters) ? (
                        Filters.map((Filter, index) => (
                          <Filter
                            key={`${headerRender("Header")}-filter-${index}`}
                            width={width}
                            orderOptions={orderOptions}
                            filtersState={filtersState}
                            disabled={loading}
                            name={id}
                            options={options}
                          />
                        ))
                      ) : (
                        <Filters
                          width={width}
                          orderOptions={orderOptions}
                          filtersState={filtersState}
                          disabled={loading}
                          name={id}
                          options={options}
                        />
                      ))}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            const { getRowProps, cells } = row;

            const allInteractible = cells.every(({ column }) => {
              const { interactable } = column as CustomColumnInstance;

              return interactable;
            });

            return (
              <Tooltip
                arrow
                id={`tooltip-${tableId}`}
                title={
                  onRowClick && !allInteractible ? rowClickDescription : ""
                }
              >
                <TableRow
                  onClick={() => onRowClick?.(row)}
                  className={`${styles.row} ${fixed && styles.fixedRow} ${
                    onRowClick && styles.clickableRow
                  }`}
                  {...getRowProps()}
                >
                  {cells.map(({ getCellProps, render: cellRender, column }) => {
                    const { interactable } = column as CustomColumnInstance;

                    return (
                      <TableCell
                        style={{ padding: 0, height: 60 }}
                        {...getCellProps()}
                        onClick={(event) => {
                          if (interactable) event.stopPropagation();
                        }}
                      >
                        <div
                          className={`${styles.cell} ${
                            interactable && styles.interactableCell
                          }`}
                          style={{ width: column.width }}
                        >
                          <span className={styles.label}>
                            {cellRender("Cell")}
                          </span>
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </Tooltip>
            );
          })}
        </TableBody>
      </RootTable>
    </TableContainer>
  ) : null;
};

FixedTable.defaultProps = {
  onRowClick: undefined,
  rowClickDescription: "",
  fixed: undefined,
};

export default FixedTable;
