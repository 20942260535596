/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";

import { useRecoilValue } from "recoil";
import { SharedFlowState } from "components/Flow/types";
import { addSource, addBankSource, addPaymentFromPlaid } from "api/payments";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import { Source as StripeSource } from "@stripe/stripe-js";
import { fetchStripeCustomer } from "api/auth";

import { useUser } from "providers/UserProvider";
import { PAYMENT_METHODS } from "./constants";

type Props = {
  sharedFlowState: SharedFlowState;
  onFinish(): void;
};

const AddPaymentMethod: FC<Props> = ({ sharedFlowState, onFinish }) => {
  const flowState = useRecoilValue(sharedFlowState);
  const notificationDispatcher = useNotificationDispatcher();

  const { paymentMethod } = flowState;
  const { setCustomer } = useUser();

  const onAddPaymentSource = async () => {
    const stripeCustomer = await fetchStripeCustomer();
    setCustomer(stripeCustomer);
  };

  const onPlaidToken = (token: any, metadata: any) => {
    addPaymentFromPlaid(token, metadata.account_id)
      .then(async () => {
        const { account } = metadata;
        const { name, mask } = account;
        notificationDispatcher({
          description: `Successfully added payment method for ${name} (${mask})`,
          type: "success",
        });

        await onAddPaymentSource();
        onFinish?.();
      })
      .catch((err) => {
        notificationDispatcher({
          description:
            err.response?.data?.error ||
            "Failed to connect bank account. Please try again or contact support",
          type: "error",
        });
      });
  };

  const onBankToken = (token: string) => {
    addBankSource(token, true)
      .then(async () => {
        notificationDispatcher({
          description: "Successfully added bank account!",
          type: "success",
        });
        await onAddPaymentSource();
        onFinish?.();
      })
      .catch((err) => {
        notificationDispatcher({
          description:
            err.response?.data?.error ||
            "Failed to connect bank account. Please try again or contact support",
          type: "error",
        });
      });
  };

  const onAddCard = async (source: StripeSource) => {
    await addSource(source.id, true)
      .then(async () => {
        notificationDispatcher({
          description: "Successfully added credit card!",
          type: "success",
        });

        await onAddPaymentSource();
        onFinish?.();
      })
      .catch((err) => {
        notificationDispatcher({
          description:
            err.response?.data?.error ||
            "Failed to add credit card. Please try again or contact support",
          type: "error",
        });
      });
  };

  const Component = PAYMENT_METHODS(paymentMethod);

  return (
    <Component
      addCreditCard={onAddCard}
      onBankToken={onBankToken}
      onPlaidToken={onPlaidToken}
    />
  );
};

export default AddPaymentMethod;
