import { Attachment } from "components/HomeAttachmentsField";
import React, { FC } from "react";

import styles from "./styles.module.scss";

interface Props {
  attachment: Attachment;
}

const ImagePreview: FC<Props> = ({ attachment }) => (
  <img className={styles.preview} alt="" src={attachment.file} />
);

export default ImagePreview;
