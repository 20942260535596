/* eslint-disable @typescript-eslint/no-explicit-any */
export const isEmpty = (array: any[]): boolean => array.length === 0;

export const replace = <T>(
  array: T[],
  finder: (item: T, index: number) => boolean,
  getNewElement: (element: T) => T
): T[] => {
  const index = array.findIndex(finder);

  return [
    ...array.slice(0, index),
    getNewElement(array[index]),
    ...array.slice(index + 1, array.length),
  ];
};
