import React, {
  FC,
  useMemo,
  useState,
  useEffect,
  ReactNode,
  ElementType,
  CSSProperties,
} from "react";
import { v4 as uuid } from "uuid";
import { useRecoilState } from "recoil";

import { objectIsEmpty } from "utils/object";
import styles from "./styles.module.scss";
import { Values, Field } from "./types";
import FormItem from "./components/FormItem";
import { formErrorsState, formValuesState } from "./atoms";
import { shouldHide } from "./utils";

interface ErrorState {
  [key: string]: string[];
}

export interface FormProps {
  onChange?(values: Values): void;
  onValidityChange?(invalid: boolean, values: Values): void;
  id?: string;
  name?: string;
  initialValues?: Values;
  fields: (Field | Field[])[];
  submitOnChange?: boolean;
  showErrors?: boolean;
  children?({
    handleSubmit,
    invalid,
    formToRender,
    values,
    clearForm,
    resetToInitialValues,
    hasChanged,
    formErrors,
  }: {
    hasChanged: boolean;
    handleSubmit: () => void;
    invalid: boolean;
    formToRender: ReactNode;
    values: Values;
    clearForm(): void;
    resetToInitialValues(values?: Values): void;
    formErrors: { [key: string]: string[] };
  }): JSX.Element | null;
  className?: string;
  onSubmit?(values: Values, initialValues: Values): Promise<void> | void;
  renderExternally?: boolean;
  disabled?: boolean;
  clearOnSubmit?: boolean;
  style?: CSSProperties;
}

interface FormConstructorProps {
  onChange?(values: Values): void;
  onValidityChange?(invalid: boolean, values: Values): void;
  id?: string;
  name?: string;
  initialValues?: Values;
  fields: (Field | Field[])[];
  submitOnChange?: boolean;
  showErrors?: boolean;
  children?({
    hasChanged,
    handleSubmit,
    invalid,
    formToRender,
    values,
    clearForm,
    resetToInitialValues,
    formErrors,
  }: {
    hasChanged: boolean;
    handleSubmit: () => void;
    invalid: boolean;
    formToRender: ReactNode;
    values: Values;
    clearForm(): void;
    resetToInitialValues(values?: Values): void;
    formErrors: { [key: string]: string[] };
  }): JSX.Element | null;
  className?: string;
  onSubmit?(values: Values, initialValues: Values): Promise<void> | void;
  renderExternally?: boolean;
  disabled?: boolean;
  clearOnSubmit?: boolean;
  fieldMapper(key: string): { Component: ElementType; default?: Values };
  style?: CSSProperties;
}

const FormConstructor: FC<FormConstructorProps> = ({
  name,
  initialValues = {},
  fields,
  children = undefined,
  className,
  onSubmit,
  disabled,
  renderExternally,
  clearOnSubmit,
  fieldMapper,
  id,
  onChange,
  style,
  onValidityChange,
  submitOnChange,
  showErrors = true,
}) => {
  const [mounted, setMounted] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const formID = useMemo(() => name ?? uuid(), []);

  const [form, setForm] = useRecoilState<Values>(formValuesState(formID));

  const [formErrors, setFormErrors] = useRecoilState<{
    [key: string]: string[];
  }>(formErrorsState(formID));

  const clearForm = () => {
    setForm({});
    setFormErrors({});
  };

  useEffect(() => () => clearForm(), []);

  useEffect(() => {
    if (mounted) onValidityChange?.(invalid, form);
  }, [invalid]);

  const resetToInitialValues = (newValues?: Values) => {
    setForm(newValues || initialValues);
  };

  const hasChanged = Object.keys(form).some(
    (key) => form[key] !== initialValues[key]
  );

  const checkForErrors = () => {
    let newErrors: ErrorState = {};

    Object.keys(formErrors).forEach((errorKey) => {
      if (errorKey.includes("__external"))
        newErrors[errorKey] = formErrors[errorKey];
    });

    fields
      .flat()
      .filter(({ hide }) => !shouldHide(hide, form))
      .forEach(({ id: fieldId, validate, dependencies }) => {
        validate?.forEach((validation) => {
          const result = validation(form[fieldId]);

          if (result)
            newErrors = {
              ...newErrors,
              [fieldId]: [...(newErrors[fieldId] ?? []), result],
            };
        });

        dependencies?.forEach(
          ({ id: dependencyId, validate: dependencyValidations }) => {
            dependencyValidations?.forEach((validation) => {
              const result = validation(form[fieldId], form[dependencyId]);

              if (result)
                newErrors = {
                  ...newErrors,
                  [fieldId]: [...(newErrors[fieldId] ?? []), result],
                };
            });
          }
        );
      });

    setFormErrors(newErrors);

    if (!objectIsEmpty(newErrors)) {
      setInvalid(true);
      return true;
    }

    setInvalid(false);
    return false;
  };

  const handleSubmit = () => {
    setSubmitted(true);

    const hasErrors = checkForErrors();

    if (!hasErrors) {
      onSubmit?.(form, initialValues);
      if (clearOnSubmit) clearForm();
    }
  };

  useEffect(() => {
    if (mounted) {
      if (submitOnChange) handleSubmit();
      else if (submitted && invalid) checkForErrors();

      if (!disabled) onChange?.(form);
    }
  }, [form]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const fieldRenderer = ({
    id: fieldId,
    type,
    title,
    placeholder,
    options,
    disabled: disabledField,
    normalize,
    actions,
    props,
    parents,
    derivateValue,
    required = false,
    help,
  }: Field) => (
    <FormItem
      setFormErrors={setFormErrors}
      showErrors={showErrors}
      help={help ?? ""}
      required={required}
      derivateValue={derivateValue}
      initialValue={initialValues?.[fieldId]}
      disabled={disabledField || disabled}
      key={fieldId}
      error={
        submitted
          ? formErrors?.[fieldId]?.[0] ||
            formErrors?.[`${fieldId}__external`]?.[0]
          : undefined
      }
      options={typeof options === "function" ? options(form) : options}
      placeholder={placeholder || title}
      title={title}
      id={fieldId}
      Component={fieldMapper(type).Component}
      defaultValue={fieldMapper(type).default}
      formDefinition={formValuesState(formID)}
      normalize={normalize}
      actions={actions}
      componentProps={props}
      parents={parents}
    />
  );

  const formToRender = fields.map((row) => {
    if (Array.isArray(row)) {
      return (
        <div
          key={row.map(({ id: fieldId }) => fieldId).join("-")}
          className={styles.inlineFields}
        >
          {row.filter(({ hide }) => !shouldHide(hide, form)).map(fieldRenderer)}
        </div>
      );
    }
    return shouldHide(row.hide, form) ? null : fieldRenderer(row);
  });

  const ChildrenWrapper = renderExternally ? React.Fragment : "div";

  const childrenProps = renderExternally ? {} : { className: styles.children };

  return (
    <div style={style} id={id} className={`${styles.form} ${className}`}>
      {!renderExternally && formToRender}
      {children && (
        <ChildrenWrapper {...childrenProps}>
          {children({
            hasChanged,
            handleSubmit,
            invalid,
            formToRender,
            values: form,
            clearForm,
            resetToInitialValues,
            formErrors,
          })}
        </ChildrenWrapper>
      )}
    </div>
  );
};

FormConstructor.defaultProps = {
  name: undefined,
  initialValues: {},
  className: "",
  renderExternally: false,
  disabled: false,
  clearOnSubmit: false,
  id: undefined,
  children: undefined,
  onSubmit: () => {},
  onChange: () => {},
  onValidityChange: () => {},
  style: {},
  submitOnChange: false,
  showErrors: true,
};

export default FormConstructor;
