import { AxiosError } from "axios";

import { Note } from "types/models";

import { apiClient, ServerError } from ".";

const createNote = async (projectID: string, description: string) => {
  const payload = {
    project: projectID,
    description,
  };
  try {
    const response = await apiClient.post<Note>("/projects/notes/", payload);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateNote = async (noteID: string, note: Note) => {
  try {
    const response = await apiClient.patch<Note>(
      `/projects/notes/${noteID}/`,
      note
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const deleteNote = async (noteID: string) => {
  try {
    const response = await apiClient.post<Note>(
      `/projects/notes/${noteID}/archive/`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchNotes = async (queryFilters: { [x: string]: string } = {}) => {
  const queryParams = new URLSearchParams(queryFilters);
  try {
    const response = await apiClient.get<Note[]>(
      `/projects/notes/?${queryParams}`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export { deleteNote, createNote, updateNote, fetchNotes };
