import React, { FC, useEffect } from "react";

import { useSidebarInteraction } from "components/Sidebar";
import Switch from "components/Switch";
import styles from "./styles.module.scss";
import GetStarted from "./views/GetStarted";
import Verify from "./views/Verify";
import AwaitContact from "./views/AwaitContact";

const Onboarding: FC = () => {
  const { hideSidebar, showSidebar } = useSidebarInteraction();

  useEffect(() => {
    hideSidebar();

    return () => showSidebar();
  }, []);

  return (
    <div className={styles.container}>
      <Switch
        routes={[
          { path: "/portal/onboarding", exact: true, Component: GetStarted },
          { path: "/portal/onboarding/verify", exact: true, Component: Verify },
          {
            path: "/portal/onboarding/await_contact",
            exact: true,
            Component: AwaitContact,
          },
        ]}
      />
    </div>
  );
};

export default Onboarding;
