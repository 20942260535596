import { atom } from "recoil";

import { Invoice, TaxRate } from "types/models";
import { PaginationInfo } from "types/client";

export const invoicePaginationInfo = atom<PaginationInfo>({
  key: "invoicePaginationInfo",
  default: {
    prevLink: null,
    nextLink: null,
    numOfItems: 1,
    currentPage: 1,
  },
});

export const adminInvoicesState = atom<Invoice[]>({
  key: "adminInvoicesState",
  default: [],
});

export const invoiceSelectedState = atom<Invoice | null>({
  key: "invoiceSelectedState",
  default: null,
});

export const taxRatesState = atom<TaxRate[]>({
  key: "taxRatesState",
  default: [],
});
