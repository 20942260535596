import React, { FC } from "react";
import { AiOutlineInfoCircle as InfoIcon } from "react-icons/ai";
import { FaHome as HomeIcon } from "react-icons/fa";

import Button from "components/Button";
import { Home } from "types/models";
import Label from "components/Label";
import { useHistory } from "react-router-dom";

import useHomes from "hooks/use-homes";
import styles from "./styles.module.scss";

type Props = {
  home: Home;
};

const PropertyCell: FC<Props> = ({ home }) => {
  const { updateHomeDetail } = useHomes();

  const history = useHistory();

  return (
    <div className={styles.container}>
      <Label className={styles.label} ellipsis>
        {home.title}
      </Label>
      <div className={styles.actions}>
        <Button
          variant="text"
          className={styles.button}
          onClick={() => history.push(`/admin/homes/${home.uid}`)}
          Icon={<HomeIcon className={styles.icon} />}
        />
        <Button
          variant="text"
          className={styles.button}
          onClick={() => updateHomeDetail(home.uid)}
          Icon={<InfoIcon className={styles.icon} />}
        />
      </div>
    </div>
  );
};

export default PropertyCell;
