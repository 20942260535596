/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";

import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import useAsync from "hooks/use-async";
import { SharedFlowState } from "components/Flow/types";
import { useSetRecoilState } from "recoil";
import { getMarketsByZipCode } from "api/markets";
import { hasPostalCode, required } from "utils/form";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import { getFromPlace } from "utils/maps";
import { Place } from "types/models";
import styles from "./styles.module.scss";

type Props = {
  sharedFlowState: SharedFlowState;
  nextStep(): void;
};

const MarketVerification: FC<Props> = ({ sharedFlowState, nextStep }) => {
  const setState = useSetRecoilState(sharedFlowState);
  const notificationDispatcher = useNotificationDispatcher();

  const [verify, verifying] = useAsync(
    async (values: { home_details: Place }) => {
      const zipCode = getFromPlace("postal_code", values.home_details);

      const response = await getMarketsByZipCode(zipCode);
      const zipCodes = response
        .map(({ zip_codes }) => Object.keys(zip_codes))
        .flat();

      if (zipCodes.includes(zipCode)) {
        notificationDispatcher({
          type: "success",
          description: "Your home is within a Humming homes service area!",
        });
        setState((state) => ({ ...state, home: values.home_details }));
        nextStep();
      } else
        notificationDispatcher({
          type: "error",
          description: "Our service is currently not available in your area.",
        });
    }
  );

  return (
    <div className={styles.container}>
      <Form
        renderExternally
        fields={[
          {
            placeholder: "Home address",
            type: "maps-autocomplete",
            id: "home_details",
            validate: [
              required("Please enter your home address"),
              hasPostalCode("This address is invalid: Missing zip code"),
            ],
          },
        ]}
        name="market-verification"
        onSubmit={verify}
      >
        {({ handleSubmit, invalid, formToRender }) => (
          <>
            <Label className={styles.title} type="h5-centered" color="primary">
              Verify your address
            </Label>
            {formToRender}
            <Button
              loading={verifying}
              className={styles.submit}
              disabled={invalid}
              onClick={handleSubmit}
            >
              Verify
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default MarketVerification;
