import React, { ReactNode, FC } from "react";
import { Source, SourceType } from "types/models";

import { mapper } from "utils/mapping";

import BankAccount from "./components/BankAccount";
import Card from "./components/Card";

type PaymentDisplayProps = {
  children?: ReactNode | Array<ReactNode>;
  source: Source;
  withActions?: boolean;
  updateCustomer?(): void;
};

const PaymentSourceCard: FC<PaymentDisplayProps> = ({
  children,
  source,
  withActions,
  updateCustomer,
}: PaymentDisplayProps) => {
  const {
    default_source: paymentPreference,
    source_data: sourceData,
    type,
    id,
  } = source;

  const TypeComponent = mapper(
    {
      [SourceType.BANK_ACCOUNT]: BankAccount,
      [SourceType.CARD]: Card,
    },
    null
  )(type);

  return (
    TypeComponent && (
      <TypeComponent
        paymentPreference={paymentPreference}
        sourceData={sourceData}
        id={id}
        withActions={withActions}
        updateCustomer={updateCustomer}
      >
        {children}
      </TypeComponent>
    )
  );
};

PaymentSourceCard.defaultProps = {
  children: null,
  withActions: false,
  updateCustomer: () => {},
};

export default PaymentSourceCard;
