import React, {
  useState,
  FC,
  useMemo,
  useLayoutEffect,
  useCallback,
  useEffect,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Button from "components/Button";
import Label from "components/Label";
import { potentialAssigneeList } from "recoil/atoms";
import useAppFilters from "components/GlobalFilters/hooks/use-app-filters";
import Table from "components/Table";

import useProjects from "hooks/use-projects";
import { DEFAULT_PROJECTS_PAGE_SIZE } from "hooks/use-projects/constants";

import { useUser } from "providers/UserProvider";
import { purgeObject } from "utils/object";
import { debounce } from "lodash";
import { ProjectStatus } from "constants/statuses";
import useAsyncState from "hooks/use-async-state";
import { CancellationReason } from "types/models";
import { fetchCancellationReasons } from "api/projects";
import styles from "./styles.module.scss";
import { createDataFromProjects, createColumns } from "./constants";
import AddProject from "./components/AddProject";
import HomeDetailPanel from "../../../../components/HomeDetailPanel";

interface Props {
  statuses: ProjectStatus[];
  title: string;
}

const ProjectList: FC<Props> = ({ statuses, title }) => {
  const history = useHistory();

  const [addingProject, setAddingProject] = useState(false);
  const potentialAssignees = useRecoilValue(potentialAssigneeList);

  const {
    data: cancellationReasons,
    inProgress: fetchingCancellationReasons,
    refresh: getCancellationReasons,
  } = useAsyncState<CancellationReason[]>([], fetchCancellationReasons);

  const { search } = useLocation();
  const { user } = useUser();
  const {
    updateProjects,
    loadingProjects,
    projects,
    pagination,
    serviceCategories,
    clearProjects,
    clearProject,
    projectCategories,
  } = useProjects(statuses);

  const {
    updatingFilters,
    selectedHome,
    assignedToMe,
    market,
    homeCoordinator,
  } = useAppFilters();

  const loading =
    updatingFilters || loadingProjects || fetchingCancellationReasons;

  const handleViewProject = (projectUID: string) => {
    history.push(`/admin/projects/${projectUID}${search}`);
  };

  useEffect(() => {
    getCancellationReasons();

    return () => {
      clearProjects();
      clearProject();
    };
  }, []);

  const updateOnSearch = useCallback(
    debounce((params: string) => {
      updateProjects({}, params);
    }, 300),
    []
  );

  useLayoutEffect(() => {
    updateOnSearch(search);
  }, [search]);

  const externalFilters = useMemo(
    () => ({
      home: selectedHome,
      market: market ? String(market) : undefined,
      home_coordinator: homeCoordinator,
    }),
    [selectedHome, market, homeCoordinator]
  );

  const externalFilterValues = useMemo(
    () =>
      purgeObject({
        assigned_to: assignedToMe ? user?.uid : undefined,
      }),
    [assignedToMe]
  );

  return (
    <>
      <HomeDetailPanel
        updateData={updateProjects}
        loadingData={loadingProjects}
      />
      <AddProject
        onSubmit={() => {
          setAddingProject(false);
          updateProjects();
        }}
        onClose={() => {
          setAddingProject(false);
        }}
        visible={addingProject}
      />
      <div className={styles.container}>
        <Label id="title" className={styles.title} type="h5" color="primary">
          {title}
        </Label>
        <Table
          rowClickDescription="Click row to see project detail"
          externalFilterValues={externalFilterValues}
          reloadData={updateProjects}
          headerActions={
            <Button id="add-new-project" onClick={() => setAddingProject(true)}>
              Create New Project
            </Button>
          }
          externalFilters={externalFilters}
          onRowClick={({ original }) => handleViewProject(original.uid)}
          loading={loading}
          pagination={{
            count: pagination.numOfItems,
            page: pagination.currentPage,
            pageSize: DEFAULT_PROJECTS_PAGE_SIZE,
          }}
          columns={createColumns(
            serviceCategories,
            potentialAssignees,
            updateProjects,
            statuses,
            cancellationReasons
          )}
          data={createDataFromProjects(projects, projectCategories)}
        />
      </div>
    </>
  );
};

export default ProjectList;
