import React from "react";
import { Avatar, Menu, Dropdown } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { FaUserCircle as UserIcon } from "react-icons/fa";

import { useSessionValue } from "providers/SessionProvider";
import { useUser } from "providers/UserProvider";

import Label from "components/Label";
import styles from "./styles.module.scss";

const VendorNavigation = () => {
  const loc = useLocation();
  const { user, setUser } = useUser();
  const { setSessionData } = useSessionValue();
  const history = useHistory();
  const logOutUser = () => {
    setSessionData(null);
    setUser(null);
    history.push("/auth/login");
  };

  const overlay = (
    <Menu
      className={styles.menu}
      mode="horizontal"
      defaultSelectedKeys={[loc.pathname]}
    >
      <Menu.Item
        className={styles.menuItem}
        onClick={logOutUser}
        key="logout"
        style={{ height: "30px" }}
      >
        <Label color="primary">Logout</Label>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.container}>
      <Dropdown placement="bottomRight" overlay={overlay}>
        <Avatar
          src={user?.profile_image || user?.avatar}
          size={30}
          icon={
            user?.profile_image ? null : (
              <UserIcon className={styles.userIcon} />
            )
          }
        />
      </Dropdown>
    </div>
  );
};

export default VendorNavigation;
