import React, { FC } from "react";

import Label from "components/Label";
import Button from "components/Button";
import Tool from "svg/Tool";
import Home from "svg/Home";
import MoneyBag from "svg/MoneyBag";

import styles from "./styles.module.scss";

type Props = {
  nextStep(): void;
};

const GetStarted: FC<Props> = ({ nextStep }) => {
  return (
    <div className={styles.container}>
      <Label type="h4-centered" color="positive">
        We empower service providers to do what they do best.
      </Label>
      <div className={styles.columns}>
        <div className={styles.column}>
          <Home />
          <Label type="centered-h6-bold" color="primary">
            More Jobs
          </Label>
          <Label type="centered-bold-s1">
            We service homes all over the Hamptons. After signing up, you will
            gain access to jobs across our portfolio.
          </Label>
        </div>
        <div className={styles.column}>
          <Tool />
          <Label type="centered-h6-bold" color="primary">
            Less Hassle
          </Label>
          <Label type="centered-bold-s1">
            Our Home Managers work directly with the homeowners to manage their
            home maintenance.
          </Label>
        </div>
        <div className={styles.column}>
          <MoneyBag />
          <Label type="centered-h6-bold" color="primary">
            Get Paid
          </Label>
          <Label type="centered-bold-s1">
            We deposit payment directly into your bank account within 30 days of
            invoice.
          </Label>
        </div>
      </div>
      <Button onClick={nextStep}>Get Started</Button>
    </div>
  );
};

export default GetStarted;
