import React, { FC } from "react";
import { useSetRecoilState } from "recoil";

import AddBankButton from "components/AddBankButton";
import AddCreditCardButton from "components/AddCreditCardButton";
import { SharedFlowState } from "components/Flow/types";

type Props = {
  sharedFlowState: SharedFlowState;
  nextStep(): void;
};

const PaymentOptions: FC<Props> = ({ sharedFlowState, nextStep }) => {
  const setSharedFlowState = useSetRecoilState(sharedFlowState);

  return (
    <div>
      <AddBankButton
        onClick={() => {
          setSharedFlowState((state) => ({
            ...state,
            paymentMethod: "bank",
          }));
          nextStep();
        }}
      />
      <AddCreditCardButton
        style={{
          marginTop: "12px",
        }}
        onClick={() => {
          setSharedFlowState((state) => ({
            ...state,
            paymentMethod: "card",
          }));
          nextStep();
        }}
      />
    </div>
  );
};

export default PaymentOptions;
