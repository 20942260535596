/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { notification } from "antd";

import { Source, SourceType, ApplianceModel } from "types/models";

/**
 * turns this into this => Turns This Into This
 *
 * @param str string to title case
 */
export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

/**
 * Formatted to have line breaks to display
 *
 * @param str string value to be formatted
 */
export const formattedText = (str: string) => {
  if (!str) return null;
  return str.split("\n").map((item, i) => <span key={i}>{item}</span>);
};

/**
 * Pluralizing words, ex: duck
 * 1 duck
 * 2 ducks
 * 0 ducks
 *
 * @param count number of items
 * @param word string - base word
 * @param suffix string - how to make it plural (sometimes can be 'es')
 */
export const pluralize = (count: number, word: string, suffix = "s") => {
  return `${count} ${word}${count === 1 ? "" : suffix}`;
};

/**
 * Replaces special characters in a string so that it may be used as part of a 'pretty' URL.
 *
 * parameterize("Dwight K. Shrute") => "dwight-k-shrute"
 * parameterize("Dwight K. Shrute", "_") => "dwight_k_shrute"
 * parameterize("Dwight K. Shrute", "_", true) => "Dwight_K_Shrute"
 *
 * @param word base word to parameterize
 * @param separator delimeter to substitute spaces in URL
 * @param preserveCase boolean - to preserve the case
 */
export const parameterize = (
  word: string,
  separator = "-",
  preserveCase = false
) => {
  const parameterizedWord = word.replace(/[^a-z0-9\-_]+/gi, separator);
  return preserveCase ? parameterizedWord : parameterizedWord.toLowerCase();
};

/**
 * Given current url, get given query param
 *
 * getQueryValue("?query=param&hello=aloha", "hello") => "aloha"
 *
 * @param params string - params to search through
 * @param queryKey string - specific key to grab in query
 */
export const getQueryValue = (params: string, queryKey: string) => {
  const pageParam = params
    .replace("?", "")
    .split("&")
    .find((param) => param.includes(queryKey));
  return pageParam?.split("=")[1];
};

/**
 * Copies string to clipboard
 *
 * only works on chrome
 * @params copiedText
 */
export const copyToClipboard = (copiedText: string) => {
  navigator.clipboard.writeText(copiedText).then(() => {
    const message = "Successfully copied wifi";
    const description = ``;
    notification.success({ message, description });
  });
};

/**
 * Text to display the payment info (ie card or bank)
 *
 * @param payment type of payment inputted
 */
export const paymentMethodText = (payment: Source) => {
  switch (payment.type) {
    case SourceType.CARD:
      return `${
        payment.source_data.funding === "debit" ? "Debit" : "Credit"
      } Card ending in ${payment.source_data.last4}`;
    case SourceType.BANK_ACCOUNT:
      return `Account ending in ${payment.source_data.last4}`;
  }

  return "";
};

/**
 * Takes in an appliance model and creates a toString to
 * make it easy for user to read
 *
 * @param model Appliance Model object
 */
export const formatApplianceModel = (model: ApplianceModel) => {
  let formattedModel: string = [model.manufacturer, model.model_id]
    .filter((x) => x)
    .join(" - ");

  if (model.label) formattedModel += [" (", model.label, ")"].join("");

  return formattedModel;
};
