/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { ACTIVITY_DISPLAY } from "constants/activity";
import { Activity } from "types/models";
import Label from "components/Label";

import { capitalize } from "lodash";
import styles from "./styles.module.scss";

type ActivityDetailProps = {
  activity: Activity;
};

const ActivityDetail: FC<ActivityDetailProps> = ({ activity }) => {
  const history = useHistory();

  const activityInfo = ACTIVITY_DISPLAY[activity.activity_type];

  const url = activityInfo.destinationURL(activity);

  return (
    <div
      className={`${styles.container} ${url && styles.clickable}`}
      onClick={() => {
        if (url) history.push(url);
      }}
    >
      <Label>{`${activity.created_by
        .split(" ")
        .map(capitalize)
        .join(" ")} - ${capitalize(activityInfo.text)}`}</Label>
      <Label type="italic">
        {moment(activity.created_at).format("MM/DD/YYYY h:mma")}
      </Label>
    </div>
  );
};

export default ActivityDetail;
