/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { FlexProps } from "rebass";
import { useRecoilState } from "recoil";
import { AnimatePresence, motion } from "framer-motion";

import { SharedFlowState } from "components/Flow/types";
import Flow from "components/Flow";
import Label from "components/Label";

import PaymentManagement from "./components/PaymentManagement";
import AddPaymentMethod from "./components/AddPaymentMethod";
import SelectPaymentMethod from "./components/SelectPaymentMethod";
import styles from "./styles.module.scss";

interface AddPaymentProps {
  nextStep(): void;
  sharedFlowState: SharedFlowState;
}

const AddPayment: FC<AddPaymentProps & FlexProps> = ({
  nextStep,
  sharedFlowState,
}) => {
  const [state, setState] = useRecoilState(sharedFlowState);
  const { addedPaymentSource } = state;

  const steps = [
    {
      title: "Add your payment information",
      subtitle:
        "You can use this dashboard to view and pay your Humming Homes invoices. We can set up your payment methods now.",
      key: "add-new-payment",
      Component: PaymentManagement,
      skipStep: nextStep,
    },
    {
      title: "Select a payment method",
      Component: SelectPaymentMethod,
      key: "payment-options",
    },
    {
      Component: AddPaymentMethod,
      key: "payment-method",
      onFinish: () => {
        setState({ ...state, addedPaymentSource: true });
        nextStep();
      },
    },
  ];

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      <motion.div
        key={addedPaymentSource ? "step-completed" : "step-in-progress"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        className={styles.wrapper}
      >
        {addedPaymentSource ? (
          <Label type="positive-bold-h5" className={styles.success}>
            Payment method added successfully!
          </Label>
        ) : (
          <Flow className={styles.flow} withoutNextStepButton steps={steps} />
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default AddPayment;
