import React, { FC } from "react";
import { Avatar, Menu, Dropdown } from "antd";
import { useHistory } from "react-router-dom";
import { FaUserCircle as UserIcon } from "react-icons/fa";

import { useSessionValue } from "providers/SessionProvider";
import { useUser } from "providers/UserProvider";
import Label from "components/Label";
import GlobalFilters from "components/GlobalFilters";

import styles from "./styles.module.scss";

const SimpleHeader: FC = () => {
  const { user, setUser } = useUser();
  const { setSessionData } = useSessionValue();

  const history = useHistory();
  const logOutUser = () => {
    setSessionData(null);
    setUser(null);
    history.push("/auth/login");
  };

  const overlay = (
    <Menu mode="horizontal" className={styles.menu}>
      <Menu.Item onClick={logOutUser} className={styles.menuItem} key="logout">
        <Label color="primary">Logout</Label>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.container}>
      <Dropdown overlay={overlay}>
        <Avatar
          className={styles.avatar}
          src={user?.profile_image ?? ""}
          size={32}
          icon={
            user?.profile_image ? null : (
              <UserIcon className={styles.userIcon} />
            )
          }
        />
      </Dropdown>
      <GlobalFilters />
    </div>
  );
};

export default SimpleHeader;
