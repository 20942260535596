/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useState } from "react";

import Label from "components/Label";

import { Appliance as ApplianceType } from "types/models";

import styles from "./styles.module.scss";
import ApplianceDetail from "./components/ApplianceDetail";

type Props = {
  appliance: ApplianceType;
};

const Appliance: FC<Props> = ({ appliance }) => {
  const { id, label } = appliance;
  const [showDetail, setShowDetail] = useState(false);

  return (
    <>
      <ApplianceDetail
        appliance={appliance}
        onClose={() => setShowDetail(false)}
        visible={showDetail}
      />
      <div
        id="appliance"
        onClick={() => setShowDetail(true)}
        className={styles.appliance}
      >
        <Label key={id} className={styles.applianceLabel} color="white">
          {label}
        </Label>
      </div>
    </>
  );
};

export default Appliance;
