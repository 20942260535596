import React from "react";
import styled from "styled-components";
import { Flex, Text } from "rebass";
import theme from "theme/theme.main";

import Bank from "svg/Bank";

const Container = styled(Flex)`
  cursor: pointer;
  border: 1px solid ${theme.colors.primary05};
  transition: opacity 0.5s ease-in-out;
  &:hover {
    opacity: 75%;
  }
`;

interface Props {
  onClick(): void;
}

function AddBankButton({ onClick, ...rest }: Props) {
  return (
    <Container
      backgroundColor="white"
      width="100%"
      maxWidth="350px"
      flexDirection="row"
      alignItems="center"
      justifyContent="start"
      padding="19px 23.5px"
      onClick={onClick}
      {...rest}
    >
      <Bank />
      <Flex
        paddingLeft="21.5px"
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
      >
        <Text fontSize={2} pb="-2px">
          ACH / Bank Transfer
        </Text>
        <Text fontSize={[0, 1, 1]} color="chamomile06">
          Choose from over 9,000 banks
        </Text>
      </Flex>
    </Container>
  );
}

export default AddBankButton;
