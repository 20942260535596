import React, { FC } from "react";

import Label from "components/Label";
import { currencyFormatter } from "utils/currency";

import styles from "./styles.module.scss";

type Props = {
  subtotal: number;
  taxPercentage: number;
  taxAmount: number;
  total: number;
};

const InvoiceSummary: FC<Props> = ({
  subtotal,
  taxPercentage,
  taxAmount,
  total,
}) => (
  <div className={styles.summary}>
    <Label className={styles.invoiceTitle} type="bold">
      Invoice for home owner
    </Label>
    <div className={styles.row}>
      <Label type="italic">Subtotal</Label>
      <Label type="bold">{currencyFormatter.format(subtotal)}</Label>
    </div>
    <div className={styles.row}>
      <Label type="italic">Tax</Label>
      <Label type="bold">{`${taxPercentage}% - ${currencyFormatter.format(
        taxAmount
      )}`}</Label>
    </div>
    <div className={styles.row}>
      <Label type="italic-bold">Total</Label>
      <Label type="bold">{currencyFormatter.format(total)}</Label>
    </div>
  </div>
);

export default InvoiceSummary;
