/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";

import { useRecoilValue } from "recoil";
import { SharedFlowState } from "components/Flow/types";
import { addSource, addBankSource, addPaymentFromPlaid } from "api/payments";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import { Source } from "types/models";
import { Source as StripeSource } from "@stripe/stripe-js";

import { PAYMENT_METHODS } from "./constants";

type Props = {
  paymentAdded?(source: Source): void;
  sharedFlowState: SharedFlowState;
  handleClose?(): void;
};

const AddPaymentMethod: FC<Props> = ({
  sharedFlowState,
  paymentAdded,
  handleClose,
}) => {
  const flowState = useRecoilValue(sharedFlowState);
  const notificationDispatcher = useNotificationDispatcher();

  const { paymentMethod } = flowState;

  const onPlaidToken = (token: any, metadata: any) => {
    addPaymentFromPlaid(token, metadata.account_id)
      .then((resp) => {
        const { account } = metadata;
        const { name, mask } = account;
        notificationDispatcher({
          description: `Successfully added payment method for ${name} (${mask})`,
          type: "success",
        });
        paymentAdded?.(resp);
      })
      .catch((err) => {
        notificationDispatcher({
          description:
            err.response?.data?.error ||
            "Failed to connect bank account. Please try again or contact support",
          type: "error",
        });
      });
  };

  const onBankToken = (token: string) => {
    addBankSource(token, true)
      .then((response) => {
        notificationDispatcher({
          description: "Successfully added bank account!",
          type: "success",
        });
        paymentAdded?.(response);
      })
      .catch((err) => {
        notificationDispatcher({
          description:
            err.response?.data?.error ||
            "Failed to connect bank account. Please try again or contact support",
          type: "error",
        });
      });
  };

  const onAddCard = async (source: StripeSource) => {
    await addSource(source.id, true)
      .then((response) => {
        paymentAdded?.(response);
      })
      .catch((err) => {
        notificationDispatcher({
          description:
            err.response?.data?.error ||
            "Failed to add credit card. Please try again or contact support",
          type: "error",
        });
      });
  };

  const Component = PAYMENT_METHODS(paymentMethod);

  return (
    <Component
      handleClose={handleClose}
      addCreditCard={onAddCard}
      onBankToken={onBankToken}
      onPlaidToken={onPlaidToken}
    />
  );
};

AddPaymentMethod.defaultProps = {
  paymentAdded: undefined,
  handleClose: () => {},
};

export default AddPaymentMethod;
