import React, { FC } from "react";
import { Text } from "rebass";
import { Button } from "antd";

type Props = {
  handleOnClick: () => void;
  text: string;
  disabled?: boolean;
  id?: string;
  overridingStyles?: { [x: string]: string | number | boolean };
};

const ActionLink: FC<Props> = ({
  handleOnClick,
  text,
  disabled,
  id,
  overridingStyles,
}) => {
  return (
    <Button
      type="link"
      id={id}
      style={{
        padding: 0,
        height: "auto",
        fontWeight: 400,
        textDecoration: "underline",
        ...overridingStyles,
      }}
      disabled={disabled}
      onClick={handleOnClick}
    >
      <Text fontSize={1}>{text}</Text>
    </Button>
  );
};

ActionLink.defaultProps = {
  disabled: false,
  id: "",
  overridingStyles: {},
};

export default ActionLink;
