import ManagePaymentPreferences from "./components/ManagePaymentPreferences";
import PreOnboardingForm from "./components/PreOnboardingForm";
import Confirmation from "./components/Confirmation";
import Terms from "./components/Terms";

export const steps = [
  {
    Component: PreOnboardingForm,
    key: "about-you",
    progressLabel: "Tell us about you",
  },
  {
    Component: ManagePaymentPreferences,
    key: "payment-preferences",
    progressLabel: "Add payment info",
  },
  {
    Component: Terms,
    key: "terms-of-service",
    progressLabel: "Terms of service",
  },
  {
    Component: Confirmation,
    key: "confirmation",
    progressLabel: "Confirmation",
  },
];
