import React, { FC } from "react";

import Modal from "components/Modal";

import Flow from "components/Flow";
import { steps } from "./constants";

type Props = {
  handleClose: () => void;
  visible: boolean;
};

const AddVendor: FC<Props> = ({ visible, handleClose }) => {
  return (
    <Modal visible={visible} title="Create Vendor" handleClose={handleClose}>
      <Flow
        withoutNextStepButton
        withoutPreviousStepButton
        steps={steps(handleClose)}
      />
    </Modal>
  );
};

export default AddVendor;
