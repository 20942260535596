import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { createUser } from "api/auth";

import { useSessionValue } from "providers/SessionProvider";
import { useUser } from "providers/UserProvider";

import Button from "components/Button";
import Form from "components/Form";
import { getError } from "utils/errors";
import Label from "components/Label";
import { minLength, required } from "utils/form";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import useAsync from "hooks/use-async";

import styles from "./styles.module.scss";

const VendorInformation: FC = () => {
  const history = useHistory();
  const { setUser } = useUser();
  const { setSessionData } = useSessionValue();
  const notificationDispatcher = useNotificationDispatcher();

  const [onSubmit, submitting] = useAsync(
    async (formValues: {
      first_name: string;
      password: string;
      last_name: string;
      email: string;
    }) => {
      const {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
      } = formValues;

      const userPayload = {
        email,
        name: `${firstName} ${lastName}`,
        password,
        role: "VENDOR",
      };

      await createUser(userPayload)
        .then((resp) => {
          setUser(resp.user);
          setSessionData(resp);
          notificationDispatcher({
            type: "success",
            description: "Account created succesfully",
          });
          history.push("/vendor/onboarding");
        })
        .catch((error) => {
          notificationDispatcher({
            type: "error",
            description: getError(
              error.response.data,
              "Registration failed, please try again"
            ),
          });
        });
    }
  );

  return (
    <div className={styles.container}>
      <Label type="h4-centered">Create an account</Label>
      <Form
        className={styles.form}
        name="create-account"
        onSubmit={onSubmit}
        fields={[
          {
            placeholder: "First Name",
            id: "first_name",
            type: "text",
            validate: [required("Please fill in your first name")],
          },
          {
            placeholder: "Last Name",
            id: "last_name",
            type: "text",
            validate: [required("Please fill in your last name")],
          },
          {
            placeholder: "Email",
            id: "email",
            type: "text",
            validate: [required("Please enter your email")],
          },
          {
            placeholder: "Password (8+ characters)",
            id: "password",
            type: "password",
            validate: [
              required("Please enter your new password"),
              minLength(8, "Please enter 8 or more characters"),
            ],
          },
        ]}
      >
        {({ handleSubmit, invalid }) => (
          <Button
            className={styles.submit}
            loading={submitting}
            disabled={invalid}
            onClick={handleSubmit}
          >
            Create account
          </Button>
        )}
      </Form>
    </div>
  );
};

export default VendorInformation;
