/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import ActiveTag from "components/ActiveTag";
import { ColumnDefinition } from "components/Table/types";
import { Home, HomeRelationships, Market, User, UserRole } from "types/models";

export interface RowData {
  id: string;
  home_address__formatted: string;
  title: string;
  onboarded: boolean;
  home_coordinator: string;
  users: HomeRelationships[];
  market__name: string;
  home_expert: string;
  home_owner: boolean | undefined;
}

export const createData = (homes: Home[], markets: Market[]): RowData[] =>
  homes.map(
    ({
      home_address: { formatted },
      title,
      uid: id,
      has_onboarded: onboarded,
      users,
      market,
      owner,
    }) => {
      const home_coordinator =
        users?.find(({ role }) => role === UserRole.HomeCoordinator)?.user
          .name || "";

      const home_expert =
        users?.find(({ role }) => role === UserRole.HomeExpert)?.user.name ||
        "";

      return {
        id,
        home_address__formatted: formatted,
        title,
        onboarded,
        home_coordinator,
        home_expert,
        market__name:
          markets.find(({ id: marketId }) => market === marketId)?.name || "",
        users,
        home_owner: owner?.is_active,
      };
    }
  );

export const createColumns = (
  homeCoordinators: User[],
  homeExperts: User[],
  markets: Market[]
): ColumnDefinition<RowData>[] => [
  {
    accessor: "title",
    fixed: "left",
    Header: "Name",
    width: 200,
    filter: "ordering",
  },
  {
    Header: "Coordinator",
    accessor: "home_coordinator",
    width: 300,
    fixed: "left",
    filter: ["set", "n/a"],
    options: [
      { value: "null", label: "NOT ASSIGNED", key: "null" },
      ...homeCoordinators.map(({ uid, name }) => ({
        value: uid,
        label: name,
        key: uid,
      })),
    ],
  },
  {
    Header: "Customer status",
    accessor: "home_owner",
    width: 300,
    filter: "n/a",
    Cell: ({ value }: { value: boolean }) => (
      <ActiveTag
        customTagVariants={{
          active: "Active",
          inactive: value === undefined ? "No home owner" : "Inactive",
        }}
        variant={value ? "active" : "inactive"}
      />
    ),
  },
  {
    Header: "Home Expert",
    accessor: "home_expert",
    width: 300,
    filter: ["set", "n/a"],
    options: [
      ...homeExperts.map(({ uid, name }) => ({
        value: uid,
        label: name,
        key: uid,
      })),
    ],
  },
  {
    accessor: "market__name",
    Header: "Market",
    width: 300,
    filter: ["set", "ordering"],
    options: markets.map(({ name, id }) => ({
      value: String(id),
      label: name,
      key: String(id),
    })),
  },
  {
    accessor: "home_address__formatted",
    Header: "Address",
    width: 450,
  },
  {
    Header: "Onboarded",
    accessor: "onboarded",
    width: 150,
    Cell: ({ value }: { value: boolean }) => (
      <ActiveTag
        customTagVariants={{ active: "Onboarded", inactive: "Not onboarded" }}
        variant={value ? "active" : "inactive"}
      />
    ),
  },
];
