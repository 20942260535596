import Label from "components/Label";
import React, { FC } from "react";

import styles from "./styles.module.scss";

type Props = {
  message: string;
};

const ErrorBoundaryFallback: FC<Props> = ({ message }) => (
  <div className={styles.container}>
    <Label type="negative-h5-centered">{message}</Label>
  </div>
);

export default ErrorBoundaryFallback;
