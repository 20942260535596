import { AxiosError } from "axios";
import { JobAppointmentClient } from "types/client";

import { Event } from "types/models";

import { apiClient, ServerError } from ".";

const createAppointmentFromJob = async (appointment: JobAppointmentClient) => {
  try {
    const response = await apiClient.post<Event>(
      "/events/events/appointment_from_job/",
      appointment
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export { createAppointmentFromJob };
