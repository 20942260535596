export const RESTRICTED_ROUTES = {
  invoices: "/portal/invoices",
  projects: "/portal/projects",
  preferences: "/portal/preferences",
};

export const FULL_RESTRICTED_ROUTES = {
  invoices: "/portal/invoices/:homeID?/:invoiceID?",
};

export const ALL_RESTRICTED_ROUTES = [
  RESTRICTED_ROUTES.invoices,
  RESTRICTED_ROUTES.preferences,
  RESTRICTED_ROUTES.projects,
];

export const PRIVATE_ROUTES = {
  onboarding: "/portal/onboarding",
  waitingForOps: "/portal/onboarding/await_contact",
  verify: "/portal/onboarding/verify",
};

export const PUBLIC_ROUTES = {
  vendorOnboarding: "/vendor/getstarted",
};

export const ROUTES = { ...PRIVATE_ROUTES, ...RESTRICTED_ROUTES };
