/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe, Elements } from "@stripe/react-stripe-js";

import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import Label from "components/Label";
import Form from "components/Form";
import Button from "components/Button";
import { onlyNumbers, required } from "utils/form";
import useAsync from "hooks/use-async";

import styles from "./styles.module.scss";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK ?? "");

interface Props {
  onToken(token: string): void;
  previousStep(): void;
}

function AddAccountForm({ onToken, previousStep }: Props) {
  const stripe = useStripe();

  const notificationDispatcher = useNotificationDispatcher();

  const [submit, submitting] = useAsync(async (values: any) => {
    if (stripe) {
      const params = {
        country: "US",
        currency: "usd",
        usage: "source",
        ...values,
      };
      await stripe
        .createToken("bank_account", params)
        .then(({ token, error }) => {
          if (error) {
            notificationDispatcher({
              message: "Error",
              type: "error",
              description: error.message ?? "",
              duration: 7,
            });
          }
          if (token) {
            onToken(token.id);
          }
        });
    }
  });

  return (
    <div className={styles.container}>
      <Label className={styles.info} type="light-italic-centered">
        To verify your Bank Account, we will initiate two micro-deposits. The
        deposits will appear in your bank account in 2-3 business days.
      </Label>
      <Form
        disabled={submitting}
        onSubmit={submit}
        name="bank-account"
        fields={[
          {
            id: "account_holder_name",
            title: "Account Holder",
            placeholder: "Name of Business or Person",
            type: "text",
            validate: [required("Please enter the account holders name.")],
          },
          {
            id: "routing_number",
            title: "Routing Number",
            validate: [required("Please enter the routing number.")],
            type: "text",
            normalize: [onlyNumbers],
            placeholder: "322271627",
          },
          {
            id: "account_number",
            title: "Account Number",
            type: "text",
            normalize: [onlyNumbers],
            validate: [required("Please enter the account number.")],
            placeholder: "000123456789",
          },
          {
            title: "Account holder type",
            placeholder: "Select account holder type",
            id: "account_holder_type",
            type: "select",
            validate: [required("This field is required.")],
            options: [
              { key: "individual", value: "individual", label: "Individual" },
              { key: "company", value: "company", label: "Company" },
            ],
          },
        ]}
      >
        {({ handleSubmit, invalid }) => (
          <Button
            className={styles.submit}
            loading={submitting}
            disabled={invalid}
            onClick={handleSubmit}
          >
            Add account
          </Button>
        )}
      </Form>
      {/* <Button onClick={previousStep} variant="text">
        Go back
        </Button> */}
    </div>
  );
}

const AddBankAccountDetail: FC<Props> = ({ onToken, previousStep }) => {
  return (
    <Elements stripe={stripePromise}>
      <AddAccountForm previousStep={previousStep} onToken={onToken} />
    </Elements>
  );
};

export default AddBankAccountDetail;
