import { fetchHomes } from "api/v2/homes";
import Table from "components/Table";
import useAsyncState from "hooks/use-async-state";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Button from "components/Button";
import { isEmpty } from "utils/array";
import { fetchMarkets } from "api/markets";
import { fetchActiveUsers } from "api/users";
import { UserRole } from "types/models";
import { debounce } from "lodash";
import { purgeObject } from "utils/object";
import styles from "./styles.module.scss";

import { createData, createColumns, RowData } from "./constants";
import Reassign from "./components/Reassign";

const HomesManagement: FC = () => {
  const [totalHomes, setTotalHomes] = useState(0);
  const [selectedRows, setSelectedRows] = useState<RowData[]>([]);
  const [reassigning, setReassigning] = useState(false);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const {
    data: markets,
    refresh: refreshMarkets,
    inProgress: fetchingMarkets,
  } = useAsyncState([], fetchMarkets);

  const {
    data: homes,
    inProgress: loadingHomes,
    refresh: refreshHomes,
  } = useAsyncState([], async (payload) => {
    const response = await fetchHomes({
      expand: "users",
      ...payload,
    }).then(({ results, count }) => {
      setTotalHomes(count);
      return results;
    });

    return response;
  });

  const {
    data: homeCoordinators,
    inProgress: fetchingHomeCoordinators,
    refresh: fetchCoordinators,
  } = useAsyncState([], () =>
    fetchActiveUsers({
      role: UserRole.HomeCoordinator,
      page_size: "100",
    }).then(({ results }) => results)
  );

  const {
    data: experts,
    inProgress: fetchingExperts,
    refresh: fetchExperts,
  } = useAsyncState([], () =>
    fetchActiveUsers({
      role: UserRole.HomeExpert,
      page_size: "100",
    }).then(({ results }) => results)
  );

  useEffect(() => {
    refreshMarkets();
    fetchCoordinators();
    fetchExperts();
  }, []);

  const updateOnSearch = useCallback(
    debounce((params: string) => {
      const paramsObj = new URLSearchParams(params);

      refreshHomes(
        purgeObject({
          home_coordinator: paramsObj.get("home_coordinator"),
          home_expert: paramsObj.get("home_expert"),
          has_home_coordinator: paramsObj.get("has_home_coordinator"),
          has_home_expert: paramsObj.get("has_home_expert"),
          has_home_owner: paramsObj.get("has_home_owner"),
          market: paramsObj.get("market"),
          ordering: paramsObj.get("ordering"),
          search: paramsObj.get("search"),
        })
      );
    }, 300),
    []
  );

  useEffect(() => {
    updateOnSearch(search);
  }, [search]);

  const loading =
    fetchingMarkets ||
    loadingHomes ||
    fetchingHomeCoordinators ||
    fetchingExperts;

  return (
    <>
      <Reassign
        onModalClose={() => setSelectedRows([])}
        homesToUpdate={selectedRows}
        visible={reassigning}
        handleClose={() => setReassigning(false)}
        updateData={refreshHomes}
      />
      <Table
        searchPrompt="Search by title, address:"
        className={styles.table}
        withSearch
        reloadData={() => {
          updateOnSearch(search);
        }}
        headerActions={
          <div className={styles.headerActions}>
            <Button
              disabled={isEmpty(selectedRows)}
              onClick={() => setReassigning(true)}
            >
              Reassign
            </Button>
          </div>
        }
        loading={loading}
        onRowClick={({ original: data }) => {
          setSelectedRows([data]);
          setReassigning(true);
        }}
        rowClickDescription="Click to edit home assignments"
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        selectable
        columns={createColumns(homeCoordinators, experts, markets)}
        data={createData(homes, markets)}
        pagination={{
          count: totalHomes,
          page: Number(searchParams.get("page")) || 1,
          pageSize: 10,
        }}
      />
    </>
  );
};

export default HomesManagement;
