/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";

import Flow from "components/Flow";
// import LinkAccountButton from "./components/LinkAccountButton";
// import ManualAccountButton from "./components/ManualAccountButton";
import AddAccountForm from "./components/AddAccountForm";

interface AddBankProps {
  onPlaidToken: (token: string, metadata: any) => void;
  onBankToken: (token: string) => void;
}

const AddBank: FC<AddBankProps> = ({
  onPlaidToken,
  onBankToken,
}: AddBankProps) => {
  /* const SelectBankingOption: FC<{ nextStep(): void }> = ({ nextStep }) => (
    <>
      <LinkAccountButton onToken={onPlaidToken} />
      <ManualAccountButton
        onClick={() => nextStep()}
        style={{
          marginTop: "12px",
        }}
      />
    </>
  ); */

  const steps = [
    /* {
      Component: SelectBankingOption,
      key: "select-banking-option",
    }, */
    {
      Component: AddAccountForm,
      key: "add-bank-form",
      onToken: onBankToken,
    },
  ];

  return <Flow withoutPreviousStepButton withoutNextStepButton steps={steps} />;
};

export default AddBank;
