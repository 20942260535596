import React, { FC, useEffect } from "react";
import Label from "components/Label";
import LoadingComponent from "components/LoadingComponent";
import useProjects from "hooks/use-projects";
import { useParams } from "react-router-dom";
import { isEmpty } from "utils/array";
import { AiOutlineDatabase as DataIcon } from "react-icons/ai";
import ProjectItem from "./components/ProjectItem";

import styles from "./styles.module.scss";

const ProjectsList: FC = () => {
  const { homeID } = useParams<{ [key: string]: string }>();

  const { updateProjects, loadingProjects, projects } = useProjects();

  useEffect(() => {
    updateProjects({ home: homeID });
  }, [homeID]);

  return (
    <LoadingComponent className={styles.container} loading={loadingProjects}>
      {!isEmpty(projects) ? (
        projects.map((project) => <ProjectItem project={project} />)
      ) : (
        <div className={styles.noProjects}>
          <DataIcon className={styles.icon} />
          <Label className={styles.noProjectsLabel} color="primary" type="bold">
            No projects
          </Label>
        </div>
      )}
    </LoadingComponent>
  );
};

export default ProjectsList;
