import { AxiosError } from "axios";

import { TaxRate } from "types/models";

import { apiClient, ServerError } from ".";

const fetchTaxRates = async () => {
  try {
    const response = await apiClient.get<TaxRate[]>("/admin/tax_rates/");
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export { fetchTaxRates };
