/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from "react";
import { Button, Upload } from "antd";
import {
  BiImageAdd as AddIcon,
  BiDownload as DownloadIcon,
} from "react-icons/bi";
import { UploadFile } from "antd/lib/upload/interface";

import { createAttachment } from "api/attachments";
import { handleBulkDownload, handleDownload } from "utils/attachments";
import ErrorBar from "components/ErrorBar";

import Modal from "components/Modal";
import useAsync from "hooks/use-async";
import LoadingComponent from "components/LoadingComponent";
import styles from "./styles.module.scss";

interface CustomAttachment extends UploadFile {
  file: string;
}

export type { CustomAttachment as Attachment };

const HomeAttachmentsField: FC<{
  onChange: (newValue: CustomAttachment[]) => void;
  error?: string;
  initialValue?: CustomAttachment[];
  id?: string;
  onDelete?(uid: string): void;
  selectedHome: string;
}> = ({ onChange, error, initialValue = [], id, onDelete, selectedHome }) => {
  const [files, setFiles] = useState<CustomAttachment[]>(
    initialValue.map(({ file, ...attachment }) => ({
      ...attachment,
      url: file,
      file,
    }))
  );

  const [internalError, setInternalError] = useState("");
  const [preview, setPreview] = useState<null | {
    title: string;
    url: string;
  }>(null);
  const [openPreview, setOpenPreview] = useState(false);

  useEffect(() => {
    onChange(files);
  }, [files]);

  useEffect(() => {
    if (internalError && selectedHome) setInternalError("");
  }, [selectedHome]);

  const [customUpload, uploading] = useAsync(
    async ({ onError, onSuccess, file }: any) => {
      if (!selectedHome)
        setInternalError("Please select a home to attach this image!");
      else {
        await createAttachment(file, selectedHome)
          .then((response) => {
            const newFile = {
              uid: response.uid,
              name: response.name,
              url: response.file,
              status: "done",
              file: response.file,
            } as CustomAttachment;

            if (files) setFiles([...files, newFile]);
            else setFiles([newFile]);

            onSuccess({ url: response.file }, file);
          })
          .catch((err) => {
            onError(err);
          });
      }
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Upload
          disabled={uploading}
          id={id}
          listType="picture-card"
          fileList={files}
          onDownload={handleDownload}
          customRequest={customUpload}
          onRemove={({ name, uid }) => {
            if (onDelete) onDelete(uid);
            setFiles(files.filter(({ name: fileName }) => fileName !== name));
          }}
          showUploadList={{ showDownloadIcon: true }}
          onPreview={(file: UploadFile) => {
            const url = file.url || "";

            if (url) {
              setOpenPreview(true);
              setPreview({ url, title: file.name || "" });
            }
          }}
        >
          <LoadingComponent loading={uploading}>
            <AddIcon className={styles.uploadIcon} />
          </LoadingComponent>
        </Upload>
        <Button
          disabled={!files?.length}
          type="text"
          onClick={() => handleBulkDownload(files)}
          className={styles.downloadButton}
        >
          <DownloadIcon className={styles.downloadIcon} />
        </Button>
      </div>
      <ErrorBar
        id={id}
        className={styles.errorBar}
        error={error || internalError}
      />
      <Modal
        contentClassName={styles.modalContent}
        visible={openPreview}
        title={preview?.title}
        handleClose={() => setOpenPreview(false)}
        onModalClosed={() => setPreview(null)}
      >
        <img
          alt={preview?.title}
          className={styles.preview}
          src={preview?.url}
        />
      </Modal>
    </div>
  );
};

HomeAttachmentsField.defaultProps = {
  error: undefined,
  initialValue: [],
  id: undefined,
  onDelete: undefined,
};

export default HomeAttachmentsField;
