import { Duration } from "types/models";
import moment, { Moment } from "moment";

import { pluralize } from "./strings";

const formattedTime = (startTime: Moment, endTime: Moment) => {
  const startTimeFormat = startTime.minutes() > 0 ? "h:mma" : "ha";
  const endTimeFormat = endTime.minutes() > 0 ? "h:mma" : "ha";

  return `${startTime.format(startTimeFormat)} - ${endTime.format(
    endTimeFormat
  )}`;
};

const formattedDuration = (duration: Duration) => {
  const { hours, minutes } = duration;

  const hrsDisplay = hours ? pluralize(hours, "hr") : null;
  const minsDisplay = minutes ? pluralize(minutes, "min") : null;
  return [hrsDisplay, minsDisplay].filter((x) => x).join(", ");
};

const displayTimeSince = (timestamp: Date) => {
  if (!timestamp) return null;

  if (moment(timestamp).isBefore(moment().subtract(1, "years"))) {
    return moment(timestamp).format("l");
  }
  if (moment(timestamp).isBefore(moment().subtract(6, "day"))) {
    return moment(timestamp).format("MMM Do");
  }
  if (moment(timestamp).isBefore(moment().subtract(1, "day"))) {
    return moment(timestamp).fromNow();
  }
  return moment(timestamp).format("LT");
};

export { formattedTime, formattedDuration, displayTimeSince };
