import { fetchHomePaymentMethods } from "api/payments";
import Label from "components/Label";
import LoadingComponent from "components/LoadingComponent";
import useAsyncState from "hooks/use-async-state";
import moment from "moment";
import React, { FC, useEffect } from "react";
import toast from "react-hot-toast";
import { Home, Source, UserRole } from "types/models";
import { isEmpty } from "utils/array";
import ActiveHomeToggle from "./components/ActiveHomeToggle";
import PaymentMethod from "./components/PaymentMethod";
import User from "./components/User";

import styles from "./styles.module.scss";

type Props = {
  loadingData: boolean;
  home: Home;
};

const Detail: FC<Props> = ({ loadingData, home }) => {
  const {
    data: paymentMethods,
    refresh: getPaymentMethods,
    inProgress: gettingPaymentMethods,
  } = useAsyncState<Source[]>([], () =>
    fetchHomePaymentMethods(home?.uid || "")
      .then(({ sources, bank_account }) => [...sources, ...bank_account])
      .catch(() => {
        toast.error("Failed to fetch Home's payment methods");
        return [];
      })
  );

  useEffect(() => {
    if (home?.uid) getPaymentMethods();
  }, [home]);

  const {
    title,
    home_address: homeAddress,
    created_at: createdAt,
    users,
    wifi_network: wifiNetwork,
    wifi_password: wifiPassword,
    access_details: accessDetails,
    kustomer_conversation_url: kustomerConversationUrl,
    owner,
  } = home || {};

  const { raw: address } = homeAddress || {};

  const homeMembers =
    users?.filter(({ role }) => role === UserRole.HomeMember) || [];

  const homeWatchers =
    users?.filter(({ role }) => role === UserRole.HomeWatcher) || [];

  const homeExperts =
    users?.filter(({ role }) => role === UserRole.HomeExpert) || [];

  const homeCoordinators =
    users?.filter(({ role }) => role === UserRole.HomeCoordinator) || [];

  return (
    <LoadingComponent
      loading={loadingData || gettingPaymentMethods}
      className={styles.container}
    >
      <ActiveHomeToggle />
      {owner && (
        <>
          <Label type="h6" color="primary">
            Homeowner
          </Label>
          <div className={styles.section}>
            <User user={owner} />
          </div>
        </>
      )}
      <Label type="h6" color="primary">
        Admin info
      </Label>
      <div className={styles.section}>
        <div className={styles.field}>
          <Label type="bold-italic">Home name:</Label>
          <Label ellipsis>{title}</Label>
        </div>
        <div className={styles.field}>
          <Label type="bold-italic">Home address:</Label>
          <Label ellipsis>{address}</Label>
        </div>
        {createdAt && (
          <div className={styles.field}>
            <Label type="bold-italic">Created at:</Label>
            <Label ellipsis>{moment(createdAt).format("MM/DD/YYYY")}</Label>
          </div>
        )}
        {kustomerConversationUrl && (
          <div className={styles.field}>
            <Label type="bold-italic">Kustomer Conversation:</Label>
            <div>
              <a
                href={kustomerConversationUrl}
                target="_blank"
                rel="noreferrer"
              >
                View
              </a>
            </div>
          </div>
        )}
        {!isEmpty(homeCoordinators) && (
          <div className={styles.verticalField}>
            <Label type="bold-italic">Home coordinators:</Label>
            <div>
              {homeCoordinators.map(({ user }) => (
                <User user={user} />
              ))}
            </div>
          </div>
        )}
        {!isEmpty(homeExperts) && (
          <div className={styles.verticalField}>
            <Label type="bold-italic">Home experts:</Label>
            <div>
              {homeExperts.map(({ user }) => (
                <User user={user} />
              ))}
            </div>
          </div>
        )}
        {!isEmpty(homeWatchers) && (
          <div className={styles.verticalField}>
            <Label type="bold-italic">Homewatchers:</Label>
            <div>
              {homeWatchers.map(({ user }) => (
                <User user={user} />
              ))}
            </div>
          </div>
        )}
        <div className={styles.field}>
          <Label type="bold-italic">Access details:</Label>
          <Label ellipsis>{accessDetails || "-"}</Label>
        </div>
        <Label type="bold-italic">Wifi</Label>
        <div className={styles.subSection}>
          <div className={styles.field}>
            <Label type="bold-italic">Wifi network:</Label>
            <Label ellipsis>{wifiNetwork || "-"}</Label>
          </div>
          <div className={styles.field}>
            <Label type="bold-italic">Wifi password:</Label>
            <Label ellipsis>{wifiPassword || "-"}</Label>
          </div>
        </div>
      </div>
      {!isEmpty(homeMembers) && (
        <>
          <Label type="h6" color="primary">
            Homemembers
          </Label>
          <div className={styles.section}>
            {homeMembers.map(({ user }) => (
              <User user={user} />
            ))}
          </div>
        </>
      )}
      {!isEmpty(paymentMethods) && (
        <>
          <Label type="h6" color="primary">
            Payment methods
          </Label>
          <div className={styles.section}>
            {paymentMethods?.map((source) => (
              <PaymentMethod key={source.id} source={source} />
            ))}
          </div>
        </>
      )}
    </LoadingComponent>
  );
};

export default Detail;
