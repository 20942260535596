import React, { useRef, RefObject, useContext, createContext, FC } from "react";

import { Instances } from "../types";

const DEFAULT_STATE = { lab: 0, molecules: {} };

export const LabContext = createContext<RefObject<Instances>>({
  current: DEFAULT_STATE,
});

export const Laboratory: FC = ({ children }) => {
  const labRef = useRef<Instances>(DEFAULT_STATE);

  return <LabContext.Provider value={labRef}>{children}</LabContext.Provider>;
};

export const useLab = (): RefObject<Instances> => useContext(LabContext);
