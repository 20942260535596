import { AxiosError } from "axios";

import { Activity } from "types/models";

import { apiClient, ServerError } from ".";

const fetchActivities = async (queryFilters: { [x: string]: string } = {}) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const response = await apiClient.get<Activity[]>(
      `/activity_log/?${queryParams}`
    );

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export { fetchActivities };
