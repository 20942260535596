import React, { FC } from "react";

import Form from "components/Form";
import Button from "components/Button";
import { createUser } from "api/auth";
import { PASSWORD_MIN_LENGTH } from "constants/site";

import Label from "components/Label";
import { minLength, onlyLetters, required, validEmail } from "utils/form";
import useAsync from "hooks/use-async";
import { AxiosError } from "axios";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import { getError } from "utils/errors";
import { SharedFlowState } from "components/Flow/types";
import { useSetRecoilState } from "recoil";
import styles from "./styles.module.scss";

type FormValues = {
  name: string;
  surname: string;
  email: string;
  password: string;
};

type Props = {
  nextStep(): void;
  sharedFlowState: SharedFlowState;
};

const CreateAccount: FC<Props> = ({ sharedFlowState, nextStep }) => {
  const setFlowState = useSetRecoilState(sharedFlowState);

  const notificationDispatcher = useNotificationDispatcher();

  const [submit, submitting] = useAsync(async (values: FormValues) => {
    const { email, password, name, surname } = values;
    const createUserProps = {
      email,
      name: `${name} ${surname}`,
      password,
      role: "VENDOR",
      accepted_terms_of_use: false,
    };

    await createUser(createUserProps)
      .then((resp) => {
        setFlowState((flowState) => ({
          ...flowState,
          vendorUID: resp.user.uid,
        }));
        nextStep();
      })
      .catch((error: AxiosError) => {
        notificationDispatcher({
          type: "error",
          description: getError(
            error.response?.data,
            "Registration failed, please try again"
          ),
        });
      });
  });

  return (
    <>
      <Label className={styles.title} color="primary" type="h6-centered">
        First, create an account
      </Label>
      <Form
        className={styles.form}
        disabled={submitting}
        onSubmit={submit}
        fields={[
          {
            placeholder: "First name",
            id: "name",
            type: "text",
            validate: [required("Please enter the vendor's first name")],
            normalize: [onlyLetters],
          },
          {
            placeholder: "Last name",
            id: "surname",
            type: "text",
            validate: [required("Please enter the vendor's last name")],
            normalize: [onlyLetters],
          },
          {
            placeholder: "Email",
            id: "email",
            type: "text",
            validate: [
              required("Please enter the vendor's email"),
              validEmail("Please enter a valid email"),
            ],
          },
          {
            placeholder: "Password",
            id: "password",
            type: "password",
            validate: [
              required("Please enter a password"),
              minLength(
                PASSWORD_MIN_LENGTH,
                `The password should be at least ${PASSWORD_MIN_LENGTH} characters long`
              ),
            ],
          },
        ]}
      >
        {({ handleSubmit, invalid }) => (
          <Button
            className={styles.submit}
            loading={submitting}
            disabled={invalid}
            onClick={handleSubmit}
          >
            Create account
          </Button>
        )}
      </Form>
    </>
  );
};

export default CreateAccount;
