/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { updateProject } from "api/projects";
import Button from "components/Button";
import CustomBadge from "components/CustomBadge";
import Form from "components/Form";
import Label from "components/Label";
import Modal from "components/Modal";
import {
  ArchivedProjectStatus,
  BadgeOptions,
  CompletedProjectStatus,
  ProjectStatus,
  ProjectStatusOptions,
} from "constants/statuses";
import useAsync from "hooks/use-async";
import React, { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { CancellationReason } from "types/models";
import { getError } from "utils/errors";
import { required } from "utils/form";
import styles from "./styles.module.scss";

interface Props {
  id: string;
  status: BadgeOptions;
  updateProjects(): void;
  assignee?: string;
  assignees: { value: string; label: string; key: string }[];
  project: string;
  cancellationReasons: CancellationReason[];
}

interface UpdatedStatus {
  status: ProjectStatus;
  assigned_to: string;
}

const UpdateStatus: FC<Props> = ({
  id,
  status,
  updateProjects,
  assignee,
  assignees,
  project,
  cancellationReasons,
}) => {
  const [shouldConfirm, setShouldConfirm] = useState(false);
  const [promptConfirmation, setPromptConfirmation] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleClose = () => setVisible(false);

  const [submit, submitting] = useAsync(
    ({ status: newStatus, assigned_to: assignedTo }: UpdatedStatus) => {
      return updateProject(id, {
        status: newStatus,
        ...(assignedTo ? { assigned_to: [assignedTo] } : {}),
      })
        .then(() => {
          toast.success("Successfully updated project status");
          updateProjects();
          handleClose();
        })
        .catch((err) =>
          toast.error(
            getError(
              err,
              "Something went wrong updating this project, try again later"
            )
          )
        );
    }
  );

  return (
    <>
      <Modal
        title={`Updating status - ${project}`}
        handleClose={handleClose}
        visible={visible}
      >
        <Form
          onChange={({ status: newStatus }: UpdatedStatus) => {
            if (
              newStatus &&
              [...ArchivedProjectStatus, ...CompletedProjectStatus].includes(
                newStatus
              )
            )
              setShouldConfirm(true);
            else {
              setShouldConfirm(false);
              setPromptConfirmation(false);
            }
          }}
          className={styles.form}
          initialValues={{ status: status.value }}
          fields={[
            {
              help: "This project was created in app and should have someone assigned before changing its status",
              title: "Assignee",
              placeholder: "Assignee",
              required: true,
              validate: [required("Please assign someone to this project")],
              id: "assigned_to",
              type: "select",
              options: assignees,
              hide: !!assignee,
            },
            {
              id: "status",
              type: "select",
              required: true,
              validate: [required("Please assign a status to this project")],
              title: "Status",
              placeholder: "Status",
              options: ProjectStatusOptions.map(({ value, display }) => ({
                value,
                label: display,
              })),
            },
            {
              hide: ({ status: formStatus }: UpdatedStatus) =>
                formStatus !== ProjectStatus.ARCHIVED,
              required: true,
              validate: [required("Please indicate the cancellation reason")],
              type: "radio",
              id: "cancellation_reason",
              title: "Please select reason for cancelling project",
              options: cancellationReasons.map(({ id: reasonId, reason }) => ({
                value: String(reasonId),
                label: reason,
              })),
            },
          ]}
          onSubmit={submit}
        >
          {({ handleSubmit, invalid, hasChanged, values }) => (
            <>
              {promptConfirmation && (
                <Label className={styles.prompt} type="positive-bold">
                  {`Are you sure you want to update this project status to
                  ${
                    ProjectStatusOptions.find(
                      ({ value }) => values.status === value
                    )?.display
                  }? This action will close all active chats and actions
                  with the customer related to this project.`}
                </Label>
              )}
              <Button
                variant={promptConfirmation ? "positive" : "primary"}
                disabled={invalid || !hasChanged}
                loading={submitting}
                onClick={
                  shouldConfirm
                    ? () => {
                        setPromptConfirmation(true);
                        setShouldConfirm(false);
                      }
                    : handleSubmit
                }
              >
                {promptConfirmation ? "Confirm" : "Update"}
              </Button>
            </>
          )}
        </Form>
      </Modal>
      <div onClick={() => setVisible(true)}>
        <CustomBadge
          status={String(status.value)}
          options={ProjectStatusOptions}
        />
      </div>
    </>
  );
};

UpdateStatus.defaultProps = {
  assignee: undefined,
};

export default UpdateStatus;
