/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

import VirtualizedList from "components/VirtualizedList";
import HomeCard from "views/Admin/views/Homes/views/HomesList/components/HomeCard";
import Loading from "components/LoadingComponent";
import TextInput from "components/TextInput";

import { homesList } from "recoil/homes";
import Label from "components/Label";

import _ from "lodash";
import styles from "./styles.module.scss";

const HomesEmptyState = () => {
  return (
    <div className={styles.noInformation}>
      <Label type="h5" color="primary">
        Search returned no results, please try again
      </Label>
    </div>
  );
};

type Props = {
  refreshHomes(...params: any[]): void;
  loadingHomes: boolean;
};

const HomesList: FC<Props> = ({ refreshHomes, loadingHomes }) => {
  const homes = useRecoilValue(homesList);

  const itemRenderer = useCallback((item) => <HomeCard home={item} />, []);

  const debouncedSearch = useMemo(() => _.debounce(refreshHomes, 500), []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Label className={styles.title} type="h5" color="primary">
          Homes
        </Label>
        <TextInput
          allowClear
          placeholder="Search by home name!"
          onChange={debouncedSearch}
        />
      </div>
      <Loading className={styles.listWrapper} loading={loadingHomes}>
        <VirtualizedList
          className={styles.list}
          itemPadding={15}
          elementsPerRow={3}
          NoDataComponent={HomesEmptyState}
          itemRenderer={itemRenderer}
          data={homes}
        />
      </Loading>
    </div>
  );
};

export default HomesList;
