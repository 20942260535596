import React, { FC } from "react";
import Flow from "components/Flow";

import { useTheme } from "theme/ThemeCreator";
import Topbar from "components/Topbar";
import { steps } from "./constants";
import styles from "./styles.module.scss";

const Onboarding: FC = () => {
  const { colors } = useTheme();

  return (
    <div className={styles.container}>
      <Topbar variant="secondary" />
      <Flow
        withoutNextStepButton
        withoutPreviousStepButton
        progressConfig={{ backgroundColor: colors.background }}
        showProgress
        className={styles.flow}
        steps={steps}
      />
    </div>
  );
};

export default Onboarding;
