import React, { FC } from "react";
import { Source } from "@stripe/stripe-js";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "antd";
import { Box, Flex } from "rebass";
import theme from "theme/theme.main";
import useAsync from "hooks/use-async";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";

type Props = {
  handleAddCard: (source: Source) => Promise<void>;
  handleClose(): void;
};

const CreditCardInput = () => {
  return (
    <Box
      backgroundColor="white"
      p={4}
      sx={{
        border: `1px solid ${theme.colors.primary05}`,
      }}
    >
      <CardElement
        options={{
          style: {
            base: {
              color: theme.colors.text,
            },
          },
        }}
      />
    </Box>
  );
};

const CreditCardForm: FC<Props> = ({ handleAddCard, handleClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const notificationDispatcher = useNotificationDispatcher();
  const [handleSubmit, loading] = useAsync(async () => {
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      await stripe
        .createSource(cardElement, { type: "card" })
        .then(async ({ error, source }) => {
          if (error)
            notificationDispatcher({
              type: "error",
              description: `Stripe could not create payment ${error.message}`,
            });
          else if (source) {
            await handleAddCard(source);
            handleClose();
          }
        });
    }
  });

  return (
    <Flex flexDirection="column" width="100%" maxWidth="350px" mt={4}>
      <CreditCardInput />
      <Button
        type="primary"
        disabled={!stripe}
        onClick={handleSubmit}
        style={{ width: "100%", marginTop: "20px" }}
        loading={loading}
        size="large"
      >
        Add Card
      </Button>
    </Flex>
  );
};

export default CreditCardForm;
