import { Tooltip } from "@mui/material";
import Label from "components/Label";
import React, { FC } from "react";
import { Invoice } from "types/models";
import { AiOutlineInfoCircle as InfoIcon } from "react-icons/ai";

import { currencyFormatter } from "utils/currency";

import styles from "./styles.module.scss";

interface Props {
  invoice: Invoice;
  className?: string;
}

const InvoiceSummary: FC<Props> = ({ invoice, className }) => {
  const { invoiceitems } = invoice;

  const creditCardFee = 0.029;

  const total = Number(invoice.total);
  const totalCC = total + total * creditCardFee;

  const subtotal = Number(invoice.subtotal);
  const subtotalCC = totalCC - Number(invoice.tax);

  return (
    <div className={`${styles.container} ${className}`}>
      <Label type="h7" color="primary" className={styles.divider}>
        Line items
      </Label>
      <div className={styles.lines}>
        {invoiceitems.map(({ description, amount }) => (
          <div className={styles.item}>
            <Label type="bold-italic">{description}</Label>
            <Label>{currencyFormatter.format(Number(amount))}</Label>
          </div>
        ))}
      </div>
      <Label type="h7" color="primary" className={styles.divider}>
        Subtotal
      </Label>
      <div className={styles.itemWrapper}>
        <div className={styles.optionalItem}>
          <Label type="bold-italic">Credit Card Fee</Label>
          <Label>2.9%</Label>
        </div>
        <Tooltip title="This gets added if the invoice is payed via Credit Card">
          <div className={styles.infoIcon}>
            <InfoIcon />
          </div>
        </Tooltip>
      </div>
      <div className={styles.item}>
        <Label type="bold-italic">Subtotal with ACH</Label>
        <Label>{currencyFormatter.format(subtotal)}</Label>
      </div>
      <div className={styles.item}>
        <Label type="bold-italic">Subtotal with CC</Label>
        <Label>{currencyFormatter.format(subtotalCC)}</Label>
      </div>
      <Label type="h7" color="primary" className={styles.divider}>
        Tax
      </Label>
      <div className={styles.item}>
        <Label type="bold-italic">Tax</Label>
        <Label>{currencyFormatter.format(Number(invoice.tax))}</Label>
      </div>
      <Label type="h7" color="primary" className={styles.divider}>
        Total
      </Label>
      <div className={styles.item}>
        <Label type="bold-italic">Total with ACH</Label>
        <Label>{currencyFormatter.format(total)}</Label>
      </div>
      <div className={styles.item}>
        <Label type="bold-italic">Total with CC</Label>
        <Label>{currencyFormatter.format(totalCC)}</Label>
      </div>
    </div>
  );
};

InvoiceSummary.defaultProps = {
  className: undefined,
};

export default InvoiceSummary;
