import Label from "components/Label";
import React, { FC } from "react";
import { HomeNote } from "types/models";
import { MdNoteAlt as NoteIcon } from "react-icons/md";
import {
  AiFillDelete as DeleteIcon,
  AiFillEdit as EditIcon,
} from "react-icons/ai";

import Button from "components/Button";
import useAsync from "hooks/use-async";
import { archiveHomeNote } from "api/homes";
import styles from "./styles.module.scss";

type Props = {
  note: HomeNote;
  updateHomeNotes(): void;
  onEditNote(noteID: string): void;
};

const Note: FC<Props> = ({ note, updateHomeNotes, onEditNote }) => {
  const { text, title, home_id: homeID, uid } = note;

  const [handleDelete, deleting] = useAsync(async () => {
    await archiveHomeNote(uid, homeID);
    updateHomeNotes();
  });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title}>
            <NoteIcon className={styles.noteIcon} />
            <Label ellipsis type="bold-italic">
              {title}
            </Label>
          </div>
          <div className={styles.actions}>
            <Button
              disabled={deleting}
              variant="secondary"
              onClick={() => onEditNote(uid)}
              Icon={<EditIcon className={styles.icon} />}
            />
            <Button
              variant="secondary"
              onClick={handleDelete}
              loading={deleting}
              Icon={<DeleteIcon className={styles.icon} />}
            />
          </div>
        </div>
        <Label breakWord>{text}</Label>
      </div>
    </div>
  );
};

export default Note;
