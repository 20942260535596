import React, { useEffect, FC } from "react";
import { Route, useRouteMatch } from "react-router-dom";

import { useUser } from "providers/UserProvider";
import { getHomesList } from "api/homes";
import { homesList } from "recoil/homes";
import useAsyncAtom from "hooks/use-async-atom";
import { Home } from "types/models";
import Content from "components/Content";

import useAppFilters from "components/GlobalFilters/hooks/use-app-filters";
import { purgeObject } from "utils/object";
import HomeDetail from "./views/HomeDetail";
import HomesList from "./views/HomesList";

const Homes: FC = () => {
  const { user } = useUser();
  const match = useRouteMatch();
  const { homeCoordinator } = useAppFilters();

  const { refresh: refreshHomes, inProgress: loadingHomes } = useAsyncAtom<
    Home[]
  >(homesList, (search: string) => {
    return getHomesList(
      purgeObject({
        ...(search ? { search } : {}),
        market: user?.market ? String(user.market) : "",
        home_coordinator: homeCoordinator,
      })
    );
  });

  useEffect(() => {
    refreshHomes();
  }, [user, homeCoordinator]);

  return (
    <Content>
      <Route exact path={match.path}>
        <HomesList refreshHomes={refreshHomes} loadingHomes={loadingHomes} />
      </Route>
      <Route path={`${match.path}/:homeID/`}>
        <HomeDetail />
      </Route>
    </Content>
  );
};

export default Homes;
