import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Form from "components/Form";
import Button from "components/Button";
import { addBankAccount, fetchCurrentVendor } from "api/vendors";
import { vendorUser } from "recoil/vendors";
import Label from "components/Label";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import useAsync from "hooks/use-async";
import { required } from "utils/form";
import Content from "components/Content";
import LoadingComponent from "components/LoadingComponent";

import useAsyncAtom from "hooks/use-async-atom";
import styles from "./styles.module.scss";

const PaymentPreferences: FC = () => {
  const notificationDispatcher = useNotificationDispatcher();
  const history = useHistory();
  const {
    data: vendor,
    inProgress: fetchingVendor,
    refresh: fetchVendor,
  } = useAsyncAtom(vendorUser, fetchCurrentVendor);

  useEffect(() => {
    fetchVendor();
  }, []);

  const [addPaymentInfo, addingPaymentInfo] = useAsync(
    async ({
      account_number: accountNumber,
      routing_number: routingNumber,
    }) => {
      if (!vendor || !vendor.bill_vendor_id || !vendor.uid)
        notificationDispatcher({
          type: "error",
          description: "Vendor is not in bill.com",
        });
      else
        await addBankAccount(vendor.uid, accountNumber, routingNumber)
          .then(() => {
            history.push("/vendor/success/");
          })
          .catch(() => {
            notificationDispatcher({
              type: "error",
              description: "Could not add bank account",
            });
          });
    }
  );

  return (
    <Content withSidebar>
      <LoadingComponent className={styles.loading} loading={fetchingVendor}>
        <Label type="h5-centered" color="primary">
          Payment preferences
        </Label>
        <Label type="centered" color="primary">
          Please add your bank information
        </Label>
        <Form
          className={styles.form}
          initialValues={{
            account_number: vendor?.bank_account?.account_number,
            routing_number: vendor?.bank_account?.routing_number,
          }}
          fields={[
            {
              id: "account_number",
              type: "text",
              placeholder: "Account Number",
              validate: [required("Please enter the account number")],
            },
            {
              id: "routing_number",
              type: "text",
              placeholder: "Routing Number",
              validate: [required("Please enter the routing number")],
            },
          ]}
          name="add-payment-information"
          onSubmit={addPaymentInfo}
        >
          {({ handleSubmit, invalid }) => (
            <Button
              className={styles.submit}
              disabled={invalid}
              loading={addingPaymentInfo}
              onClick={handleSubmit}
            >
              {vendor?.bank_account
                ? "Confirm Banking Info"
                : "Add Payment Info"}
            </Button>
          )}
        </Form>
      </LoadingComponent>
    </Content>
  );
};

export default PaymentPreferences;
