import Button from "components/Button";
import React, { FC } from "react";

import { RecoilState, useRecoilState } from "recoil";
import Form from "components/Form";
import { Values } from "components/FormConstructor/types";
import moment from "moment";
import styles from "./styles.module.scss";

type Props = {
  name: string;
  disabled: boolean;
  filtersState: RecoilState<{ [key: string]: string | undefined }>;
};

const SetFilter: FC<Props> = ({ name, disabled, filtersState }) => {
  const [tableFilters, setTableFilters] = useRecoilState(filtersState);

  return (
    <Form
      disabled={disabled}
      className={styles.dropdown}
      id={name}
      initialValues={
        tableFilters[`${name}__gte`]
          ? {
              [name]: [
                moment(tableFilters[`${name}__gte`]).toString(),
                moment(tableFilters[`${name}__lte`]).toString(),
              ],
            }
          : {}
      }
      fields={[
        {
          id: name,
          type: "range",
          props: { showPastDates: true, format: "MM/DD/YYYY" },
        },
      ]}
      onSubmit={(values: Values) => {
        setTableFilters({
          ...tableFilters,
          [`${name}__gte`]: values[name]
            ? moment(values[name][0]).format("YYYY-MM-DD")
            : undefined,
          [`${name}__lte`]: values[name]
            ? moment(values[name][1]).format("YYYY-MM-DD")
            : undefined,
          page: "1",
        });
      }}
    >
      {({ handleSubmit, clearForm }) => (
        <div className={styles.actions}>
          <Button size="small" onClick={handleSubmit}>
            Apply
          </Button>
          <Button
            size="small"
            onClick={() => clearForm()}
            variant="secondary-outlined"
          >
            Clear
          </Button>
        </div>
      )}
    </Form>
  );
};

export default SetFilter;
