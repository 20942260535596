import { atom } from "recoil";
import { Action } from "types/models";

const totalActionsState = atom<number>({
  key: "totalActionsState",
  default: 0,
});

const actionSelectedState = atom<Action | null>({
  key: "actionSelectedState",
  default: null,
});

const actionListState = atom<Action[] | null>({
  key: "actionListState",
  default: null,
});

export { totalActionsState, actionSelectedState, actionListState };
