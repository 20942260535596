import { AxiosError } from "axios";
import { v4 as uuid } from "uuid";

import {
  FinalizedInvoice,
  Invoice,
  InvoiceClient,
  InvoiceItem,
} from "types/models";

import { apiClient, ServerError } from ".";

const scheduleAppointment = async (information: {
  email: string;
  address: string;
  city: string;
  zip: string;
  state: string;
  country: string;
  appointment: string;
  first_name: string;
}) => {
  try {
    const response = await apiClient.post(`/v1/leads/`, {
      ...information,
      responder_uuid: uuid(),
      flow_label: "web-onboarding",
      variant_uuid: "f61dd332-44e5-49bb-914c-913f9cda7d00",
    });
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const deleteAppointment = async (appointmentID: string) => {
  try {
    const response = await apiClient.delete(`/admin/events/${appointmentID}/`);

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchInvoice = async (invoiceID: string) => {
  try {
    const response = await apiClient.get<Invoice>(
      `/admin/invoices/${invoiceID}/`
    );

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const sendInvoiceReminder = async (invoiceID: string) => {
  try {
    const response = await apiClient.post<Invoice>(
      `/admin/invoices/${invoiceID}/send-email-reminder/`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const createInvoice = async (invoice: InvoiceClient) => {
  try {
    const lines =
      invoice?.lines?.map((item: InvoiceItem) => {
        return {
          ...item,
          amount: Math.trunc(parseFloat(item.amount) * 100),
          tax_rates: invoice.default_tax_rates,
        };
      }) || [];

    const response = await apiClient.post<Invoice>("/admin/invoices/", {
      ...invoice,
      lines,
    });

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateInvoice = async (invoiceID: string, invoice: InvoiceClient) => {
  try {
    const response = await apiClient.patch<Invoice>(
      `/admin/invoices/${invoiceID}/`,
      invoice
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const finalizeInvoice = async (
  invoiceID: string,
  serviceRateOverride: number,
  paidExternally: boolean
) => {
  try {
    const payload = {
      service_rate_override: serviceRateOverride,
      paid_externally: paidExternally,
      payment_method_type: paidExternally ? "Paid Externally" : "none",
    };

    const response = await apiClient.post<FinalizedInvoice>(
      `/admin/invoices/${invoiceID}/finalize-invoice/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const createLineItem = async (invoiceID: string, newLineItem: InvoiceItem) => {
  try {
    const payload = {
      ...newLineItem,
      amount: Math.trunc(parseFloat(newLineItem.amount) * 100),
    };
    const response = await apiClient.post<InvoiceItem>(
      `/admin/invoices/${invoiceID}/invoiceitems/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateLineItem = async (
  invoiceID: string,
  updatedInvoiceItem: InvoiceItem
) => {
  try {
    const payload = {
      ...updatedInvoiceItem,
      amount: Math.trunc(parseFloat(updatedInvoiceItem.amount) * 100),
    };
    const response = await apiClient.patch<InvoiceItem>(
      `/admin/invoices/${invoiceID}/invoiceitems/${updatedInvoiceItem.id}/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const deleteLineItem = async (invoiceID: string, lineItemID: string) => {
  try {
    const response = await apiClient.delete(
      `/admin/invoices/${invoiceID}/invoiceitems/${lineItemID}`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export {
  fetchInvoice,
  createInvoice,
  updateInvoice,
  sendInvoiceReminder,
  finalizeInvoice,
  createLineItem,
  updateLineItem,
  deleteLineItem,
  scheduleAppointment,
  deleteAppointment,
};
