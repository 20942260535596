import React, { FC } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import { UserRole } from "types/models";
import { useUser } from "providers/UserProvider";

type Props = {
  roles?: UserRole[];
  authenticated: boolean | null;
  path: string | undefined;
  exact?: boolean;
  checkFor?: boolean;
};

const PrivateRoute: FC<Props> = ({
  roles = [],
  authenticated,
  path,
  exact,
  children,
  checkFor,
}) => {
  const { user } = useUser();
  const { pathname, search } = useLocation();
  const isAuthorized = authenticated && user && roles.includes(user.role);

  return (
    <Route
      exact={exact}
      path={path}
      render={({ location: newLocation }) =>
        isAuthorized ? (
          checkFor ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              search: `redirectUrl=${pathname}${search}`,
              state: { from: newLocation },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.defaultProps = {
  exact: false,
  roles: [],
  checkFor: true,
};

export default PrivateRoute;
