import React, { FC } from "react";
import { DatePicker as RootDatePicker } from "antd";
import moment from "moment";

import ErrorBar from "components/ErrorBar";

import styles from "./styles.module.scss";

type Props = {
  onChange(newValue: string | undefined): void;
  placeholder?: string;
  id?: string;
  value: string;
  disabled?: boolean;
  format?: string;
  valueFormat?: string;
  futureDates?: boolean;
  pastDates?: boolean;
  error?: string;
  showTime?: boolean;
};

const DatePicker: FC<Props> = ({
  onChange,
  placeholder,
  id,
  value,
  format = "MM/DD/YYYY",
  disabled,
  futureDates = true,
  pastDates = false,
  showTime,
  error,
  valueFormat,
}) => {
  return (
    <div className={styles.container}>
      <RootDatePicker
        showTime={showTime}
        id={id}
        dropdownClassName={styles.dropdown}
        className={styles.picker}
        format={format}
        disabledDate={
          futureDates
            ? (date: moment.Moment) => date.isBefore(moment())
            : pastDates
            ? (date: moment.Moment) => !date.isBefore(moment())
            : () => false
        }
        disabled={disabled}
        placeholder={placeholder}
        value={value ? moment(value) : undefined}
        onChange={(currentValue) => {
          onChange(currentValue?.format(valueFormat));
        }}
      />
      <ErrorBar id={id} error={error} />
    </div>
  );
};

DatePicker.defaultProps = {
  placeholder: "",
  id: undefined,
  disabled: false,
  format: "MM/DD/YYYY",
  futureDates: true,
  error: undefined,
  showTime: false,
  valueFormat: undefined,
  pastDates: false,
};

export default DatePicker;
