/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimatePresence } from "framer-motion";
import React, { ElementType, FC, memo } from "react";
import {
  Route as RouterRoute,
  Switch as RouterSwitch,
  RouteComponentProps,
  useLocation,
} from "react-router-dom";

import { UserRole } from "types/models";

import PrivateRoute from "./components/PrivateRoute";
import { levelPathname } from "./utils";

export { levelPathname };

interface Route {
  isPrivate?: boolean;
  exact?: boolean;
  path: string;
  Component?: ElementType;
  roles?: UserRole[];
  render?: ({ location }: RouteComponentProps) => JSX.Element;
  checkFor?: boolean;
  [key: string]: any;
}

type Props = {
  authenticated?: boolean;
  routes: Route[];
  FallbackComponent?: FC | null;
};

const Switch: FC<Props> = ({
  authenticated = false,
  routes,
  FallbackComponent,
}) => {
  const location = useLocation();

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      <RouterSwitch location={location}>
        {routes.map(
          ({
            isPrivate = false,
            exact = false,
            path,
            Component,
            roles,
            render,
            checkFor,
            ...props
          }) =>
            isPrivate ? (
              <PrivateRoute
                key={path}
                exact={exact}
                path={path}
                authenticated={authenticated}
                roles={roles}
                checkFor={checkFor}
              >
                {Component && <Component {...props} />}
              </PrivateRoute>
            ) : render ? (
              <RouterRoute
                key={path}
                path={path}
                exact={exact}
                render={render}
              />
            ) : (
              <RouterRoute key={path} path={path} exact={exact}>
                {Component && <Component {...props} />}
              </RouterRoute>
            )
        )}
        {FallbackComponent ? (
          <RouterRoute key={`${location.pathname}-FALLBACK`}>
            <FallbackComponent />
          </RouterRoute>
        ) : null}
      </RouterSwitch>
    </AnimatePresence>
  );
};

Switch.defaultProps = {
  FallbackComponent: null,
  authenticated: false,
};

export default memo(Switch);
