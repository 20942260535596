import React from "react";

import { Project } from "types/models";
import SubSection from "components/SubSection";
import { currencyFormatter } from "utils/currency";
import { formattedText } from "utils/strings";
import moment from "moment";
import Label from "components/Label";

import styles from "./styles.module.scss";

type ProjectCollapseDetailProps = {
  project: Project;
};

const ProjectCollapseDetail = ({ project }: ProjectCollapseDetailProps) => {
  return (
    <div className={styles.container}>
      <SubSection title="Description" flexDirection="column">
        {formattedText(project.details)}
      </SubSection>
      <div className={styles.doubleSection}>
        {project?.due_date && (
          <SubSection title="Due date">
            <Label>
              {`${moment(project.due_date).format("MM/DD/YY")} - In ${moment
                .duration(
                  moment(project.due_date).diff(moment(), "days"),
                  "days"
                )
                .humanize()}`}
            </Label>
          </SubSection>
        )}
        <SubSection title="Service offering" flexDirection="column">
          {project.service_offering?.full_name}
        </SubSection>
      </div>
      <div className={styles.doubleSection}>
        <SubSection title="Total Cost" flexDirection="column" width="50%">
          {currencyFormatter.format(project.total_amount_due)}
        </SubSection>
        <SubSection title="Outstanding Balance" flexDirection="column">
          {currencyFormatter.format(project.balance_remaining)}
        </SubSection>
      </div>
    </div>
  );
};

export default ProjectCollapseDetail;
