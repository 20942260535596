import React, { useEffect, FC } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  BsBellFill as ActionCenterIcon,
  BsLightbulbFill as RequestsIcon,
} from "react-icons/bs";
import {
  FaPencilRuler as ProjectsIcon,
  FaHome as HomesIcon,
  FaCheckSquare as CompletedIcon,
  FaArchive as ArchivedIcon,
} from "react-icons/fa";
import { AiFillSetting as ManageIcon } from "react-icons/ai";

import Sidebar from "components/Sidebar";
import { useUser } from "providers/UserProvider";
import { totalActionsState, totalRequestsState } from "recoil/atoms";

export const findBasePath = (pathname: string): string => {
  if (pathname.includes("/admin/projects")) {
    return "/admin/projects";
  }
  if (pathname.includes("/admin/requests")) {
    return "/admin/requests";
  }
  if (pathname.includes("/admin/jobs")) {
    return "/admin/jobs";
  }
  if (pathname.includes("/admin/homes")) {
    return "/admin/homes";
  }
  if (pathname.includes("/admin/action")) {
    return "/admin/actions";
  }
  return pathname;
};

const AdminNavigation: FC = () => {
  const { user } = useUser();
  const { pathname } = useLocation();
  const [actionsCount, setActionsCount] = useRecoilState(totalActionsState);
  const [requestsCount, setRequestsCount] = useRecoilState(totalRequestsState);
  const history = useHistory();

  const path = findBasePath(pathname);

  useEffect(() => {
    if (user?.totalActions) setActionsCount(user?.totalActions);
    if (user?.totalRequests) setRequestsCount(user?.totalRequests);
  }, [user, setActionsCount, setRequestsCount]);

  return (
    <Sidebar
      externalSelectedOption={path}
      options={[
        {
          key: "/admin/actions",
          label: "Action Center",
          onClick: () => history.push("/admin/actions"),
          Icon: ActionCenterIcon,
          notifications: actionsCount,
        },
        {
          key: "/admin/homes",
          label: "Homes",
          onClick: () => history.push("/admin/homes"),
          Icon: HomesIcon,
        },
        {
          key: "/admin/all-projects",
          label: "All Projects",
          onClick: () => history.push("/admin/all-projects"),
          Icon: ProjectsIcon,
          notifications: requestsCount,
        },
        {
          key: "/admin/requests",
          label: "Requested Projects",
          onClick: () => history.push("/admin/requests"),
          Icon: RequestsIcon,
          notifications: requestsCount,
        },
        {
          key: "/admin/projects",
          label: "Active Projects",
          onClick: () => history.push("/admin/projects"),
          Icon: ProjectsIcon,
        },
        {
          key: "/admin/completed-projects",
          label: "Completed Projects",
          onClick: () => history.push("/admin/completed-projects"),
          Icon: CompletedIcon,
        },
        {
          key: "/admin/archived-projects",
          label: "Archived Projects",
          onClick: () => history.push("/admin/archived-projects"),
          Icon: ArchivedIcon,
        },

        {
          key: "/admin/manage",
          label: "Manage",
          onClick: () => history.push("/admin/manage"),
          Icon: ManageIcon,
        },
      ]}
    />
  );
};

export default AdminNavigation;
