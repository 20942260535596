import { AxiosError } from "axios";
import { HomeResponse } from "types/responses";
import { apiClient, ServerError } from "api/index";

const fetchHomes = async (queryFilters: { [x: string]: string } = {}) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const response = await apiClient.get<HomeResponse>(
      `/homes/?timestamp=${new Date().getTime()}&${queryParams}`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export { fetchHomes };
