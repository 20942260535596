import React, { FC } from "react";

import Label from "components/Label";

import styles from "./styles.module.scss";

const Verify: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Label type="h4-centered" color="white">
          Welcome to
        </Label>
        <Label type="h4-centered" color="white">
          Humming Homes!
        </Label>
        <Label className={styles.subtitle} type="h5-centered" color="white">
          We look forward to meeting you!
        </Label>
        <Label type="centered" color="white">
          The rest of the app will be available after completing your onboarding
          appointment!
        </Label>
        <a className={styles.contactUs} href="mailto:hello@humminghomes.com">
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default Verify;
