/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Form, Input, message, Select } from "antd";
import { Flex, Text } from "rebass";
import { useRecoilState } from "recoil";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CgClose as CloseIcon } from "react-icons/cg";

import Button from "components/Button";
import { fetchVendors } from "api/vendors";
import { updateJob, createJob } from "api/jobs";
import SubSection from "components/SubSection";
import { jobSelectedState } from "recoil/atoms";
import { JobClient } from "types/client";
import { Job, Vendor } from "types/models";
import { formattedText } from "utils/strings";
import { CreateNewSelect } from "elements/dropdowns";
import AddVendor from "components/AddVendor";
import Loading from "components/Loading";

import Label from "components/Label";
import useProjects from "hooks/use-projects";
import useAsync from "hooks/use-async";
import LoadingComponent from "components/LoadingComponent";
import styles from "./styles.module.scss";

type InlineEditProps = {
  job: Job;
  editing: boolean;
  setEditing: (x: boolean) => void;
};

const InlineJobEdit = ({ job, editing, setEditing }: InlineEditProps) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const loc = useLocation();
  const { projectID } = useParams<{ [key: string]: string }>();
  const [selectedJob, setSelectedJob] = useRecoilState(jobSelectedState);
  const { updateProjectJobs } = useProjects();

  const [newJob, setNewJob] = useState<JobClient | { [x: string]: any }>({});

  const [loadingVendorsList, setLoadingVendorsList] = useState(false);

  const [addingVendor, setAddingVendor] = useState(false);
  const [vendorDropdown, setVendorDropdown] = useState<Vendor[]>([]);

  const handleInputChange = (event: {
    target: { value: any; name: string };
  }) => {
    const updatedJob: { [key: string]: any } = { ...newJob };
    const newValue = event.target.value;

    if (newValue) {
      updatedJob[event.target.name] = newValue;
    } else {
      delete updatedJob[event.target.name];
    }

    setNewJob(updatedJob as Vendor);
  };

  const [handleSubmit, submitting] = useAsync(async () => {
    setEditing(false);
    const updatedJob = newJob as Job;

    const invoicesCreated =
      selectedJob?.invoices && selectedJob.invoices.length > 0;
    const vendorChanged =
      newJob.selected_vendor &&
      newJob.selected_vendor !== selectedJob?.selected_vendor;

    const conditionsForDuplicate = invoicesCreated && vendorChanged;

    if (!conditionsForDuplicate) {
      await updateJob(updatedJob as Job, job?.uid || "")
        .then(() => {
          updateProjectJobs();
          message.success("Updated Job");
        })
        .catch(() => {
          message.error("Could not update Job");
        });
    } else {
      if (!selectedJob) return;
      const duplicateJob = {
        ...selectedJob,
        selected_vendor: updatedJob.selected_vendor,
        name: updatedJob.name || selectedJob.name,
      };
      await createJob(duplicateJob as Job)
        .then((jobResponse) => {
          updateProjectJobs();
          setSelectedJob(jobResponse);
          history.push(
            `/admin/projects/${projectID}/jobs/${jobResponse.uid}/${loc.search}`
          );
          message.success("Created duplicate job");
        })
        .catch(() => {
          message.error("Could not create duplicate job");
        });
    }
    setNewJob({});
  });

  const handleOpenModal = () => {
    setAddingVendor(true);
  };

  const handleCloseModal = () => {
    setAddingVendor(false);
  };

  const handleSearchVendors = (searchValue: string) => {
    const newInput: Vendor = {
      uid: "-1",
      vendor_name: searchValue,
    };
    if (searchValue === "") {
      setVendorDropdown([newInput]);
      return;
    }
    setLoadingVendorsList(true);
    fetchVendors({
      search: searchValue,
    })
      .then((vendors) => {
        setVendorDropdown([newInput, ...vendors]);
      })
      .catch(() => message.error("Could not load vendors"))
      .finally(() => setLoadingVendorsList(false));
  };

  return (
    <LoadingComponent loading={submitting}>
      <div className={styles.header}>
        <Label className={styles.title} type="h5" color="primary">
          Job
        </Label>
        <div className={styles.actions}>
          {editing ? (
            <>
              <Button
                size="medium"
                onClick={() => {
                  form
                    .validateFields()
                    .then((_values) => {
                      handleSubmit();
                    })
                    .catch(() => {
                      message.error("Please fill all required fields");
                    });
                }}
              >
                Save Job
              </Button>
              <Button
                className={styles.closeButton}
                variant="text"
                Icon={<CloseIcon />}
                onClick={() => setEditing(false)}
              />
            </>
          ) : (
            <Button
              size="medium"
              onClick={() => {
                setSelectedJob(job);
                setEditing(true);
              }}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      <Form
        form={form}
        initialValues={{
          name: job.name,
          details: job.details,
          project_id: job.project,
          vendor: job.vendor_name || job.selected_vendor,
        }}
      >
        {editing && (
          <>
            <SubSection title="Job Name" flexDirection="column">
              <Form.Item
                name="name"
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: "Please title this job" }]}
              >
                <Input
                  type="text"
                  name="name"
                  placeholder="Title this job"
                  style={{
                    border: "1px solid rgba(108, 114, 128, 0.2)",
                    borderRadius: "6px",
                  }}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </SubSection>
            <SubSection
              title="Service Provider"
              flexDirection="column"
              marginTop={4}
            >
              <Form.Item name="vendor_id" style={{ marginBottom: 0 }}>
                <CreateNewSelect
                  showSearch
                  allowClear
                  className="styled-dropdown"
                  placeholder={
                    !job.vendor_name ? (
                      "Type in vendor name..."
                    ) : (
                      <Text color="text">{job.vendor_name}</Text>
                    )
                  }
                  optionFilterProp="label"
                  filterOption
                  onSelect={(value: any) => {
                    if (value && value < 0) {
                      handleOpenModal();
                      return;
                    }
                    form.setFieldsValue({ vendor_id: value });
                    handleInputChange({
                      target: { name: "selected_vendor", value: value || null },
                    });
                    const invoicesCreated =
                      selectedJob?.invoices && selectedJob.invoices.length > 0;
                    const vendorChanged =
                      value !== selectedJob?.selected_vendor;
                    if (invoicesCreated && vendorChanged)
                      message.warning(
                        "You may want to change the name of the duplicate job"
                      );
                  }}
                  onSearch={(value) => {
                    handleSearchVendors(value);
                  }}
                  notFoundContent={
                    loadingVendorsList ? <Loading height="100px" /> : "No data"
                  }
                >
                  {!loadingVendorsList &&
                    vendorDropdown &&
                    vendorDropdown
                      .slice()
                      .sort((a, b) =>
                        a.uid === "-1"
                          ? -1
                          : b.uid === "-1"
                          ? 1
                          : 0 ||
                            (a.vendor_name || "").localeCompare(
                              b.vendor_name || ""
                            )
                      )
                      .filter(
                        (vendor) =>
                          (!!vendor.city && !!vendor.zip_code) ||
                          vendor.uid === "-1"
                      )
                      .map((vendor) => {
                        return (
                          <Select.Option
                            key={vendor.uid as string}
                            value={vendor.uid}
                            label={vendor.vendor_name}
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text>{vendor.vendor_name}</Text>
                              {Number(vendor.uid) < 0 && (
                                <Text
                                  id="create-new"
                                  padding={2}
                                  style={{ border: "2px solid #354760" }}
                                >
                                  Create New
                                </Text>
                              )}
                            </Flex>
                          </Select.Option>
                        );
                      })}
                </CreateNewSelect>
              </Form.Item>
            </SubSection>
            {selectedJob?.invoices && selectedJob?.invoices.length > 0 && (
              <Text marginTop={2}>
                *Duplicate job will be created if vendor is updated
              </Text>
            )}
          </>
        )}
        <SubSection title="Description" flexDirection="column" marginTop={3}>
          {editing ? (
            <Form.Item
              name="details"
              rules={[
                {
                  required: true,
                  message: "Please describe what needs to be done",
                },
              ]}
            >
              <Input.TextArea
                autoSize={{ minRows: 3 }}
                name="details"
                placeholder="Explain what needs to be done"
                style={{
                  border: "1px solid rgba(108, 114, 128, 0.2)",
                  borderRadius: "6px",
                }}
                onChange={handleInputChange}
              />
            </Form.Item>
          ) : (
            <Text width="70%">{formattedText(job.details)}</Text>
          )}
        </SubSection>
      </Form>
      <AddVendor visible={addingVendor} handleClose={handleCloseModal} />
    </LoadingComponent>
  );
};

export default InlineJobEdit;
