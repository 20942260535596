import React, { useEffect, useState, FC, useLayoutEffect } from "react";
import { Button, Tooltip } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Flex } from "rebass";
import { HiChevronLeft as ChevronLeft } from "react-icons/hi";

import HideLeftPanel from "svg/HideLeftPanel";
import theme from "theme/theme.main";
import LoadingComponent from "components/LoadingComponent";

import Label from "components/Label";
import Greeting from "components/Greeting";
import useProjects from "hooks/use-projects";
import Modal from "components/Modal";
import ViewPanel from "./components/ViewPanel";
import ProjectCreationAndModification from "../../components/ProjectCreationAndModification";
import ProjectSpecifics from "./components/ProjectSpecifics";
import AddJob from "./components/AddJob";

import styles from "./styles.module.scss";

const ProjectDetail: FC = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { projectID } = useParams<{ [key: string]: string }>();

  const [editing, setEditing] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [creatingJob, setCreatingJob] = useState(false);

  const {
    loadingProjectDetails,
    updateProjectDetails,
    clearProject,
    updateServiceCategories,
  } = useProjects();

  useEffect(() => {
    updateServiceCategories();
    return clearProject;
  }, []);

  useLayoutEffect(() => {
    updateProjectDetails(projectID, true);
  }, [projectID]);

  useEffect(() => {
    if (pathname.includes("/edit")) setEditing(true);
  }, [pathname, setEditing]);

  return (
    <>
      <AddJob visible={creatingJob} handleClose={() => setCreatingJob(false)} />
      <Modal
        contentClassName={styles.editPanel}
        handleClose={() => setEditing(false)}
        visible={editing}
      >
        <ProjectCreationAndModification
          onSubmit={() => {
            setEditing(false);
            updateProjectDetails();
          }}
        />
      </Modal>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.actions}>
            <Button
              className={styles.allProjectsButton}
              id="project-back-button"
              onClick={() => {
                history.push(`/admin/projects${search}`);
              }}
            >
              <ChevronLeft className={styles.chevron} />
              <Label type="button" color="primary">
                All Projects
              </Label>
            </Button>
          </div>
          <Tooltip
            placement="left"
            title={`${minimize ? "Show" : "Hide"} Project Details`}
          >
            <Flex
              onClick={() => setMinimize(!minimize)}
              css={{ cursor: "pointer" }}
            >
              <HideLeftPanel color={theme.colors.chamomile05} />
            </Flex>
          </Tooltip>
        </div>
        <LoadingComponent
          loading={loadingProjectDetails}
          className={styles.content}
        >
          <Greeting
            hi="expandWidth"
            goodbye="expandWidth"
            unmountOn={minimize}
            className={styles.actions}
          >
            <div className={styles.panel}>
              <ViewPanel
                setCreatingJob={() => setCreatingJob(true)}
                setEditing={setEditing}
              />
            </div>
          </Greeting>
          <div className={styles.projectSpecifics}>
            <ProjectSpecifics />
          </div>
        </LoadingComponent>
      </div>
    </>
  );
};

export default ProjectDetail;
