import React, { useState, useEffect, FC } from "react";
import { useLocation, useHistory } from "react-router-dom";

import Button from "components/Button";
import { fetchStripeCustomer } from "api/auth";
import { updateUser } from "api/users";
import { Source, SourceType } from "types/models";
import Loading from "components/Loading";
import { useUser } from "providers/UserProvider";
import PaymentSource from "components/PaymentSource";
import ROUTES from "routes";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";

import { useSetRecoilState } from "recoil";
import { pageTitleState } from "recoil/atoms";
import Modal from "components/Modal";
import Label from "components/Label";
import Content from "components/Content";
import { useSidebarInteraction } from "components/Sidebar";
import AddPayment from "./components/AddPayment";
import AutomaticPayment from "./components/AutomaticPayment";
import VerifyAccountForm from "./components/VerifyAccountForm";
import styles from "./styles.module.scss";

const Preferences: FC = () => {
  const { showSidebar } = useSidebarInteraction();
  const history = useHistory();
  const { pathname } = useLocation();

  const { user, setUser, customer, setCustomer } = useUser();

  useEffect(() => {
    showSidebar();
  }, []);

  useEffect(() => {
    if (pathname !== ROUTES.PREFERENCES) history.replace(ROUTES.PREFERENCES);
  }, []);

  const [showPaymentManagement, setShowPaymentManagement] = useState(false);
  const [verifyAccount, setVerifyAccount] = useState<Source | null>(null);
  const [selectedPayment, setSelectedPayment] = useState(false);
  const setPageTitle = useSetRecoilState(pageTitleState);
  const notificationDispatcher = useNotificationDispatcher();

  useEffect(() => {
    setPageTitle("Payment Preferences");
  }, [setPageTitle]);

  useEffect(() => {
    const defaultPayment = customer?.sources?.find((src) => src.default_source);
    if (defaultPayment) setSelectedPayment(true);
  }, [customer]);

  const fetchAndSetCustomer = async () => {
    const stripeCustomer = await fetchStripeCustomer();
    setCustomer(stripeCustomer);
  };

  const handleAutoPayment = async (switchOn: boolean) => {
    if (user) {
      const newUser = await updateUser(
        { automatic_payments_enabled: switchOn },
        user.uid
      );
      setUser(newUser);
    }
  };

  const handleNewPayment = () => {
    notificationDispatcher({
      type: "success",
      message: "Payments Updated",
      description:
        "A new payment has been added and set as the default payment",
    });
    fetchAndSetCustomer();
  };

  return (
    <Content withSidebar className={styles.container}>
      <Label type="h5" color="primary">
        Payment methods
      </Label>
      <div className={styles.content}>
        <Button
          className={styles.update}
          onClick={() => setShowPaymentManagement(true)}
        >
          Update payment preferences
        </Button>
        {!customer && <Loading />}
        <div className={styles.sources}>
          {customer?.sources
            ?.filter(
              (source) =>
                source.type === SourceType.BANK_ACCOUNT ||
                source.type === SourceType.CARD
            )
            .map((paymentSrc) => (
              <div className={styles.source}>
                <PaymentSource
                  key={paymentSrc.id}
                  withActions
                  source={paymentSrc}
                >
                  {paymentSrc.status === "new" && (
                    <Button
                      size="medium"
                      variant="primary"
                      onClick={() => {
                        setVerifyAccount(paymentSrc);
                      }}
                    >
                      Verify
                    </Button>
                  )}
                </PaymentSource>
              </div>
            ))}
        </div>
      </div>
      <Modal
        title="Add payment source"
        handleClose={() => setShowPaymentManagement(false)}
        visible={showPaymentManagement}
      >
        <AddPayment
          handleClose={() => setShowPaymentManagement(false)}
          paymentAdded={handleNewPayment}
        />
      </Modal>
      <Modal
        handleClose={() => setVerifyAccount(null)}
        title="Reassign Request?"
        visible={verifyAccount !== null}
      >
        {verifyAccount && (
          <VerifyAccountForm
            source={verifyAccount}
            onSuccess={(source) => {
              notificationDispatcher({
                type: "success",
                message: "Bank Account Verified",
                description: `The bank account at ${source.source_data.bank_name} ending in ${source.source_data.last4} has been verified`,
              });
              fetchAndSetCustomer();
              setVerifyAccount(null);
            }}
          />
        )}
      </Modal>
      <AutomaticPayment
        autoPaymentOn={user?.automatic_payments_enabled || false}
        selectedPayment={selectedPayment}
        switchAutoPayment={handleAutoPayment}
      />
    </Content>
  );
};

export default Preferences;
