/* eslint-disable @typescript-eslint/no-explicit-any */
import { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import { InvoiceItem, Project, User } from "types/models";
import { objectIsEmpty } from "./object";

/** Removes all null & duplicate users for table filter */
export const getUniqueRequestors = (fetchedRequests: Project[]) => {
  return fetchedRequests.reduce((uniqueUsers: User[], currProject) => {
    const createdBy = currProject.created_by_detail;
    if (!createdBy) return uniqueUsers;

    return uniqueUsers.find((uniqueUser) => uniqueUser.uid === createdBy.uid)
      ? uniqueUsers
      : [...uniqueUsers, createdBy];
  }, []);
};

/**
 * TODO: deprecate once repeated line item bug is fixed
 * (currently duplicate line items are appearing because
 * djstripe is giving each item two seperate id's)
 *
 * @param invoiceItems line items on an invoice
 */
export const getUniqueLineItems = (invoiceItems: InvoiceItem[]) => {
  return invoiceItems.reduce(
    (uniqueInvoiceItems: InvoiceItem[], currInvoiceItem) => {
      const currentItem = `${currInvoiceItem.description}-${currInvoiceItem.amount}`;
      const repeatedItem = uniqueInvoiceItems.find(
        (invItem) => `${invItem.description}-${invItem.amount}` === currentItem
      );

      return repeatedItem
        ? uniqueInvoiceItems
        : [...uniqueInvoiceItems, currInvoiceItem];
    },
    []
  );
};

type QueryParamsType = { [x: string]: string };

export const handleInputFilter = (
  event: any,
  searchFilters: (updatedFilters: QueryParamsType) => void
) => {
  const updatedDisplayFilters: QueryParamsType = {};
  const newValue = event.target.value || null;

  updatedDisplayFilters.search = newValue;
  searchFilters(updatedDisplayFilters);
};

/**
 * handles 3 different selection scenarios
 * 1. No dates selected => no filter
 * 2. One date selcted => user is not finished, no search
 * 3. Two dates selected => add to filter, hit endpoint
 *
 * @param dates date range of start & end
 */
export const handleDateFilter = (
  dates: RangeValue<Moment> | null,
  searchFilters: (updatedFilters: QueryParamsType) => void
) => {
  const updatedDisplayFilters: QueryParamsType = {};

  if (!dates) {
    updatedDisplayFilters.created_at__gte = null as any;
    updatedDisplayFilters.created_at__lte = null as any;
    searchFilters(updatedDisplayFilters);
    return;
  }
  if (!dates[0] || !dates[1]) return;

  const startDateRange = dates[0].format("YYYY-MM-DD");
  const endDateRange = dates[1].format("YYYY-MM-DD");
  updatedDisplayFilters.created_at__gte = startDateRange;
  updatedDisplayFilters.created_at__lte = endDateRange;
  searchFilters(updatedDisplayFilters);
};

/**
 * Adds, updates, and deletes new filters on url param.
 * In order to delete a param, searchQuery must have
 * that key's value be null
 *
 * updateStickyFilters("?a=foo&b=bar", {b: null}) => "a=foo"
 * updateStickyFilters("?a=foo&b=bar", {b: "foobar"}) => "a=foo&b=foobar"
 * updateStickyFilters("?a=foo&b=bar", {c: "oo_la_la"}) => "a=foo&b=bar&add=oo_la_la"
 *
 * @param existingStickyFilters - string of current params
 * @param searchQuery - any additional params added
 */
export const updateStickyFilters = (
  existingStickyFilters: string,
  newFilters: { [x: string]: string } = {}
) => {
  const newParams = { ...newFilters };
  const convertedFilters: { [x: string]: string } = newParams;
  const existingParams = existingStickyFilters?.split("?")[1];

  const pageUpdate = Object.keys(newParams).includes("page");

  if (existingParams) {
    existingParams.split("&").forEach((param) => {
      const [key, value] = param.split("=");

      if (!key || !value) return;
      if (newParams[key] === null) {
        delete convertedFilters[key];
      } else convertedFilters[key] = newParams[key] || value;
    });
    // if pagination update OR page reload, do not remove page filter
    if (!pageUpdate && !objectIsEmpty(newFilters)) delete convertedFilters.page;
  }

  Object.keys(convertedFilters).forEach((keyParam) => {
    if (convertedFilters[keyParam] === null) delete convertedFilters[keyParam];
  });

  const convertedToString = new URLSearchParams(convertedFilters);
  return decodeURIComponent(convertedToString.toString());
};
