/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as Logo } from "assets/updated_logo.svg";

import { useTheme } from "theme/ThemeCreator";

import styles from "./styles.module.scss";

type Props = {
  variant?: string;
  actions?: JSX.Element[];
};

const Topbar: FC<Props> = ({ variant = "primary", actions = [] }) => {
  const { topbar } = useTheme();
  const history = useHistory();

  const { backgroundColor, logoColor } = topbar[variant];

  return (
    <div style={{ backgroundColor }} className={styles.simpleTopbar}>
      <div className={styles.clickable} onClick={() => history.push("/")}>
        <Logo style={{ fill: logoColor }} className={styles.logo} />
      </div>
      <div className={styles.actions}>{actions}</div>
    </div>
  );
};

Topbar.defaultProps = {
  variant: "primary",
  actions: [],
};

export default Topbar;
