import { mapper } from "utils/mapping";
import ImagePreview from "./components/ImagePreview";
import MiscPreview from "./components/MiscPreview";
import PDFPreview from "./components/PDFPreview";

export const previewRenderers = mapper(
  {
    jpg: ImagePreview,
    jpeg: ImagePreview,
    png: ImagePreview,
    pdf: PDFPreview,
  },
  MiscPreview
);
