import Label from "components/Label";
import React, { FC } from "react";

import styles from "./styles.module.scss";

type Props = {
  placeholder: string;
};

const LabelField: FC<Props> = ({ placeholder }) => (
  <div className={styles.container}>
    <Label type="h6" color="primary">
      {placeholder}
    </Label>
    <div className={styles.divider} />
  </div>
);

export default LabelField;
