import Label from "components/Label";
import React, { FC } from "react";

type Props = {
  title: string;
  amount: number;
};

const ProjectHeader: FC<Props> = ({ title, amount }) => (
  <div>
    <Label type="italic-bold">{`${title} (${amount})`}</Label>
  </div>
);
export default ProjectHeader;
