import Button from "components/Button";
import Form from "components/Form";
import React, { FC, useEffect } from "react";
import { PlanBlueprintItem, User } from "types/models";
import { required } from "utils/form";
import { PROJECT_ORIGIN } from "constants/projects";

import useAsync from "hooks/use-async";
import { addAttachmentsToProject } from "api/attachments";
import { ProjectClient } from "types/client";
import { createProject } from "api/projects";
import toast from "react-hot-toast";
import { isEmpty } from "utils/array";
import { Attachment } from "components/HomeAttachmentsField";
import useProjects from "hooks/use-projects";
import useAsyncState from "hooks/use-async-state";
import { getHomePlan } from "api/homes";
import Label from "components/Label";
import {
  ArchivedProjectStatus,
  CompletedProjectStatus,
  ProjectStatus,
  ProjectStatusOptions,
} from "constants/statuses";
import styles from "./styles.module.scss";

interface Props {
  assignees: User[];
  onSubmit?(): void;
  home: string;
}

interface FormValues {
  plan_blueprint_item: string;
  name: string;
  details: string;
  service_offering: number;
  home: string;
  attachments: Attachment[];
  additional_notes: string;
  annual_maintenance: boolean;
  tenant_request: boolean;
  assigned_to: string;
  due_date: string;
}

const MaintenancePlanProject: FC<Props> = ({ assignees, onSubmit, home }) => {
  const {
    data: maintenancePlan,
    refresh: getMaintenancePlan,
    inProgress: fetchingMaintenancePlan,
  } = useAsyncState(null, () => getHomePlan({ home }), {
    failureCallback: () =>
      toast.error("No maintenance plan associated to this home", {
        position: "top-right",
      }),
  });

  useEffect(() => {
    getMaintenancePlan();
  }, [home]);

  const { updateProjectDetails, serviceCategories } = useProjects();

  const [handleCreateProject, creatingProject] = useAsync(
    async (values: FormValues) => {
      const assignee = assignees.find(({ uid }) => uid === values.assigned_to);
      const { attachments, ...rest } = values;
      const newProject: ProjectClient = {
        ...rest,
        home,
        assigned_to: assignee ? [assignee.uid] : [],
      };
      const response = await createProject(newProject);

      if (response) {
        toast.success("Created Project", { position: "top-right" });

        if (Array.isArray(attachments) && !isEmpty(attachments)) {
          const { uid: projectUID } = response;

          await addAttachmentsToProject(
            projectUID,
            attachments.map(({ uid }) => uid)
          )
            .then(() =>
              toast.success("Uploaded Attachments", { position: "top-right" })
            )
            .catch(() =>
              toast.error("Failed to upload Attachments", {
                position: "top-right",
              })
            );
        }
        updateProjectDetails();
        onSubmit?.();
      } else {
        toast.error("Failed to create project", { position: "top-right" });
      }
    }
  );

  return (
    <Form
      renderExternally
      fields={[
        {
          required: true,
          placeholder: "Plan project",
          props: { ordered: true },
          type: "select",
          id: "plan_blueprint_item",
          options: maintenancePlan?.plan_blueprint_items.map((item) => ({
            label: item.service_offering.full_name,
            value: item.id,
          })),
        },
      ]}
    >
      {({ values: parentFormValues, formToRender }) => {
        const { plan_blueprint_item: blueprintItemId } = parentFormValues;
        const planItem = maintenancePlan?.plan_blueprint_items.find(
          ({ id }) => id === blueprintItemId
        ) as PlanBlueprintItem;

        const { service_offering: offering, description } = planItem || {};

        return (
          <div className={styles.innerForm}>
            <div className={styles.section}>
              <Label type="h6" color="primary">
                Select a Maintenance plan project
              </Label>
              {formToRender}
            </div>
            {!!planItem && (
              <Form
                disabled={fetchingMaintenancePlan}
                className={styles.form}
                initialValues={{
                  name: offering.full_name,
                  details: description,
                  service_category: offering.category?.parent?.id,
                  service_subcategory: offering.category?.id,
                  service_offering: offering.id,
                  home,
                }}
                fields={[
                  {
                    required: true,
                    title: "Service category",
                    type: "select",
                    id: "service_category",
                    disabled: true,
                    options: serviceCategories.map(({ name, id }) => ({
                      label: name,
                      value: id,
                    })),
                    validate: [required("This field is required")],
                    props: { ordered: true },
                  },
                  {
                    required: true,
                    title: "Service subcategory",
                    disabled: true,
                    type: "select",
                    id: "service_subcategory",
                    parents: [{ id: "service_category" }],
                    props: { ordered: true },
                    options: (values) => {
                      const { service_category: category } = values;

                      return (
                        (category &&
                          serviceCategories
                            .find(({ id }) => id === category)
                            ?.children?.map(({ name, id }) => ({
                              label: name,
                              value: id,
                            }))) ||
                        []
                      );
                    },
                    validate: [required("This field is required")],
                  },
                  {
                    required: true,
                    title: "Service offering",
                    disabled: true,
                    props: { ordered: true },
                    type: "select",
                    id: "service_offering",
                    parents: [
                      { id: "service_category" },
                      { id: "service_subcategory" },
                    ],
                    options: (values) => {
                      const {
                        service_category: category,
                        service_subcategory: subcategory,
                      } = values;

                      return (
                        (category &&
                          subcategory &&
                          serviceCategories
                            .find(({ id }) => id === category)
                            ?.children?.find(({ id }) => id === subcategory)
                            ?.service_offerings?.map(({ name, id }) => ({
                              label: name,
                              value: id,
                            }))) ||
                        []
                      );
                    },
                    validate: [required("This field is required")],
                  },
                  {
                    required: true,
                    type: "text",
                    id: "name",
                    validate: [required("This field is required")],
                    title: "Project's title",
                    disabled: true,
                  },
                  {
                    id: "status",
                    type: "select",
                    required: true,
                    validate: [
                      required("Please assign a status to this project"),
                    ],
                    title: "Status",
                    placeholder: "Status",
                    options: ProjectStatusOptions.map(({ value, display }) => ({
                      value,
                      label: display,
                    })).filter(
                      ({ value }) =>
                        ![
                          ...ArchivedProjectStatus,
                          ...CompletedProjectStatus,
                        ].includes(value as ProjectStatus)
                    ),
                  },
                  {
                    required: true,
                    type: "area",
                    id: "details",
                    validate: [
                      required("Please describe what needs to be done"),
                    ],
                    title: "Description",
                  },
                  {
                    type: "date",
                    id: "due_date",
                    title: "Due date",
                  },
                  {
                    title: "Internal notes",
                    type: "area",
                    id: "additional_notes",
                  },
                  {
                    required: true,
                    type: "select",
                    title: "Assignment",
                    id: "assigned_to",
                    options: assignees.map(({ name, uid }) => ({
                      value: uid,
                      label: name,
                    })),
                    validate: [
                      required("Please assign someone to the project"),
                    ],
                  },
                  {
                    id: "project_origin",
                    title: "Project's origin",
                    type: "select",
                    options: PROJECT_ORIGIN,
                  },
                  {
                    id: "is_emergency",
                    placeholder: "Emergency",
                    type: "checkbox",
                  },
                  {
                    id: "tenant_request",
                    placeholder: "Requested by tenant",
                    type: "checkbox",
                  },
                  {
                    id: "attachments",
                    type: "home-attachments",
                    title: "Attachments",
                    props: { selectedHome: home },
                  },
                ]}
                onSubmit={(values) => {
                  handleCreateProject({ ...values, ...parentFormValues });
                }}
              >
                {({ handleSubmit, invalid }) => (
                  <Button
                    loading={creatingProject || fetchingMaintenancePlan}
                    className={styles.submit}
                    disabled={invalid}
                    onClick={handleSubmit}
                  >
                    Create Maintenance Project
                  </Button>
                )}
              </Form>
            )}
          </div>
        );
      }}
    </Form>
  );
};

MaintenancePlanProject.defaultProps = {
  onSubmit: undefined,
};

export default MaintenancePlanProject;
