import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { finalizeInvoice } from "api/admin";

import { jobSelectedState } from "recoil/atoms";
import { InvoiceStatus } from "types/models";
import useProjects from "hooks/use-projects";
import InvoiceSummary from "components/InvoiceSummary";
import toast from "react-hot-toast";
import Form from "components/Form";
import Button from "components/Button";
import useAsync from "hooks/use-async";

import { maxFloat, minFloat, required } from "utils/form";
import styles from "./styles.module.scss";

const FinalizeInvoice = () => {
  const loc = useLocation();
  const history = useHistory();
  const { projectID, jobID, invoiceID } = useParams<{
    [key: string]: string;
  }>();

  const { updateProjectJobs } = useProjects();

  const job = useRecoilValue(jobSelectedState);

  const invoice = job?.invoices?.find((inv) => inv.id === invoiceID);

  useEffect(() => {
    if (
      !job ||
      !job.selected_vendor ||
      !invoice ||
      !invoice.vendor_obj.uid ||
      invoice.status === InvoiceStatus.Open
    ) {
      toast.error("Malformed invoice");
      history.push(`/admin/projects/${projectID}/jobs/${jobID}${loc.search}`);
    }
  }, []);

  const [finalize, finalizing] = useAsync(
    async (values: {
      take_rate?: string;
      payment_details: "normal" | "payment-only";
    }) => {
      const { take_rate: takeRate, payment_details: details } = values;

      const paidExternally = details === "payment-only";

      await finalizeInvoice(
        invoice?.id || "",
        Number(takeRate) / 100,
        paidExternally
      )
        .then(() => {
          updateProjectJobs();
          toast.success("Invoice Sent");
        })
        .catch(() => {
          toast.error("Could not send invoice");
        })
        .finally(() => {
          history.push(
            `/admin/projects/${projectID}/jobs/${jobID}${loc.search}`
          );
        });
    }
  );

  return (
    <Form
      className={styles.form}
      onSubmit={finalize}
      initialValues={{ payment_details: "normal" }}
      fields={[
        {
          title: "Vendor Take Rate",
          type: "percentage",
          id: "take_rate",
          placeholder: "Vendor Take Rate",
          validate: [
            required("Please enter a vendor take rate"),
            minFloat("Percentage must be greater o equal to 0%", 0),
            maxFloat("Percentage must be lower than 100%", 100),
          ],
        },
        {
          id: "payment_details",
          title: "Payment Details",
          type: "radio",
          validate: [required("Please specify payment details")],
          required: true,
          options: [
            {
              label: "Normal Invoice (charge homeowner, pay vendor)",
              value: "normal",
            },
            {
              label:
                "Collect Payment Only (charge homeowner, don't pay vendor)",
              value: "payment-only",
            },
          ],
        },
      ]}
    >
      {({ handleSubmit }) => (
        <>
          {invoice && <InvoiceSummary invoice={invoice} />}
          <Button
            className={styles.submit}
            onClick={handleSubmit}
            loading={finalizing}
          >
            Send Invoice
          </Button>
        </>
      )}
    </Form>
  );
};

export default FinalizeInvoice;
