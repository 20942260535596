/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from "react";
import { Input, InputRef } from "antd";

import ErrorBar from "components/ErrorBar";

import styles from "./styles.module.scss";

type Props = {
  onChange: (newValue: string | undefined) => void;
  error?: string;
  placeholder?: string;
  normalize?: ((value: string) => string)[];
  value?: string;
  id?: string;
  disabled?: boolean;
  type?: string;
  autoComplete?: string;
  allowClear?: boolean;
  className?: string;
  showErrors?: boolean;
};

const TextInput = forwardRef<InputRef, Props>(
  (
    {
      onChange,
      error,
      placeholder,
      normalize,
      value,
      disabled,
      type = "text",
      autoComplete = "on",
      allowClear,
      id,
      className,
      showErrors = true,
    },
    ref
  ) => {
    return (
      <div className={`${styles.container} ${className}`}>
        <Input
          ref={ref}
          id={id}
          autoComplete={autoComplete}
          allowClear={allowClear}
          disabled={disabled}
          placeholder={placeholder}
          className={styles.input}
          value={value}
          type={type}
          onChange={(event: any) => {
            const { value: newValue } = event.target;
            if (!newValue) onChange(undefined);
            else
              onChange(normalize?.reduce((acc, curr) => curr(acc), newValue));
          }}
        />
        {showErrors && <ErrorBar id={id} error={error} />}{" "}
      </div>
    );
  }
);

TextInput.defaultProps = {
  error: undefined,
  placeholder: "",
  normalize: [],
  disabled: false,
  type: "text",
  autoComplete: "on",
  allowClear: false,
  value: undefined,
  id: undefined,
  className: undefined,
  showErrors: true,
};

export default TextInput;
