/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ElementType, ReactNode, useEffect, useState } from "react";
import { RecoilState, useRecoilState } from "recoil";
import { AiOutlineInfoCircle as InfoIcon } from "react-icons/ai";

import Label from "components/Label";

import { isDefined } from "utils/form";
import Greeting from "components/Greeting";
import { isEmpty } from "utils/array";
import { Tooltip } from "@mui/material";
import { purgeObject } from "utils/object";
import { Values, Value, Option } from "../../types";

import styles from "./styles.module.scss";

type Props = {
  help: NonNullable<ReactNode>;
  required: boolean;
  id: string;
  Component: ElementType;
  formDefinition: RecoilState<Values>;
  title?: string;
  placeholder?: string;
  options?: Option[];
  error?: string;
  disabled?: boolean;
  initialValue?: Value;
  normalize?: ((currentValue: Value) => Value)[];
  actions?: ReactNode;
  componentProps?: { [key: string]: any };
  parents?: { id: string }[];
  derivateValue?(form: Values): Value;
  defaultValue?: Values;
  showErrors: boolean;
  setFormErrors: (
    newErros: (errors: { [key: string]: string[] }) => {
      [key: string]: string[];
    }
  ) => void;
};

const FormItem: FC<Props> = ({
  defaultValue,
  id,
  Component,
  formDefinition,
  title,
  placeholder,
  options,
  error,
  disabled,
  initialValue,
  normalize,
  actions,
  componentProps,
  derivateValue = undefined,
  parents = [],
  required,
  help,
  showErrors,
  setFormErrors,
}) => {
  const [form, setForm] = useRecoilState<Values>(formDefinition);
  const [ignoreFirstRender, setIgnoreFirstRender] = useState(true);
  const [initialValuesSet, setInitialValuesSet] = useState(false);

  const currentValue = form[id];

  const onChange = (newValue: Value) => {
    setForm((currentForm) => ({ ...currentForm, [id]: newValue }));
  };

  const visible = parents.every(({ id: parentId }) =>
    isDefined(form[parentId])
  );

  const parentValues: { [key: string]: any } = {};

  parents.forEach(({ id: parentId }) => {
    parentValues[parentId] = form[parentId];
  });

  useEffect(() => {
    if (!visible) onChange(undefined);
  }, [visible]);

  useEffect(
    () => {
      if (initialValuesSet && ignoreFirstRender) {
        setIgnoreFirstRender(false);
        return;
      }

      if (!isEmpty(parents)) {
        onChange(undefined);
      }
    },
    parents.map((parent) => form[parent.id])
  );

  useEffect(() => {
    setInitialValuesSet(true);

    if (isDefined(initialValue) || isDefined(defaultValue)) {
      onChange(initialValue || defaultValue);
    }
  }, []);

  useEffect(() => {
    if (derivateValue) onChange(derivateValue(form));
  }, [derivateValue, derivateValue?.(form)]);

  return (
    <Greeting
      duration={0.2}
      hi="expandHeight"
      goodbye="expandHeight"
      unmountOn={!visible}
      className={styles.fieldWrapper}
    >
      {title && (
        <Label className={styles.title}>{`${title}${
          required ? " *" : ""
        }`}</Label>
      )}
      <div className={styles.field}>
        <Component
          showErrors={showErrors}
          values={form}
          normalize={normalize}
          initialValue={initialValue}
          error={error}
          options={options}
          placeholder={
            !title
              ? `${placeholder}${required && placeholder ? " *" : ""}`
              : placeholder
          }
          id={id}
          setError={(err: string) =>
            setFormErrors((errors) => ({
              ...errors,
              [`${id}__external`]: [err],
            }))
          }
          clearError={() =>
            setFormErrors((errors) =>
              purgeObject({
                ...errors,
                [`${id}__external`]: undefined,
              })
            )
          }
          value={currentValue}
          onChange={onChange}
          disabled={disabled}
          {...componentProps}
          {...parentValues}
        />
        {actions}
        {help && (
          <Tooltip arrow title={help}>
            <div className={styles.iconContainer}>
              <InfoIcon className={styles.infoIcon} />
            </div>
          </Tooltip>
        )}
      </div>
    </Greeting>
  );
};

FormItem.defaultProps = {
  title: "",
  placeholder: "",
  options: [],
  error: undefined,
  disabled: false,
  initialValue: undefined,
  normalize: [],
  actions: null,
  componentProps: {},
  parents: [],
  derivateValue: undefined,
  defaultValue: undefined,
};

export default FormItem;
