import Button from "components/Button";
import Label from "components/Label";
import React, { FC } from "react";
import { Heading } from "rebass";
import theme from "theme/theme.main";

import styles from "./styles.module.scss";

interface Props {
  nextStep(): void;
}

const Confirmation: FC<Props> = ({ nextStep }) => {
  return (
    <div className={styles.container}>
      <Heading
        fontSize="32px"
        lineHeight="1.3"
        color={theme.colors.success}
        fontWeight={500}
        marginTop={6}
      >
        Step 3 of 5
      </Heading>
      <Label type="centered-h4" color="primary">
        Check your email!
      </Label>
      <Label color="primary" type="centered-s1">
        We have sent you an invitation to set up your Bill.com account. Please
        accept the invite we sent to your email and finish creating your
        Bill.com account before continuing to Step 4. Once done, return to this
        page and continue with the remaining steps.
      </Label>
      <Button onClick={nextStep}>Next</Button>
    </div>
  );
};

export default Confirmation;
