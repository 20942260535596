import React, { FC } from "react";
import { FaCreditCard as PaymentPreferencesIcon } from "react-icons/fa";

import Label from "components/Label";
import { SourceData } from "types/models";
import { ChildrenType } from "types/components";

import Greeting from "components/Greeting";
import CardActions from "../CardActions";

import styles from "./styles.module.scss";

type Props = {
  children?: ChildrenType;
  sourceData: SourceData;
  paymentPreference: boolean;
  id: string;
  withActions: boolean;
  updateCustomer: () => void;
};

const Card: FC<Props> = ({
  sourceData,
  children,
  paymentPreference,
  id,
  updateCustomer,
  withActions,
}: Props) => {
  const { brand, last4, funding } = sourceData;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <PaymentPreferencesIcon className={styles.icon} />
        <div className={styles.information}>
          <Label type="italic-bold">{brand}</Label>
          <Label type="italic">{`${
            funding === "debit" ? "Debit" : "Credit"
          } Card ending in ${last4}`}</Label>
          <Greeting
            unmountOn={!paymentPreference}
            hi="expandHeight"
            goodbye="expandHeight"
            duration={0.3}
          >
            <Label type="bold-positive">Preferred Payment Method</Label>
          </Greeting>
          {children}
        </div>
      </div>
      {withActions && <CardActions id={id} updateCustomer={updateCustomer} />}
    </div>
  );
};

Card.defaultProps = {
  children: null,
};

export default Card;
