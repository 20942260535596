/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import { Button } from "antd";
import { useParams } from "react-router-dom";

import Label from "components/Label";
import { applianceModelsState } from "recoil/homes";
import LoadingComponent from "components/LoadingComponent";
import {
  fetchApplianceModels,
  createAppliance,
  fetchRooms,
  updateAppliance,
  deleteAppliance,
} from "api/homes";
import {
  addAttachmentsToAppliance,
  removeAttachmentsFromAppliance,
} from "api/attachments";
import { roomListState } from "recoil/atoms";
import Form from "components/Form";
import useAsyncAtom from "hooks/use-async-atom";
import { useRecoilValue } from "recoil";
import { formatApplianceModel } from "utils/strings";
import { required } from "utils/form";
import useAppFilters from "components/GlobalFilters/hooks/use-app-filters";

import useAsync from "hooks/use-async";
import { Values } from "components/FormConstructor/types";
import { Appliance, Attachment } from "types/models";
import styles from "./styles.module.scss";
import NewModel from "./components/NewModel";

const NewApplianceForm: FC<{
  initialValues?: { [key: string]: any };
  onSubmit(): void;
  editing?: boolean;
  applianceId?: string;
}> = ({ initialValues, onSubmit, editing, applianceId }) => {
  const [addingNewModel, setAddingNewModel] = useState(false);

  const { selectedHome } = useAppFilters();

  const {
    inProgress: fetchingApplianceModels,
    data: applianceModels,
    refresh: refreshApplianceModels,
  } = useAsyncAtom(applianceModelsState, () => fetchApplianceModels());

  const rooms = useRecoilValue(roomListState);

  useEffect(() => {
    refreshApplianceModels();
  }, []);

  const { homeID } = useParams<{ [key: string]: string }>();

  const { refresh } = useAsyncAtom(roomListState, () =>
    fetchRooms({ home: homeID, expand: "appliances" })
  );

  const [submit, submitting] = useAsync(async (values: Values) => {
    const { attachments, ...appliance } = values;
    const { id } = await createAppliance(appliance as Appliance);

    if (attachments)
      addAttachmentsToAppliance(
        id,
        attachments?.map((attachment: Attachment) => attachment.uid)
      );

    refresh();
    onSubmit();
  });

  const [submitUpdate, submittingUpdate] = useAsync(async (values: Values) => {
    const { id } = initialValues || {};
    const { attachments, ...appliance } = values;

    if (id) {
      await updateAppliance(id, appliance as Appliance, {
        expand: "room_details,model_details",
      });

      if (attachments)
        addAttachmentsToAppliance(
          id,
          attachments?.map((attachment: Attachment) => attachment.uid)
        );

      refresh();
      onSubmit();
    }
  });

  const [removeAppliancesAttachment, removingAttachment] = useAsync(
    async (uid: string) => {
      if (applianceId) await removeAttachmentsFromAppliance(applianceId, [uid]);
    }
  );

  const [removeAppliance, deleting] = useAsync(async () => {
    await deleteAppliance(applianceId ?? "");
    refresh();
    onSubmit();
  });

  return (
    <LoadingComponent
      className={styles.container}
      loading={fetchingApplianceModels}
    >
      <>
        <NewModel
          refreshModels={refreshApplianceModels}
          visible={addingNewModel}
          onClose={() => setAddingNewModel(false)}
        />
        <Form
          disabled={removingAttachment}
          initialValues={initialValues}
          onSubmit={editing ? submitUpdate : submit}
          name="new-appliance-form"
          fields={[
            {
              id: "model",
              type: "select",
              options: applianceModels.map(({ id, label, ...model }) => ({
                key: id?.toString() || "",
                value: id?.toString() || "",
                label: formatApplianceModel({ ...model, label, id }),
              })),
              title: "Brand",
              placeholder: "Choose appliance model",
              validate: [required("This field is required.")],
              actions: (
                <Button
                  onClick={() => setAddingNewModel(true)}
                  type="primary"
                  className={styles.newModelButton}
                >
                  <Label type="button" color="white">
                    Add new model
                  </Label>
                </Button>
              ),
            },
            {
              id: "serial_number",
              type: "text",
              title: "Serial number",
              placeholder: "Appliance serial number",
              validate: [required("This field is required.")],
            },
            {
              id: "room",
              type: "select",
              options: rooms?.map(({ id, floor, name }) => ({
                key: id.toString(),
                value: id.toString(),
                label: `${name} - Floor ${floor}`,
              })),
              title: "Room",
              placeholder: "Choose appliance's room",
              disabled: !editing && !!initialValues?.room,
            },
            {
              id: "notes",
              type: "area",
              title: "Notes",
              placeholder: "Notes on appliance",
            },
            {
              id: "attachments",
              type: "home-attachments",
              title: "Appliance's images",
              props: {
                selectedHome,
                onDelete: removeAppliancesAttachment,
              },
            },
          ]}
        >
          {({ handleSubmit }) => (
            <div className={styles.actions}>
              {editing && (
                <Button
                  loading={deleting}
                  disabled={
                    submitting ||
                    submittingUpdate ||
                    deleting ||
                    removingAttachment
                  }
                  className={styles.delete}
                  onClick={removeAppliance}
                >
                  <Label type="button" color="white">
                    Remove appliance
                  </Label>
                </Button>
              )}
              <Button
                loading={submitting || submittingUpdate}
                disabled={submitting || submittingUpdate || removingAttachment}
                className={styles.submit}
                type="primary"
                onClick={handleSubmit}
              >
                <Label type="button" color="white">
                  {editing ? "Update Appliance" : "Add Appliance"}
                </Label>
              </Button>
            </div>
          )}
        </Form>
      </>
    </LoadingComponent>
  );
};

NewApplianceForm.defaultProps = {
  initialValues: undefined,
  editing: false,
  applianceId: undefined,
};

export default NewApplianceForm;
