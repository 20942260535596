import Button from "components/Button";
import React, { FC } from "react";
import {
  HiChevronDown as DescendingIcon,
  HiChevronUp as AscendingIcon,
} from "react-icons/hi";
import { RecoilState, useRecoilState } from "recoil";
import { ORDER, orderFormatting } from "./constants";

import styles from "./styles.module.scss";

type Props = {
  name: string;
  disabled: boolean;
  filtersState: RecoilState<{ [key: string]: string | undefined }>;
};

const OrderFilter: FC<Props> = ({ name, disabled, filtersState }) => {
  const [tableFilters, setTableFilters] = useRecoilState(filtersState);

  const { ordering } = tableFilters;

  const isDescending = ordering?.charAt(0) === "-";
  const isThisFilter =
    (isDescending ? ordering?.slice(1, ordering.length) : ordering) === name;

  return (
    <div className={styles.container}>
      <Button
        variant="secondary"
        disabled={disabled}
        className={`${styles.button} ${
          isThisFilter && !isDescending && styles.selected
        }`}
        onClick={() =>
          setTableFilters({
            ...tableFilters,
            ordering: orderFormatting[ORDER.ASCENDING](name),
          })
        }
        Icon={<AscendingIcon />}
      />
      <Button
        variant="secondary"
        disabled={disabled}
        className={`${styles.button} ${
          isThisFilter && isDescending && styles.selected
        }`}
        onClick={() =>
          setTableFilters({
            ...tableFilters,
            ordering: orderFormatting[ORDER.DESCENDING](name),
          })
        }
        Icon={<DescendingIcon />}
      />
    </div>
  );
};

export default OrderFilter;
