/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Attachment } from "components/HomeAttachmentsField";
import Button from "components/Button";
import Label from "components/Label";
import Modal from "components/Modal";
import React, { FC, useState, ElementType } from "react";
import { handleDownloadAttachment } from "utils/attachments";
import { previewRenderers } from "./constants";
import styles from "./styles.module.scss";

interface Props {
  attachments: Attachment[];
}

interface PreviewRendererProps {
  attachment: Attachment;
}

const AttachmentsPreview: FC<Props> = ({ attachments }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [previewAttachment, setPreviewAttachment] = useState<Attachment | null>(
    null
  );
  const [PreviewRenderer, setPreviewRenderer] =
    useState<ElementType<PreviewRendererProps> | null>(null);

  return (
    <>
      <Modal
        className={styles.preview}
        title={previewAttachment?.name}
        visible={showPreview}
        handleClose={() => setShowPreview(false)}
        onModalClosed={() => {
          setPreviewAttachment(null);
          setPreviewRenderer(null);
        }}
        contentClassName={styles.previewWrapper}
      >
        {previewAttachment && (
          <>
            {PreviewRenderer && (
              <PreviewRenderer attachment={previewAttachment} />
            )}
            <Button onClick={() => handleDownloadAttachment(previewAttachment)}>
              Download
            </Button>
          </>
        )}
      </Modal>
      <div className={styles.attachments}>
        {attachments.map((attachment) => {
          const { url, name } = attachment || {};

          const nonNullName = name || attachment.file.split("/").pop() || "";

          const extension = nonNullName.split(".").pop();

          const Renderer: ElementType<PreviewRendererProps> | null =
            previewRenderers(extension);

          return (
            <div
              onClick={() => {
                setPreviewAttachment(attachment);
                setShowPreview(true);
                setPreviewRenderer(() => Renderer);
              }}
              key={`${url}-${name}`}
              aria-label={nonNullName}
              className={`${styles.attachment} ${
                !Renderer && styles.noPreview
              }`}
            >
              <Label color="primary" ellipsis>
                {nonNullName}
              </Label>
              {extension && (
                <Label className={styles.extension} type="uppercase-positive">
                  {extension}
                </Label>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AttachmentsPreview;
