import React from "react";
import Checkbox from "components/Checkbox";
import { mapper } from "utils/mapping";
import DateFilter from "./components/DateFilter";
import OrderFilter from "./components/OrderFilter";
import SetFilter from "./components/SetFilter";
import { ColumnDefinition, MinimumRow } from "./types";
import styles from "./styles.module.scss";
import NotAssignedFilter from "./components/NotAssignedFilter";

export const filterMapper = mapper(
  {
    date: DateFilter,
    set: SetFilter,
    ordering: OrderFilter,
    "n/a": NotAssignedFilter,
  },
  null
);

export const filterKeyMapper = mapper(
  {
    date: (key: string) => [`${key}__gte`, `${key}__lte`],
    "n/a": (key: string) => [`has_${key}`],
  },
  (key: string) => [key]
);

export const CheckBoxCol = <T extends MinimumRow>(
  selectRows: (newRows: T[]) => void,
  selectedRows: T[]
): ColumnDefinition<T> => ({
  interactable: true,
  Header: "",
  accessor: "id",
  Cell: ({
    value,
    row: { original },
  }: {
    value: string | number;
    row: { original: T };
  }) => {
    return (
      <div className={styles.checkboxWrapper}>
        <Checkbox
          placeholder=""
          value={!!selectedRows.find(({ id }) => id === value)}
          onChange={(checked) => {
            if (checked) {
              selectRows([...selectedRows, original]);
            } else {
              selectRows(selectedRows.filter(({ id }) => id !== value));
            }
          }}
        />
      </div>
    );
  },
  width: 100,
});
