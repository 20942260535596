import React, { FC, useEffect } from "react";
import { useSidebarInteraction } from "components/Sidebar";

import Flow from "components/Flow";
import { useTheme } from "theme/ThemeCreator";
import Label from "components/Label";
import styles from "./styles.module.scss";
import { steps } from "./constants";

const Verify: FC = () => {
  const { hideSidebar } = useSidebarInteraction();
  const { colors } = useTheme();

  useEffect(() => {
    hideSidebar();
  }, []);

  return (
    <div className={styles.container}>
      <Label className={styles.title} type="h5-centered" color="primary">
        Let&apos;s verify your account!
      </Label>
      <Flow
        progressConfig={{ backgroundColor: colors.background }}
        className={styles.flow}
        showProgress
        withoutNextStepButton
        withoutPreviousStepButton
        steps={steps}
      />
    </div>
  );
};

export default Verify;
