/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { Table, Pagination } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { Invoice, InvoiceStatus } from "types/models";

import { calculateTableHeight } from "components/ListView";
import InvoiceStatusTag from "components/InvoiceStatusTag";
import { currencyFormatter } from "utils/currency";
import { COL_WIDTH, CUS_INVOICE_PAGE_SIZE } from "constants/site";
import { updateStickyFilters } from "utils/projects";
import { SortedInfo } from "types/external";
import { InvoiceResponse } from "types/responses";
import LoadingComponent from "components/LoadingComponent";

import styles from "./styles.module.scss";

type Props = {
  invoiceResponse: InvoiceResponse;
  fetchingInvoices: boolean;
};

const InvoiceList: FC<Props> = ({ invoiceResponse, fetchingInvoices }) => {
  const loc = useLocation();
  const history = useHistory();
  const { tableHeight } = calculateTableHeight(1);

  const [paginationInfo, setPaginationInfo] = useState({
    numOfItems: invoiceResponse.count,
    prevLink: invoiceResponse.previous || null,
    nextLink: invoiceResponse.next || null,
    currentPage:
      Object.fromEntries(new URLSearchParams(loc.search)).page || "1",
  });

  useEffect(() => {
    setPaginationInfo({
      numOfItems: invoiceResponse.count,
      prevLink: invoiceResponse.previous || null,
      nextLink: invoiceResponse.next || null,
      currentPage:
        Object.fromEntries(new URLSearchParams(loc.search)).page || "1",
    });
  }, [invoiceResponse]);

  const columns = [
    {
      title: "Date",
      sorter: true,
      dataIndex: "created",
      key: "created",
      render: (date: Date) => {
        return date && moment(date).format("MM/DD/YYYY");
      },
      responsive: ["md"] as Breakpoint[],
      width: 150,
    },
    {
      title: "Provider",
      dataIndex: "vendor",
      key: "vendor",
      ellipsis: true,
      responsive: ["sm"] as Breakpoint[],
    },
    {
      title: "Description",
      sorter: true,
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      title: "Amount",
      sorter: true,
      dataIndex: "amount_due",
      key: "amount_due",
      width: COL_WIDTH.medium,
      render: (amount_due: string) => {
        return amount_due && `${currencyFormatter.format(Number(amount_due))}`;
      },
    },
    {
      title: "Status",
      sorter: true,
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (_status: InvoiceStatus, record: Invoice) => {
        return <InvoiceStatusTag invoice={record} />;
      },
    },
  ];

  const handlePagination = (pageNum: number) => {
    const stickyParams = updateStickyFilters(loc.search, {
      page: String(pageNum),
    });

    history.push(`${loc.pathname}?${stickyParams}`);
  };

  const handleTableChange = async (
    _pagination: any,
    _filters: any,
    sorter: any
  ) => {
    const sortingParam = sorter.order
      ? `${sorter.order === "ascend" ? "-" : ""}${sorter.columnKey.replace(
          "_detail_snippet",
          ""
        )}`
      : (null as any);
    const newSortedInfo: SortedInfo = {};
    newSortedInfo[sorter.columnKey] = sorter.order;

    const stickyParams = updateStickyFilters(loc.search, {
      ordering: sortingParam,
    });

    history.push(`${loc.pathname}?${stickyParams}`);
  };

  return (
    <>
      <LoadingComponent className={styles.container} loading={fetchingInvoices}>
        <Table
          rowKey={(record) => record.id}
          dataSource={invoiceResponse.results}
          columns={columns}
          pagination={false}
          scroll={{ y: tableHeight }}
          onChange={handleTableChange}
          showSorterTooltip={false}
          onRow={(record) => {
            return {
              onClick: () => {
                history.push(
                  `/portal/invoices/${record.home_id}/${record.id}${
                    loc.search && `/${loc.search}`
                  }`
                );
              },
              style: {
                cursor: "pointer",
              },
            };
          }}
        />
      </LoadingComponent>
      <Pagination
        defaultCurrent={1}
        showSizeChanger={false}
        defaultPageSize={Number(CUS_INVOICE_PAGE_SIZE)}
        total={invoiceResponse.count}
        current={Number(paginationInfo.currentPage)}
        onChange={(page) => handlePagination(page)}
      />
    </>
  );
};

export default InvoiceList;
