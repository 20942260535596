function identifyLogin(userID: string, email: string, name: string) {
  const segment = window.analytics;
  if (segment) {
    segment.identify({
      user: userID,
      email,
      name,
    });
  }
}

export { identifyLogin };
