/* eslint-disable @typescript-eslint/naming-convention */
import { Home, InvoiceClient, InvoiceItem, Job, Vendor } from "types/models";

export interface FormValues {
  due_date?: string;
  description: string;
  vendor_invoice_number?: string;
  lines: InvoiceItem[];
  tax?: string;
}

export const formatInvoiceForm = (
  formValues: FormValues,
  job: Job,
  home: Home,
  vendor: Vendor
): InvoiceClient => {
  const values = { ...formValues };
  const { tax } = values;

  delete values.tax;

  const homeOwner = home.users.find((user) => user.role === "HOME_OWNER")?.user
    .uid;

  return {
    ...values,
    default_tax_rates: tax ? [tax] : [],
    job_uid: job.uid,
    home_id: home.uid,
    home_name: home.title,
    vendor: vendor.vendor_name || "",
    vendor_id: vendor.uid,
    market_id: String(home.market),
    job_name: job.name,
    user_uid: homeOwner || "",
  };
};
