import React, { FC } from "react";
import { Button, Form, Input, message, Select } from "antd";
import { Flex, Heading, Text } from "rebass";
import { useRecoilState } from "recoil";

import { vendorUser } from "recoil/vendors";
import { STATE_ABBR } from "constants/site";
import { Event } from "types/components";

import theme from "theme/theme.main";
import { AddressInfoSelect } from "elements/dropdowns";

type Props = {
  nextStep(): void;
};

const AddressInfo: FC<Props> = ({ nextStep }: Props) => {
  const [form] = Form.useForm();
  const [vendor, setVendor] = useRecoilState(vendorUser);

  const handleInputChange = (event: Event) => {
    const { value, name: key } = event.target;

    setVendor({ uid: "", ...vendor, [key]: value });
  };

  const handleSubmit = () => {
    nextStep();
  };

  return (
    <>
      <Heading
        fontSize={5}
        lineHeight="1.3"
        color={theme.colors.success}
        fontWeight={500}
        marginTop={6}
      >
        Step 1 of 5
      </Heading>
      <Text fontSize="1rem" color="primary05" mb={3}>
        Please enter your address information
      </Text>
      <Flex
        margin={["0 auto", "0 auto", "24px auto 0"]}
        backgroundColor="white"
        padding={[4, 5]}
        style={{
          borderRadius: "8px",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        }}
        flexDirection="column"
        width={["85%", "65%"]}
        maxWidth="500px"
      >
        <Form
          form={form}
          initialValues={{
            vendor_name: vendor?.vendor_name,
            address_line_1: vendor?.address_line_1,
            address_line_2: vendor?.address_line_2,
            city: vendor?.city,
            state: vendor?.state,
            zip_code: vendor?.zip_code,
          }}
        >
          <Form.Item
            name="vendor_name"
            style={{ marginBottom: 0 }}
            rules={[
              { required: true, message: "Please add your business name" },
            ]}
          >
            <Input
              type="text"
              name="vendor_name"
              bordered={false}
              placeholder="Business name"
              onChange={(event) => {
                const value = event.target.value.replace(/[^\w\s'-]/gi, "");
                form.setFieldsValue({ vendor_name: value });
                handleInputChange({
                  target: { name: "vendor_name", value },
                });
              }}
              style={{
                padding: "16px",
                height: "40px",
                borderBottom: "1px solid rgba(108, 114, 128, 0.2)",
              }}
            />
          </Form.Item>
          <Form.Item
            name="address_line_1"
            style={{ marginBottom: 0, marginTop: "8px" }}
            rules={[{ required: true, message: "Please enter an address" }]}
          >
            <Input
              type="text"
              name="address_line_1"
              placeholder="Address line 1"
              bordered={false}
              onChange={(event) => {
                const value = event.target.value.replace(/[^\w\s-]/gi, "");
                form.setFieldsValue({ address_line_1: value });
                handleInputChange({
                  target: { name: "address_line_1", value },
                });
              }}
              style={{
                padding: "16px",
                height: "40px",
                borderBottom: "1px solid rgba(108, 114, 128, 0.2)",
              }}
            />
          </Form.Item>
          <Form.Item
            name="address_line_2"
            style={{ marginBottom: 0, marginTop: "8px" }}
          >
            <Input
              type="text"
              name="address_line_2"
              placeholder="Address line 2"
              bordered={false}
              onChange={(event) => {
                const value = event.target.value.replace(/[^\w\s-]/gi, "");
                form.setFieldsValue({ address_line_2: value });
                handleInputChange({
                  target: { name: "address_line_2", value },
                });
              }}
              style={{
                padding: "16px",
                height: "40px",
                borderBottom: "1px solid rgba(108, 114, 128, 0.2)",
              }}
            />
          </Form.Item>
          <Flex justifyContent="space-between">
            <Form.Item
              name="city"
              style={{ marginBottom: 0, width: "44%", marginTop: "8px" }}
              rules={[{ required: true, message: "Please add your city" }]}
            >
              <Input
                type="text"
                name="city"
                placeholder="City"
                bordered={false}
                onChange={(event) => {
                  const value = event.target.value.replace(/[^\w\s-]/gi, "");
                  form.setFieldsValue({ city: value });
                  handleInputChange({
                    target: { name: "city", value },
                  });
                }}
                style={{
                  padding: "16px",
                  height: "40px",
                  borderBottom: "1px solid rgba(108, 114, 128, 0.2)",
                }}
              />
            </Form.Item>
            <Form.Item
              name="state"
              style={{ marginBottom: 0, width: "20%", marginTop: "8px" }}
              rules={[{ required: true, message: "Please select a state" }]}
            >
              <AddressInfoSelect
                showSearch
                placeholder="ST"
                optionFilterProp="children"
                bordered={false}
                filterOption
                onChange={(value) => {
                  form.setFieldsValue({ state: value });
                  handleInputChange({
                    target: { name: "state", value },
                  });
                }}
              >
                {STATE_ABBR.map((state) => {
                  return (
                    <Select.Option key={state} value={state}>
                      {state}
                    </Select.Option>
                  );
                })}
              </AddressInfoSelect>
            </Form.Item>
            <Form.Item
              name="zip_code"
              style={{ marginBottom: 0, width: "32%", marginTop: "8px" }}
              rules={[
                { required: true, message: "Please add your zipcode" },
                {
                  validator: (_rule, value) => {
                    if (!value || value.length === 5) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Zipcode needs to be 5 numbers")
                    );
                  },
                },
              ]}
            >
              <Input
                type="text"
                name="zip_code"
                max={5}
                placeholder="Zipcode"
                bordered={false}
                onChange={(event) => {
                  const value = event.target.value
                    .replace(/[^\d]{5}/gi, "")
                    .substring(0, 5);
                  form.setFieldsValue({ zip_code: value });
                  handleInputChange({
                    target: { name: "zip_code", value },
                  });
                }}
                style={{
                  padding: "16px",
                  height: "40px",
                  borderBottom: "1px solid rgba(108, 114, 128, 0.2)",
                }}
              />
            </Form.Item>
          </Flex>
        </Form>
        <Button
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then(() => handleSubmit())
              .catch(() =>
                message.error(
                  "Oops! Something went wrong. Please ensure all fields are properly entered without error."
                )
              );
          }}
          style={{
            marginTop: "24px",
            width: "250px",
            alignSelf: "center",
          }}
        >
          Next
        </Button>
      </Flex>
    </>
  );
};

export default AddressInfo;
