import React, { useEffect, useState, FC } from "react";

import { fetchProjects } from "api/projects";
import { Project } from "types/models";

import Phone from "svg/Phone";
import Label from "components/Label";
import useAsync from "hooks/use-async";
import LoadingComponent from "components/LoadingComponent";
import Collapsable from "components/Collapsable";
import {
  ActiveProjectStatus,
  ArchivedProjectStatus,
  CompletedProjectStatus,
  ProjectStatus,
  RequestedProjectStatus,
} from "constants/statuses";

import { isEmpty } from "utils/array";
import ProjectColumn from "./components/ProjectColumn";
import styles from "./styles.module.scss";
import ProjectHeader from "./components/ProjectHeader";

const ActiveProjects: FC = () => {
  const [unstartedProjects, setUnstartedProjects] = useState<Project[]>([]);
  const [inProgressProjects, setInProgressProjects] = useState<Project[]>([]);
  const [completedProjects, setCompletedProjects] = useState<Project[]>([]);

  const [getProjects, loadingProjects] = useAsync(async () => {
    const projectsResponse = await fetchProjects({
      expand: "home_detail,service_offering",
      status: [
        ...ActiveProjectStatus,
        ...RequestedProjectStatus,
        ...ArchivedProjectStatus,
      ].join(","),
    });

    const projects = projectsResponse.results;

    setUnstartedProjects(
      projects.filter(({ status }) =>
        RequestedProjectStatus.includes(status as ProjectStatus)
      )
    );

    setInProgressProjects(
      projects.filter(({ status }) =>
        ActiveProjectStatus.includes(status as ProjectStatus)
      )
    );

    setCompletedProjects(
      projects.filter(({ status }) =>
        [...ArchivedProjectStatus, ...CompletedProjectStatus].includes(
          status as ProjectStatus
        )
      )
    );
  });

  useEffect(() => {
    getProjects();
  }, []);

  const projectsExist =
    !isEmpty(unstartedProjects) ||
    !isEmpty(inProgressProjects) ||
    !isEmpty(completedProjects);

  return (
    <>
      <Label type="h5" color="primary">
        Projects
      </Label>
      <LoadingComponent className={styles.container} loading={loadingProjects}>
        <div className={styles.content}>
          {projectsExist ? (
            <>
              <Collapsable
                unmountOnCollapse
                headerClassName={styles.header}
                className={styles.collapsable}
                disabled={!unstartedProjects.length}
                withIcon
                header={
                  <ProjectHeader
                    title="Not Started"
                    amount={unstartedProjects.length}
                  />
                }
              >
                <ProjectColumn projects={unstartedProjects} />
              </Collapsable>
              <Collapsable
                unmountOnCollapse
                headerClassName={styles.header}
                className={styles.collapsable}
                disabled={!inProgressProjects.length}
                withIcon
                header={
                  <ProjectHeader
                    title="In Progress"
                    amount={inProgressProjects.length}
                  />
                }
              >
                <ProjectColumn projects={inProgressProjects} />
              </Collapsable>
              <Collapsable
                unmountOnCollapse
                headerClassName={styles.header}
                className={styles.collapsable}
                disabled={!completedProjects.length}
                withIcon
                header={
                  <ProjectHeader
                    title="Completed"
                    amount={completedProjects.length}
                  />
                }
              >
                <ProjectColumn projects={completedProjects} />
              </Collapsable>
            </>
          ) : (
            <div className={styles.notice}>
              <Phone />
              <Label type="h5-centered">
                You have no active projects, create one in the Humming Homes
                mobile app!
              </Label>
            </div>
          )}
        </div>
      </LoadingComponent>
    </>
  );
};

export default ActiveProjects;
