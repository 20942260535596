import React, { FC } from "react";

import Panel from "components/Panel";
import NewApplianceForm from "components/NewApplianceForm";
import Label from "components/Label";

import useScreenSize from "hooks/use-screen-size";
import styles from "./styles.module.scss";

const NewAppliancePanel: FC<{
  visible: boolean;
  onClose(): void;
  id: string;
}> = ({ visible, onClose, id }) => {
  const { screenHeight } = useScreenSize();

  return (
    <Panel
      unmountOnCollapse
      onClose={onClose}
      visible={visible}
      size={screenHeight * 0.8}
      position="bottom"
      containerClassName={styles.container}
    >
      <Label className={styles.title} type="h5" color="primary">
        New appliance
      </Label>
      <NewApplianceForm onSubmit={onClose} initialValues={{ room: id }} />
    </Panel>
  );
};

export default NewAppliancePanel;
