import { fetchActivities } from "api/activity";
import { fetchJobs } from "api/jobs";
import { fetchNotes } from "api/notes";
import {
  fetchCategories,
  fetchProject,
  fetchProjects,
  fetchServiceCategories,
} from "api/projects";
import useAppFilters from "components/GlobalFilters/hooks/use-app-filters";
import { ProjectStatus, AllProjectStatus } from "constants/statuses";
import useAsyncAtom from "hooks/use-async-atom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Project } from "types/models";
import { purgeObject } from "utils/object";
import { updateStickyFilters } from "utils/projects";

import {
  activeStatusesState,
  projectActivitiesState,
  projectCategoriesState,
  projectJobsState,
  projectNotesState,
  projectsPaginationState,
  projectsState,
  selectedProjectState,
  serviceCategoriesState,
} from "./atoms";
import { defaultRequestParams } from "./constants";

const useProjects = (statuses: ProjectStatus[] = AllProjectStatus) => {
  const { market, homeCoordinator } = useAppFilters();

  const { search } = useLocation();

  const [pagination, setPagination] = useRecoilState(projectsPaginationState);

  const [activeStatuses, setActiveStatuses] =
    useRecoilState(activeStatusesState);

  useEffect(() => {
    setActiveStatuses(statuses);
  }, [statuses]);

  const {
    data: serviceCategories,
    inProgress: loadingServiceCategories,
    refresh: updateServiceCategories,
  } = useAsyncAtom(serviceCategoriesState, () =>
    fetchServiceCategories()
      .then((response) => response.results)
      .catch(() => [])
  );

  const {
    data: projectCategories,
    inProgress: loadingProjectCategories,
    refresh: updateProjectCategories,
  } = useAsyncAtom(projectCategoriesState, fetchCategories);

  const {
    data: projects,
    inProgress: loadingProjects,
    refresh: updateProjects,
    overrideState: setProjects,
  } = useAsyncAtom(
    projectsState,
    (externalParams: { home?: string } = {}, externalSearch?: string) => {
      // TODO: fix params
      return fetchProjects(
        purgeObject({
          ...defaultRequestParams(statuses || activeStatuses),
          ...externalParams,
          home_coordinator: homeCoordinator,
          market: market ? String(market) : undefined,
        }),
        updateStickyFilters(externalSearch ?? search)
      )
        .then(({ results, count, previous, next }) => {
          updateServiceCategories();
          updateProjectCategories();

          const currentPage = new URLSearchParams(externalSearch).get("page");

          setPagination({
            numOfItems: count,
            prevLink: previous || null,
            nextLink: next || null,
            currentPage: Number(currentPage) || pagination.currentPage,
          });

          return results;
        })
        .catch(() => [] as Project[]);
    }
  );

  const {
    data: selectedProjectJobs,
    inProgress: loadingProjectJobs,
    refresh: updateProjectJobs,
    overrideState: resetProjectJobs,
  } = useAsyncAtom(
    projectJobsState,
    async (
      parameters: { projectUID?: string; viewArchived: boolean } = {
        viewArchived: false,
      }
    ) => {
      const { projectUID, viewArchived } = parameters;

      const id = projectUID ?? selectedProject?.uid;

      return id
        ? fetchJobs({
            archived: String(viewArchived),
            project: id,
            expand: "invoices,time_proposals,attachments,appointment_events",
          })
        : [];
    }
  );

  const {
    data: projectNotes,
    inProgress: loadingProjectNotes,
    refresh: updateProjectNotes,
    overrideState: resetProjectNotes,
  } = useAsyncAtom(projectNotesState, async (projectUID?: string) => {
    const id = projectUID ?? selectedProject?.uid;

    return id ? fetchNotes({ project: id }) : [];
  });

  const {
    data: projectActivities,
    inProgress: loadingProjectActivities,
    refresh: updateProjectActivities,
    overrideState: resetProjectActivities,
  } = useAsyncAtom(projectActivitiesState, async (projectUID?: string) => {
    const id = projectUID ?? selectedProject?.uid;

    return id
      ? fetchActivities({
          project: id,
        })
      : [];
  });

  const {
    data: selectedProject,
    inProgress: loadingProjectDetails,
    refresh: updateProjectDetails,
    overrideState: resetSelectedProject,
  } = useAsyncAtom(
    selectedProjectState,
    async (projectUID?: string, updateAll = false): Promise<Project | null> => {
      const id = projectUID ?? selectedProject?.uid;

      if (id) {
        const response = await fetchProject(id, {
          expand: "attachments,created_by_detail_snippet,service_offering",
        });

        if (response) {
          if (updateAll) {
            updateProjectJobs({ projectUID: response.uid });
            updateProjectNotes(response.uid);
            updateProjectActivities(response.uid);
          }

          return response;
        }
      }

      return null;
    }
  );

  const updateAllProjectsInformation = (projectUID?: string) => {
    updateProjects();

    if (selectedProject || projectUID)
      updateProjectDetails(projectUID ?? selectedProject?.uid, true);
  };

  const clearProject = () => {
    resetSelectedProject(null);
    resetProjectActivities([]);
    resetProjectJobs([]);
    resetProjectNotes([]);
  };

  const clearProjects = () => {
    setProjects([]);
  };

  return {
    projects,
    serviceCategories,
    projectCategories,
    pagination,
    selectedProject,
    selectedProjectJobs,
    projectNotes,
    projectActivities,

    updateProjectJobs,
    updateProjectCategories,
    updateProjects,
    updateProjectDetails,
    updateAllProjectsInformation,
    updateProjectActivities,
    updateProjectNotes,
    updateServiceCategories,

    clearProject,
    clearProjects,

    loadingProjectCategories,
    loadingServiceCategories,
    loadingProjectActivities,
    loadingProjectDetails,
    loadingProjects:
      loadingProjects || loadingServiceCategories || loadingProjectCategories,
    loadingProjectJobs,
    loadingProjectNotes,
    loadingProjectsInformation:
      loadingServiceCategories ||
      loadingProjectCategories ||
      loadingProjectDetails ||
      loadingProjectJobs ||
      loadingProjectNotes ||
      loadingProjects ||
      loadingProjectActivities,
  };
};

export default useProjects;
