import React, {
  useEffect,
  FC,
  useMemo,
  useLayoutEffect,
  useCallback,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { potentialAssigneeList } from "recoil/atoms";
import {
  createColumns,
  createDataFromActions,
} from "views/Admin/views/ActionCenter/views/ActionList/utils";
import Label from "components/Label";

import useAppFilters from "components/GlobalFilters/hooks/use-app-filters";
import useActions from "hooks/use-actions";
import Table from "components/Table";
import { DEFAULT_ACTIONS_PAGE_SIZE } from "hooks/use-actions/constants";
import { useUser } from "providers/UserProvider";
import { purgeObject } from "utils/object";
import { debounce } from "lodash";
import styles from "./styles.module.scss";
import ActionDetail from "./components/ActionDetail";

const ActionList: FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { user } = useUser();

  const { market, assignedToMe, updatingFilters } = useAppFilters();

  const potentialAssignees = useRecoilValue(potentialAssigneeList);

  const { updateActions, loadingActions, actions, pagination, resetActions } =
    useActions();

  const loading = updatingFilters || loadingActions;

  const updateOnSearch = useCallback(
    debounce((params: string) => {
      updateActions(params);
    }, 300),
    []
  );

  useLayoutEffect(() => {
    updateOnSearch(search);
  }, [search]);

  useEffect(() => resetActions, []);

  const handleViewAction = (actionUID: string) => {
    history.push(`/admin/actions/${actionUID}${search}`);
  };

  const columns = createColumns(potentialAssignees);

  const externalFilters = useMemo(
    () => ({
      market: market ? String(market) : undefined,
    }),
    [market]
  );

  const externalFilterValues = useMemo(
    () =>
      purgeObject({
        assigned_to: assignedToMe ? user?.uid : undefined,
      }),
    [assignedToMe]
  );

  return (
    <>
      <ActionDetail />
      <div className={styles.container}>
        <Label id="title" type="h5" color="primary" className={styles.title}>
          Action Center
        </Label>
        <Table
          rowClickDescription="Click row to see action"
          externalFilters={externalFilters}
          reloadData={updateActions}
          externalFilterValues={externalFilterValues}
          onRowClick={({ original }) => handleViewAction(original.uid)}
          loading={loading}
          data={createDataFromActions(actions)}
          columns={columns}
          pagination={{
            page: pagination.currentPage,
            pageSize: DEFAULT_ACTIONS_PAGE_SIZE,
            count: pagination.numOfItems,
          }}
        />
      </div>
    </>
  );
};

export default ActionList;
