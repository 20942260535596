import React, { FC } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { GREETINGS } from "./constants";

type Props = {
  hi?: string;
  goodbye?: string;
  className?: string;
  duration?: number;
  ease?: string;
  unmountOn?: boolean;
};

const Greeting: FC<Props> = ({
  hi = "fade",
  goodbye = "fade",
  children,
  className,
  duration,
  ease,
  unmountOn,
}) => {
  const { hi: hiAnimation } = GREETINGS[hi as keyof typeof GREETINGS];
  const { goodbye: goodbyeAnimation } =
    GREETINGS[goodbye as keyof typeof GREETINGS];

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      {!unmountOn && (
        <motion.div
          transition={{ duration, ease }}
          className={className}
          {...hiAnimation}
          {...goodbyeAnimation}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Greeting.defaultProps = {
  hi: "fade",
  goodbye: "fade",
  className: "",
  duration: 0.7,
  ease: "easeOut",
  unmountOn: false,
};

export default Greeting;
