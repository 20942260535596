import Label from "components/Label";
import React, { FC } from "react";
import { User as UserType } from "types/models";
import { FaUserCircle as UserIcon } from "react-icons/fa";

import styles from "./styles.module.scss";

type Props = {
  user: UserType;
};

const User: FC<Props> = ({ user }) => (
  <div className={styles.user}>
    <UserIcon className={styles.icon} />
    <div className={styles.content}>
      <div className={styles.field}>
        <Label type="bold-italic">Name:</Label>
        <Label>{user.name}</Label>
      </div>
      <div className={styles.field}>
        <Label type="bold-italic">Email:</Label>
        <Label>{user.email}</Label>
      </div>
      {user.phone_number && (
        <div className={styles.field}>
          <Label type="bold-italic">Phone number:</Label>
          <Label>{user.phone_number}</Label>
        </div>
      )}
    </div>
  </div>
);

export default User;
