import theme from "theme/theme.main";
import { JOB_STATUS } from "types/models";

export enum ProjectStatus {
  REQUEST_RECEIVED = "REQUEST_RECEIVED",
  STARTS_IN_FUTURE = "STARTS_IN_FUTURE",
  MATCHING_WITH_VENDOR = "MATCHING_WITH_VENDOR",
  IN_PROGRESS = "IN_PROGRESS",
  ARCHIVED = "ARCHIVED",
  COMPLETED = "COMPLETED",
}

export const ActiveProjectStatus = [
  ProjectStatus.STARTS_IN_FUTURE,
  ProjectStatus.MATCHING_WITH_VENDOR,
  ProjectStatus.IN_PROGRESS,
];

export const RequestedProjectStatus = [ProjectStatus.REQUEST_RECEIVED];

export const CompletedProjectStatus = [ProjectStatus.COMPLETED];

export const ArchivedProjectStatus = [ProjectStatus.ARCHIVED];

export const AllProjectStatus = [
  ...ActiveProjectStatus,
  ...RequestedProjectStatus,
  ...ArchivedProjectStatus,
  ...CompletedProjectStatus,
];

export interface BadgeOptions {
  display: string;
  value: string | number;
  color: string;
}

export const JobStatusOptions: BadgeOptions[] = [
  {
    display: "Created",
    value: JOB_STATUS.CREATED,
    color: theme.colors.gray,
  },
  {
    display: "Started",
    value: JOB_STATUS.STARTED,
    color: theme.colors.semanticCritical,
  },
  {
    display: "Vendor Selected",
    value: JOB_STATUS.VENDOR_SELECTED,
    color: theme.colors.semanticCritical,
  },
  {
    display: "Estimate Requested",
    value: JOB_STATUS.ESTIMATE_REQUESTED,
    color: theme.colors.semanticCritical,
  },
  {
    display: "Payment Due",
    value: JOB_STATUS.PAYMENT_DUE,
    color: theme.colors.semanticCritical,
  },
  {
    display: "Payment Pending",
    value: JOB_STATUS.PENDING_INVOICE,
    color: theme.colors.semanticCritical,
  },
  {
    display: "Completed",
    value: JOB_STATUS.COMPLETED,
    color: theme.colors.semanticPositive,
  },
  {
    display: "Archived",
    value: JOB_STATUS.ARCHIVED,
    color: theme.colors.semanticNegative,
  },
];

export const ProjectStatusOptions: BadgeOptions[] = [
  {
    display: "Request Received",
    value: ProjectStatus.REQUEST_RECEIVED,
    color: theme.colors.gray,
  },
  {
    display: "Starts in Future",
    value: ProjectStatus.STARTS_IN_FUTURE,
    color: theme.colors.semanticPositive,
  },
  {
    display: "Matching with vendor",
    value: ProjectStatus.MATCHING_WITH_VENDOR,
    color: theme.colors.semanticCritical,
  },
  {
    display: "In Progress",
    value: ProjectStatus.IN_PROGRESS,
    color: theme.colors.semanticPositive,
  },
  {
    display: "Archived",
    value: ProjectStatus.ARCHIVED,
    color: theme.colors.semanticNegative,
  },
  {
    display: "Completed",
    value: ProjectStatus.COMPLETED,
    color: theme.colors.semanticPositive,
  },
];

export const DEFAULT_PROJECT_STATUSES = ProjectStatusOptions.filter(
  (status) => status.value !== ProjectStatus.ARCHIVED
)
  .map((status) => status.value)
  .join(",");

export const ActionStatusOptions: BadgeOptions[] = [
  {
    display: "New Request",
    value: "new_request",
    color: theme.colors.pastel01,
  },
  {
    display: "Unpaid Invoice",
    value: "unpaid_invoice",
    color: theme.colors.pastel02,
  },
  {
    display: "Unreplied Estimate",
    value: "service_estimate_approval",
    color: theme.colors.pastel03,
  },
  {
    display: "No Appointment",
    value: "no_appointment",
    color: theme.colors.pastel04,
  },
  {
    display: "Project Unpaused",
    value: "unpaused_project",
    color: theme.colors.pastel05,
  },
  {
    display: "Estimate Approved",
    value: "approved_service_estimate",
    color: theme.colors.pastel07,
  },
];

// To differentiate draft requests (decline) from projects (cancel)
export const ARCHIVE_PROJECT_TYPE = {
  CANCEL: "cancel",
  DECLINE: "decline",
};
