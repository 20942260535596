/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import moment from "moment";

import CustomBadge from "components/CustomBadge";
import { ActionStatusOptions, ProjectStatusOptions } from "constants/statuses";
import { Action, User } from "types/models";

import { ColumnDefinition } from "components/Table/types";
import ActionButton from "./components/ActionButton";

type RowData = {
  property: string;
  name: string;
  created_at: string;
  assigned_to: string;
  issues: Action;
  id: string;
  uid: string;
  status: string;
};

export const createDataFromActions = (actions: Action[]): RowData[] =>
  actions.map((action) => {
    const {
      home_snippet: home,
      project_snippet: project,
      created_at: createdAt,
      uid,
    } = action;

    return {
      property: home?.title ?? "",
      name: project?.name ?? "",
      assigned_to: project?.assigned_to[0]?.name ?? "",
      created_at: moment(createdAt).format("MMM D h:mma"),
      issues: action,
      status: project?.project_status || "",
      uid,
      id: uid,
    };
  });

export const createColumns = (
  assignees: User[]
): ColumnDefinition<RowData>[] => [
  {
    Header: "Home",
    accessor: "property",
    width: 200,
    fixed: "left",
  },
  {
    Header: "Project",
    accessor: "name",
    width: 250,
    fixed: "left",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }: { value: string }) => (
      <CustomBadge status={String(value)} options={ProjectStatusOptions} />
    ),
    width: 200,
  },
  {
    Header: "Alerted At",
    accessor: "created_at",
    filter: "ordering",
    width: 150,
  },
  {
    orderOptions: true,
    Header: "Assigned To",
    accessor: "assigned_to",
    width: 200,
    filter: "set",
    options: assignees.map(({ uid, name }) => ({
      label: name,
      key: String(uid),
      value: uid,
    })),
  },
  {
    Header: "Action",
    accessor: "issues",
    filter: "set",
    options: ActionStatusOptions.map(({ display, value }) => ({
      value: String(value),
      label: display,
      key: String(value),
    })),
    fixed: "right",
    width: 350,
    interactable: true,
    Cell: ({ value }: { value: Action }) => <ActionButton action={value} />,
  },
];
