import React, { useState, FC, useEffect } from "react";
import { Button } from "antd";
import { HiChevronDown as CollapsedIcon } from "react-icons/hi";

import Label from "components/Label";
import CustomBadge from "components/CustomBadge";
import useProjects from "hooks/use-projects";
import { JobStatusOptions } from "constants/statuses";
import { Job } from "types/models";
import { currencyFormatter } from "utils/currency";

import Collapsable from "components/Collapsable";
import { isEmpty } from "utils/array";
import LoadingComponent from "components/LoadingComponent";
import { useParams } from "react-router-dom";
import JobDetail from "./components/JobDetail";
import styles from "./styles.module.scss";

type PanelProps = {
  job: Job;
};

const PanelHeader = ({ job }: PanelProps) => {
  return (
    <div className={styles.jobHeader}>
      <div className={styles.jobHeader}>
        <Label>
          {`${job.name} - ${currencyFormatter.format(job.total_quote_amount)}`}
        </Label>
        <CustomBadge status={job?.status} options={JobStatusOptions} />
      </div>
      <CollapsedIcon className={styles.collapsedIcon} />
    </div>
  );
};

const JobsList: FC = () => {
  const { selectedProjectJobs, updateProjectJobs, loadingProjectJobs } =
    useProjects();

  const { jobID } = useParams<{ [key: string]: string }>();

  const [selectedJob, setSelectedJob] = useState(jobID);

  const [viewArchived, setViewArchived] = useState(false);

  useEffect(() => {
    updateProjectJobs({ viewArchived });
  }, [viewArchived]);

  return (
    <LoadingComponent loading={loadingProjectJobs} className={styles.wrapper}>
      <div id="jobs-list" className={styles.content}>
        {isEmpty(selectedProjectJobs) ? (
          <Label color="primary">
            {viewArchived
              ? "No jobs have been archived."
              : "No jobs have been added."}
          </Label>
        ) : (
          selectedProjectJobs.map((job) => {
            return (
              <Collapsable
                id={job.uid}
                open={selectedJob === job.uid}
                header={<PanelHeader job={job} />}
                key={job.uid}
                className={styles.job}
                onSelect={(id: string) => {
                  if (id === selectedJob) setSelectedJob("");
                  else {
                    setSelectedJob(id);
                  }
                }}
              >
                <JobDetail job={job} setViewArchived={setViewArchived} />
              </Collapsable>
            );
          })
        )}
        <Button
          onClick={() => setViewArchived(!viewArchived)}
          id="view-archived-jobs"
          style={{
            alignSelf: "center",
            marginTop: "2rem",
            fontWeight: 500,
            border: "0",
          }}
        >
          {viewArchived ? "Hide Archived Jobs" : "View Archived Jobs"}
        </Button>
      </div>
    </LoadingComponent>
  );
};

export default JobsList;
