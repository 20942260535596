/* eslint-disable react/no-array-index-key */
import React, { FC } from "react";
import { Checkbox as RootCheckbox } from "antd";

import ErrorBar from "components/ErrorBar";
import Label from "components/Label";

import styles from "./styles.module.scss";

type Props = {
  disabled?: boolean;
  onChange(checked: boolean): void;
  value: boolean | undefined;
  error?: string;
  placeholder?: string;
  className?: string;
};

const Checkbox: FC<Props> = ({
  disabled,
  onChange,
  value,
  error,
  placeholder = "",
  className,
}) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <RootCheckbox
        checked={value}
        value={value}
        disabled={disabled}
        onChange={({ target }) => onChange(target.checked)}
      >
        <Label>{placeholder}</Label>
      </RootCheckbox>
      <ErrorBar error={error} />
    </div>
  );
};

Checkbox.defaultProps = {
  disabled: false,
  error: undefined,
  placeholder: "",
  className: undefined,
};

export default Checkbox;
