import React, { FC } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  FaFileInvoiceDollar as InvoiceIcon,
  FaCreditCard as PaymentPreferencesIcon,
} from "react-icons/fa";

import Sidebar from "components/Sidebar";

const Navigation: FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <Sidebar
      fixed
      externalSelectedOption={pathname}
      options={[
        {
          label: "Invoices",
          key: "/vendor/invoices",
          onClick: () => history.push("/vendor/invoices"),
          Icon: InvoiceIcon,
        },
        {
          label: "Preferences",
          key: "/vendor/preferences",
          onClick: () => history.push("/vendor/preferences"),
          Icon: PaymentPreferencesIcon,
        },
      ]}
    />
  );
};

export default Navigation;
