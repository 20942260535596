/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";

import Flow from "components/Flow";
import { Source } from "types/models";

import PaymentManagement from "./components/PaymentManagement";
import AddPaymentMethod from "./components/AddPaymentMethod";
import SelectPaymentMethod from "./components/SelectPaymentMethod";
import styles from "./styles.module.scss";

interface AddPaymentProps {
  paymentAdded: (source: Source) => void;
  handleClose(): void;
}

const AddPayment: FC<AddPaymentProps> = ({ paymentAdded, handleClose }) => {
  const steps = [
    {
      title: "Manage Payment Methods",
      key: "add-new-payment",
      Component: ({ nextStep: addNewPayment }: { nextStep(): void }) => (
        <PaymentManagement addNewPayment={addNewPayment} />
      ),
    },
    {
      title: "Select a payment method",
      Component: SelectPaymentMethod,
      key: "payment-options",
    },
    {
      Component: AddPaymentMethod,
      paymentAdded,
      key: "payment-method",
      handleClose,
    },
  ];

  return <Flow className={styles.flow} withoutNextStepButton steps={steps} />;
};

export default AddPayment;
