import React, { FC } from "react";
import { Redirect, RouteComponentProps, useRouteMatch } from "react-router-dom";
import * as Sentry from "@sentry/react";

import Switch from "components/Switch";
import { useSessionValue } from "providers/SessionProvider";
import { UserRole } from "types/models";
import ErrorBoundaryFallback from "components/ErrorBoundaryFallback";
import CreateAccount from "./views/CreateAccount";
import SuccessOnboarding from "./views/Onboarding/views/Success";
import Invoices from "./views/Invoices";
import Onboarding from "./views/Onboarding";
import Topbar from "./components/Topbar";
import Sidebar from "./components/Sidebar";
import styles from "./styles.module.scss";
import PaymentPreferences from "./views/PaymentPreferences";

const Vendor: FC = () => {
  const match = useRouteMatch();
  const { sessionData } = useSessionValue();

  const authenticated = !!sessionData?.access_token;

  const routes = [
    {
      path: `${match.path}/success`,
      Component: SuccessOnboarding,
      isPrivate: true,
      roles: [UserRole.Vendor],
    },
    {
      path: `${match.path}/invoices`,
      Component: Invoices,
      isPrivate: true,
      roles: [UserRole.Vendor],
    },
    {
      path: `${match.path}/onboarding`,
      Component: Onboarding,
      isPrivate: true,
      roles: [UserRole.Vendor],
    },
    {
      path: `${match.path}/preferences`,
      Component: PaymentPreferences,
      isPrivate: true,
      roles: [UserRole.Vendor],
    },
    {
      exact: true,
      path: `${match.path}/getstarted`,
      Component: CreateAccount,
    },
    {
      path: "*",
      render: ({ location }: RouteComponentProps): JSX.Element => (
        <Redirect
          to={{
            pathname: `${match.path}/invoices`,
            state: { from: location },
          }}
        />
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.content} id="humming-content">
        <Topbar />
        <Sentry.ErrorBoundary
          fallback={
            <ErrorBoundaryFallback message="Whoops! Something went wrong, we'll work on fixing it!" />
          }
        >
          <Switch authenticated={authenticated} routes={routes} />
        </Sentry.ErrorBoundary>
      </div>
    </div>
  );
};

export default Vendor;
