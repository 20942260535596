import { mapper } from "utils/mapping";
import TextInput from "components/TextInput";
import TextArea from "components/TextArea";
import Select from "components/Select";
import HomeAttachmentsField from "components/HomeAttachmentsField";
import CurrencyField from "components/CurrencyField";
import DatePicker from "components/DatePicker";
import RangePicker from "components/RangePicker";
import Checklist from "components/Checklist";
import Toggle from "components/Toggle";
import PasswordField from "components/PasswordField";
import Checkbox from "components/Checkbox";
import GoogleMapsAutocomplete from "components/GoogleMapsAutocomplete";
import HomeField from "components/HomeField";
import LabelField from "components/LabelField";
import AmountField from "components/AmountField";
import PercentageField from "components/PercentageField";
import AttachmentsField from "components/AttachmentsField";
import NewLineItemField from "components/NewLineItemField";
import RadioField from "components/Radio";

export const fieldMapper = mapper(
  {
    label: { Component: LabelField },
    text: { Component: TextInput },
    area: { Component: TextArea },
    select: { Component: Select },
    attachments: { Component: AttachmentsField, default: [] },
    "home-attachments": { Component: HomeAttachmentsField, default: [] },
    "line-items": { Component: NewLineItemField },
    currency: { Component: CurrencyField, default: "0.00" },
    date: { Component: DatePicker },
    range: { Component: RangePicker },
    checklist: { Component: Checklist },
    toggle: { Component: Toggle, default: false },
    password: { Component: PasswordField },
    checkbox: { Component: Checkbox, default: false },
    "maps-autocomplete": { Component: GoogleMapsAutocomplete },
    home: { Component: HomeField },
    amount: { Component: AmountField },
    percentage: { Component: PercentageField },
    radio: { Component: RadioField },
  },
  TextInput
);
