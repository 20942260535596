import Label from "components/Label";
import React, { FC } from "react";
import { Source, SourceType } from "types/models";
import { AiFillCreditCard as CardIcon } from "react-icons/ai";
import BankIcon from "svg/Bank";
import { SourceStatus } from "constants/payments";

import ActiveTag from "components/ActiveTag";
import styles from "./styles.module.scss";

interface Props {
  source: Source;
}

const PaymentMethod: FC<Props> = ({ source }) => {
  const { type, source_data: data, status, default_source: isDefault } = source;

  const isCard = type === SourceType.CARD;

  const {
    funding,
    last4,
    brand,
    bank_name: bank,
    account_number: accNum,
  } = data;

  return (
    <div
      className={`${styles.container} ${isDefault && styles.defaultContainer}`}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          {isCard ? <CardIcon /> : <BankIcon />}
          <div>
            <Label type="bold-italic" color="primary">
              {isCard ? `${brand} ${funding}` : bank}
            </Label>
            {last4 && (
              <Label
                type="bold-italic"
                color="primary"
              >{`Last 4 digits: ${last4}`}</Label>
            )}
            {accNum && (
              <Label
                type="bold-italic"
                color="primary"
              >{`Account number: ${accNum}`}</Label>
            )}
          </div>
        </div>
        <ActiveTag
          variant={
            status === SourceStatus.CHARGEABLE ||
            status === SourceStatus.VERIFIED
              ? "active"
              : "inactive"
          }
        />
      </div>
      {isDefault && (
        <Label
          className={styles.defaultLabel}
          type="bold-italic-centered"
          color="primary"
        >
          Default payment method
        </Label>
      )}
    </div>
  );
};

export default PaymentMethod;
