/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import { AiOutlineClear as ClearIcon } from "react-icons/ai";
import { GrPowerReset as ResetIcon } from "react-icons/gr";

import Collapsable from "components/Collapsable";
import Label from "components/Label";

import Spinner from "components/Spinner";
import Form from "components/Form";
import { useUser } from "providers/UserProvider";
import useAsyncState from "hooks/use-async-state";
import { Market, UserRole } from "types/models";
import { fetchMarkets } from "api/markets";
import { useRecoilState } from "recoil";
import { fetchActiveUsers, updateMarketOnUser } from "api/users";
import useAsync from "hooks/use-async";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import Button from "components/Button";
import { objectIsEmpty, purgeObject } from "utils/object";
import { FilterIcons } from "./constants";
import styles from "./styles.module.scss";
import { AppFilters } from "./types";
import useCallbackOnURLChange from "./hooks/use-callback-on-url-change";
import { appFiltersState } from "./atoms";

const GlobalFilters: FC = () => {
  const { user, setUser } = useUser();

  const [isOpen, setIsOpen] = useState(false);

  const [appFilters, setAppFilters] =
    useRecoilState<AppFilters>(appFiltersState);

  useLayoutEffect(() => {
    setAppFilters((state) => ({ ...state, market: user?.market }));
  }, []);

  const numberOfFiltersApplied = Object.keys(appFilters).filter(
    (key) => key !== "updatingFilters" && appFilters[key as keyof AppFilters]
  ).length;

  const FilterIcon = FilterIcons(String(numberOfFiltersApplied));

  const {
    data: markets,
    refresh: refreshMarkets,
    inProgress: fetchingMarkets,
  } = useAsyncState<Market[]>([], fetchMarkets);

  const {
    data: homeCoordinators,
    inProgress: fetchingHomeCoordinators,
    refresh: fetchCoordinators,
  } = useAsyncState([], () =>
    fetchActiveUsers({
      role: UserRole.HomeCoordinator,
      page_size: "100",
    }).then(({ results }) => results)
  );

  useEffect(() => {
    refreshMarkets();
  }, []);

  useEffect(() => {
    if (isOpen) fetchCoordinators();
  }, [isOpen]);

  const notificationDispatcher = useNotificationDispatcher();

  const [updateMarket, updatingMarket] = useAsync(
    async (marketID: number | null) => {
      if (user?.uid)
        await updateMarketOnUser(marketID, user.uid)
          .then((newUser) => {
            setUser(newUser);
          })
          .catch(() =>
            notificationDispatcher({
              type: "error",
              description: "Could not update market state",
            })
          );
    }
  );

  useCallbackOnURLChange(
    { urlParamID: "homeID", queryParamID: "home" },
    appFilters.selectedHome,
    (id: string) => {
      setAppFilters((state) => ({ ...state, selectedHome: id }));
    }
  );

  const loading = fetchingMarkets || fetchingHomeCoordinators;

  useLayoutEffect(() => {
    setAppFilters((state) => ({
      ...state,
      updatingFilters: updatingMarket || loading,
    }));
  }, [updatingMarket, loading]);

  return (
    <Collapsable
      id="global-filters"
      onSelect={() => {
        if (isOpen) setIsOpen(false);
        else setIsOpen(true);
      }}
      withBackdrop
      unmountOnCollapse
      disabled={loading}
      className={`${styles.container} ${loading && styles.containerDisabled}`}
      header={
        <div className={styles.header}>
          {loading ? (
            <Spinner className={styles.icon} iconClassName={styles.spinner} />
          ) : (
            <FilterIcon className={styles.icon} />
          )}
          <Label type="s1" color={loading ? "gray" : "white"}>
            Filters
          </Label>
        </div>
      }
    >
      <div className={styles.filters}>
        <Form
          className={styles.form}
          disabled={appFilters.updatingFilters}
          initialValues={appFilters}
          fields={[
            {
              placeholder: "Home coordinator",
              id: "homeCoordinator",
              type: "select",
              options: homeCoordinators.map(({ name, uid }) => ({
                value: uid,
                label: name,
              })),
            },
            {
              placeholder: "Select a market",
              id: "market",
              type: "select",
              options: markets.map(({ name, id }) => ({
                value: id,
                label: name,
              })),
            },
            {
              id: "selectedHome",
              type: "home",
            },
            {
              id: "assignedToMe",
              type: "toggle",
              title: "Filter by me",
            },
          ]}
          name="global-filters"
          onSubmit={(values: AppFilters) => {
            setAppFilters((state) => {
              const purgedValues = purgeObject(
                values as {
                  [key: string]: any;
                }
              );

              const { market } = purgedValues;

              if (market !== appFilters.market) {
                if (market) updateMarket(market);
                else updateMarket(null);
              }

              return (
                objectIsEmpty(purgedValues)
                  ? {
                      assignedToMe: false,
                      updatingFilters: false,
                    }
                  : purgedValues
              ) as AppFilters;
            });
          }}
        >
          {({ handleSubmit, resetToInitialValues, clearForm }) => (
            <div className={styles.actions}>
              <Button
                className={styles.applyButton}
                loading={appFilters.updatingFilters || updatingMarket}
                variant="secondary"
                onClick={handleSubmit}
              >
                Apply
              </Button>
              <Button
                className={styles.action}
                disabled={appFilters.updatingFilters || updatingMarket}
                variant="secondary"
                onClick={() => resetToInitialValues()}
                Icon={<ResetIcon />}
              />
              <Button
                className={styles.action}
                disabled={appFilters.updatingFilters || updatingMarket}
                variant="secondary"
                onClick={clearForm}
                Icon={<ClearIcon />}
              />
            </div>
          )}
        </Form>
      </div>
    </Collapsable>
  );
};

export default GlobalFilters;
