import React, { useEffect } from "react";

import Flow from "components/Flow";

import { useSidebarInteraction } from "components/Sidebar";
import { steps } from "./utils";
import styles from "./styles.module.scss";

const CreateAccount = () => {
  const { hideSidebar, showSidebar } = useSidebarInteraction();

  useEffect(() => {
    hideSidebar();

    return () => showSidebar();
  }, []);

  return (
    <div className={styles.container}>
      <Flow
        className={styles.flow}
        showProgress
        withoutPreviousStepButton
        withoutNextStepButton
        steps={steps}
      />
    </div>
  );
};

export default CreateAccount;
