/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import ProjectCreationAndModification from "views/Admin/views/Projects/components/ProjectCreationAndModification";
import Panel from "components/Panel";

import styles from "./styles.module.scss";

const AddProject: FC<{
  visible: boolean;
  onClose(): void;
  onSubmit(): void;
}> = ({ visible, onClose, onSubmit }) => (
  <Panel
    unmountOnCollapse
    containerClassName={styles.container}
    id="create-project-panel"
    position="right"
    size={600}
    visible={visible}
    onClose={onClose}
  >
    <ProjectCreationAndModification onSubmit={onSubmit} />
  </Panel>
);

export default AddProject;
