import { useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";

const getScreenSize = () => ({
  screenWidth:
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth,
  screenHeight:
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight,
});

const useScreenSize = (): {
  screenWidth: number;
  screenHeight: number;
  isResponsive: boolean;
} => {
  const [parameters, setParameters] = useState<{
    screenWidth: number;
    screenHeight: number;
  }>({ ...getScreenSize() });

  const handleUpdateScreen = useCallback(
    debounce(() => {
      setParameters({ ...getScreenSize() });
    }),
    []
  );

  useEffect(() => {
    window.addEventListener("resize", handleUpdateScreen);

    return () => window.removeEventListener("resize", handleUpdateScreen);
  }, [handleUpdateScreen]);

  return { ...parameters, isResponsive: parameters.screenWidth < 1024 };
};

export default useScreenSize;
