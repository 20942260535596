import Label from "components/Label";
import React, { FC } from "react";

import styles from "./styles.module.scss";

type Props = {
  title?: string;
  className?: string;
  variant?: string;
};

const Section: FC<Props> = ({
  title,
  children,
  className,
  variant = "base",
}) => (
  <div className={`${styles.container} ${styles[variant]} ${className}`}>
    {title && (
      <Label
        type="bold-italic"
        className={`${styles.title} ${styles[`${variant}Title`]}`}
      >
        {title}
      </Label>
    )}
    {children}
  </div>
);

Section.defaultProps = {
  className: undefined,
  title: undefined,
  variant: "base",
};

export default Section;
