import React, { FC } from "react";
import { Button } from "antd";
import { motion } from "framer-motion";

import Label from "components/Label";
import { Room as RoomType } from "types/models";

import styles from "./styles.module.scss";
import Room from "./components/Room";

type Props = {
  onClick(): void;
  isSelected: boolean;
  index: number;
  addRoom(): void;
  floor: RoomType[];
  floorVariants: {
    selected: { [key: string]: string | number };
    unselected: { [key: string]: string | number };
  };
};

const Floor: FC<Props> = ({
  floorVariants,
  onClick,
  isSelected,
  index,
  addRoom,
  floor,
}) => (
  <motion.div
    onClick={onClick}
    animate={isSelected ? floorVariants.selected : floorVariants.unselected}
    className={styles.floor}
  >
    <div className={styles.floorHeader}>
      <Label color="white" className={styles.floorNumber}>
        {index}
      </Label>
      <Label color="primary" type="h6-bold" className={styles.floorTitle}>
        {index ? `Floor ${index}` : "Basement"}
      </Label>
      <Button onClick={addRoom} className={styles.addButton} type="primary">
        <Label type="button">Add Room</Label>
      </Button>
    </div>
    <div className={styles.floorContent}>
      {floor.map(({ name, id, appliances }) => (
        <Room id={id.toString()} name={name} appliances={appliances} key={id} />
      ))}
    </div>
  </motion.div>
);

export default Floor;
