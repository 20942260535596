import { atom } from "recoil";
import { Home, HomeNote } from "types/models";

export const selectedHomeState = atom<Home | null>({
  default: null,
  key: "selected-home-state",
});

export const homeNotesState = atom<HomeNote[]>({
  default: [],
  key: "home-notes-state",
});
