import React, { FC } from "react";
import { useParams } from "react-router-dom";

import Button from "components/Button";
import { updateProject } from "api/projects";
import { ProjectStatus } from "constants/statuses";
import Modal from "components/Modal";
import useAsync from "hooks/use-async";
import Label from "components/Label";

import { toast } from "react-hot-toast";
import { getError } from "utils/errors";
import useProjects from "hooks/use-projects";
import styles from "./styles.module.scss";

type Props = {
  handleClose: () => void;
  visible: boolean;
};

const CompleteProject: FC<Props> = ({ handleClose, visible }) => {
  const { projectID } = useParams<{ [key: string]: string }>();
  const { updateProjectDetails } = useProjects();

  const [completeProject, completingProject] = useAsync(async () =>
    updateProject(projectID, { status: ProjectStatus.COMPLETED })
      .then(() => {
        toast.success("Completed Project Successfully");
        updateProjectDetails();
        handleClose();
      })
      .catch((error) =>
        toast.error(getError(error, "Failed to complete project"))
      )
  );

  return (
    <Modal visible={visible} handleClose={handleClose}>
      <div className={styles.modal}>
        <Label type="positive-s1-bold-centered">
          Are you sure you want to update this project status to Completed? This
          action will close all active chats and actions with the customer
          related to this project.
        </Label>
        <Button
          loading={completingProject}
          onClick={completeProject}
          variant="positive"
        >
          Complete
        </Button>
      </div>
    </Modal>
  );
};

export default CompleteProject;
