import { AxiosError } from "axios";
import _ from "lodash";
import { ProjectClient } from "types/client";

import {
  Project,
  CancellationReason,
  ProjectCategory,
  Paginated,
  ServiceCategory,
} from "types/models";
import { ProjectResponse } from "types/responses";

import { apiClient, ServerError } from ".";

const createProject = async (newProject: ProjectClient) => {
  try {
    const payload = {
      ...newProject,
      uid: null,
    };

    const response = await apiClient.post<Project>("/projects/", payload);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchProjects = async (
  queryFilters: { [x: string]: string } = {},
  stickyParams?: string
) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const combinedQueryParams = _.compact([queryParams, stickyParams]).join(
      "&"
    );
    const response = await apiClient.get<ProjectResponse>(
      `/projects/?${combinedQueryParams}`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchProject = async (
  uid: string,
  queryFilters: { [x: string]: string } = {}
) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    queryParams.set(
      "expand",
      "home_detail,created_by_detail,attachments,service_offering"
    );
    const response = await apiClient.get<Project>(
      `/projects/${uid}/?${queryParams}`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateProject = async (
  projectID: string,
  updatedProject: ProjectClient
) => {
  try {
    const response = await apiClient.patch<Project>(
      `/projects/${projectID}/`,
      updatedProject
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const archiveProject = async (
  projectID: string,
  cancellationReason: number
) => {
  const payload = {
    cancellation_reason: cancellationReason,
  };
  try {
    const response = await apiClient.post(
      `/projects/${projectID}/archive/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateServiceOffering = async (id: number, payload: { name: string }) => {
  try {
    const response = await apiClient.put(
      `/admin/service_offerings/${id}/`,
      payload
    );

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateServiceCategory = async (id: string, payload: { name: string }) => {
  try {
    const response = await apiClient.put(
      `/admin/service_categories/${id}/`,
      payload
    );

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const getAllServiceCategories = async () => {
  try {
    const response = await apiClient.get(
      `/admin/service_categories/?page_size=1000&parent_categories=true`
    );

    return response.data.results;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const createServiceCategory = async (
  categoryId: string | null,
  { name }: { name: string }
) => {
  try {
    const response = await apiClient.post(`/admin/service_categories/`, {
      name,
      parent: categoryId,
    });

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const createServiceOffering = async (
  subcategoryId: string,
  {
    name,
  }: {
    name: string;
  }
) => {
  try {
    const response = await apiClient.post("/admin/service_offerings/", {
      name,
      category: subcategoryId,
    });

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchServiceCategories = async () => {
  try {
    const response = await apiClient.get<Paginated<ServiceCategory>>(
      `/service_categories/?page_size=1000`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchCategories = async () => {
  try {
    const response = await apiClient.get<ProjectCategory[]>(
      `/projects/categories/`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const approveRequest = async (requestID: string) => {
  try {
    const response = await apiClient.post(`/projects/${requestID}/approve/`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const restoreProject = async (projectID: string) => {
  try {
    const response = await apiClient.post(`/projects/${projectID}/restore/`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const removeAssignmentFromProject = async (
  projectID: string,
  userID: string
) => {
  const payload = {
    user: userID,
  };
  try {
    const response = await apiClient.post(
      `/projects/${projectID}/remove_assignment/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchCancellationReasons = async () => {
  try {
    const response = await apiClient.get<CancellationReason[]>(
      `/cancellation_reasons/`
    );

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export {
  fetchCategories,
  createProject,
  fetchProjects,
  fetchProject,
  updateProject,
  approveRequest,
  archiveProject,
  restoreProject,
  removeAssignmentFromProject,
  fetchCancellationReasons,
  fetchServiceCategories,
  updateServiceOffering,
  createServiceOffering,
  updateServiceCategory,
  createServiceCategory,
  getAllServiceCategories,
};
