import { AxiosError } from "axios";
import { AdminUserClient } from "types/client";
import { User, UserRole } from "types/models";
import { UserResponse } from "types/responses";
import { apiClient, ServerError } from ".";

const fetchUsers = async (queryFilters: { [x: string]: string } = {}) => {
  const queryParams = new URLSearchParams(queryFilters);
  try {
    const response = await apiClient.get<UserResponse>(
      `/admin/users/?${queryParams}`
    );

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchActiveUsers = async (queryFilters: { [x: string]: string } = {}) => {
  const queryParams = new URLSearchParams(queryFilters);
  try {
    const response = await apiClient.get<UserResponse>(
      `/admin/users/?is_active=true&${queryParams}`
    );

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const getCurrentUser = async (queryFilters: { [x: string]: string } = {}) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const response = await apiClient.get<User>(`/users/me/?${queryParams}`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const getUser = async (uid: string) => {
  try {
    const response = await apiClient.get<User>(`/users/${uid}/`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

type UpdatedValues = {
  automatic_payments_enabled?: boolean;
  has_onboarded?: boolean;
  accepted_terms_of_use?: boolean;
  phone_number?: string;
  email?: string;
};

type AdminUpdatedValues = {
  automatic_payments_enabled?: boolean;
  has_onboarded?: boolean;
  accepted_terms_of_use?: boolean;
  phone_number?: string;
  email?: string;
  bio?: string;
  role?: UserRole;
  profile_image?: File;
};

const createAdmin = async (user: AdminUserClient) => {
  try {
    const userValues = { ...user };

    const formData = new FormData();

    if (userValues.profile_image) {
      formData.append("profile_image", userValues.profile_image as Blob);
      delete userValues.profile_image;
    }

    Object.keys(userValues).forEach((key) => {
      formData.append(key, userValues[key as keyof AdminUserClient] as string);
    });

    const response = await apiClient.post<User>(`/admin/users/`, formData);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const adminUpdateUser = async (
  updatedValues: AdminUpdatedValues,
  userID: string
) => {
  try {
    const values = { ...updatedValues };

    const formData = new FormData();

    if (values.profile_image) {
      formData.append("profile_image", values.profile_image as Blob);
      delete values.profile_image;
    }

    Object.keys(values).forEach((key) => {
      formData.append(key, values[key as keyof AdminUpdatedValues] as string);
    });

    const response = await apiClient.patch<User>(
      `/admin/users/${userID}/`,
      formData
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateUser = async (updatedValues: UpdatedValues, userID: string) => {
  try {
    const response = await apiClient.patch<User>(
      `/users/${userID}/`,
      updatedValues
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateMarketOnUser = async (marketID: number | null, userID: string) => {
  const payload = {
    market: marketID,
  };
  try {
    const response = await apiClient.put<User>(
      `/users/${userID}/update_market/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export {
  fetchUsers,
  updateUser,
  getUser,
  getCurrentUser,
  updateMarketOnUser,
  adminUpdateUser,
  createAdmin,
  fetchActiveUsers,
};
