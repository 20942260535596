import Spinner from "components/Spinner";
import useAsyncState from "hooks/use-async-state";
import React, { ComponentType, useLayoutEffect, ReactNode } from "react";

interface ChildrenProps<T> {
  data: T;
  refresh(): void;
}

interface Props<T, E> {
  asyncRequest(): Promise<T>;
  dependencies?: (string | number | boolean)[];
  children: (childrenProps: ChildrenProps<T>) => ReactNode;
  onFailure(error: E): void;
  className?: string;
  BadData?: ComponentType;
}

const AsyncInject = <T, E>({
  asyncRequest,
  dependencies = [],
  children,
  onFailure,
  className,
  BadData,
}: Props<T, E>) => {
  const { refresh, data, inProgress } = useAsyncState(null, () =>
    asyncRequest().catch((e: E) => {
      onFailure(e);
      return null;
    })
  );

  useLayoutEffect(() => {
    refresh();
  }, [...dependencies]);

  return (
    <div className={className}>
      {inProgress ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      ) : data === null ? (
        BadData && <BadData />
      ) : (
        children({
          data,
          refresh,
        })
      )}
    </div>
  );
};

AsyncInject.defaultProps = {
  className: undefined,
  BadData: null,
  dependencies: [],
};

export default AsyncInject;
