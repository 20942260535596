import React, { FC, useEffect, useState } from "react";

import Button from "components/Button";
import Label from "components/Label";
import { useUser } from "providers/UserProvider";
import { updateUser } from "api/users";
import LoadingComponent from "components/LoadingComponent";

import useAsync from "hooks/use-async";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

const Confirmation: FC = () => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const { user, setUser } = useUser();
  const notificationDispatcher = useNotificationDispatcher();
  const [updateUserInformation, updatingUserInformation] = useAsync(
    async () => {
      if (user?.uid && !user.has_onboarded)
        await updateUser({ has_onboarded: true }, user.uid)
          .then((newUser) => {
            setUser(newUser);
            setError(false);
          })
          .catch(() => {
            notificationDispatcher({
              type: "error",
              message: "Whoops!",
              description: "Something went wrong, please try again later!",
            });
            setError(true);
          });
    }
  );

  useEffect(() => {
    updateUserInformation();
  }, []);

  return (
    <LoadingComponent
      loading={updatingUserInformation}
      className={styles.container}
    >
      {error ? (
        <>
          <Label color="primary" type="centered-h4">
            We are sorry, but
          </Label>
          <Label color="primary" type="centered-h6-bold">
            something went wrong
          </Label>
          <Button onClick={() => updateUserInformation()}>Try again</Button>
        </>
      ) : (
        <>
          <Label type="s3-centered-uppercase-bold" color="primary">
            Welcome to Humming Homes
          </Label>
          <Label type="centered-h4" color="primary">
            You&apos;re all set!
          </Label>
          <div>
            <Label type="centered-h6" color="primary">
              Now, download the app to
            </Label>
            <Label type="centered-h6" color="primary">
              get started
            </Label>
          </div>
          <Button
            labelVariant="uppercase"
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/humming-homes/id1511753658",
                "_blank"
              )
            }
          >
            Download the app
          </Button>
          <Button onClick={() => history.push("/")} variant="text">
            Home
          </Button>
        </>
      )}
    </LoadingComponent>
  );
};

export default Confirmation;
