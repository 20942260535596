import { AxiosError } from "axios";

import { ServiceFee, Vendor } from "types/models";
import { InvoiceResponse } from "types/responses";

import { apiClient, ServerError } from ".";

const createVendor = async (newVendor: Vendor, ownerID?: string) => {
  try {
    const payload = {
      ...newVendor,
      vendor_legal_name: newVendor.vendor_name,
      uid: null,
      owner_uid: ownerID,
    };

    const response = await apiClient.post<Vendor>("/vendors/", payload);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateVendor = async (updatedVendor: Vendor) => {
  try {
    const response = await apiClient.patch<Vendor>(
      `/vendors/${updatedVendor.uid}/`,
      updatedVendor
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchVendors = async (queryFilters: { [x: string]: string } = {}) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const response = await apiClient.get<Vendor[]>(`/vendors/?${queryParams}`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchVendor = async (vendorID: string) => {
  try {
    const response = await apiClient.get<Vendor>(`/vendors/${vendorID}/`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchCurrentVendor = async () => {
  try {
    const response = await apiClient.get<Vendor>("/vendors/me/");
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const addBankAccount = async (
  vendorID: string,
  account_number: string,
  routing_number: string
) => {
  try {
    const payload = {
      vendor: vendorID,
      account_number,
      routing_number,
    };

    const response = await apiClient.post(
      "/vendors/create_vendor_bank_account/",
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const calculateServiceFee = async (
  homeID: string,
  vendorID: string,
  invoiceID: string,
  percentOverride: number | null
) => {
  try {
    const payload = {
      home_id: homeID,
      vendor_id: vendorID,
      invoice_id: invoiceID,
      service_fee_percentage_override: percentOverride,
    };

    const response = await apiClient.post<ServiceFee>(
      "/vendors/calculate_service_fee/",
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchVendorInvoices = async (
  queryFilters: { [x: string]: string } = {},
  stickyParams?: string
) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const combinedQueryParams = [queryParams, stickyParams].join("&");
    const response = await apiClient.get<InvoiceResponse>(
      `/vendors/invoices/?${combinedQueryParams}`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export {
  addBankAccount,
  calculateServiceFee,
  createVendor,
  updateVendor,
  fetchVendors,
  fetchVendor,
  fetchCurrentVendor,
  fetchVendorInvoices,
};
