import React, { FC } from "react";
import { Text } from "rebass";

import { SourceData } from "types/models";
import { ChildrenType } from "types/components";
import Bank from "svg/Bank";

import CardActions from "../CardActions";

import styles from "./styles.module.scss";

type Props = {
  children?: ChildrenType;
  paymentPreference: boolean;
  sourceData: SourceData;
  withActions: boolean;
  id: string;
  updateCustomer: () => void;
};

const BankAccount: FC<Props> = ({
  children,
  paymentPreference,
  sourceData,
  withActions,
  id,
  updateCustomer,
}: Props) => {
  const { bank_name: bankName, last4 } = sourceData;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Bank />
        <div className={styles.information}>
          <Text>{bankName}</Text>
          <Text>{`Account ending in ${last4}`}</Text>
          {paymentPreference && (
            <Text fontSize="10px" css={{ textTransform: "uppercase" }}>
              Preferred Payment Method
            </Text>
          )}
          {children}
        </div>
      </div>
      {withActions && <CardActions id={id} updateCustomer={updateCustomer} />}
    </div>
  );
};

BankAccount.defaultProps = {
  children: null,
};

export default BankAccount;
