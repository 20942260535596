/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "emotion-theming";
import { RecoilRoot } from "recoil";
import { Toaster } from "react-hot-toast";

import { UserProvider } from "providers/UserProvider";
import { SessionProvider } from "providers/SessionProvider";
import LDProvider from "providers/LaunchDarklyProvider";
import theme from "theme/theme.main";
import { ThemeCreator } from "theme/ThemeCreator";
import { Laboratory } from "hooks/use-lab/Laboratory";

import HummingRouter from "./components/HummingRouter";
import "./App.less";

declare global {
  interface Window {
    analytics: any;
  }
}

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <SessionProvider>
          <UserProvider>
            <ThemeProvider theme={theme}>
              <ThemeCreator>
                <Laboratory>
                  <Toaster />
                  <Router>
                    <HummingRouter />
                  </Router>
                </Laboratory>
              </ThemeCreator>
            </ThemeProvider>
          </UserProvider>
        </SessionProvider>
      </RecoilRoot>
    </div>
  );
}

export default LDProvider(App);
