/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Flex } from "rebass";

type Props = {
  backdrop?: boolean;
  iconColor?: string;
  [x: string]: any;
};

const Loading: FC<Props> = ({
  backdrop = false,
  iconColor = "",
  ...rest
}: Props) => {
  const antIcon = (
    <LoadingOutlined style={{ color: iconColor, fontSize: 24 }} spin />
  );
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
      backgroundColor={backdrop ? "rgba(0, 0, 0, 0.15)" : null}
      {...rest}
    >
      <Spin size="large" indicator={antIcon} />
    </Flex>
  );
};

Loading.defaultProps = {
  backdrop: false,
  iconColor: "",
};

export default Loading;
