/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, FC } from "react";
import { Divider, message, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { useHistory, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { AiOutlinePlus as PlusIcon } from "react-icons/ai";

import {
  addAttachmentsToJob,
  createAttachment,
  removeAttachmentsFromJob,
} from "api/attachments";
import { archiveJob, completeJob, reopenJob, restoreJob } from "api/jobs";
import SubSection from "components/SubSection";
import { JOB_ACTIONS } from "constants/site";
import { jobSelectedState } from "recoil/atoms";
import { Event, Job, JOB_STATUS } from "types/models";
import { currencyFormatter } from "utils/currency";
import { handleDownload, isFileImage } from "utils/attachments";
import { parameterize } from "utils/strings";
import Modal from "components/Modal";

import Label from "components/Label";
import useProjects from "hooks/use-projects";
import Button from "components/Button";
import Invoices from "./components/InvoiceList";
import InlineJobEdit from "./components/InlineJobEdit";
import JobDetailSection from "./components/JobDetailSection";

import styles from "./styles.module.scss";
import Appointment from "./components/Appointment";

type JobViewProps = {
  job: Job;
  setViewArchived: (x: boolean) => void;
};

const JobDetail: FC<JobViewProps> = ({ job, setViewArchived }) => {
  const { updateProjectJobs, selectedProject } = useProjects();

  const history = useHistory();
  const loc = useLocation();
  const setSelectedJob = useSetRecoilState(jobSelectedState);

  const [editing, setEditing] = useState(false);
  const [disableArchiveJob, setDisableArchiveJob] = useState(false);
  const [loadingCompleteToggle, setLoadingCompleteToggle] = useState(false);
  const [loadingArchiveToggle, setLoadingArchiveToggle] = useState(false);
  const [jobAppointments, setJobApointments] = useState<Event[]>([]);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageList, setImageList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");

  useEffect(() => {
    if (job.appointment_events) setJobApointments(job.appointment_events);

    setDisableArchiveJob(
      job.status === JOB_STATUS.COMPLETED || jobAppointments.length > 0
    );
  }, [job, jobAppointments.length]);

  useEffect(() => {
    const files: UploadFile[] = [];
    const images: UploadFile[] = [];
    job?.attachments?.forEach((file) => {
      const formattedFile = {
        uid: file.uid,
        name: file.name,
        url: file.file,
        status: "done",
      } as UploadFile;
      const isImage = isFileImage(formattedFile);

      if (isImage) images.push(formattedFile);
      else files.push(formattedFile);
    });

    setImageList(images);
    setFileList(files);
  }, [job]);

  const handleToggleComplete = () => {
    setLoadingCompleteToggle(true);
    if (job.status === JOB_STATUS.COMPLETED) {
      reopenJob(job.uid)
        .then(() => {
          updateProjectJobs();
          message.success("Reopened job");
        })
        .catch(() => message.error("Could not reopen job"))
        .finally(() => {
          setLoadingCompleteToggle(false);
        });
    } else {
      completeJob(job.uid)
        .then(() => {
          updateProjectJobs();
          message.success("Completed job");
        })
        .catch(() => message.error("Could not complete job"))
        .finally(() => {
          setLoadingCompleteToggle(false);
        });
    }
  };

  const handleToggleArchive = () => {
    setLoadingArchiveToggle(true);
    if (job.status === JOB_STATUS.ARCHIVED) {
      restoreJob(job.uid)
        .then(() => {
          updateProjectJobs();
          message.success("Restored job");
          setViewArchived(false);
        })
        .catch(() => message.error("Could not restore job"))
        .finally(() => {
          setLoadingArchiveToggle(false);
        });
    } else {
      archiveJob(job.uid)
        .then(() => {
          updateProjectJobs();
          message.success("Archived job");
        })
        .catch(() => message.error("Could not archive job"))
        .finally(() => {
          setLoadingArchiveToggle(false);
        });
    }
  };

  const handlePreviewImage = async (file: UploadFile) => {
    setPreviewImage(file.url || "");
    setPreviewVisible(true);
    setPreviewTitle(file.name || "");
  };

  const customUpload = async ({ onError, onSuccess, file }: any) => {
    if (!job || !selectedProject || !selectedProject?.home) {
      message.error("Project associated with job doesn't have a home.");
      return;
    }

    createAttachment(file, selectedProject.home, job.uid)
      .then((resp) => {
        const newFile = {
          uid: resp.uid,
          name: resp.name,
          url: resp.file,
          status: "done",
        } as UploadFile;
        onSuccess({ url: resp.file }, file);

        addAttachmentsToJob(job.uid, [resp.uid])
          .then(() => {
            if (isFileImage(newFile)) {
              setImageList([...imageList, newFile]);
              message.success("Successfully added image");
            } else {
              setFileList([...fileList, newFile]);
              message.success("Successfully added file");
            }
          })
          .catch(() => {
            message.error("Could not add image");
          });
      })
      .catch((err) => {
        onError(err);
      });
  };

  const handleRemove = (file: UploadFile) => {
    if (!job) return;

    removeAttachmentsFromJob(job.uid, [file.uid])
      .then(() => {
        const isImage = isFileImage(file);
        if (isImage) {
          setImageList(imageList.filter((item) => item.uid !== file.uid));
        } else {
          setFileList(fileList.filter((item) => item.uid !== file.uid));
        }
      })
      .catch(() => {
        message.error("Could not delete image");
      });
  };

  return (
    <div className={styles.container}>
      <InlineJobEdit editing={editing} setEditing={setEditing} job={job} />
      {!editing && (
        <div className={styles.information}>
          {job.vendor_name && (
            <SubSection title="Service Provider" flexDirection="column">
              {job.vendor_name}
            </SubSection>
          )}
          <SubSection title="Balance Owed" flexDirection="column">
            <Label>{currencyFormatter.format(job.balance_remaining)}</Label>
          </SubSection>
          <SubSection title="Job Cost" flexDirection="column">
            <Label>{currencyFormatter.format(job.total_quote_amount)}</Label>
          </SubSection>
        </div>
      )}
      {job.status === JOB_STATUS.COMPLETED && (
        <JobDetailSection
          title="Invoices"
          buttonID="create-invoice-button"
          handleClickAdd={() => {
            setSelectedJob(job);
            history.push(
              `/admin/projects/${job.project}/jobs/${job.uid}/${parameterize(
                JOB_ACTIONS.CREATE_INVOICE
              )}${loc.search}`
            );
          }}
        >
          <Invoices
            jobID={job?.uid || ""}
            invoices={job.invoices}
            bills={job.bills}
          />
        </JobDetailSection>
      )}
      <JobDetailSection
        title="Appointments"
        buttonID="create-appointment-button"
        handleClickAdd={() => {
          setSelectedJob(job);
          history.push(
            `/admin/projects/${job.project}/jobs/${job.uid}/${parameterize(
              JOB_ACTIONS.SCHEDULE_APPOINTMENT
            )}${loc.search}`
          );
        }}
      >
        {jobAppointments.map((appt) => (
          <Appointment appointment={appt} />
        ))}
      </JobDetailSection>
      {job.status !== JOB_STATUS.COMPLETED && (
        <JobDetailSection
          title="Invoices"
          buttonID="create-invoice-button"
          handleClickAdd={() => {
            setSelectedJob(job);
            history.push(
              `/admin/projects/${job.project}/jobs/${job.uid}/${parameterize(
                JOB_ACTIONS.CREATE_INVOICE
              )}${loc.search}`
            );
          }}
        >
          <Invoices
            jobID={job.uid || ""}
            invoices={job.invoices}
            bills={job.bills}
          />
        </JobDetailSection>
      )}
      <JobDetailSection title="Attachments" addButton={false}>
        <Upload
          listType="picture-card"
          className="upload-btn"
          fileList={imageList}
          showUploadList={{
            showDownloadIcon: true,
            showRemoveIcon: true,
          }}
          onPreview={handlePreviewImage}
          onDownload={handleDownload}
          onRemove={handleRemove}
          customRequest={customUpload}
        >
          <PlusIcon className={styles.icon} />
        </Upload>
        <Upload
          listType="text"
          fileList={fileList}
          showUploadList={{
            showRemoveIcon: true,
          }}
          onRemove={handleRemove}
          customRequest={customUpload}
        />
      </JobDetailSection>
      <Divider style={{ marginTop: 0, marginBottom: "10px" }} />
      {!editing && (
        <div className={styles.actions}>
          <Button
            className={styles.archiveButton}
            disabled={disableArchiveJob}
            id="archive-job-btn"
            loading={loadingArchiveToggle}
            onClick={handleToggleArchive}
          >
            {job.status === JOB_STATUS.ARCHIVED ? "Restore Job" : "Archive Job"}
          </Button>
          <Button
            variant="primary"
            id="complete-job-btn"
            loading={loadingCompleteToggle}
            onClick={handleToggleComplete}
          >
            {job.status === JOB_STATUS.COMPLETED
              ? "Reopen Job"
              : "Complete Job"}
          </Button>
        </div>
      )}
      <Modal
        contentClassName={styles.modalPreview}
        visible={previewVisible}
        title={previewTitle}
        handleClose={() => setPreviewVisible(false)}
      >
        <img alt={previewTitle} className={styles.preview} src={previewImage} />
      </Modal>
    </div>
  );
};

export default JobDetail;
