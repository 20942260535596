/* eslint-disable react/no-array-index-key */
import React, { FC, useEffect } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import Label from "components/Label";
import useProjects from "hooks/use-projects";
import { isEmpty } from "utils/array";
import LoadingComponent from "components/LoadingComponent";
import ActivityDetail from "./components/ActivityDetail";
import styles from "./styles.module.scss";

const ActivityList: FC = () => {
  const {
    projectActivities,
    loadingProjectActivities,
    updateProjectActivities,
  } = useProjects();

  useEffect(() => {
    updateProjectActivities();
  }, []);

  return (
    <LoadingComponent
      loading={loadingProjectActivities}
      className={styles.container}
    >
      <SimpleBar className={styles.list}>
        {!isEmpty(projectActivities) ? (
          projectActivities.map((activity, index) => (
            <ActivityDetail activity={activity} key={`${index}-ACTIVITY`} />
          ))
        ) : (
          <Label>No activities on project</Label>
        )}
      </SimpleBar>
    </LoadingComponent>
  );
};

export default ActivityList;
