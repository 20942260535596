export interface ServiceCategory {
  id: string;
  name: string;
  children: ServiceCategory[];
  service_offerings: ServiceOfferingSnippet[];
}

export interface ServiceCategorySnippet {
  id: string;
  name: string;
  parent: ServiceCategorySnippet | null;
}

export interface ServiceOfferingSnippet {
  id: number;
  name: string;
  full_name: string;
  active: boolean;
}

export interface ServiceOffering {
  id: number;
  name: string;
  full_name: string;
  category: ServiceCategorySnippet;
  active: boolean;
  product_line: string;
}

export interface Paginated<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface ProjectCategory {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export interface Calendar {
  id: string;
  account_id: string;
  name: string;
  description: string | null;
  location: string | null;
  timezone: string | null;
  home: number;
}

export interface Home {
  uid: string;
  title: string;
  address: string;
  description: string;
  home_address: HomeAddress;
  users: HomeRelationships[];
  created_at: Date;
  updated_at: Date;
  calendar: Calendar;
  cover_image?: string;
  attachments: Attachment[];
  encrypted_data_exists: boolean;
  market: number;
  owner?: User;
  archived_at: Date;
  archived_reason?: string;
  img?: string;
  has_onboarded: boolean;
  wifi_credentials?: string;
  wifi_password?: string;
  wifi_network?: string;
  access_details?: string;
  kustomer_conversation_url?: string;
  djstripe_subscription_id: string | null;
}

export interface HomeClient {
  uid: string;
  title: string;
  address: string;
  description?: string;
  users?: HomeRelationships[];
  wifi_credentials?: string;
  wifi_password?: string;
  wifi_network?: string;
  access_details?: string;
}

export interface Room {
  id: number;
  home: string;
  name: string;
  floor: number;
  appliances: Appliance[];
}

export interface Appliance {
  id: string;
  label: string;
  serial_number: string;
  notes: string;
  attachments: Attachment[];
  room: number;
  model: number;
  room_details: Room;
  model_details: ApplianceModel;
}

export interface ApplianceModel {
  id: number;
  manufacturer: string;
  label?: string;
  model_id?: string;
  documentation_url?: string;
}

export interface HomeAddress {
  formatted: string;
  latitude: number | null;
  locality: Locality | null;
  longitude: number | null;
  raw: string;
  route: string;
  street_number: string;
}

export interface Locality {
  state: string;
  country: string;
  name: string;
  postal_code: string;
}

export interface HomeRelationships {
  role: UserRole;
  user: User;
}

export interface HomeManagerProfile {
  hometown?: string;
  bio?: string;
  ask_me_about?: string;
  local_favorites?: string;
}

export enum UserRole {
  HomeCoordinator = "HOME_COORDINATOR",
  Admin = "ADMIN",
  HummingManager = "HUMMING_MANAGER",
  HomeMember = "HOME_MEMBER",
  HomeOwner = "HOME_OWNER",
  Vendor = "VENDOR",
  HomeExpert = "HOME_EXPERT",
  HomeWatcher = "HUMMING_WATCHER",
}

export interface User {
  is_active: boolean;
  uid: string;
  name: string;
  email: string;
  phone_number: string;
  role: UserRole;
  password: string;
  avatar: string;
  profile_image: string;
  has_onboarded: boolean;
  has_sent_welcome_email: boolean;
  accepted_privacy_policy: boolean;
  accepted_terms_of_use: boolean;
  automatic_payments_enabled: boolean;
  tax_rate: TaxRate | null;
  home_manager_profile: HomeManagerProfile | null;
  totalActions: number;
  totalRequests: number;
  market: number;
  bio?: string;
}

// Note: bank_account is technically not a source but our backend combines them
// both should be updated to use payment methods which follows this pattern
export enum SourceType {
  BANK_ACCOUNT = "bank_account",
  CARD = "card",
  ACH_CREDIT_TRANSFER = "ach_credit_transfer",
}

export interface SourceData {
  bank_name?: string;
  last4?: string;
  brand?: string;
  exp_month?: string;
  exp_year?: string;
  funding?: string;
  account_number?: string;
}

export interface Source {
  type: SourceType;
  currency: string;
  default_source: boolean;
  id: string;
  status: string;
  source_data: SourceData;
}

export interface StripeCustomer {
  id: string;
  sources?: Source[];
}

export interface Token {
  access_token: string;
  refresh_token: string;
  user: User;
}

export type InvoiceItem = BaseLineItem;

interface BaseLineItem {
  description: string;
  amount: string;
  id: string;
  tax_rates?: TaxRate[];
  type: string;
}

export enum InvoiceStatus {
  Draft = "draft",
  Open = "open",
  Paid = "paid",
  Uncollectible = "uncollectible",
  Void = "void",
  Unpaid = "open",
  Processing = "processing",
}

export interface InvoiceClient {
  due_date?: string;
  user_uid: string;
  description: string;
  lines: InvoiceItem[];
  job_uid: string;
  home_name: string;
  home_id: string;
  vendor: string;
  vendor_id: string;
  vendor_invoice_number?: string;
  market_id: string;
  job_name: string;
  default_tax_rates?: string[];
  paid_externally?: boolean;
}

export interface Invoice {
  home_id: string;
  vendor_pay_method: string;
  id: string;
  created: Date;
  description: string;
  charge: {
    id: string;
    amount: string;
    receipt_url: string;
  };
  account_name: string;
  status: InvoiceStatus;
  invoiceitems: InvoiceItem[];
  default_tax_rates: TaxRate[];
  amount_due: string;
  amount_paid: string;
  amount_remaining: string;
  attempt_count: number;
  attempted: boolean;
  currency: string;
  due_date: Date;
  subtotal: string;
  tax: string;
  tax_percent: string;
  total: string;
  number: number;
  pre_payment_credit_notes_amount: number;
  vendor: string;
  vendor_obj: Vendor;
  vendor_invoice_number: string;
  hosted_invoice_url: string;
}

export interface FinalizedInvoice {
  invoice: Invoice;
  service_fee: number;
  service_fee_percentage: number;
  service_fee_percentage_source: string;
  total_cost: number;
}

// Bills

export interface Bill {
  id: number;
  vendor: string;
  invoice: string;
  percent_to_vendor: number;
  job: string;
  billdotcom_id: string;
  created_at: Date;
  updated_at: Date;
  billdotcomdetail: BillDotComDetail;
}

export interface BillDotComDetail {
  id: number;
  vendorId: string;
  paidAmount: string;
}

// Attachments

export interface Attachment {
  uid: string;
  name: string;
  file: string;
  home: string;
  job_set: string[];
  project_set: string[];
}

// Projects

export interface PlanBlueprint {
  id: string;
  icon_name: string;
  name: string;
  color: string;
}

export interface PlanBlueprintItem {
  description: string;
  latest_project: string | null;
  id: string;
  service_offering: ServiceOffering;
}

export interface HomePlan {
  name: string;
  id: string;
  plan_blueprint_items: PlanBlueprintItem[];
}

export interface Project {
  is_emergency: boolean;
  due_date: string | null;
  additional_notes?: string;
  uid: string;
  name: string;
  details: string;
  home: string;
  home_detail: Home;
  jobs: Job[];
  service_offering: ServiceOffering | null;
  category: number | null;
  created_by: string | null;
  created_by_detail: User | null;
  attachments: Attachment[];
  balance_remaining: number;
  total_amount_due: number;
  approved_at: string;
  upcoming_appointments?: Appointment[];
  all_appointments?: Appointment[];
  status: string;
  created_at: Date;
  annual_maintenance: boolean;
  tenant_request: boolean;
  archived_at: Date | null;
  paused_until: Date | null;
  assigned_to: UserSnippet[];
  project_origin?: string;
  plan_blueprint?: PlanBlueprint;
  kustomer_conversation_url?: string;
  updated_at: string;
}

export interface UserSnippet {
  uid: string;
  name: string;
}

export interface CancellationReason {
  id: number;
  reason: string;
}

// Jobs

export enum JOB_STATUS { // eslint-disable-line @typescript-eslint/naming-convention
  CREATED = "CREATED",
  STARTED = "STARTED",
  ESTIMATE_REQUESTED = "ESTIMATE_REQUESTED",
  VENDOR_SELECTED = "VENDOR_SELECTED",
  PAYMENT_DUE = "PAYMENT_DUE",
  COMPLETED = "COMPLETED",
  ARCHIVED = "ARCHIVED",
  PENDING_INVOICE = "PENDING_INVOICE",
}

export interface Job {
  uid: string;
  name: string;
  details: string;
  project: string;
  selected_vendor: string;
  vendor_name: string;
  completion_verification: string | null;
  created_by: User;
  invoices: Invoice[];
  bills: Bill[];
  time_proposals: TimeProposal[];
  attachments: Attachment[];
  balance_remaining: number;
  total_quote_amount: number;
  status: string;
  created_at: string;
  appointment_events?: Event[];
  vendor_stripe_account: string | null;
}

// Vendors

interface BankAccount {
  account_number: string;
  routing_number: string;
}

export interface Vendor {
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  contact_method?: string;
  country?: string;
  created_at?: Date;
  email?: string;
  functions?: VendorFunction[];
  owner?: number;
  phone_number?: string;
  phone_number_mobile?: string;
  state?: string;
  stripe_account_id?: string;
  stripe_setup_link?: string;
  bill_vendor_id?: string | null;
  bank_account?: BankAccount;
  uid: string;
  updated_at?: Date;
  vendor_name?: string;
  website?: string;
  zip_code?: string;
}

export interface VendorFunction {
  name: string;
}

export interface ServiceFee {
  total_cost: number;
  service_fee_percentage: number;
  service_fee_percentage_source: string;
  service_fee: number;
}

export interface VendorOnHome {
  service_rate: number;
  vendor: Vendor;
}

// Taxes

export interface TaxRate {
  djstripe_id: number;
  id: string;
  livemode: boolean;
  created: Date;
  metadata: string;
  description: string;
  djstripe_created: Date;
  djstripe_updated: Date;
  active: boolean;
  display_name: string;
  inclusive: boolean;
  jurisdiction: string;
  percentage: string;
}

// Events

export interface Event {
  id: string;
  vendor: string; // UID
  job: string;
  account_id: string;
  title: string;
  description: string | null;
  when: {
    start_time: number | null;
    end_time: number | null;
  };
  busy: boolean;
  calendar: string;
  status: string;
  type: string;
}

export interface Appointment {
  id: string;
  task_id: string;
  vendor_id: string;
  home_id: string;
  job?: string;
  when: {
    start_time: number | null;
    end_time: number | null;
  };
}
export interface JobAppointment {
  job: string;
  start_timestamp: number;
  end_timestamp: number;
}

export interface TimeProposal {
  id: string;
  archived_at: Date;
  title: string;
  description: string | null;
  start_window_begin: Date | null;
  start_window_end: Date | null;
  duration: Duration;
  job: string;
  calendar: string;
  vendor: string;
}

export interface Duration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

// Actions

export interface Action {
  action_type_id: string;
  created_at: Date;
  paused_at: Date;
  project_snippet?: ProjectSnippetOnAction;
  home_snippet: HomeSnippetOnAction;
  issue: string;
  job?: string;
  obj_type: string;
  uid: string;
}

export interface ProjectSnippetOnAction {
  assigned_to: UserSnippet[];
  name: string;
  uid: string;
  details: string;
  priority: number;
  project_status: string;
}

export interface HomeSnippetOnAction {
  title: string;
  uid: string;
  home_address: HomeAddress;
  cover_image: string;
}

export interface ActionType {
  actionableURL: (action: Action) => void; // TODO: rename this awful, awful name
  text: string;
}

// Service Estimate

export interface ServiceEstimate {
  uid: string;
  job: string;
  vendor: string;
  amount: number | string | null;
  homeowner_approved_at: Date;
  created_at: Date;
  updated_at: Date;
  archived_at: Date;
  sent_to_owner: Date;
  sent_to_vendor_at: Date;
  conversation_id: string;
  attachments: Attachment[];
}

export interface HomeNote {
  uid: string;
  title: string;
  text: string;
  home_id: string;
}

export interface HomeNoteClient {
  title: string;
  text: string;
}

// Notes
export interface Note {
  uid: string;
  description: string;
  created_by: string;
  created_by_name: string;
  project_id: string;
  archived_at: string | null;
  created_at: Date;
  updated_at: Date;
}

// Activities
export interface Activity {
  uid: string;
  project: string;
  job: string | null;
  activity_type: string;
  created_at: Date;
  created_by: string;
  object_id: string;
}

export type ActivityDisplay = {
  text: string;
  destinationURL: (x: Activity) => string;
};

// Markets
export interface Market {
  id: number;
  name: string;
  zip_codes: { string: string };
  created_at: Date;
  updated_at: Date;
}

export interface Place {
  address_components: {
    types: string[];
    long_name: string;
    short_name: string;
  }[];
  formatted_address: string;
}
