import React, { FC } from "react";
import { motion } from "framer-motion";

import useScreenSize from "hooks/use-screen-size";

import styles from "./styles.module.scss";

const Content: FC<{ className?: string; withSidebar?: boolean }> = ({
  children,
  className,
  withSidebar,
}) => {
  const { screenWidth, isResponsive } = useScreenSize();

  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: "0px" }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", duration: 0.7 }}
      className={styles.container}
    >
      <div
        style={{
          width: isResponsive
            ? "100%"
            : withSidebar
            ? screenWidth - 250
            : "100%",
        }}
        className={`${styles.content} ${className}`}
      >
        {children}
      </div>
    </motion.div>
  );
};

Content.defaultProps = {
  className: "",
  withSidebar: false,
};

export default Content;
