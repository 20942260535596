/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, FC, useContext, ComponentType } from "react";

import { Theme } from "types/components";

import theme from "./theme.main";

export const ThemeContext = createContext<Theme>({
  theme,
});

export const ThemeCreator: FC = ({ children }) => (
  <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
);

export const useTheme = (): Theme => useContext(ThemeContext);

export function Themed<T>(
  WrappedComponent: ComponentType<T>
): FC<Pick<T, Exclude<keyof T, "theme">>> {
  return (props) => <WrappedComponent {...(props as T)} theme={theme} />;
}
