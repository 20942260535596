import { atomFamily } from "recoil";
import { Values } from "./types";

export const formValuesState = atomFamily<Values, string>({
  key: "formValues",
  default: {},
});

export const formErrorsState = atomFamily<Values, string>({
  key: "formErrors",
  default: {},
});
