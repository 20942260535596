import Button from "components/Button";
import Label from "components/Label";
import React, { FC } from "react";
import styles from "./styles.module.scss";

const Confirmation: FC = () => {
  return (
    <div className={styles.container}>
      <Label type="h5" color="primary">
        Thanks for setting your password!
      </Label>
      <div>
        <Label type="s1" color="primary">
          Now the next step is to download the Humming Homes App.
        </Label>
        <Label type="s1" color="primary">
          Click on the button below to start your journey with us!
        </Label>
      </div>
      <Button
        onClick={() =>
          window.open(
            "https://apps.apple.com/us/app/humming-homes/id1511753658",
            "_self"
          )
        }
      >
        Download our App on your iPhone
      </Button>
    </div>
  );
};

export default Confirmation;
