import React, { useContext, ReactNode, useMemo } from "react";
import { usePersistedState } from "context/PersistedContext";

import SessionContext from "context/SessionContext";
import { Token } from "types/models";

interface SessionProviderType {
  children: ReactNode | Array<ReactNode>;
}

const SESSION_KEY = "_humming_session";

export const SessionProvider = ({ children }: SessionProviderType) => {
  const [sessionData, setState] = usePersistedState(SESSION_KEY, "{}");

  const setSessionData = (data: Token | null) => {
    if (data && data.access_token) {
      setState(data);
    } else {
      setState({});
    }
  };

  const value = useMemo(
    () => ({ sessionData, setSessionData }),
    [sessionData, setSessionData]
  );

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export const useSessionValue = () => useContext(SessionContext);

export const getStoredSession = () => {
  return JSON.parse(localStorage.getItem(SESSION_KEY) || "{}") as Token;
};

export const setStoredSession = (session: Token) => {
  if (session && session.access_token) {
    localStorage.setItem(SESSION_KEY, JSON.stringify(session));
  } else {
    localStorage.setItem(SESSION_KEY, JSON.stringify("{}"));
  }
};
