/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from "react";
import { Button, Upload, UploadFile } from "antd";
import {
  BiImageAdd as AddIcon,
  BiDownload as DownloadIcon,
} from "react-icons/bi";

import { handleBulkDownload } from "utils/attachments";
import ErrorBar from "components/ErrorBar";

import Modal from "components/Modal";
import useAsync from "hooks/use-async";
import LoadingComponent from "components/LoadingComponent";
import styles from "./styles.module.scss";

const AttachmentsField: FC<{
  onChange: (newValue: UploadFile[]) => void;
  error?: string;
  initialValue?: UploadFile[];
  id?: string;
}> = ({ onChange, error, initialValue = [], id }) => {
  const [files, setFiles] = useState<UploadFile[]>(initialValue);

  const [preview, setPreview] = useState<null | {
    title: string;
    url: string;
  }>(null);
  const [openPreview, setOpenPreview] = useState(false);

  useEffect(() => {
    onChange(files);
  }, [files]);

  const [upload, uploading] = useAsync(async ({ file }: any) => {
    if (files) setFiles([...files, file]);
    else setFiles([file]);
  });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Upload
          disabled={uploading}
          id={id}
          listType="picture-card"
          fileList={files}
          customRequest={upload}
          onRemove={({ name }) => {
            setFiles(files.filter(({ name: fileName }) => fileName !== name));
          }}
          onPreview={(file) => {
            const { url, name } = file;

            if (url) {
              setOpenPreview(true);
              setPreview({ url, title: name });
            }
          }}
        >
          <LoadingComponent loading={uploading}>
            <AddIcon className={styles.uploadIcon} />
          </LoadingComponent>
        </Upload>
        {files.some(({ url }) => !!url) && (
          <Button
            disabled={!files?.length}
            type="text"
            onClick={() => handleBulkDownload(files)}
            className={styles.downloadButton}
          >
            <DownloadIcon className={styles.downloadIcon} />
          </Button>
        )}
      </div>
      <ErrorBar id={id} className={styles.errorBar} error={error} />
      <Modal
        contentClassName={styles.modalContent}
        visible={openPreview}
        title={preview?.title}
        handleClose={() => setOpenPreview(false)}
        onModalClosed={() => setPreview(null)}
      >
        <img
          alt={preview?.title}
          className={styles.preview}
          src={preview?.url}
        />
      </Modal>
    </div>
  );
};

AttachmentsField.defaultProps = {
  error: undefined,
  initialValue: [],
  id: undefined,
};

export default AttachmentsField;
