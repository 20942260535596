import Button from "components/Button";
import Form from "components/Form";
import React, { FC } from "react";
import { Project, User } from "types/models";
import { required } from "utils/form";
import { PROJECT_ORIGIN } from "constants/projects";

import useAsync from "hooks/use-async";
import {
  addAttachmentsToProject,
  removeAttachmentsFromProject,
} from "api/attachments";
import { ProjectClient } from "types/client";
import {
  createProject,
  removeAssignmentFromProject,
  updateProject,
} from "api/projects";
import toast from "react-hot-toast";
import { isEmpty } from "utils/array";
import { Attachment } from "components/HomeAttachmentsField";
import useProjects from "hooks/use-projects";
import { useParams } from "react-router-dom";
import {
  ArchivedProjectStatus,
  CompletedProjectStatus,
  ProjectStatus,
  ProjectStatusOptions,
} from "constants/statuses";
import styles from "./styles.module.scss";

interface Props {
  project: Project | null;
  assignees: User[];
  onSubmit?(): void;
  home: string;
}

interface FormValues {
  name: string;
  details: string;
  service_offering: number;
  service_category: number;
  service_subcategoru: number;
  home: string;
  attachments: Attachment[];
  additional_notes: string;
  annual_maintenance: boolean;
  tenant_request: boolean;
  assigned_to: string;
  due_date: string;
}

const BlankProject: FC<Props> = ({ project, assignees, onSubmit, home }) => {
  const { projectID } = useParams<{ [key: string]: string }>();

  const { updateProjectDetails, serviceCategories, projectCategories } =
    useProjects();

  const offerings = serviceCategories
    .map(
      ({ children }) =>
        children
          ?.map(
            ({ service_offerings }) =>
              service_offerings
                ?.map(({ id, full_name }) => ({
                  name: full_name,
                  id,
                }))
                .flat() || []
          )
          .flat() || []
    )
    .flat();

  const isEditing = !!project;
  const hasPlan = !!project?.plan_blueprint;

  const [removeAttachment, removingAttachment] = useAsync(
    async (uid: string) => {
      if (project) await removeAttachmentsFromProject(project.uid, [uid]);
    }
  );

  const [handleCreateProject, creatingProject] = useAsync(
    async (values: FormValues) => {
      const assignee = assignees.find(({ uid }) => uid === values.assigned_to);
      const { attachments, ...rest } = values;
      const newProject: ProjectClient = {
        ...rest,
        home,
        assigned_to: assignee ? [assignee.uid] : [],
      };
      const response = await createProject(newProject);

      if (response) {
        toast.success("Created Project", { position: "top-right" });

        if (Array.isArray(attachments) && !isEmpty(attachments)) {
          const { uid: projectUID } = response;

          await addAttachmentsToProject(
            projectUID,
            attachments.map(({ uid }) => uid)
          )
            .then(() =>
              toast.success("Uploaded Attachments", { position: "top-right" })
            )
            .catch(() =>
              toast.error("Failed to upload Attachments", {
                position: "top-right",
              })
            );
        }
        updateProjectDetails();
        onSubmit?.();
      } else {
        toast.error("Failed to create project", { position: "top-right" });
      }
    }
  );
  const [handleUpdateProject, updatingProject] = useAsync(
    async (values: FormValues) => {
      const { attachments, ...rest } = values;
      const assignee = assignees.find(({ uid }) => uid === values.assigned_to);
      const newProject: ProjectClient = {
        ...rest,
        home,
        assigned_to: assignee ? [assignee.uid] : [],
      };
      await Promise.all(
        project?.assigned_to.map(async ({ uid }) =>
          removeAssignmentFromProject(project.uid, uid)
        ) || []
      );
      if (project && Array.isArray(attachments) && !isEmpty(attachments)) {
        const { uid: projectUID } = project;
        await addAttachmentsToProject(
          projectUID,
          attachments.map(({ uid }) => uid)
        );
      }
      await updateProject(projectID, newProject)
        .then(() => {
          updateProjectDetails();
          toast.success("Updated Project", { position: "top-right" });
          onSubmit?.();
        })
        .catch(() => {
          toast.error("Failed to update project", { position: "top-right" });
        });
    }
  );

  return (
    <Form
      className={styles.form}
      initialValues={{
        is_emergency: project?.is_emergency,
        name: project?.name,
        status: project?.status,
        details: project?.details,
        category: project?.category,
        service_category: project?.service_offering?.category?.parent?.id,
        service_subcategory: project?.service_offering?.category?.id,
        service_offering: project?.service_offering?.id,
        home: project?.home_detail?.uid,
        attachments: project?.attachments,
        additional_notes: project?.additional_notes,
        annual_maintenance: project?.annual_maintenance,
        tenant_request: project?.tenant_request,
        assigned_to: project?.assigned_to[0]?.uid,
        project_origin: project?.project_origin,
        due_date: project?.due_date,
      }}
      fields={[
        {
          required: true,
          title: "Service category",
          type: "select",
          id: "service_category",
          disabled: hasPlan,
          options: serviceCategories.map(({ name, id }) => ({
            label: name,
            value: id,
          })),
          validate: [required("This field is required")],
          props: { ordered: true },
        },
        {
          required: true,
          title: "Service subcategory",
          disabled: hasPlan,
          type: "select",
          id: "service_subcategory",
          parents: [{ id: "service_category" }],
          props: { ordered: true },
          options: (values) => {
            const { service_category: category } = values;

            return (
              (category &&
                serviceCategories
                  .find(({ id }) => id === category)
                  ?.children?.map(({ name, id }) => ({
                    label: name,
                    value: id,
                  }))) ||
              []
            );
          },
          validate: [required("This field is required")],
        },
        {
          required: true,
          title: "Service offering",
          disabled: hasPlan,
          props: { ordered: true },
          type: "select",
          id: "service_offering",
          parents: [{ id: "service_category" }, { id: "service_subcategory" }],
          options: (values) => {
            const {
              service_category: category,
              service_subcategory: subcategory,
            } = values;

            return (
              (category &&
                subcategory &&
                serviceCategories
                  .find(({ id }) => id === category)
                  ?.children?.find(({ id }) => id === subcategory)
                  ?.service_offerings?.map(({ name, id }) => ({
                    label: name,
                    value: id,
                  }))) ||
              []
            );
          },
          validate: [required("This field is required")],
        },
        {
          required: true,
          type: "text",
          id: "name",
          validate: [required("This field is required")],
          title: "Title",
          disabled: true,
          derivateValue: ({ service_offering }: FormValues) =>
            offerings.find(({ id }) => id === service_offering)?.name || "",
        },
        {
          required: true,
          type: "area",
          id: "details",
          validate: [required("Please describe what needs to be done")],
          title: "Description",
        },
        {
          id: "status",
          type: "select",
          required: true,
          validate: [required("Please assign a status to this project")],
          title: "Status",
          placeholder: "Status",
          options: ProjectStatusOptions.map(({ value, display }) => ({
            value,
            label: display,
          })).filter(
            ({ value }) =>
              ![...ArchivedProjectStatus, ...CompletedProjectStatus].includes(
                value as ProjectStatus
              )
          ),
        },
        {
          type: "date",
          id: "due_date",
          title: "Due date",
        },
        {
          title: "Internal notes",
          type: "area",
          id: "additional_notes",
        },
        {
          required: true,
          type: "select",
          title: "Assignment",
          id: "assigned_to",
          options: assignees.map(({ name, uid }) => ({
            value: uid,
            label: name,
          })),
          validate: [required("Please assign someone to the project")],
        },
        ...(project?.category
          ? [
              {
                disabled: true,
                title: "Category",
                type: "select",
                id: "category",
                options: projectCategories.map(({ name, id }) => ({
                  label: name,
                  value: id,
                })),
              },
            ]
          : []),
        {
          id: "project_origin",
          title: "Project's origin",
          type: "select",
          options: PROJECT_ORIGIN,
        },
        {
          id: "is_emergency",
          placeholder: "Emergency",
          type: "checkbox",
        },
        {
          id: "tenant_request",
          placeholder: "Requested by tenant",
          type: "checkbox",
        },
        {
          id: "attachments",
          type: "home-attachments",
          title: "Attachments",
          props: { onDelete: removeAttachment, selectedHome: home },
        },
      ]}
      onSubmit={isEditing ? handleUpdateProject : handleCreateProject}
    >
      {({ handleSubmit, invalid }) => (
        <Button
          loading={creatingProject || updatingProject || removingAttachment}
          className={styles.submit}
          disabled={invalid}
          onClick={handleSubmit}
        >
          {isEditing ? "Save Project" : "Create Project"}
        </Button>
      )}
    </Form>
  );
};

BlankProject.defaultProps = {
  onSubmit: undefined,
};

export default BlankProject;
