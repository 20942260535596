import { UserRole } from "types/models";

export const USER_ROLE_LABELS = {
  [UserRole.HomeCoordinator]: "Home Coordinator",
  [UserRole.Admin]: "Admin",
  [UserRole.HomeMember]: "Home member",
  [UserRole.HomeOwner]: "Home owner",
  [UserRole.HomeWatcher]: "Home watcher",
  [UserRole.HomeExpert]: "Home Expert",
  [UserRole.HummingManager]: "Humming manager",
  [UserRole.Vendor]: "Vendor",
};
