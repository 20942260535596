import React, { ReactElement, forwardRef } from "react";
import styled from "styled-components";
import { LoadingOutlined as Loading } from "@ant-design/icons";

import { Themed } from "theme/ThemeCreator";
import { Theme } from "types/components";
import Greeting from "components/Greeting";
import Label from "components/Label";

type Props = {
  theme: Theme;
  Icon?: ReactElement;
  variant?: string;
  children?: string;
  size?: string;
  onClick(): void;
  disabled?: boolean;
  loading?: boolean;
  id?: string;
  className?: string;
  iconAfter?: boolean;
  labelVariant?: string;
  stopPropagation?: boolean;
};

type StyledProps = {
  theme: Theme;
  variant: string;
  size: string;
  iconAfter: boolean;
  isIconVariant: boolean;
};

const StyledButton = styled.button<StyledProps>`
  ${({ theme, variant, size, disabled, iconAfter, isIconVariant }) => {
    const buttonTheme = theme.button;

    const {
      backgroundColor,
      borderColor,
      borderRadius,
      color,
      hover,
      disabled: disabledTheme,
    } = buttonTheme[variant];

    const {
      backgroundColor: hoverBackgroundColor,
      borderColor: hoverBorderColor,
      color: hoverColor,
    } = hover;

    const {
      color: disabledColor,
      backgroundColor: disabledBackgroundColor,
      borderColor: disabledBorderColor,
    } = disabledTheme;

    return `
      align-items: center;
      display: flex;
      flex-direction: ${iconAfter ? "row-reverse" : "row"};
      background-color: ${backgroundColor};
      border: 1px solid ${borderColor ?? backgroundColor};
      border-radius: ${borderRadius}px;
      color: ${color};
      ${!disabled && "cursor: pointer;"}
      font-weight: 500;
      justify-content: center;
      padding: ${
        isIconVariant ? buttonTheme.iconPadding : buttonTheme[`${size}Padding`]
      };
      transition: all 0.3s;

      &:hover {
        background-color: ${hoverBackgroundColor};
        border: 1px solid ${hoverBorderColor ?? hoverBackgroundColor};
        color: ${hoverColor};
      }

      &:disabled {
        background-color: ${disabledBackgroundColor};
        border: 1px solid ${disabledBorderColor ?? disabledBackgroundColor};
        color: ${disabledColor};
      }
    `;
  }}
`;

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      theme,
      Icon,
      children,
      variant = "primary",
      size = "xxlarge",
      onClick,
      disabled,
      loading,
      id,
      className,
      iconAfter = false,
      labelVariant,
      stopPropagation,
    },
    ref
  ) => {
    return (
      <StyledButton
        ref={ref}
        isIconVariant={!!Icon && !children}
        id={id}
        onClick={(event) => {
          if (stopPropagation) event.stopPropagation();
          onClick();
        }}
        size={size}
        variant={variant}
        theme={theme}
        disabled={disabled || loading}
        className={className}
        iconAfter={iconAfter}
      >
        <Greeting hi="expandWidth" goodbye="expandWidth" unmountOn={!loading}>
          <Loading style={{ marginRight: 10 }} />
        </Greeting>
        {Icon}
        {children && <Label type={`button-${labelVariant}`}>{children}</Label>}
      </StyledButton>
    );
  }
);

Button.defaultProps = {
  Icon: undefined,
  variant: "primary",
  size: "xxlarge",
  disabled: false,
  loading: false,
  id: undefined,
  className: undefined,
  iconAfter: false,
  children: undefined,
  labelVariant: "",
  stopPropagation: false,
};

export default Themed<Props>(Button);
