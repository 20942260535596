/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect } from "react";

const useBackdrop = (id: string, callback: () => void) => {
  const documentClickHandler = useCallback((event: any) => {
    if (!event.target.closest(`#${id}`)) callback();
  }, []);

  useEffect(() => {
    document.addEventListener("click", documentClickHandler);
    return () => document.removeEventListener("click", documentClickHandler);
  }, []);
};

export default useBackdrop;
