/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Flex, Heading } from "rebass";
import { CSSObject } from "styled-components";
import theme from "theme/theme.main";

type SubSectionProps = {
  title: string;
  children?: React.ReactNode;
  flexDirection?: "row" | "column";
  headerStyles?: CSSObject;
  [x: string]: any;
  headerClassName?: string;
};

const SubSection: FC<SubSectionProps> = ({
  title,
  children,
  flexDirection,
  headerStyles,
  headerClassName,
  ...styles
}: SubSectionProps) => {
  if (!children) return null;
  return (
    <Flex
      marginTop="8px"
      justifyContent="space-between"
      flexDirection={flexDirection}
      {...styles}
    >
      <Heading
        fontSize="12px"
        color={theme.colors.text}
        marginBottom={flexDirection === "row" ? 0 : 2}
        fontFamily="inherit"
        css={{ fontWeight: 600, textTransform: "uppercase", ...headerStyles }}
        className={headerClassName}
      >
        {title}
      </Heading>
      {children}
    </Flex>
  );
};

SubSection.defaultProps = {
  children: null,
  flexDirection: "column",
  headerStyles: {},
  headerClassName: "",
};

export default SubSection;
