/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useMemo } from "react";
import {
  MdNavigateNext as NextIcon,
  MdNavigateBefore as PreviousIcon,
} from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { v4 as uniqueID } from "uuid";

import Button from "components/Button";
import Label from "components/Label";

import { atom, useRecoilState } from "recoil";
import styles from "./styles.module.scss";
import Progress from "./components/Progress";
import { Step } from "./types";

type Props = {
  steps: Step[];
  withoutPreviousStepButton?: boolean;
  withoutNextStepButton?: boolean;
  className?: string;
  showProgress?: boolean;
  contentClassName?: string;
  progressConfig?: { backgroundColor?: string };
};

const Flow: FC<Props> = ({
  steps,
  withoutNextStepButton,
  withoutPreviousStepButton,
  className,
  showProgress,
  contentClassName,
  progressConfig = {},
}: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [forward, setForward] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [previousDisabled, setPreviousDisabled] = useState(false);

  const sharedState = useMemo(
    () => atom({ key: `FLOW-${uniqueID()}`, default: {} }),
    []
  );

  useRecoilState(sharedState);

  const totalSteps = steps.length;

  const withoutHeader = withoutNextStepButton && withoutPreviousStepButton;

  const {
    Component,
    title,
    subtitle,
    onGoToNextStep,
    onGoToPreviousStep,
    key,
    onFinish,
    ...stepProps
  } = steps[currentStep];

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
    setForward(true);
    onGoToNextStep?.();
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
    setForward(false);
    onGoToPreviousStep?.();
  };

  const variants = {
    initial: (customForward: boolean) => ({
      x: customForward ? "100%" : "-100%",
    }),
    exit: (customForward: boolean) => ({ x: customForward ? "-100%" : "100%" }),
    active: { x: "0px" },
  };

  return (
    <div className={`${styles.container} ${className}`}>
      {showProgress && (
        <Progress
          setCurrentStep={(newStep: number) => {
            if (newStep > currentStep) setForward(true);
            else setForward(false);
            setCurrentStep(newStep);
          }}
          config={progressConfig}
          steps={steps}
          currentStep={currentStep}
        />
      )}
      {!withoutHeader && (
        <div className={styles.header}>
          {!withoutPreviousStepButton && (
            <Button
              className={styles.previousButton}
              variant="text"
              onClick={previousStep}
              Icon={<PreviousIcon className={styles.icon} />}
              disabled={previousDisabled || currentStep === 0}
            />
          )}
          {!withoutNextStepButton && (
            <Button
              className={styles.nextButton}
              variant="primary"
              onClick={nextStep}
              Icon={<NextIcon className={styles.icon} />}
              iconAfter
              disabled={nextDisabled || currentStep === totalSteps - 1}
            />
          )}
        </div>
      )}
      <AnimatePresence initial={false} custom={forward} exitBeforeEnter>
        <motion.div
          custom={forward}
          className={`${styles.content} ${contentClassName}`}
          key={key}
          animate="active"
          exit="exit"
          initial="initial"
          transition={{ ease: "easeInOut", duration: 0.5 }}
          variants={variants}
        >
          {(title || subtitle) && (
            <div className={styles.headerInfo}>
              {title && (
                <Label
                  className={styles.titles}
                  type="centered-h5"
                  color="primary"
                >
                  {title}
                </Label>
              )}
              {subtitle && (
                <Label className={styles.titles} type="centered-light">
                  {subtitle}
                </Label>
              )}
            </div>
          )}
          <Component
            onFinish={onFinish}
            sharedFlowState={sharedState}
            nextStep={nextStep}
            previousStep={previousStep}
            setNextButtonDisabled={setNextDisabled}
            setPreviousbuttonDisabled={setPreviousDisabled}
            {...stepProps}
          />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

Flow.defaultProps = {
  withoutNextStepButton: false,
  withoutPreviousStepButton: false,
  className: "",
  showProgress: false,
  contentClassName: undefined,
  progressConfig: {},
};

export default Flow;
