import React, { FC, useEffect } from "react";

import Form from "components/Form";
import { required } from "utils/form";
import Button from "components/Button";
import useAsyncAtom from "hooks/use-async-atom";
import { fetchTaxRates } from "api/taxes";
import { taxRatesState } from "recoil/invoices";
import LoadingComponent from "components/LoadingComponent";

import { Home, InvoiceClient, InvoiceItem, Job, Vendor } from "types/models";

import { formatInvoiceForm, FormValues } from "./utils";
import InvoiceSummary from "./components/InvoiceSummary";
import styles from "./styles.module.scss";

type Props = {
  creatingInvoice: boolean;
  createInvoice(invoice: InvoiceClient): void;
  job: Job;
  disabled?: boolean;
  vendor: Vendor;
  home: Home;
};

const InvoiceForm: FC<Props> = ({
  creatingInvoice,
  createInvoice,
  job,
  disabled,
  vendor,
  home,
}) => {
  const {
    data: taxRates,
    inProgress: fetchingTaxes,
    refresh: getTaxes,
  } = useAsyncAtom(taxRatesState, () => fetchTaxRates());

  useEffect(() => {
    getTaxes();
  }, []);

  return (
    <LoadingComponent className={styles.container} loading={fetchingTaxes}>
      <Form
        name="invoice-form"
        onSubmit={(newInvoice: FormValues) =>
          createInvoice(formatInvoiceForm(newInvoice, job, home, vendor))
        }
        renderExternally
        disabled={creatingInvoice || disabled}
        initialValues={{
          service_provider: vendor.uid,
        }}
        fields={[
          {
            id: "invoice-detail",
            placeholder: "Invoice Detail",
            type: "label",
          },
          {
            title: "Customer Memo",
            type: "area",
            id: "description",
            placeholder: "What needs to be done?",
            required: true,
            validate: [required("Please describe what needs to be done")],
          },
          {
            id: "vendor-invoice-detail-label",
            placeholder: "Vendor Invoice Detail",
            type: "label",
          },
          {
            title: "Invoice number",
            placeholder: "Input invoice number",
            type: "text",
            id: "vendor_invoice_number",
          },
          {
            title: "Received invoice on",
            type: "date",
            id: "vendors_invoice_reception_date",
            validate: [required("Field is required")],
            required: true,
            props: {
              futureDates: false,
              pastDates: true,
              format: "YYYY-MM-DD",
              valueFormat: "YYYY-MM-DD",
            },
          },
          {
            title: "Service Provider",
            type: "select",
            disabled: true,
            id: "service_provider",
            options: [{ label: vendor.vendor_name || "", value: vendor.uid }],
          },
          {
            id: "invoice-charges-label",
            placeholder: "Invoice Charges",
            type: "label",
          },
          {
            title: "Invoice items",
            validate: [
              required("Please add at least one item to this invoice."),
            ],
            id: "lines",
            type: "line-items",
            help: "Invalid line items will not be added to the invoice.",
            required: true,
          },
          {
            title: "Tax",
            type: "select",
            id: "tax",
            options: taxRates
              .filter(({ active }) => active)
              .map(({ djstripe_id: stripeID, description }) => ({
                value: `${stripeID}`,
                label: description,
                key: `${stripeID}`,
              })),
          },
        ]}
      >
        {({ handleSubmit, formToRender, values, invalid }) => {
          const { lines: invoiceItems, tax: currentTaxRateStripID } = values;

          const subtotal =
            (invoiceItems as InvoiceItem[])?.reduce(
              (acc, { amount }) => acc + parseFloat(amount),
              0
            ) ?? 0;

          const tax = taxRates?.find(
            ({ djstripe_id: stripeID }) =>
              stripeID.toString() === currentTaxRateStripID
          );

          const { percentage = "0" } = tax || {};

          const taxPercentage = parseFloat(percentage);
          const taxAmount = (taxPercentage / 100) * subtotal;

          const total = taxAmount + subtotal;

          return (
            <>
              {formToRender}
              <InvoiceSummary
                total={total}
                taxAmount={taxAmount}
                taxPercentage={taxPercentage}
                subtotal={subtotal}
              />
              <Button
                loading={creatingInvoice}
                disabled={invalid || disabled}
                className={styles.nextStepButton}
                id="create-invoice-next-step"
                onClick={handleSubmit}
              >
                {creatingInvoice ? "Creating Invoice" : "Create Invoice"}
              </Button>
            </>
          );
        }}
      </Form>
    </LoadingComponent>
  );
};

InvoiceForm.defaultProps = {
  disabled: false,
};

export default InvoiceForm;
