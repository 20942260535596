import { mapper } from "utils/mapping";

export const tagVariants = mapper(
  {
    emergency: {
      label: "Emergency",
      labelProps: { type: "negative" },
    },
    normal: {
      label: "Normal",
      labelProps: { type: "neutral" },
    },
  },
  {}
);
