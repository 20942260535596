import React, { useEffect, FC } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import Loading from "components/Loading";
import { fetchCurrentVendor } from "api/vendors";
import { vendorUser } from "recoil/vendors";
import Flow from "components/Flow";

import { useSidebarInteraction } from "components/Sidebar";
import useAsync from "hooks/use-async";
import { getSteps } from "./utils";
import styles from "./styles.module.scss";

const Onboarding: FC = () => {
  const { hideSidebar, showSidebar } = useSidebarInteraction();

  useEffect(() => {
    hideSidebar();

    return () => showSidebar();
  }, []);

  const location = useLocation();
  const setVendor = useSetRecoilState(vendorUser);

  const addPaymentMethod = location.pathname.includes("add-bank-info");

  const [getVendor, gettingVendor] = useAsync(() =>
    fetchCurrentVendor()
      .then(setVendor)
      .catch(() => setVendor(null))
  );

  const steps = getSteps(addPaymentMethod);

  useEffect(() => {
    getVendor();
  }, []);

  return !gettingVendor ? (
    <div className={styles.container}>
      <Flow className={styles.flow} withoutNextStepButton steps={steps} />
    </div>
  ) : (
    <Loading />
  );
};

export default Onboarding;
