import React, { FC, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { HiChevronLeft as Chevron } from "react-icons/hi";
import { IoClose as CloseIcon } from "react-icons/io5";

import { ReactComponent as Logo } from "assets/logo.svg";
import useScreenSize from "hooks/use-screen-size";

import { useRecoilState, useRecoilValue } from "recoil";
import Button from "components/Button";
import styles from "./styles.module.scss";
import SidebarItem from "./components/SidebarItem";
import { hiddenState, unmountedState } from "./atoms";
import useSidebarInteraction from "./hooks/use-sidebar-interaction";
import useMousePosition from "./hooks/use-mouse-position";
import useToggleSidebar from "./hooks/use-toggle-sidebar";

export { useSidebarInteraction, useToggleSidebar };
interface Option {
  onClick(): void;
  key: string;
  label: string;
  Icon: FC<{ className: string }>;
  notifications?: number;
}

type Props = {
  options: Option[];
  externalSelectedOption?: string;
  fixed?: boolean;
};

const Sidebar: FC<Props> = ({
  options,
  externalSelectedOption = "",
  fixed,
}) => {
  const [hidden, setHidden] = useRecoilState(hiddenState);
  const { x: mouseX } = useMousePosition();

  const [panelHover, setPanelHover] = useState(false);
  const unmounted = useRecoilValue(unmountedState);

  const { isResponsive } = useScreenSize();
  const [selectedOption, setSelectedOption] = useState<string>(
    externalSelectedOption
  );

  useEffect(() => {
    setSelectedOption(externalSelectedOption);
  }, [externalSelectedOption]);

  const [collapsed, setCollapsed] = useState(false);

  const collapseButtonVariants = {
    collapsed: {
      rotate: 180,
    },
    expanded: { rotate: 0 },
  };

  const containerVariants = {
    collapsed: {
      width: 155,
      transition: { ease: "easeInOut", duration: 0.5 },
    },
    expanded: {
      width: 300,
      transition: { ease: "easeInOut", duration: 0.5 },
    },
  };

  const expandVariants = {
    visible: {
      width: 5,
    },
    hidden: {
      width: 0,
      padding: 0,
    },
  };

  const wrapperVariants = {
    visible: {
      x: 0,
    },
    hidden: (customCollapsed: boolean) => ({
      x: customCollapsed ? -155 : -300,
    }),
  };

  const isFixed = fixed && !isResponsive;

  return !unmounted ? (
    <motion.div
      custom={collapsed}
      animate={
        isFixed || (isResponsive ? !hidden : mouseX <= 10 || panelHover)
          ? "visible"
          : "hidden"
      }
      initial="hidden"
      variants={wrapperVariants}
      className={`${styles.wrapper} ${isFixed && styles.fixedSidebar}`}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        onHoverStart={() => setPanelHover(true)}
        onHoverEnd={() => setPanelHover(false)}
        initial={{ width: 0 }}
        animate={collapsed ? "collapsed" : "expanded"}
        className={styles.container}
        variants={containerVariants}
      >
        <div className={styles.header}>
          <Logo />
        </div>
        {options.map(({ Icon, label, onClick, key, notifications }) => (
          <SidebarItem
            collapsed={collapsed}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            Icon={Icon}
            label={label}
            onClick={onClick}
            ownKey={key}
            key={key}
            notifications={notifications}
          />
        ))}
        <div className={styles.actions}>
          <motion.button
            onClick={() => setCollapsed(!collapsed)}
            className={styles.actionButton}
            variants={collapseButtonVariants}
          >
            <Chevron className={styles.chevron} />
          </motion.button>
          {isResponsive && (
            <Button
              className={styles.actionButton}
              onClick={() => setHidden(true)}
              Icon={<CloseIcon className={styles.chevron} />}
            />
          )}
        </div>
      </motion.div>
      {!isFixed && !isResponsive && (
        <motion.div
          animate={mouseX <= 10 || panelHover ? "hidden" : "visible"}
          variants={expandVariants}
          className={styles.expand}
        />
      )}
    </motion.div>
  ) : null;
};

Sidebar.defaultProps = {
  externalSelectedOption: "",
  fixed: false,
};

export default Sidebar;
