import { AxiosError } from "axios";

import { setStoredSession } from "providers/SessionProvider";
import { StripeCustomer, Token } from "types/models";

import { apiClient } from ".";

export interface ServerError {
  message: string[];
  detail: string;
  code: string;
  error: string;
  non_field_errors: string[];
}

const login = async (email: string, password: string) => {
  try {
    const user = {
      email,
      password,
    };
    const response = await apiClient.post<Token>("/auth/login/", { ...user });
    const newSession = response.data;
    if (newSession) {
      setStoredSession(newSession);
    }
    return newSession;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

// User Management

type UserCreationProps = {
  email: string;
  password: string;
  role: string;
  name?: string;
  phone_number?: string;
  avatar?: string | null;
  accepted_terms_of_use?: boolean;
  accepted_privacy_policy?: boolean;
};

const createUser = async ({
  email,
  name,
  password,
  role,
  avatar = "",
  phone_number = "",
  accepted_privacy_policy = false,
  accepted_terms_of_use = false,
}: UserCreationProps) => {
  try {
    const payload = {
      email,
      password,
      name,
      avatar,
      role,
      phone_number,
      accepted_privacy_policy,
      accepted_terms_of_use,
    };
    const response = await apiClient.post<Token>(
      "/auth/registration/",
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchStripeCustomer = async () => {
  try {
    const response = await apiClient.get<StripeCustomer>("/customers/me/");
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

// Password Management

interface DetailResponse {
  detail: string;
}

const resetPassword = async (email: string) => {
  try {
    const payload = {
      email,
    };
    const response = await apiClient.post<DetailResponse>(
      "/auth/password/reset/",
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const resetPasswordConfirm = async (
  uid: string,
  token: string,
  new_password1: string,
  new_password2: string
) => {
  try {
    const payload = {
      uid,
      token,
      new_password1,
      new_password2,
    };
    const response = await apiClient.post<DetailResponse>(
      "/auth/password/reset/confirm/",
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export {
  createUser,
  login,
  resetPassword,
  resetPasswordConfirm,
  fetchStripeCustomer,
};
