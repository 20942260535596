import React, { useState, useEffect, FC, useMemo } from "react";

import Select from "components/Select";
import { Home } from "types/models";
import { getHomesList } from "api/homes";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import useAsyncState from "hooks/use-async-state";
import Spinner from "components/Spinner";

import { isEmpty } from "utils/array";
import styles from "./styles.module.scss";

type Props = {
  onChange(newHome: string | undefined): void;
  value: Home | undefined;
  values: { market: number | undefined };
  disabled?: boolean;
};

const HomeField: FC<Props> = ({ onChange, value, values, disabled }) => {
  const notificationDispatcher = useNotificationDispatcher();
  const [homeSearch, setHomeSearch] = useState("");
  const { market } = values;

  const handleChange = (homeID: string) => {
    setHomeSearch("");

    if (homeID) {
      onChange(homeID);
    } else {
      onChange(undefined);
    }
  };

  const getHomes = (search: string, currentMarket: number) =>
    getHomesList({
      ...(search ? { search } : {}),
      ...(currentMarket ? { market: String(currentMarket) } : {}),
      archived: "false",
    });

  const {
    data: homes,
    refresh: refreshHomes,
    inProgress: fetchingHomes,
  } = useAsyncState<Home[]>([], getHomes, {
    debounceTime: 500,
    failureCallback: () =>
      notificationDispatcher({
        type: "error",
        description: "Failed to load homes",
      }),
  });

  const options = useMemo(
    () =>
      [...homes].map(({ uid, title }) => ({
        id: uid,
        label: title,
        value: uid,
      })),
    [homes]
  );

  useEffect(() => {
    if (value?.uid) {
      const isOptionValid =
        !isEmpty(options) &&
        !!options.find(({ value: optionValue }) => value?.uid === optionValue);
      if (!isOptionValid) onChange(undefined);
    }
  }, [options]);

  useEffect(() => {
    refreshHomes(homeSearch, market);
  }, [homeSearch, market]);

  return (
    <Select
      onDropdownVisibleChange={() => setHomeSearch("")}
      disabled={disabled}
      loading={fetchingHomes}
      options={fetchingHomes ? [] : options}
      showSearch
      allowClear
      placeholder="Search or select Home"
      value={value}
      notFoundContent={
        fetchingHomes ? (
          <Spinner className={styles.spinner} />
        ) : (
          "No homes found"
        )
      }
      onSearch={setHomeSearch}
      onChange={handleChange}
      onClear={() => {
        setHomeSearch("");
        onChange(undefined);
      }}
    />
  );
};

HomeField.defaultProps = {
  disabled: false,
};

export default HomeField;
