import { useRecoilState } from "recoil";
import { hiddenState } from "../atoms";

const useToggleSidebar = () => {
  const [hidden, setHidden] = useRecoilState(hiddenState);

  return () => setHidden(!hidden);
};

export default useToggleSidebar;
