import { AxiosError } from "axios";

import {
  Home,
  Room,
  ApplianceModel,
  Appliance,
  VendorOnHome,
  HomeClient,
  HomeNote,
  HomeNoteClient,
  HomePlan,
} from "types/models";

import { apiClient, ServerError } from ".";

const getHomeNotes = async (uid: string) => {
  try {
    const response = await apiClient.get<HomeNote[]>(
      `/notes/homes/?home=${uid}&customer_facing=false`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const addHomeNote = async (home: string, note: HomeNoteClient) => {
  try {
    const response = await apiClient.post<HomeNote>("/notes/homes/", {
      ...note,
      home,
      customer_facing: false,
    });
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateHomeNote = async (
  home_note_id: string,
  home: string,
  note: HomeNoteClient
) => {
  try {
    const response = await apiClient.patch<HomeNote>(
      `/notes/homes/${home_note_id}/`,
      {
        ...note,
        home,
        customer_facing: false,
      }
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const archiveHomeNote = async (home_note_id: string, home: string) => {
  try {
    const response = await apiClient.post<HomeNote[]>(
      `/notes/homes/${home_note_id}/archive/`,
      { home }
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateHome = async (fieldsToUpdate: HomeClient) => {
  try {
    const response = await apiClient.put<Home>(
      `/homes/${fieldsToUpdate.uid}/`,
      fieldsToUpdate
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const addHomeRelationship = async (
  homeID: string,
  payload: { user_uid: string; role: string }[]
) => {
  try {
    const response = await apiClient.post<Home>(
      `/homes/${homeID}/create_relationships/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const removeHomeRelationship = async (
  homeID: string,
  payload: { user_uid: string; role: string }[]
) => {
  try {
    const response = await apiClient.delete<Home>(
      `/homes/${homeID}/delete_relationships/`,
      { data: payload }
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const getHomesList = async (queryFilters: { [x: string]: string } = {}) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const response = await apiClient.get<Home[]>(
      `/homes/lean_list/?${queryParams}`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const getHomePlan = async ({ home }: { home: string }) => {
  try {
    const response = await apiClient.get<HomePlan>(
      `/homes/${home}/plan_blueprint/`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const createForHomeOwner = async (homeInfo: {
  address: string;
  city: string;
  country: string;
  state: string;
  zip: string;
}) => {
  try {
    const response = await apiClient.post<Home>(
      `/homes/create_for_home_owner/`,
      homeInfo
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchHomeDetail = async (
  homeID: string,
  queryFilters: { [x: string]: string } = {}
) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const response = await apiClient.get<Home>(
      `/homes/${homeID}/?${queryParams}`
    );

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchRooms = async (queryFilters: { [x: string]: string } = {}) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const response = await apiClient.get<Room[]>(`/rooms/?${queryParams}`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const createRoom = async (
  homeID: string,
  newRoomName: string,
  floorNum: number
) => {
  try {
    const payload = {
      home: homeID,
      name: newRoomName,
      floor: floorNum,
    };
    const response = await apiClient.post<Room>("/rooms/", payload);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const createAppliance = async (appliance: Appliance) => {
  try {
    const response = await apiClient.post<Appliance>("/appliances/", appliance);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateAppliance = async (
  applianceID: string,
  appliance: Appliance,
  queryFilters: { [x: string]: string } = {}
) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const response = await apiClient.patch<Appliance>(
      `/appliances/${applianceID}/?${queryParams}`,
      appliance
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const deleteAppliance = async (applianceID: string) => {
  try {
    const response = await apiClient.delete<Appliance>(
      `/appliances/${applianceID}/`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchAppliance = async (
  applianceID: string,
  queryFilters: { [x: string]: string } = {}
) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const response = await apiClient.get<Appliance>(
      `/appliances/${applianceID}/?${queryParams}`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchApplianceModels = async () => {
  try {
    const response = await apiClient.get<ApplianceModel[]>(
      "/appliance_models/"
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const createModelAppliance = async (newApplianceModel: ApplianceModel) => {
  try {
    const response = await apiClient.post<ApplianceModel>(
      "/appliance_models/",
      newApplianceModel
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const archiveHome = async (homeID: string, archivedReason: string) => {
  try {
    const response = await apiClient.post<Home>(`/homes/${homeID}/archive/`, {
      archived_reason: archivedReason,
    });
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const restoreHome = async (homeID: string) => {
  try {
    const response = await apiClient.post<Home>(`/homes/${homeID}/restore/`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchVendorsOnHome = async (homeID: string) => {
  try {
    const response = await apiClient.get<VendorOnHome[]>(
      `/homes/${homeID}/vendors/`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export {
  createRoom,
  createAppliance,
  deleteAppliance,
  getHomesList,
  fetchHomeDetail,
  fetchRooms,
  fetchAppliance,
  fetchApplianceModels,
  createModelAppliance,
  updateAppliance,
  archiveHome,
  restoreHome,
  fetchVendorsOnHome,
  createForHomeOwner,
  updateHome,
  getHomeNotes,
  addHomeNote,
  updateHomeNote,
  archiveHomeNote,
  getHomePlan,
  addHomeRelationship,
  removeHomeRelationship,
};
