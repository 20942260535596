import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import Button from "components/Button";

import Form from "components/Form";
import { updateProject } from "api/projects";
import { Project } from "types/models";
import { PAUSE_TIME } from "constants/site";
import Modal from "components/Modal";
import { required } from "utils/form";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import useAsync from "hooks/use-async";
import useProjects from "hooks/use-projects";

import styles from "./styles.module.scss";

type PauseProjectProps = {
  handleClose: () => void;
  visible: boolean;
};

const PauseProject = ({ handleClose, visible }: PauseProjectProps) => {
  const history = useHistory();
  const loc = useLocation();
  const { projectID } = useParams<{ [key: string]: string }>();
  const { updateProjects, selectedProject, updateProjectDetails } =
    useProjects();
  const notificationDispatcher = useNotificationDispatcher();

  const [submit, submitting] = useAsync(async ({ date }: { date: string }) => {
    const formattedDate = moment(
      `${moment(date).format("MM/DD/YY")} ${PAUSE_TIME}`,
      "MM/DD/YY h:mm a"
    ).toDate();

    await updateProject(projectID, { paused_until: formattedDate })
      .then((newProject: Project) => {
        updateProjects();
        updateProjectDetails(newProject.uid);
        notificationDispatcher({
          type: "success",
          description: "Paused project",
        });
        history.push(`/admin/projects/${projectID}${loc.search}`);
        handleClose();
      })
      .catch(() =>
        notificationDispatcher({
          type: "error",
          description: "Project could not be paused",
        })
      );
  });

  return (
    <Modal
      visible={visible}
      title={`Pause Project "${selectedProject?.name}"`}
      handleClose={handleClose}
    >
      <Form
        className={styles.form}
        onSubmit={submit}
        name="pause-project"
        fields={[
          {
            id: "date",
            type: "date",
            title: "Pause until",
            validate: [required("This field is required")],
          },
        ]}
      >
        {({ handleSubmit, invalid }) => (
          <Button
            className={styles.submit}
            onClick={handleSubmit}
            loading={submitting}
            disabled={invalid}
          >
            Pause project
          </Button>
        )}
      </Form>
    </Modal>
  );
};

export default PauseProject;
