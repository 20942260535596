/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, FC } from "react";
import { Button, Checkbox, Form, Input, message, Select, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Heading, Flex, Text } from "rebass";
import { useRecoilValue } from "recoil";
import { AiOutlinePlus as PlusIcon } from "react-icons/ai";

import { addAttachmentsToJob, createAttachment } from "api/attachments";
import { createJob } from "api/jobs";
import { fetchVendors } from "api/vendors";
import SubSection from "components/SubSection";
import { jobSelectedState } from "recoil/atoms";
import { JobClient } from "types/client";
import { Job, Vendor } from "types/models";
import { handleDownload } from "utils/attachments";
import AddVendor from "components/AddVendor";
import { CreateNewSelect } from "elements/dropdowns";
import Loading from "components/Loading";
import Modal from "components/Modal";
import useProjects from "hooks/use-projects";

import Panel from "components/Panel";
import styles from "./styles.module.scss";

interface Props {
  visible: boolean;
  handleClose(): void;
}

const AddJob: FC<Props> = ({ visible, handleClose }) => {
  const history = useHistory();
  const loc = useLocation();
  const { updateProjectJobs, selectedProject } = useProjects();
  const { projectID } = useParams<{ [key: string]: string }>();
  const [form] = Form.useForm();
  const job = useRecoilValue(jobSelectedState);

  const [newJob, setNewJob] = useState<JobClient | { [x: string]: any }>({});
  const [loading, setLoading] = useState(false);

  // Vendor search
  const [loadingVendorsList, setLoadingVendorsList] = useState(false);

  // Add Vendor
  const [addingVendor, setAddingVendor] = useState(false);
  const [vendorDropdown, setVendorDropdown] = useState<Vendor[]>([]);

  // Images
  const [projectFileList, setProjectFileList] = useState<UploadFile[]>([]);
  const [showProjectFiles, setShowProjectFiles] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");

  useEffect(() => {
    const projectImages = selectedProject?.attachments?.map(
      (image) =>
        ({
          uid: image.uid,
          name: image.name,
          url: image.file,
          status: "done",
        } as UploadFile)
    );

    if (projectImages) setProjectFileList(projectImages);
    form.resetFields();
    setShowProjectFiles(false);
  }, [job, selectedProject]);

  const handleInputChange = (event: {
    target: { value: any; name: string };
  }) => {
    const updatedJob: { [key: string]: any } = { ...newJob };
    const newValue = event.target.value;

    if (newValue) {
      updatedJob[event.target.name] = newValue;
    } else {
      delete updatedJob[event.target.name];
    }

    setNewJob(updatedJob as Job);
  };

  const handlePreviewImage = async (file: UploadFile) => {
    setPreviewImage(file.url || "");
    setPreviewVisible(true);
    setPreviewTitle(file.name || "");
  };

  const handleCompleteJob = ({ onSuccess, onError, file }: any) => {
    if (!job?.uid) {
      message.error("Job must be selected");
      return;
    }
    if (!selectedProject) {
      message.error("Job must be attached to home");
      return;
    }

    createAttachment(file, selectedProject.home, job.uid)
      .then((resp) => {
        onSuccess({ url: resp.file }, file);
        const updatedJob = newJob;
        updatedJob.completion_verification = resp.uid;
        setNewJob(updatedJob);
      })
      .catch((err) => {
        onError(err);
        message.error("Couldn't complete job -- try selecting button");
      });
  };

  const handleSubmit = () => {
    if (!newJob) return;
    setLoading(true);
    const updatedJob: Job = newJob as Job;
    if (selectedProject?.uid) updatedJob.project = selectedProject.uid;
    setNewJob(updatedJob);

    createJob(newJob as Job)
      .then(async (jobResponse) => {
        if (showProjectFiles) await addProjectAttachments(jobResponse.uid);
        updateProjectJobs();
        message.success("Created Job");
        form.resetFields();
        setLoading(false);
        handleClose();
        history.push(
          `/admin/projects/${projectID}/jobs/${jobResponse.uid}/${loc.search}`
        );
      })
      .catch(() => {
        message.error("Could not create Job");
        handleClose();
      });
  };

  const addProjectAttachments = async (jobID: string) => {
    const projectFileIDs = projectFileList.map(
      (projectFile) => projectFile.uid
    );
    addAttachmentsToJob(jobID, projectFileIDs).catch(() => {
      message.error("Could not add image");
    });
  };

  const handleOpenModal = () => {
    setAddingVendor(true);
  };

  const handleCloseModal = () => {
    setAddingVendor(false);
  };

  const handleSearchVendors = (searchValue: string) => {
    const newInput: Vendor = {
      uid: "-1",
      vendor_name: searchValue,
    };
    if (searchValue === "") {
      setVendorDropdown([newInput]);
      return;
    }
    setLoadingVendorsList(true);
    fetchVendors({
      search: searchValue,
    })
      .then((vendors) => {
        setVendorDropdown([newInput, ...vendors]);
      })
      .catch(() => message.error("Could not load vendors"))
      .finally(() => setLoadingVendorsList(false));
  };

  return (
    <Panel
      containerClassName={styles.panel}
      size={400}
      position="right"
      visible={visible}
      onClose={handleClose}
    >
      <Heading>Create Job</Heading>
      <Form form={form}>
        <SubSection
          title="Job name"
          flexDirection="column"
          mt={4}
          marginBottom={2}
        >
          <Form.Item
            name="name"
            style={{ marginBottom: 0 }}
            rules={[{ required: true, message: "Please title this job" }]}
          >
            <Input
              type="text"
              name="name"
              placeholder="Title this job"
              style={{
                border: "1px solid rgba(108, 114, 128, 0.2)",
                borderRadius: "6px",
              }}
              onChange={handleInputChange}
            />
          </Form.Item>
        </SubSection>

        <SubSection
          title="Description"
          flexDirection="column"
          mt={4}
          marginBottom={2}
        >
          <Form.Item
            name="details"
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: "Please describe what needs to be done",
              },
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 3 }}
              name="details"
              placeholder="Explain what needs to be done"
              style={{
                border: "1px solid rgba(108, 114, 128, 0.2)",
                borderRadius: "6px",
              }}
              onChange={handleInputChange}
            />
          </Form.Item>
        </SubSection>

        <SubSection title="Project" flexDirection="column" mt={4}>
          {selectedProject?.name}
        </SubSection>

        <SubSection
          title="Service Provider"
          flexDirection="column"
          mt={4}
          marginBottom={2}
        >
          <Form.Item name="selected_vendor" style={{ margin: 0 }}>
            <CreateNewSelect
              showSearch
              className="styled-dropdown"
              placeholder="Type in vendor name..."
              optionFilterProp="label"
              filterOption
              onSelect={(value: any) => {
                if (value && parseInt(value, 10) < 0) {
                  handleOpenModal();
                  return;
                }
                handleInputChange({
                  target: { name: "selected_vendor", value },
                });
              }}
              onSearch={(value) => {
                handleSearchVendors(value);
              }}
              notFoundContent={
                loadingVendorsList ? <Loading height="100px" /> : "No data"
              }
            >
              {!loadingVendorsList &&
                vendorDropdown &&
                vendorDropdown
                  .slice()
                  .sort((a, b) =>
                    a.uid === "-1"
                      ? -1
                      : b.uid === "-1"
                      ? 1
                      : 0 ||
                        (a.vendor_name || "").localeCompare(b.vendor_name || "")
                  )
                  .filter(
                    (vendor) =>
                      (!!vendor.city && !!vendor.zip_code) ||
                      vendor.uid === "-1"
                  )
                  .map((vendor) => {
                    return (
                      <Select.Option
                        key={vendor.uid}
                        value={vendor.uid}
                        label={vendor.vendor_name}
                      >
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text
                            data-testid={
                              Number(vendor.uid) < 0
                                ? "newVendor"
                                : "existingVendor"
                            }
                          >
                            {vendor.vendor_name}
                          </Text>
                          {Number(vendor.uid) < 0 && (
                            <Text
                              id="create-new"
                              padding={2}
                              style={{ border: "2px solid #354760" }}
                            >
                              Create New
                            </Text>
                          )}
                        </Flex>
                      </Select.Option>
                    );
                  })}
            </CreateNewSelect>
          </Form.Item>
        </SubSection>

        {showProjectFiles && (
          <SubSection title="Attachments" flexDirection="column">
            <Upload
              listType="picture-card"
              fileList={projectFileList}
              showUploadList={{ showDownloadIcon: true, showRemoveIcon: false }}
              onPreview={handlePreviewImage}
              onDownload={handleDownload}
            />
          </SubSection>
        )}

        {/* add attachments button */}
        {!!projectFileList.length && (
          <Form.Item name="addProjectImages" style={{ marginBottom: 0 }}>
            <Checkbox
              name="addProjectImages"
              id="add-project-images"
              onChange={(event) => setShowProjectFiles(event.target.checked)}
              style={{ margin: "1rem 0 0" }}
            >
              Include project attachments
            </Checkbox>
          </Form.Item>
        )}

        {selectedProject && (
          <Checkbox
            name="copyProjectDetailsToJob"
            onChange={(event) => {
              if (event.target.checked) {
                form.setFieldsValue({
                  name: selectedProject.name,
                  details: selectedProject.details,
                });
                handleInputChange({
                  target: { name: "details", value: selectedProject.details },
                });
                handleInputChange({
                  target: { name: "name", value: selectedProject.name },
                });
              } else {
                form.setFieldsValue({ name: "", details: "" });
                handleInputChange({
                  target: { name: "details", value: "" },
                });
                handleInputChange({
                  target: { name: "name", value: "" },
                });
              }
            }}
            style={{ margin: "1rem 0 0" }}
          >
            Use Project Details
          </Checkbox>
        )}

        {/* TODO: Restrict to only one upload, show document if associated */}
        {false && (
          <SubSection
            title="Completion Document"
            flexDirection="column"
            headerStyles={{ fontWeight: 400 }}
          >
            <Form.Item name="completionDoc" style={{ marginBottom: 0 }}>
              <Upload
                listType="picture-card"
                showUploadList={{
                  showDownloadIcon: true,
                }}
                onPreview={handlePreviewImage}
                onDownload={handleDownload}
                customRequest={handleCompleteJob}
              >
                <PlusIcon className={styles.plusIcon} />
              </Upload>
            </Form.Item>
          </SubSection>
        )}
      </Form>
      <Button
        type="primary"
        id="create-job-button"
        style={{
          textTransform: "uppercase",
          fontWeight: 500,
          width: "100%",
          marginTop: "1rem",
        }}
        loading={loading}
        onClick={() => {
          form
            .validateFields()
            .then((_values) => {
              handleSubmit();
            })
            .catch(() => {
              message.error("Please fill all required fields");
            });
        }}
      >
        Create Job
      </Button>
      <Modal
        contentClassName={styles.previewModal}
        visible={previewVisible}
        title={previewTitle}
        handleClose={() => setPreviewVisible(false)}
      >
        <img alt={previewTitle} className={styles.preview} src={previewImage} />
      </Modal>
      <AddVendor visible={addingVendor} handleClose={handleCloseModal} />
    </Panel>
  );
};

export default AddJob;
