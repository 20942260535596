import React, { FC } from "react";
import { IoIosWarning as WarningIcon } from "react-icons/io";
import { v4 as uuid } from "uuid";

import Label from "components/Label";
import Greeting from "components/Greeting";

import styles from "./styles.module.scss";

const ErrorBar: FC<{ error?: string; className?: string; id?: string }> = ({
  error,
  className,
  id,
}) => (
  <Greeting
    duration={0.2}
    hi="expandHeight"
    goodbye="expandHeight"
    unmountOn={!error}
    className={className}
  >
    <div className={styles.container}>
      <WarningIcon className={styles.icon} />
      <Label id={`${id ?? uuid()}-ERRORS`} type="negative">
        {error}
      </Label>
    </div>
  </Greeting>
);

ErrorBar.defaultProps = {
  id: undefined,
  error: undefined,
  className: "",
};

export default ErrorBar;
