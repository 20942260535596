/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Drawer } from "antd";
import { Flex, Heading, Text } from "rebass";
import styled from "styled-components";

import Loading from "components/Loading";

export const HEADER_NAV_HEIGHT = 280;

export const calculateTableHeight = (numOfTables = 1) => {
  switch (numOfTables) {
    case 1:
      return { tableHeight: window.innerHeight - HEADER_NAV_HEIGHT };
    case 2:
      return {
        tableHeight: (window.innerHeight - HEADER_NAV_HEIGHT) * 0.75 - 60,
        restTableHeight: (window.innerHeight - HEADER_NAV_HEIGHT) * 0.25,
      };
    default:
      return { tableHeight: window.innerHeight - HEADER_NAV_HEIGHT };
  }
};

type ActionItemsProps = {
  className?: string;
  children: React.ReactNode;
  // rest of props
  [x: string]: any;
};

const ActionItems = ({ className, children, ...rest }: ActionItemsProps) => (
  <Flex
    className={className}
    justifyContent="space-between"
    width="300px"
    mb={[3, 3, 4]}
    {...rest}
  >
    {children}
  </Flex>
);

ActionItems.defaultProps = {
  className: undefined,
};

type TableDisplayProps = {
  children?: React.ReactNode;
  isLoading: boolean;
  emptyListID?: string;
  tableItems: any[] | null;
  emptyListMessage?: string;
  EmptyListElement?: any;
  [x: string]: any;
};

const TableDisplay = ({
  children,
  isLoading,
  tableItems,
  emptyListID,
  emptyListMessage = "No Items Found",
  EmptyListElement,
  ...rest
}: TableDisplayProps) => {
  if (isLoading) return <Loading height="100%" />;

  if (!tableItems || tableItems.length === 0)
    return EmptyListElement || <Text id={emptyListID}>{emptyListMessage}</Text>;

  return (
    <Flex
      className="table-wrapper"
      width="100%"
      flexDirection="column"
      {...rest}
    >
      {children}
    </Flex>
  );
};

TableDisplay.defaultProps = {
  children: null,
  emptyListID: undefined,
  emptyListMessage: "",
  EmptyListElement: "",
};

type SidePanelProps = {
  children: React.ReactNode;
  onClose: () => void;
  isVisible: boolean;
  width?: number;
  mask?: boolean;
  closable?: boolean;
  // rest of props
  [x: string]: any;
};

const StyledDrawer = styled(Drawer)`
  &.shadowed-panel .ant-drawer-content {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16),
      0 8px 8px 0 rgba(0, 0, 0, 0.16);
  }
`;

const SidePanel = ({
  children,
  onClose,
  isVisible,
  width = 400,
  mask = true,
  closable = true,
  ...rest
}: SidePanelProps) => (
  <StyledDrawer
    className={mask ? "" : "shadowed-panel"}
    width={window.innerWidth > 578 ? width : "100%"} // Full width on mobile
    style={{ top: 60, height: "calc(100% - 60px)" }} // still show header
    placement="right"
    onClose={onClose}
    visible={isVisible}
    bodyStyle={{ padding: "1rem" }}
    closeIcon={closable ? <>✕</> : null}
    mask={mask}
    closable={closable}
    {...rest}
  >
    {children}
  </StyledDrawer>
);

SidePanel.defaultProps = {
  width: 400,
  mask: true,
  closable: true,
};

type ViewPageProps = {
  children: React.ReactNode;
  [x: string]: any; // rest of props
};

// TODO: rename & write JSDoc
const ViewPage = ({ children, ...rest }: ViewPageProps) => {
  return (
    <Flex
      flexDirection="column"
      id="full-view-page"
      backgroundColor="white"
      height="100%"
      width="100%"
      css={{
        borderRadius: "4px",
        border: "1px solid rgba(108, 114, 128, 0.2)",
      }}
      {...rest}
    >
      {children}
    </Flex>
  );
};

type ListViewProps = {
  pageTitle: string;
  subtitle?: string;
  headingID?: string;
  subtitleID?: string;
  children?: React.ReactNode;
  // rest of props
  [x: string]: any;
};

const ListView = ({
  pageTitle,
  subtitle,
  headingID,
  subtitleID,
  children,
  ...rest
}: ListViewProps) => (
  <Flex
    className="list-view"
    flexDirection="column"
    alignItems="flex-start"
    width="100%"
    height="100%"
    padding={[0, 3, 4]}
    {...rest}
  >
    <Heading
      fontSize={[3, 3, 4]}
      fontWeight="body"
      color="primary"
      id={headingID}
      mb={subtitle ? 0 : [3, 3, 4]}
      sx={{
        display: "block",
        "@media screen and (max-width: 578px)": {
          display: "none",
        },
      }}
    >
      {pageTitle}
    </Heading>
    {subtitle && (
      <Text id={subtitleID} mb={[3, 3, 4]}>
        {subtitle}
      </Text>
    )}
    {children}
  </Flex>
);

ListView.defaultProps = {
  subtitle: "",
  headingID: "",
  subtitleID: "",
  children: null,
};

ListView.ActionItems = ActionItems;
ListView.TableDisplay = TableDisplay;
ListView.SidePanel = SidePanel;
ListView.ViewPage = ViewPage;
export default ListView;
