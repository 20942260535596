import React, { useContext, useState, useEffect, FC, useMemo } from "react";
import * as Sentry from "@sentry/react";
import Loading from "components/Loading";
import { useSessionValue } from "providers/SessionProvider";
import UserContext from "context/UserContext";
import { User, StripeCustomer } from "types/models";
import { fetchStripeCustomer } from "api/auth";
import { getCurrentUser } from "api/users";
import { identifyLogin } from "analytics/segment";
import { useLDClient } from "launchdarkly-react-client-sdk";

export const UserProvider: FC = ({ children }) => {
  const [user, setStateUser] = useState<User | null>(null);
  const [customer, setCustomer] = useState<StripeCustomer | null>(null);
  const { sessionData, setSessionData } = useSessionValue();
  const missingUser = !!sessionData?.refresh_token && !user;
  const [loading, setLoading] = useState<boolean>(missingUser);

  const LDClient = useLDClient();

  useEffect(() => {
    if (user && LDClient) {
      const { uid, name, email } = user;
      LDClient.identify({ key: uid, name, email });
    }
  }, [user]);

  const clearUser = () => {
    setSessionData(null);
    setStateUser(null);
    setCustomer(null);
  };

  const setUser = (newUser: User | null) => {
    setStateUser(newUser);
    if (newUser) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          email: newUser.email,
          id: newUser.uid,
        });
        scope.setTags({
          role: newUser.role,
        });
      });
      identifyLogin(newUser.uid, newUser.email, newUser.name);
    }
  };

  if (missingUser) {
    getCurrentUser({ expand: "totalActions,totalRequests" })
      .then((newUser) => {
        setUser(newUser);
      })
      .then(async () => {
        const newCustomer = await fetchStripeCustomer();
        setCustomer(newCustomer);
      })
      .catch(() => {
        clearUser();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const value = useMemo(
    () => ({ customer, setCustomer, user, setUser }),
    [customer, setCustomer, user, setUser]
  );

  return (
    <UserContext.Provider value={value}>
      {loading && <Loading />}
      {!loading && children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
