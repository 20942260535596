import { Attachment } from "components/HomeAttachmentsField";
import React, { FC } from "react";

import styles from "./styles.module.scss";

interface Props {
  attachment: Attachment;
}

const PDFPreview: FC<Props> = ({ attachment }) => (
  <iframe
    title={attachment.name}
    className={styles.preview}
    src={attachment.file}
  />
);

export default PDFPreview;
