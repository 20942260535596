import React, { FC } from "react";

import { scheduleAppointment } from "api/admin";
import Button from "components/Button";
import { SharedFlowState } from "components/Flow/types";
import Form from "components/Form";
import Label from "components/Label";
import useAsync from "hooks/use-async";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import moment from "moment";
import { useUser } from "providers/UserProvider";
import { useRecoilValue } from "recoil";
import { getFromPlace } from "utils/maps";
import { useHistory } from "react-router-dom";
import { createForHomeOwner } from "api/homes";

import styles from "./styles.module.scss";

type Props = {
  sharedFlowState: SharedFlowState;
};

const ScheduleAppointment: FC<Props> = ({ sharedFlowState }) => {
  const { user } = useUser();
  const history = useHistory();
  const flowState = useRecoilValue(sharedFlowState);
  const notificationDispatcher = useNotificationDispatcher();

  const [submit, submitting] = useAsync(
    async (values: { appointment: string }) => {
      const { home } = flowState;
      const { email = "", name = "" } = user || {};

      const country = getFromPlace("country", home);
      const state = getFromPlace("administrative_area_level_1", home);
      const zip = getFromPlace("postal_code", home);
      const city =
        getFromPlace("administrative_area_level_3", home) ||
        getFromPlace("locality", home);

      const streetNumber = getFromPlace("street_number", home);
      const route = getFromPlace("route", home);
      const address = `${streetNumber} ${route}`;

      await scheduleAppointment({
        country,
        state,
        zip,
        city,
        email,
        first_name: name,
        address,
        appointment: moment(values.appointment).format(
          "MMMM dd, yyyy 'at' h:mm a"
        ),
      })
        .then(() =>
          createForHomeOwner({
            zip,
            city,
            state: getFromPlace(
              "administrative_area_level_1",
              home,
              "short_name"
            ),
            country: getFromPlace("country", home, "short_name"),
            address,
          })
        )
        .then(() =>
          notificationDispatcher({
            type: "success",
            description: "Appointment created succesfully",
          })
        )
        .then(() => {
          history.push("/portal/onboarding/await_contact");
        })
        .catch(() =>
          notificationDispatcher({
            type: "error",
            description: "Couldn't create appointment, try again later",
          })
        );
    }
  );

  return (
    <Form
      renderExternally
      className={styles.container}
      name="schedule-appointment"
      fields={[
        {
          placeholder: "Appointment",
          type: "date",
          id: "appointment",
          props: { format: "MM/DD/YYYY HH", showTime: true },
        },
      ]}
      onSubmit={submit}
    >
      {({ invalid, handleSubmit, formToRender }) => (
        <>
          <Label type="centered-h5" color="primary">
            Schedule an appointment with us
          </Label>
          <Label className={styles.subtitle} type="centered" color="primary">
            We will get everything set for you, so that you can start using all
            services
          </Label>
          {formToRender}
          <Button
            className={styles.submit}
            onClick={handleSubmit}
            disabled={invalid}
            loading={submitting}
          >
            Schedule
          </Button>
        </>
      )}
    </Form>
  );
};

export default ScheduleAppointment;
