/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { FaHome as HomeIcon } from "react-icons/fa";

import { Home } from "types/models";

import Label from "components/Label";

import styles from "./styles.module.scss";

type Props = {
  home: Home;
};

const HomeCard: FC<Props> = ({ home }) => {
  const history = useHistory();

  const handleViewHome = ({ uid }: { uid: string }) => {
    history.push(`/admin/homes/${uid}`);
  };

  const { home_address: homeAddress, img, title, address } = home;

  const [street, city, locality] =
    (homeAddress?.raw || address || "").split(", ") || [];

  return (
    <div
      className={styles.container}
      role="button"
      onClick={() => handleViewHome(home)}
    >
      <div className={styles.information}>
        <Label type="s1-italic-bold">{title}</Label>
        <Label type="s2-italit">{street}</Label>
        <Label type="s2-italit">{`${city}, ${locality}`}</Label>
      </div>
      <div className={styles.houseContainer}>
        {img ? (
          <img className={styles.image} src={img} alt="" />
        ) : (
          <HomeIcon className={styles.icon} />
        )}
      </div>
    </div>
  );
};

export default HomeCard;
