import React, { useEffect, useState, FC } from "react";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";

import Button from "components/Button";
import { sendInvoiceReminder } from "api/admin";
import { JOB_ACTIONS } from "constants/site";
import { Invoice, InvoiceStatus, Bill } from "types/models";
import { parameterize } from "utils/strings";
import Label from "components/Label";
import Collapsable from "components/Collapsable";
import Section from "components/Section";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import { AxiosError } from "axios";
import { getError } from "utils/errors";
import InvoiceSummary from "components/InvoiceSummary";
import BillDetail from "./components/BillDetail";

import styles from "./styles.module.scss";
import InvoiceDate from "./components/InvoiceDate";
import EditInvoice from "./components/EditInvoice";

type Props = {
  invoice: Invoice;
  bills: Bill[];
  jobID: string;
};

const InvoiceDetail: FC<Props> = ({ invoice, bills, jobID }) => {
  const notificationDispatcher = useNotificationDispatcher();
  const history = useHistory();
  const { projectID } = useParams<{ [key: string]: string }>();

  const [billOnInvoice, setBillOnInvoice] = useState<Bill>();
  const [editingInvoice, setEditingInvoice] = useState(false);

  const invoiceCountdown = moment(invoice.due_date).diff(
    moment(),
    "days",
    true
  );

  const isDraft = invoice.status === InvoiceStatus.Draft;
  const isPaid = invoice.status === InvoiceStatus.Paid;

  useEffect(() => {
    if (!bills) return;

    const invoiceBill = bills.find(
      ({ invoice: invoiceId }) => invoiceId === invoice.id
    );

    setBillOnInvoice(invoiceBill);
  }, [bills, invoice.id]);

  const handleInvoiceReminder = () => {
    sendInvoiceReminder(invoice.id)
      .then(() =>
        notificationDispatcher({
          type: "success",
          description: "Success!",
          message: "Invoice reminder email sent",
        })
      )
      .catch((error: AxiosError) => {
        notificationDispatcher({
          type: "error",
          description: getError(error.response?.data, "Something went wrong!"),
        });
      });
  };

  return (
    <>
      <EditInvoice
        visible={editingInvoice}
        handleClose={() => setEditingInvoice(false)}
        invoice={invoice}
      />
      <div className={styles.container}>
        <div className={styles.header}>
          <Section
            variant="dashed"
            className={styles.headerSection}
            title="Service Provider"
          >
            <Label>{invoice.vendor}</Label>
          </Section>
          <Section
            variant="dashed"
            className={styles.headerSection}
            title="Payment Method"
          >
            <Label>{invoice.vendor_pay_method ?? "-"}</Label>
          </Section>
        </div>
        <Section variant="dashed" title="Due Date">
          <InvoiceDate
            dueDate={invoice.due_date}
            invoiceCountdown={invoiceCountdown}
          />
        </Section>
        <Section variant="dashed" title="Memo">
          <Label>{invoice.description || "N/A"}</Label>
        </Section>
        {!isPaid && invoice.hosted_invoice_url && (
          <Section variant="dashed" title="Stripe Hosted Invoice Link">
            <a
              href={invoice.hosted_invoice_url}
              target="_blank"
              rel="noreferrer"
            >
              {invoice.hosted_invoice_url}
            </a>
          </Section>
        )}
        {billOnInvoice && !isDraft && (
          <Collapsable
            className={styles.billDetail}
            header={<Label type="bold-italic">Bill to vendor</Label>}
          >
            <BillDetail bill={billOnInvoice} amountPaid={invoice.amount_paid} />
          </Collapsable>
        )}
        <Section variant="dashed" title="Invoice Breakdown">
          <InvoiceSummary className={styles.summary} invoice={invoice} />
        </Section>
        <div className={styles.actionsContainer}>
          <Button variant="secondary" onClick={handleInvoiceReminder}>
            Send Reminder
          </Button>
          {isDraft && (
            <Button
              onClick={() => {
                history.push(
                  `/admin/projects/${projectID}/jobs/${jobID}/${parameterize(
                    JOB_ACTIONS.FINALIZE_INVOICE
                  )}/${invoice.id}`
                );
              }}
            >
              Send Invoice
            </Button>
          )}
          {isDraft && (
            <Button variant="secondary" onClick={() => setEditingInvoice(true)}>
              Edit Invoice
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default InvoiceDetail;
