import { message } from "antd";

import { sendInvoiceReminder } from "api/admin";
import { JOB_ACTIONS } from "constants/site";
import { Action, ActionType } from "types/models";
import { parameterize } from "utils/strings";

export const ACTION_ISSUE_URLS = {
  new_request: {
    actionableURL: (action: Action) => {
      if (!action.project_snippet) {
        message.error("Project not correctly linked");
        return;
      }
      window.open(`/admin/projects/${action.project_snippet.uid}`);
    },
    text: "Review Request",
  },
  unpaid_invoice: {
    actionableURL: (action: Action) => {
      sendInvoiceReminder(action.action_type_id)
        .then(() => {
          message.success("Invoice reminder email sent");
        })
        .catch(() => message.error("Could not send invoice reminder"));
    },
    text: "Remind Homeowner",
  },
  service_estimate_approval: {
    actionableURL: (action: Action) => {
      if (!action.project_snippet || !action.job) {
        message.error("Project or job not correctly linked");
        return;
      }
      window.open(
        `/admin/projects/${action.project_snippet.uid}/jobs/${action.job}/estimates/`
      );
    },
    text: "Go to Job",
  },
  no_appointment: {
    actionableURL: (action: Action) => {
      if (!action.project_snippet) {
        message.error("Project not correctly linked");
        return;
      }
      window.open(
        `/admin/projects/${action.project_snippet.uid}/jobs/${
          action.job
        }/${parameterize(JOB_ACTIONS.SCHEDULE_APPOINTMENT)}`
      );
    },
    text: "Schedule Appointment",
  },
  unpaused_project: {
    actionableURL: (action: Action) => {
      if (!action.project_snippet) {
        message.error("Project not correctly linked");
        return;
      }
      window.open(`/admin/projects/${action.project_snippet.uid}/`);
    },
    text: "Go to Project",
  },
  approved_service_estimate: {
    actionableURL: (action: Action) => {
      if (!action.project_snippet || !action.job) {
        message.error("Project or job not correctly linked");
        return;
      }
      window.open(
        `/admin/projects/${action.project_snippet.uid}/jobs/${action.job}/`
      );
    },
    text: "Go to Job",
  },
} as { [x: string]: ActionType };

export const ACTION_MODAL_TYPES = {
  DELETE: "delete",
  PAUSE: "pause",
};
