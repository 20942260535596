import React, { FC } from "react";
import { Dialog as RootDialog } from "@mui/material";

import ModalHeader from "./components/ModalHeader";
import styles from "./styles.module.scss";

type Props = {
  handleClose: () => void;
  title?: string;
  visible: boolean;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  onModalClosed?(): void;
};

const Modal: FC<Props> = ({
  handleClose,
  title,
  visible,
  children,
  className,
  contentClassName,
  onModalClosed,
}) => (
  <RootDialog
    TransitionProps={{ unmountOnExit: true, onExited: onModalClosed }}
    classes={{ paper: `${className} ${styles.container}` }}
    onClose={handleClose}
    open={visible}
    BackdropProps={{
      classes: {
        root: styles.root,
      },
    }}
  >
    <ModalHeader title={title} onClose={handleClose} />
    <div className={`${contentClassName} ${styles.content}`}>{children}</div>
  </RootDialog>
);

Modal.defaultProps = {
  title: "",
  className: undefined,
  contentClassName: undefined,
  onModalClosed: () => {},
};

export default Modal;
