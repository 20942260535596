/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useLayoutEffect, useMemo } from "react";
import { Pagination } from "@mui/material";
import { v4 as uuid } from "uuid";
import { AiOutlineClear as ClearIcon } from "react-icons/ai";
import { MdRefresh as ResetIcon } from "react-icons/md";
import { atom, useRecoilState } from "recoil";
import { useHistory, useLocation } from "react-router-dom";

import { objectIsEmpty, purgeObject } from "utils/object";
import { isEmpty } from "utils/array";
import Button from "components/Button";

import Checkbox from "components/Checkbox";
import TextInput from "components/TextInput";
import styles from "./styles.module.scss";
import { ColumnDefinition, MinimumRow } from "./types";
import FixedTable from "./components/FixedTable";
import NoData from "./components/NoData";
import Loading from "./components/Loading";
import { CheckBoxCol, filterKeyMapper } from "./constants";

export type PaginationConfig = {
  count: number;
  page: number;
  pageSize: number;
};

type CommonProps<T> = {
  data: T[];
  columns: ColumnDefinition<T>[];
  pagination?: PaginationConfig;
  loading?: boolean;
  externalFilters?: { [key: string]: string | undefined };
  headerActions?: JSX.Element;
  externalFilterValues?: { [key: string]: string | undefined };
  reloadData?(): void;
  withSearch?: boolean;
  className?: string;
};

type SelectableProps<T> =
  | {
      selectable?: false;
      selectedRows?: never;
      setSelectedRows?: never;
    }
  | {
      selectable: true;
      selectedRows: T[];
      setSelectedRows(selectedRows: T[]): void;
    };

type OnClickProps<T> =
  | {
      onRowClick(rowData: { original: T }): void;
      rowClickDescription: NonNullable<ReactNode>;
    }
  | {
      onRowClick?: undefined;
      rowClickDescription?: never;
    };

type WithSearchProps =
  | {
      withSearch: true;
      searchPrompt: string;
    }
  | {
      withSearch?: false;
      searchPrompt?: never;
    };

const Table = <T extends MinimumRow>({
  className,
  data,
  columns,
  pagination,
  loading = false,
  onRowClick,
  externalFilters = {},
  headerActions,
  externalFilterValues = {},
  reloadData,
  selectable,
  setSelectedRows,
  selectedRows,
  rowClickDescription,
  withSearch,
  searchPrompt,
}: CommonProps<T> & SelectableProps<T> & OnClickProps<T> & WithSearchProps) => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const filtersState = useMemo(() => {
    return atom<{ [key: string]: string | undefined }>({
      default: {},
      key: `table-filter-state-${uuid()}`,
    });
  }, []);

  const [filters, setFilters] = useRecoilState(filtersState);

  useLayoutEffect(() => () => setFilters({}), []);

  const leftColumns = [
    ...(selectable ? [CheckBoxCol(setSelectedRows, selectedRows)] : []),
    ...columns.filter(({ fixed }) => fixed === "left"),
  ];
  const rightColumns = columns.filter(({ fixed }) => fixed === "right");
  const scrollableColumns = columns.filter(({ fixed }) => !fixed);

  useLayoutEffect(() => {
    const params = new URLSearchParams(
      purgeObject({ ...filters, ...externalFilters, ...externalFilterValues })
    ).toString();

    const fixedParamsObj = new URLSearchParams(search);

    columns.forEach(({ accessor, filter }) => {
      if (filter) {
        if (Array.isArray(filter)) {
          const filterRelatedKeys: string[] = filter
            .map((fil) => filterKeyMapper(fil)(accessor))
            .flat();

          filterRelatedKeys.forEach((key) => fixedParamsObj.delete(key));
        } else {
          const filterRelatedKeys: string[] = filterKeyMapper(filter)(accessor);

          filterRelatedKeys.forEach((key) => fixedParamsObj.delete(key));
        }
      }
    });

    Object.keys(externalFilters).forEach((key) => fixedParamsObj.delete(key));

    fixedParamsObj.delete("page");
    fixedParamsObj.delete("ordering");
    fixedParamsObj.delete("search");

    const fixedParams = fixedParamsObj.toString();

    const newSearch = `?${
      fixedParams ? `${fixedParams}&` : ""
    }${decodeURIComponent(params)}`;

    history.push(`${pathname}${newSearch}`);
  }, [filters, externalFilters, externalFilterValues]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    const newFilters: { [key in keyof T]?: string | null } = {};

    columns.forEach(({ accessor, filter }) => {
      if (filter) {
        if (Array.isArray(filter)) {
          filter.forEach((f) => {
            const key: string[] = filterKeyMapper(f)(accessor);

            key.forEach((k) => {
              newFilters[k as keyof T] = searchParams.get(k);
            });
          });
        } else {
          const key: string[] = filterKeyMapper(filter)(accessor);

          key.forEach((k) => {
            newFilters[k as keyof T] = searchParams.get(k);
          });
        }
      }
    });

    setFilters(purgeObject(newFilters));
  }, []);

  useEffect(() => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      ...externalFilterValues,
    }));
  }, [externalFilterValues]);

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.header}>
        <div className={styles.tableActions}>
          {reloadData && (
            <Button
              Icon={<ResetIcon className={styles.icon} />}
              onClick={() => {
                setSelectedRows?.([]);
                reloadData();
              }}
            >
              Reload
            </Button>
          )}
          {columns.some(({ filter }) => !!filter) && (
            <Button
              disabled={objectIsEmpty(filters)}
              Icon={<ClearIcon className={styles.icon} />}
              variant="secondary-outlined"
              onClick={() => setFilters({})}
            >
              Clear filters
            </Button>
          )}
        </div>
        {withSearch && (
          <TextInput
            placeholder={searchPrompt}
            className={styles.searchBar}
            onChange={(newSearch) => {
              setFilters((oldFilters) => ({
                ...oldFilters,
                search: newSearch,
              }));
            }}
            value={filters.search}
          />
        )}
        <div className={styles.actions}>{headerActions}</div>
      </div>
      {selectable && (
        <div className={styles.selectAll}>
          <Checkbox
            className={styles.selectAllCheckbox}
            placeholder="Select All in Page"
            value={data.every(({ id }) =>
              selectedRows.map(({ id: rowId }) => rowId).includes(id)
            )}
            onChange={(checked) => {
              const pageDataId = data.map(({ id }) => id);
              const currentlySelected = [...selectedRows].filter(
                ({ id }) => !pageDataId.includes(id)
              );

              if (checked) {
                setSelectedRows([...currentlySelected, ...data]);
              } else {
                setSelectedRows(currentlySelected);
              }
            }}
          />
          {selectedRows && (
            <Button
              disabled={isEmpty(selectedRows)}
              Icon={<ClearIcon className={styles.icon} />}
              variant="secondary-outlined"
              onClick={() => setSelectedRows?.([])}
            >
              Clear Selections
            </Button>
          )}
        </div>
      )}
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          <FixedTable
            rowClickDescription={rowClickDescription}
            tableId="left"
            filtersState={filtersState}
            loading={loading}
            onRowClick={onRowClick}
            fixed="left"
            data={loading ? [] : data}
            columns={leftColumns}
          />
          <FixedTable
            rowClickDescription={rowClickDescription}
            tableId="center"
            filtersState={filtersState}
            loading={loading}
            onRowClick={onRowClick}
            data={loading ? [] : data}
            columns={scrollableColumns}
          />
          <FixedTable
            rowClickDescription={rowClickDescription}
            tableId="right"
            filtersState={filtersState}
            loading={loading}
            onRowClick={onRowClick}
            fixed="right"
            data={loading ? [] : data}
            columns={rightColumns}
          />
        </div>
        {isEmpty(data) && !loading && <NoData />}
        {loading && <Loading />}
      </div>
      {pagination && (
        <Pagination
          disabled={loading}
          shape="rounded"
          className={styles.pagination}
          count={Math.ceil(pagination.count / pagination.pageSize)}
          page={pagination.page}
          onChange={(_, newPage) =>
            setFilters((newFilters) => ({
              ...newFilters,
              page: String(newPage),
            }))
          }
        />
      )}
    </div>
  );
};

Table.defaultProps = {
  pagination: undefined,
  loading: false,
  externalFilters: {},
  headerActions: undefined,
  externalFilterValues: {},
  reloadData: undefined,
  withSearch: undefined,
  className: undefined,
};

export default Table;
