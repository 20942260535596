import { ProjectStatus } from "constants/statuses";
import { atom } from "recoil";
import { PaginationInfo } from "types/client";
import {
  Activity,
  Job,
  Note,
  Project,
  ProjectCategory,
  ServiceCategory,
} from "types/models";

export const projectsState = atom<Project[]>({
  key: "hook-projectsState",
  default: [],
});

export const projectCategoriesState = atom<ProjectCategory[]>({
  key: "hook-projectCategoriesState",
  default: [],
});

export const serviceCategoriesState = atom<ServiceCategory[]>({
  key: "hook-serviceCategoriesState",
  default: [],
});

export const projectsPaginationState = atom<PaginationInfo>({
  key: "hook-projectsPaginationState",
  default: {
    prevLink: null,
    nextLink: null,
    numOfItems: 0,
    currentPage: 1,
  },
});

export const activeStatusesState = atom<ProjectStatus[]>({
  key: "active-statuses",
  default: [],
});

export const selectedProjectState = atom<Project | null>({
  key: "hook-selectedProjectState",
  default: null,
});

export const projectJobsState = atom<Job[]>({
  key: "hook-projectJobsState",
  default: [],
});

export const projectNotesState = atom<Note[]>({
  key: "hook-projectNotesState",
  default: [],
});

export const projectActivitiesState = atom<Activity[]>({
  key: "hook-projectActivitiesState",
  default: [],
});
