import { AxiosError } from "axios";

import { Job, ServiceEstimate } from "types/models";
import { JobResponse } from "types/responses";
import { EstimateRequest } from "types/client";

import { apiClient, ServerError } from ".";

const createJob = async (newJob: Job) => {
  try {
    const payload = {
      ...newJob,
      uid: null,
    };

    const response = await apiClient.post<Job>("/jobs/", payload);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchJobs = async (queryFilters?: { [x: string]: string }) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const response = await apiClient.get<JobResponse>(`/jobs/?${queryParams}`);
    return response.data.results;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const updateJob = async (updatedJob: Job, jobID?: string) => {
  try {
    const response = await apiClient.patch<Job>(`/jobs/${jobID}/`, updatedJob);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const archiveJob = async (uid: string) => {
  try {
    const response = await apiClient.post<Job>(`/jobs/${uid}/archive/`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const completeJob = async (uid: string) => {
  try {
    const response = await apiClient.post<Job>(`/jobs/${uid}/complete/`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const reopenJob = async (uid: string) => {
  try {
    const response = await apiClient.post<Job>(`/jobs/${uid}/reopen/`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const restoreJob = async (uid: string) => {
  try {
    const response = await apiClient.post<Job>(`/jobs/${uid}/restore/`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const requestVendorEstimate = async (
  jobID: string,
  payload: EstimateRequest
) => {
  try {
    const response = await apiClient.post<ServiceEstimate>(
      `/jobs/${jobID}/request_vendor_estimate/`,
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export {
  createJob,
  fetchJobs,
  updateJob,
  archiveJob,
  completeJob,
  reopenJob,
  restoreJob,
  requestVendorEstimate,
};
