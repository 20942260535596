import React, { FC } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import Content from "components/Content";
import { ProjectStatus } from "constants/statuses";

import JobAction from "./components/JobAction";
import ProjectDetail from "./views/ProjectDetail";
import ProjectList from "./views/ProjectsList";

interface Props {
  title: string;
  statuses: ProjectStatus[];
}

const Projects: FC<Props> = ({ statuses, title }) => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <Content>
      <Switch location={location}>
        <Route
          exact
          path={match.path}
          render={() => <ProjectList title={title} statuses={statuses} />}
        />
        <Route
          path={`${match.path}/:projectID/jobs/:jobID/invoices/:invoiceID`}
          render={() => <ProjectDetail />}
        />
        <Route
          path={`${match.path}/:projectID/jobs/:jobID`}
          render={() => <ProjectDetail />}
        />
        <Route
          path={`${match.path}/:projectID/notes/:noteID`}
          render={() => <ProjectDetail />}
        />
        <Route
          path={`${match.path}/:projectID/`}
          render={() => <ProjectDetail />}
        />
      </Switch>
      <Route
        path={`${match.path}/:projectID/jobs/:jobID/:jobAction/:invoiceID?`}
      >
        <JobAction />
      </Route>
    </Content>
  );
};

export default Projects;
