import { Tag } from "antd";
import styled from "styled-components";

import theme from "theme/theme.main";

export const CustomTag = styled(Tag)`
  border-radius: 5px;
  align-items: center;
  display: flex;
  color: ${(props) =>
    props.color === theme.colors.inProgress && theme.colors.text};
  width: fit-content;
`;
