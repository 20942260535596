import React, { FC } from "react";
import Button from "components/Button";

import { Bill } from "types/models";
import { currencyFormatter } from "utils/currency";
import Section from "components/Section";
import Label from "components/Label";

import styles from "./styles.module.scss";

type BillDetailProps = {
  bill: Bill;
  amountPaid: string;
};

const InvoiceDetail: FC<BillDetailProps> = ({ bill, amountPaid }) => {
  const baseURL = process.env.REACT_APP_BILL_DOT_COM_BASE_URL;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Section variant="dashed" title="Paid amount">
          <Label>
            {bill.billdotcomdetail.paidAmount
              ? currencyFormatter.format(
                  Number(bill.billdotcomdetail.paidAmount)
                )
              : "N/A"}
          </Label>
        </Section>
        <Section variant="dashed" title="Vendor paid">
          <Label>
            {currencyFormatter.format(
              (bill.percent_to_vendor / 100) * Number(amountPaid)
            )}
          </Label>
        </Section>
      </div>
      <Button
        variant="text"
        onClick={() => window.open(`${baseURL}${bill.billdotcomdetail.id}`)}
      >
        More bill information
      </Button>
    </div>
  );
};

export default InvoiceDetail;
