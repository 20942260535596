/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";

import { RecoilState, useRecoilState } from "recoil";
import Checkbox from "components/Checkbox";

import styles from "./styles.module.scss";

type Props = {
  name: string;
  disabled: boolean;
  filtersState: RecoilState<{ [key: string]: string | boolean | undefined }>;
};

const NotAssignedFilter: FC<Props> = ({ name, disabled, filtersState }) => {
  const [filters, setTableFilters] = useRecoilState(filtersState);

  return (
    <Checkbox
      className={styles.checkbox}
      disabled={disabled}
      placeholder="N\A only"
      onChange={(value) => {
        if (value === true)
          setTableFilters((tableFilters) => ({
            ...tableFilters,
            [name]: undefined,
            [`has_${name}`]: "false",
            page: "1",
          }));
        else
          setTableFilters((tableFilters) => ({
            ...tableFilters,
            [name]: undefined,
            [`has_${name}`]: undefined,
            page: "1",
          }));
      }}
      value={filters[`has_${name}`] === "false"}
    />
  );
};

export default NotAssignedFilter;
