import React, { FC } from "react";
import { Route, useRouteMatch } from "react-router-dom";

import Content from "components/Content";
import ActionList from "./views/ActionList";

const Action: FC = () => {
  const match = useRouteMatch();

  return (
    <Content>
      <Route path={`${match.path}/:actionID?`}>
        <ActionList />
      </Route>
    </Content>
  );
};

export default Action;
