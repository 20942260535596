import Collapsable from "components/Collapsable";
import Content from "components/Content";
import Label from "components/Label";
import React, { FC } from "react";
import HomesManagement from "./components/HomesManagement";
import ServiceOfferings from "./components/ServiceOfferings";
import UserManagement from "./components/UserManagement";

import styles from "./styles.module.scss";

const Manage: FC = () => {
  return (
    <Content className={styles.container}>
      <Label type="h4" color="primary">
        Manage
      </Label>
      <div className={styles.modules}>
        {[
          { Component: ServiceOfferings, label: "Service Catalog" },
          { Component: UserManagement, label: "User Management" },
          { Component: HomesManagement, label: "Home Management" },
        ].map(({ label, Component }) => (
          <Collapsable
            unmountOnCollapse
            withIcon
            className={styles.collapsable}
            header={
              <div className={styles.header}>
                <Label type="h5" color="primary">
                  {label}
                </Label>
              </div>
            }
          >
            <Component />
          </Collapsable>
        ))}
      </div>
    </Content>
  );
};

export default Manage;
