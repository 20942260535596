/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Input } from "antd";
import { AiFillLock as Locked, AiFillUnlock as Unlocked } from "react-icons/ai";

import ErrorBar from "components/ErrorBar";

import styles from "./styles.module.scss";

const { Password } = Input;

const PasswordField: FC<{
  onChange: (newValue: string) => void;
  error?: string;
  placeholder?: string;
  normalize?: ((value: string) => string)[];
  value: string;
  disabled?: boolean;
  autoComplete?: string;
  id?: string;
}> = ({
  onChange,
  error,
  placeholder,
  normalize,
  value,
  disabled,
  autoComplete = "on",
  id,
}) => {
  return (
    <div className={styles.container}>
      <Password
        type="password"
        id={id}
        iconRender={(visible) => (visible ? <Unlocked /> : <Locked />)}
        autoComplete={autoComplete}
        disabled={disabled}
        placeholder={placeholder}
        className={styles.input}
        value={value}
        onChange={(event: any) => {
          const { value: newValue } = event.target;
          onChange(normalize?.reduce((acc, curr) => curr(acc), newValue));
        }}
      />
      <ErrorBar id={id} error={error} />
    </div>
  );
};

PasswordField.defaultProps = {
  error: undefined,
  placeholder: "",
  normalize: [],
  id: undefined,
  disabled: false,
  autoComplete: "on",
};

export default PasswordField;
