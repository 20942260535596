import React, { FC } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { createInvoice } from "api/admin";
import { jobSelectedState } from "recoil/atoms";

import { InvoiceClient, Vendor } from "types/models";
import useAsync from "hooks/use-async";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import LoadingComponent from "components/LoadingComponent";
import Label from "components/Label";

import useProjects from "hooks/use-projects";
import toast from "react-hot-toast";
import { getError } from "utils/errors";
import InvoiceForm from "./components/InvoiceForm";
import styles from "./styles.module.scss";
import { canCreateInvoice } from "./utils";

type Props = {
  selectedVendor: Vendor | null;
  gettingVendor: boolean;
};

const CreateInvoice: FC<Props> = ({ selectedVendor, gettingVendor }) => {
  const history = useHistory();
  const location = useLocation();

  const selectedJob = useRecoilValue(jobSelectedState);
  const { updateProjectJobs, selectedProject } = useProjects();

  const { projectID, jobID } = useParams<{ [key: string]: string }>();
  const home = selectedProject?.home_detail;

  const notificationDispatcher = useNotificationDispatcher();

  const [submit, submitting] = useAsync(async (invoice: InvoiceClient) => {
    if (!selectedJob || !selectedProject || !selectedVendor)
      notificationDispatcher({
        type: "error",
        description: "Error creating invoice",
      });
    else {
      await createInvoice(invoice)
        .then(async () => {
          notificationDispatcher({
            description: "Invoice created!",
            type: "success",
          });
          updateProjectJobs(projectID);
          history.push(
            `/admin/projects/${projectID}/jobs/${jobID}${location.search}`
          );
        })
        .catch((error) => {
          toast.error(
            getError(error.response?.data, "Could not create invoice")
          );
        });
    }
  });

  const canCreate = !!home && canCreateInvoice(home);

  return (
    <LoadingComponent className={styles.container} loading={gettingVendor}>
      <>
        {!canCreate && (
          <Label className={styles.warning} type="critical">
            Cannot create invoice, please check the following information: The
            home you are invoicing might not have a user flagged as home owner.
          </Label>
        )}
        {selectedJob ? (
          selectedProject &&
          selectedVendor && (
            <InvoiceForm
              vendor={selectedVendor}
              home={selectedProject.home_detail}
              disabled={!canCreate}
              creatingInvoice={submitting}
              createInvoice={submit}
              job={selectedJob}
            />
          )
        ) : (
          <Label>Please select a job!</Label>
        )}
      </>
    </LoadingComponent>
  );
};

export default CreateInvoice;
