export const GREETINGS = {
  collapse: {
    hi: { initial: { height: 0 }, animate: { height: "100%" } },
    goodbye: { exit: { height: 0 } },
  },
  fade: {
    hi: { initial: { opacity: 0 }, animate: { opacity: 1 } },
    goodbye: { exit: { opacity: 0 } },
  },
  expandWidth: {
    hi: {
      initial: { width: 0 },
      animate: { width: "fit-content", overflow: "hidden" },
    },
    goodbye: { exit: { width: 0 } },
  },
  expandHeight: {
    hi: {
      initial: { height: 0 },
      animate: { height: "fit-content", overflow: "hidden" },
    },
    goodbye: { exit: { height: 0 } },
  },
  slide: {
    hi: { initial: { x: "-100%" }, animate: { x: "0px" } },
    goodbye: { exit: { x: "100%" } },
  },
  flex: {
    hi: {
      initial: { width: 0, flex: 0 },
      animate: { flex: "1 0 auto" },
    },
    goodbye: { exit: { width: 0, flex: 0 } },
  },
};
