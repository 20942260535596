import { useRecoilValue } from "recoil";
import { appFiltersState } from "../../atoms";
import { AppFilters } from "../../types";

const useAppFilters = (): AppFilters => {
  const filters = useRecoilValue(appFiltersState);

  return filters;
};

export default useAppFilters;
