import React, { FC } from "react";

import { HomeAddress } from "types/models";
import Label from "../Label";

import styles from "./styles.module.scss";

type FormatAddressProps = {
  home: HomeAddress;
  className?: string;
};

export const FormatAddress: FC<FormatAddressProps> = ({ home, className }) => {
  const { street_number: streetNumber, route, locality } = home;
  if (!streetNumber && !locality && !route) return <Label>{home.raw}</Label>;

  return (
    <div className={`${styles.container} ${className}`}>
      <Label>{`${streetNumber} ${route}`}</Label>
      <Label>
        {`${locality?.name} ${locality?.state}, ${locality?.postal_code} ${locality?.country}`}
      </Label>
    </div>
  );
};

FormatAddress.defaultProps = {
  className: "",
};
