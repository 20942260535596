import React, { FC } from "react";
import { Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

type Props = {
  title: string;
  disabled?: boolean;
  onConfirm: () => void;
  children: React.ReactNode;
};

const ConfirmAction: FC<Props> = ({ title, disabled, onConfirm, children }) => {
  return (
    <Popconfirm
      title={title}
      disabled={disabled && disabled}
      okText="Yes"
      cancelText="No"
      okButtonProps={{
        style: {
          width: "70px",
          height: "30px",
          borderRadius: "4px",
        },
      }}
      cancelButtonProps={{
        style: {
          width: "70px",
          height: "30px",
          borderRadius: "4px",
        },
      }}
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      onConfirm={() => onConfirm()}
    >
      {children}
    </Popconfirm>
  );
};

ConfirmAction.defaultProps = {
  disabled: false,
};

export default ConfirmAction;
