/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounce } from "lodash";
import { useMemo, useState } from "react";

const useAsyncState = <T>(
  initialValue: T,
  asyncRequest: (...params: any[]) => Promise<T>,
  config?: {
    defaultLoading?: boolean;
    failureCallback?: (error: string) => void;
    debounceTime?: number;
    successCallback?: () => void;
  }
): {
  data: T;
  refresh: (...params: any[]) => void;
  inProgress: boolean;
  error: string;
  overrideState: (V: T) => void;
} => {
  const {
    defaultLoading = false,
    failureCallback,
    debounceTime = 0,
    successCallback,
  } = config || {};

  const [inProgress, setInProgress] = useState(defaultLoading);
  const [error, setError] = useState("");
  const [data, setData] = useState(initialValue);

  const refresh = useMemo(
    () =>
      debounce((...params: any[]) => {
        setInProgress(true);
        asyncRequest(...params)
          .then((newData: T) => {
            setData(newData);
            setInProgress(false);
            successCallback?.();
          })
          .catch((newError: string) => {
            setError(newError);
            failureCallback?.(newError);
          })
          .finally(() => setInProgress(false));
      }, debounceTime),
    [asyncRequest]
  );

  return {
    data,
    refresh,
    inProgress,
    error,
    overrideState: setData,
  };
};

export default useAsyncState;
