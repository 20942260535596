import { atom } from "recoil";

import { Job } from "types/models";

export const jobsOnProjectState = atom<Job[]>({
  key: "jobsOnProjectState",
  default: [],
});

export const jobSelectedState = atom<Job | null>({
  key: "jobSelectedState",
  default: null,
});

export const jobsUpdatingState = atom<boolean>({
  key: "jobsUpdatingState",
  default: false,
});
