import React, { FC } from "react";

import Flow from "components/Flow";
import styles from "./styles.module.scss";
import SetPassword from "./components/SetPassword";
import Confirmation from "./components/Confirmation";

const ResetConfirm: FC = () => {
  return (
    <Flow
      className={styles.container}
      withoutNextStepButton
      withoutPreviousStepButton
      steps={[{ Component: SetPassword }, { Component: Confirmation }]}
    />
  );
};

export default ResetConfirm;
