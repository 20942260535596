import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useRecoilState } from "recoil";

import { globalParamsState } from "./atoms";

const useGlobalParams = (): { [key: string]: string } => {
  const [state, setState] = useRecoilState(globalParamsState);

  const { params } = useRouteMatch();

  useEffect(() => {
    setState(params);

    return () => setState({});
  }, []);

  return state;
};

export default useGlobalParams;
