import React, { FC } from "react";
import { Tooltip } from "antd";

import Button from "components/Button";
import Label from "components/Label";

import styles from "./styles.module.scss";

const displayAutomaticPayment = (autoPaymentOn: boolean) =>
  autoPaymentOn ? (
    <Label type="italic-bold-positive">ON</Label>
  ) : (
    <Label type="italic-bold-negative">OFF</Label>
  );

type AutomaticPaymentProps = {
  autoPaymentOn: boolean;
  selectedPayment: boolean;
  switchAutoPayment: (switchOn: boolean) => void;
};

const AutomaticPayment: FC<AutomaticPaymentProps> = ({
  autoPaymentOn,
  selectedPayment,
  switchAutoPayment,
}) => (
  <div className={styles.container}>
    <div className={styles.status}>
      <Label className={styles.statusLabel}>Automatic Payments are</Label>
      {displayAutomaticPayment(autoPaymentOn)}
    </div>
    {!selectedPayment && (
      <Label type="italic">
        You must select a Checking or Savings account to turn on automatic
        payments.
      </Label>
    )}
    {selectedPayment && autoPaymentOn ? (
      <Button size="medium" onClick={() => switchAutoPayment(false)}>
        Turn off Automatic Payments
      </Button>
    ) : (
      <Tooltip title="Disabling automatic payments for now">
        <Button
          size="medium"
          disabled={!selectedPayment || autoPaymentOn}
          onClick={() => switchAutoPayment(true)}
        >
          Turn on Automatic Payments
        </Button>
      </Tooltip>
    )}
  </div>
);

export default AutomaticPayment;
