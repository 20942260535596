export const PROJECT_ORIGIN = [
  {
    value: "COLD_CALL",
    label: "Cold Call",
  },
  {
    value: "HCC_MONTHLY_MEETING",
    label: "HCC Monthly Meeting",
  },
  {
    value: "EMAIL",
    label: "Email",
  },
  {
    value: "MAINTENANCE_SURVEY",
    label: "Fall/Spring Maintenance Survey",
  },
  {
    value: "ONBOARDING",
    label: "Onboarding",
  },
];

export const projectOriginToLabel = (originValue: string) =>
  PROJECT_ORIGIN.find(({ value }) => value === originValue)?.label ?? "";
