import { useRecoilState } from "recoil";
import { unmountedState } from "../atoms";

const useSidebarInteraction = () => {
  const [unmounted, setUnmounted] = useRecoilState(unmountedState);

  return {
    unmounted,
    hideSidebar: () => setUnmounted(true),
    showSidebar: () => setUnmounted(false),
  };
};

export default useSidebarInteraction;
