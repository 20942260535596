import { AxiosError } from "axios";

import { ActionResponse } from "types/responses";

import { Action } from "types/models";
import { apiClient, ServerError } from ".";

const fetchActions = async (
  queryFilters: { [x: string]: string } = {},
  stickyParams?: string
) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const combinedQueryParams = [queryParams, stickyParams].join("&");
    const response = await apiClient.get<ActionResponse>(
      `/alert_queue/?${combinedQueryParams}`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchAction = async (actionID: string) => {
  try {
    const response = await apiClient.get<Action>(`/alert_queue/${actionID}/`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const deleteAction = async (actionID: string) => {
  try {
    const response = await apiClient.delete(`/alert_queue/${actionID}/`);
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const pauseAction = async (actionID: string) => {
  try {
    const response = await apiClient.post(
      `/alert_queue/${actionID}/pause_action/`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export { fetchActions, fetchAction, deleteAction, pauseAction };
