import Button from "components/Button";
import Form from "components/Form";
import Modal from "components/Modal";
import React, { FC } from "react";

import { USER_ROLE_LABELS } from "constants/roles";

import { adminUpdateUser } from "api/users";
import useAsync from "hooks/use-async";
import { UserRole } from "types/models";
import toast from "react-hot-toast";
import { getError } from "utils/errors";
import { required } from "utils/form";
import { targetRoles } from "../../constants";
import styles from "./styles.module.scss";

interface Props {
  visible: boolean;
  usersToUpdate: {
    id: string;
    name: string;
    role: string;
    is_active: boolean;
  }[];
  handleClose(): void;
  updateData(): void;
}

interface FormValues {
  new_role: UserRole;
}

const BatchEdit: FC<Props> = ({
  visible,
  usersToUpdate,
  handleClose,
  updateData,
}) => {
  const multiple = usersToUpdate.length > 1;
  const any = usersToUpdate.length === 1;

  const [submit, submitting] = useAsync(
    async ({ new_role: role }: FormValues) =>
      Promise.all(usersToUpdate.map(({ id }) => adminUpdateUser({ role }, id)))
        .then(() => {
          toast.success(`Successfully updated ${multiple ? "users" : "user"}`);
          updateData();
          handleClose();
        })
        .catch((error) => {
          toast.error(
            getError(
              error.response?.data,
              `Something went wrong updating ${multiple ? "users" : "user"}`
            )
          );
        })
  );

  return (
    <Modal
      handleClose={handleClose}
      visible={visible}
      title={multiple ? "Batch update" : "User update"}
    >
      <Form
        initialValues={
          multiple
            ? {}
            : any
            ? {
                new_role: usersToUpdate[0].role,
              }
            : {}
        }
        className={styles.form}
        disabled={submitting}
        fields={[
          {
            title: "New role",
            id: "new_role",
            type: "select",
            validate: [required("Role is required")],
            options: targetRoles.map((role) => ({
              value: role,
              label: USER_ROLE_LABELS[role],
            })),
          },
        ]}
        onSubmit={submit}
      >
        {({ handleSubmit, invalid, hasChanged }) => (
          <Button
            className={styles.submit}
            loading={submitting}
            disabled={invalid || !hasChanged}
            onClick={handleSubmit}
          >
            Save
          </Button>
        )}
      </Form>
    </Modal>
  );
};

export default BatchEdit;
