import React, { FC } from "react";

const Phone: FC<{
  width?: string | number;
  height?: string | number;
  color?: string;
}> = ({ width = "180px", height = "180px", color = "black" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x="0px"
      y="0px"
      viewBox="0 0 600 600"
      fill={color}
      strokeWidth="1px"
    >
      <path
        style={{ fill: "#EFEFEF" }}
        d="M97.211,0C70.804,0,49.32,21.484,49.32,47.891V464.11c0,26.406,21.484,47.89,47.891,47.89h137.787V0
	H97.211z"
      />
      <path
        style={{ fill: "#DDDDDD" }}
        d="M77.849,464.109V47.891C77.849,21.484,99.333,0,125.74,0H97.211C70.804,0,49.32,21.484,49.32,47.891
	V464.11c0,26.406,21.484,47.89,47.891,47.89h28.529C99.333,512,77.849,490.516,77.849,464.109z"
      />
      <path
        style={{ fill: "#535454" }}
        d="M102.06,26.716L102.06,26.716c-13.316,0-24.211,10.895-24.211,24.211v56.664
	c0,13.316,10.895,24.211,24.211,24.211l0,0c13.316,0,24.211-10.895,24.211-24.211V50.927
	C126.271,37.611,115.376,26.716,102.06,26.716z"
      />
      <path
        style={{ fill: "#444443" }}
        d="M102.06,107.59V50.927c0-8.913,4.885-16.735,12.105-20.937c-16.082-9.36-36.316,2.407-36.316,20.937
	v56.664c0,18.521,20.223,30.304,36.316,20.937C106.945,124.325,102.06,116.503,102.06,107.59z"
      />
      <path
        style={{ fill: "#535454" }}
        d="M414.789,0H234.997c-26.407,0-47.891,21.484-47.891,47.891V464.11
	c0,26.407,21.484,47.891,47.891,47.891h179.792c26.407,0,47.891-21.484,47.891-47.891V47.891C462.68,21.484,441.197,0,414.789,0z"
      />
      <path
        style={{ fill: "#444443" }}
        d="M213.636,464.109V47.891C213.636,21.484,235.119,0,261.526,0h-26.529
	c-26.407,0-47.891,21.484-47.891,47.891V464.11c0,26.407,21.484,47.891,47.891,47.891h26.529
	C235.119,512,213.636,490.516,213.636,464.109z"
      />
      <path
        style={{ fill: "#A4D9F4" }}
        d="M436.146,464.109c0,11.795-9.562,21.357-21.357,21.357H234.997c-11.795,0-21.357-9.562-21.357-21.357
	V47.891c0-11.795,9.562-21.357,21.357-21.357h22.407c4.414,0,7.992,3.578,7.992,7.992v5.068c0,6.8,5.563,12.363,12.363,12.363
	h94.267c6.8,0,12.363-5.563,12.363-12.363v-7.33c0-3.165,2.566-5.731,5.731-5.731h24.669c11.795,0,21.357,9.562,21.357,21.357
	V464.109z"
      />
      <path
        style={{ fill: "#7CC9E8" }}
        d="M239.119,464.109V47.891c0-11.155,8.555-20.3,19.46-21.261c-1.241-0.184,0.079-0.056-23.582-0.096
	c-11.795,0-21.357,9.562-21.357,21.357V464.11c0,11.795,9.562,21.357,21.357,21.357h25.478
	C248.681,485.466,239.119,475.905,239.119,464.109z"
      />
      <g>
        <path
          style={{ fill: "#666666" }}
          d="M355.99,34.261h-40.343c-4.267,0-7.727-3.459-7.727-7.727s3.459-7.727,7.727-7.727h40.343
		c4.267,0,7.727,3.459,7.727,7.727C363.716,30.802,360.257,34.261,355.99,34.261z"
        />
        <path
          style={{ fill: "#666666" }}
          d="M287.057,32.959c-5.532-3.636-4.191-12.525,2.771-14.001c4.736-0.977,9.242,2.691,9.242,7.572
		c0,3.589-2.477,6.806-6.223,7.583C292.353,34.205,288.63,34.023,287.057,32.959z"
        />
        <path
          style={{ fill: "#666666" }}
          d="M97.77,58.386c-3.674-2.51-4.433-7.304-2.132-10.715c4.089-6.211,14.145-3.457,14.145,4.286
		c0,3.737-2.687,6.912-6.213,7.572C102.607,59.731,99.552,59.557,97.77,58.386z"
        />
      </g>
      <path
        style={{ fill: "#F9D698" }}
        d="M97.77,85.688c-3.674-2.51-4.433-7.304-2.132-10.715c3.204-4.983,11.083-4.536,13.558,1.329
	c1.17,2.765,0.59,6.159-1.669,8.417C105.685,86.561,100.94,87.771,97.77,85.688z"
      />
      <path
        style={{ fill: "#666666" }}
        d="M97.77,112.978c-5.099-3.351-4.404-11.132,1.329-13.558c8.094-3.163,14.221,6.808,8.428,12.6
	C105.518,114.029,101.2,115.322,97.77,112.978z"
      />
      <path
        style={{ fill: "#70B5E8" }}
        d="M263.357,114.163h-20.676c-1.895,0-3.446-1.551-3.446-3.446V90.04c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C266.804,112.612,265.253,114.163,263.357,114.163z"
      />
      <path
        style={{ fill: "#EFEFEF" }}
        d="M312.034,114.163h-20.676c-1.895,0-3.446-1.551-3.446-3.446V90.04c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C315.48,112.612,313.929,114.163,312.034,114.163z"
      />
      <path
        style={{ fill: "#92B620" }}
        d="M360.71,114.163h-20.676c-1.895,0-3.446-1.551-3.446-3.446V90.04c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C364.156,112.612,362.605,114.163,360.71,114.163z"
      />
      <path
        style={{ fill: "#898989" }}
        d="M409.386,114.163H388.71c-1.895,0-3.446-1.551-3.446-3.446V90.04c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C412.832,112.612,411.281,114.163,409.386,114.163z"
      />
      <path
        style={{ fill: "#92B620" }}
        d="M263.357,163.319h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C266.804,161.768,265.253,163.319,263.357,163.319z"
      />
      <path
        style={{ fill: "#898989" }}
        d="M312.034,163.319h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C315.48,161.768,313.929,163.319,312.034,163.319z"
      />
      <path
        style={{ fill: "#70B5E8" }}
        d="M360.71,163.319h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C364.156,161.768,362.605,163.319,360.71,163.319z"
      />
      <path
        style={{ fill: "#F94A46" }}
        d="M409.386,163.319H388.71c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C412.832,161.768,411.281,163.319,409.386,163.319z"
      />
      <path
        style={{ fill: "#DDA36F" }}
        d="M263.357,213.114h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C266.804,211.563,265.253,213.114,263.357,213.114z"
      />
      <path
        style={{ fill: "#F9D698" }}
        d="M312.034,213.114h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C315.48,211.563,313.929,213.114,312.034,213.114z"
      />
      <path
        style={{ fill: "#898989" }}
        d="M360.71,213.114h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C364.156,211.563,362.605,213.114,360.71,213.114z"
      />
      <path
        style={{ fill: "#F9D698" }}
        d="M409.386,213.114H388.71c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C412.832,211.563,411.281,213.114,409.386,213.114z"
      />
      <g>
        <path
          style={{ fill: "#70B5E8" }}
          d="M263.357,262.27h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
		h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C266.804,260.719,265.253,262.27,263.357,262.27z"
        />
        <path
          style={{ fill: "#70B5E8" }}
          d="M312.034,262.27h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
		h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C315.48,260.719,313.929,262.27,312.034,262.27z"
        />
      </g>
      <path
        style={{ fill: "#F94A46" }}
        d="M360.71,262.27h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C364.156,260.719,362.605,262.27,360.71,262.27z"
      />
      <path
        style={{ fill: "#EFEFEF" }}
        d="M263.357,311.426h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C266.804,309.875,265.253,311.426,263.357,311.426z"
      />
      <path
        style={{ fill: "#92B620" }}
        d="M312.034,311.426h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C315.48,309.875,313.929,311.426,312.034,311.426z"
      />
      <path
        style={{ fill: "#898989" }}
        d="M360.71,311.426h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C364.156,309.875,362.605,311.426,360.71,311.426z"
      />
      <path
        style={{ fill: " #FFB70D" }}
        d="M409.386,262.27H388.71c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C412.832,260.719,411.281,262.27,409.386,262.27z"
      />
      <path
        style={{ fill: "#92B620" }}
        d="M263.358,463.095h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C266.804,461.544,265.253,463.095,263.358,463.095z"
      />
      <path
        style={{ fill: "#70B5E8" }}
        d="M312.034,463.095h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C315.48,461.544,313.929,463.095,312.034,463.095z"
      />
      <path
        style={{ fill: "#92B620" }}
        d="M360.71,463.095h-20.676c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C364.156,461.544,362.605,463.095,360.71,463.095z"
      />
      <path
        style={{ fill: "#EFEFEF" }}
        d="M409.386,463.095H388.71c-1.895,0-3.446-1.551-3.446-3.446v-20.676c0-1.895,1.551-3.446,3.446-3.446
	h20.676c1.895,0,3.446,1.551,3.446,3.446v20.676C412.832,461.544,411.281,463.095,409.386,463.095z"
      />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

Phone.defaultProps = {
  width: "180px",
  height: "180px",
  color: "black",
};

export default Phone;
