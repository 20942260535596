import Button from "components/Button";
import Form from "components/Form";
import Modal from "components/Modal";
import React, { FC, useEffect } from "react";

import { fetchActiveUsers } from "api/users";
import useAsync from "hooks/use-async";
import { UserRole } from "types/models";
import toast from "react-hot-toast";
import { getError } from "utils/errors";
import useAsyncState from "hooks/use-async-state";
import LoadingComponent from "components/LoadingComponent";
import { addHomeRelationship, removeHomeRelationship } from "api/homes";
import styles from "./styles.module.scss";
import { RowData } from "../../constants";

interface Props {
  visible: boolean;
  homesToUpdate: RowData[];
  handleClose(): void;
  updateData(): void;
  onModalClose(): void;
}

interface FormValues {
  coordinator_id?: string;
  expert_id?: string;
}

const Reassign: FC<Props> = ({
  visible,
  homesToUpdate,
  handleClose,
  updateData,
  onModalClose,
}) => {
  const {
    data: homeCoordinators,
    inProgress: fetchingHomeCoordinators,
    refresh: fetchCoordinators,
  } = useAsyncState([], () =>
    fetchActiveUsers({
      role: UserRole.HomeCoordinator,
      page_size: "100",
    }).then(({ results }) => results)
  );

  const {
    data: homeExperts,
    inProgress: fetchingHomeExperts,
    refresh: fetchExperts,
  } = useAsyncState([], () =>
    fetchActiveUsers({
      role: UserRole.HomeExpert,
      page_size: "100",
    }).then(({ results }) => results)
  );

  useEffect(() => {
    fetchCoordinators();
    fetchExperts();
  }, []);

  const [submit, submitting] = useAsync(
    async ({ coordinator_id, expert_id }: FormValues) => {
      if (coordinator_id) {
        const deletePromises = homesToUpdate.map(({ id, users }) => {
          const coordinators = users
            .filter(({ role }) => role === UserRole.HomeCoordinator)
            .map(({ role, user: { uid: user_uid } }) => ({ role, user_uid }));

          return removeHomeRelationship(id, coordinators);
        });

        await Promise.all(deletePromises);

        const createPromises = homesToUpdate.map(({ id }) => {
          return addHomeRelationship(id, [
            { user_uid: coordinator_id, role: UserRole.HomeCoordinator },
          ]).catch((error) => {
            toast.error(
              getError(
                error.response?.data,
                "Failed to create home relationship"
              )
            );
          });
        });

        await Promise.all(createPromises);
      }

      if (expert_id) {
        const deletePromises = homesToUpdate.map(({ id, users }) => {
          const coordinators = users
            .filter(({ role }) => role === UserRole.HomeExpert)
            .map(({ role, user: { uid: user_uid } }) => ({ role, user_uid }));

          return removeHomeRelationship(id, coordinators);
        });

        await Promise.all(deletePromises);

        const createPromises = homesToUpdate.map(({ id }) => {
          return addHomeRelationship(id, [
            { user_uid: expert_id, role: UserRole.HomeExpert },
          ]).catch((error) => {
            toast.error(
              getError(
                error.response?.data,
                "Failed to remove home relationship"
              )
            );
          });
        });

        await Promise.all(createPromises);
      }

      updateData();
      handleClose();
    }
  );

  return (
    <Modal
      onModalClosed={onModalClose}
      handleClose={handleClose}
      visible={visible}
      title="Reassign"
    >
      <LoadingComponent
        className={styles.loadingWrapper}
        loading={fetchingHomeCoordinators || fetchingHomeExperts}
      >
        <Form
          className={styles.form}
          disabled={submitting}
          fields={[
            {
              title: "New Home coordinator",
              id: "coordinator_id",
              type: "select",
              options: homeCoordinators.map(({ name, uid }) => ({
                value: uid,
                label: name,
              })),
            },
            {
              title: "New Home expert",
              id: "expert_id",
              type: "select",
              options: homeExperts.map(({ name, uid }) => ({
                value: uid,
                label: name,
              })),
            },
          ]}
          onSubmit={submit}
        >
          {({ handleSubmit, invalid, hasChanged }) => (
            <Button
              className={styles.submit}
              loading={submitting}
              disabled={invalid || !hasChanged}
              onClick={handleSubmit}
            >
              Save
            </Button>
          )}
        </Form>
      </LoadingComponent>
    </Modal>
  );
};

export default Reassign;
