import axios, { AxiosError } from "axios";

import { getStoredSession, setStoredSession } from "providers/SessionProvider";
import { Token } from "types/models";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const tokenRefreshPath = "/auth/token/refresh/";
interface RefreshData {
  access: string;
  refresh: string;
}

export interface ServerError {
  message: string[];
  detail: string;
  code: string;
  error: string;
  non_field_errors: string[];
}

const apiClient = axios.create({
  baseURL: `${baseURL}/api`,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use((config) => {
  const session = getStoredSession();

  if (
    session?.access_token &&
    !config.url?.includes("/auth/") &&
    config?.headers
  ) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${session.access_token}`;
  }

  return config;
});

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url === tokenRefreshPath
    ) {
      window.location.href = "/auth/login";
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry // eslint-disable-line no-underscore-dangle
    ) {
      originalRequest._retry = true; // eslint-disable-line no-underscore-dangle
      return refresh().then((session) => {
        if (!isServerError(session)) {
          originalRequest.headers.Authorization = `Bearer ${session.access_token}`;
          return axios(originalRequest);
        }
        return undefined;
      });
    }
    return Promise.reject(error);
  }
);

const refresh = async () => {
  try {
    const session = getStoredSession();
    const response = await apiClient.post<RefreshData>(tokenRefreshPath, {
      refresh: session.refresh_token,
    });
    const refreshData = response.data;
    // merge refresh data on to old session to maintain login response
    const newSession = {
      ...session,
      access_token: refreshData.access,
      refresh_token: refreshData.refresh,
    } as Token;
    // Note this won't refresh context
    setStoredSession(newSession);
    return newSession;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    if (axiosError && axiosError.response) {
      return axiosError.response.data;
    }
    throw err;
  }
};

const isServerError = (data: Token | ServerError): data is ServerError => {
  return (data as ServerError).code !== undefined;
};

export { isServerError, apiClient };
