import { AxiosError } from "axios";

import { Source } from "types/models";

import { apiClient, ServerError } from ".";

const addPaymentFromPlaid = async (
  public_token: string,
  account_id: string
) => {
  try {
    const payload = {
      public_token,
      account_id,
    };
    const response = await apiClient.post<Source>(
      "/customers/payment/plaid/",
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const addSource = async (token: string, isDefault: boolean) => {
  try {
    const payload = {
      set_default: isDefault,
      source: token,
    };
    const response = await apiClient.post<Source>(
      "/customers/payments/add-source/",
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const removePaymentMethod = async (id: string) => {
  try {
    const response = await apiClient.delete<Source>(
      `customers/payments/${id}/delete-payment-method/`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchHomePaymentMethods = async (homeID: string) => {
  try {
    const response = await apiClient.get<{
      sources: Source[];
      bank_account: Source[];
    }>(`/homes/${homeID}/payment_methods`);

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const addBankSource = async (token: string, isDefault: boolean) => {
  try {
    const payload = {
      set_default: isDefault,
      source: token,
    };
    const response = await apiClient.post<Source>(
      "/customers/payments/add-bank-account/",
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const verifyBankAccount = async (
  bank_account_id: string,
  amounts: number[]
) => {
  try {
    const payload = {
      bank_account_id,
      amounts,
    };
    const response = await apiClient.post<Source>(
      "/customers/payments/verify-bank-account/",
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const setDefaultPaymentMethod = async (source: string) => {
  try {
    const payload = {
      source,
    };
    const response = await apiClient.post<Source>(
      "/customers/payments/set-default-payment-method/",
      payload
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export {
  addPaymentFromPlaid,
  addBankSource,
  addSource,
  verifyBankAccount,
  setDefaultPaymentMethod,
  removePaymentMethod,
  fetchHomePaymentMethods,
};
