import React, { FC } from "react";

import { useUser } from "providers/UserProvider";
import { fetchStripeCustomer } from "api/auth";
import { setDefaultPaymentMethod } from "api/payments";
import PaymentSourceCard from "components/PaymentSourceCard";
import { SourceStatus } from "constants/payments";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import Button from "components/Button";
import { Source, SourceType } from "types/models";

import useAsync from "hooks/use-async";
import styles from "./styles.module.scss";

type Props = {
  source: Source;
  withActions?: boolean;
};

const PaymentSource: FC<Props> = ({ source, withActions, children }) => {
  const notificationDispatcher = useNotificationDispatcher();
  const { setCustomer } = useUser();
  const paymentPreference = source.default_source;

  const fetchAndSetCustomer = async () => {
    const stripeCustomer = await fetchStripeCustomer();
    setCustomer(stripeCustomer);
  };

  const [makePreferredPayment, makingPreferredPayment] = useAsync(async () => {
    if (
      source.status === SourceStatus.CHARGEABLE ||
      source.status === SourceStatus.VERIFIED
    ) {
      await setDefaultPaymentMethod(source.id).then(async () => {
        const account =
          source.type === SourceType.BANK_ACCOUNT
            ? "bank account"
            : "credit card";
        const message = "Default Payment Updated";
        const description = `The ${account} ending in #${source.source_data.last4} will be used for all invoices going forward`;
        notificationDispatcher({ type: "success", message, description });
        await fetchAndSetCustomer();
      });
    } else {
      const message = "Cannot Update Default Payment";
      const description = "Default payment method must be verified";
      notificationDispatcher({ type: "error", message, description });
    }
  });

  return (
    <div className={styles.container}>
      <PaymentSourceCard
        updateCustomer={fetchAndSetCustomer}
        withActions={withActions}
        source={source}
      />
      <div className={styles.actions}>
        {children}
        <Button
          className={styles.button}
          variant="primary"
          size="medium"
          onClick={makePreferredPayment}
          disabled={paymentPreference}
          loading={makingPreferredPayment}
        >
          {paymentPreference
            ? "Preferred payment method"
            : makingPreferredPayment
            ? "Changing preferred payment method"
            : "Make preferred payment method"}
        </Button>
      </div>
    </div>
  );
};

PaymentSource.defaultProps = {
  withActions: false,
};

export default PaymentSource;
