import React, { memo, FC } from "react";
import { motion } from "framer-motion";

import Label from "components/Label";

import { useSetRecoilState } from "recoil";
import { hiddenState } from "components/Sidebar/atoms";
import useScreenSize from "hooks/use-screen-size";
import NotificationsBadge from "./components/NotificationsBadge";
import styles from "../../styles.module.scss";

type Props = {
  onClick(): void;
  ownKey: string;
  label: string;
  Icon: FC<{ className: string }>;
  selectedOption: string;
  setSelectedOption(newOption: string): void;
  collapsed: boolean;
  notifications?: number;
};

const SidebarItem: FC<Props> = ({
  onClick,
  ownKey,
  label,
  Icon,
  selectedOption,
  setSelectedOption,
  collapsed,
  notifications = 0,
}) => {
  const { isResponsive } = useScreenSize();
  const setHidden = useSetRecoilState(hiddenState);

  const variants = {
    collapsed: {
      width: 0,
      transition: { ease: "easeInOut", duration: 0.5 },
    },
    expanded: {
      width: "100%",
      transition: { ease: "easeInOut", duration: 0.5 },
    },
  };

  return (
    <button
      className={`${styles.option} ${
        selectedOption === ownKey && styles.selectedOption
      }`}
      onClick={() => {
        if (isResponsive) {
          setHidden(true);
        }
        setSelectedOption(ownKey);
        onClick();
      }}
      type="button"
    >
      {Icon && <Icon className={styles.icon} />}
      <motion.div
        animate={collapsed ? "collapsed" : "expanded"}
        variants={variants}
      >
        <Label ellipsis className={styles.label} type="s1" color="white">
          {label}
        </Label>
      </motion.div>
      {!!notifications && <NotificationsBadge count={notifications} />}
    </button>
  );
};

SidebarItem.defaultProps = {
  notifications: 0,
};

export default memo(SidebarItem);
