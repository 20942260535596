/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Select as RootSelect, SelectProps } from "antd";

import ErrorBar from "components/ErrorBar";

import Label from "components/Label";
import styles from "./styles.module.scss";

interface Props<T> extends SelectProps<T> {
  error?: string;
  onChange(newValue: any): void;
  options: { label: string; value: any }[] | undefined;
  ordered?: boolean;
  showErrors?: boolean;
}

const Select: FC<Props<any>> = ({
  options,
  error,
  onChange,
  value,
  id,
  ordered,
  showErrors = true,
  ...selectProps
}) => {
  return (
    <div className={styles.container}>
      <RootSelect
        {...selectProps}
        allowClear
        id={id}
        showSearch
        optionFilterProp="label"
        value={value}
        bordered={false}
        className={`${styles.select} ${selectProps.className}`}
        dropdownClassName={styles.dropdown}
        {...(ordered
          ? {
              filterSort: (a, b) => (a.id < b.id ? -1 : 1),
            }
          : {})}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        onClear={() => {
          onChange(undefined);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {options?.map(({ value: optionValue, label }) => (
          <RootSelect.Option
            id={label}
            className={styles.dropdownItem}
            value={optionValue}
            key={optionValue}
            label={label}
          >
            <Label>{label}</Label>
          </RootSelect.Option>
        ))}
      </RootSelect>
      {showErrors && <ErrorBar id={id} error={error} />}
    </div>
  );
};

Select.defaultProps = {
  error: undefined,
  ordered: false,
  showErrors: true,
};

export default Select;
