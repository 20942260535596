import React, { FC } from "react";
import { Widget } from "@typeform/embed-react";

import { useRecoilState } from "recoil";
import { SharedFlowState } from "components/Flow/types";
import Button from "components/Button";
import styles from "./styles.module.scss";

type Props = {
  sharedFlowState: SharedFlowState;
  nextStep(): void;
};

const Onboarding: FC<Props> = ({ sharedFlowState, nextStep }) => {
  const [state, setState] = useRecoilState(sharedFlowState);

  return (
    <div className={styles.container}>
      <Widget
        onSubmit={() => {
          setState({ ...state, submittedPreOnboardingForm: true });
          nextStep();
        }}
        className={styles.form}
        id="nGFLENl3"
      />
      <Button variant="text" onClick={nextStep}>
        Skip
      </Button>
    </div>
  );
};

export default Onboarding;
