import React, { FC, useState } from "react";
import { Button, Spin as Loading } from "antd";

import useNotificationDispatcher from "hooks/use-notification-dispatcher";

import { createActions } from "./utils";
import styles from "./styles.module.scss";

type Props = {
  id: string;
  updateCustomer: () => void;
};

const CardActions: FC<Props> = ({ id, updateCustomer }: Props) => {
  const [performingAction, setPerformingAction] = useState<boolean>(false);
  const notificationDispatcher = useNotificationDispatcher();

  const actions = createActions(
    setPerformingAction,
    updateCustomer,
    notificationDispatcher
  );

  return (
    <div className={styles.container}>
      {performingAction && <Loading className={styles.loading} />}
      {!performingAction &&
        actions.map(({ Icon, onClick }) => (
          <Button
            key={Icon.displayName}
            disabled={performingAction}
            type="text"
            className={styles.button}
            onClick={onClick(id)}
          >
            <Icon className={styles.icon} />
          </Button>
        ))}
    </div>
  );
};

export default CardActions;
