import React, { useEffect } from "react";

import useGlobalParams from "hooks/use-global-params";

import useHomes from "hooks/use-homes";
import { useParams } from "react-router-dom";
import LoadingComponent from "components/LoadingComponent";
import HomeDetailPanel from "views/Admin/components/HomeDetailPanel";
import HomeSpecifics from "./components/HomeSpecifics";
import styles from "./styles.module.scss";

const ViewHome = () => {
  useGlobalParams();
  const { homeID } = useParams<{ [key: string]: string }>();
  const { clearHomeDetail, updateHomeDetail, loadingHomeDetail } = useHomes();

  useEffect(() => {
    updateHomeDetail(homeID);
  }, [homeID]);

  useEffect(() => clearHomeDetail, []);

  return (
    <LoadingComponent loading={loadingHomeDetail} className={styles.container}>
      <HomeDetailPanel
        className={styles.details}
        loadingData={loadingHomeDetail}
        updateData={updateHomeDetail}
        renderStatic
      />
      <HomeSpecifics />
    </LoadingComponent>
  );
};

export default ViewHome;
