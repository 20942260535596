import React, { useEffect, FC } from "react";
import { Divider } from "antd";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box, Flex, Heading } from "rebass";

import ActionLink from "components/ActionLink";
import NameAndAddress from "components/NameAndAddress";
import SubSection from "components/SubSection";
import useActions from "hooks/use-actions";
import Panel from "components/Panel";
import LoadingComponent from "components/LoadingComponent";

import styles from "./styles.module.scss";

const ActionDetail: FC = () => {
  const history = useHistory();
  const { actionID } = useParams<{ [key: string]: string }>();
  const { search } = useLocation();

  const {
    selectedAction,
    updateActionDetail,
    loadingActionDetail,
    resetAction,
  } = useActions();

  useEffect(() => {
    if (actionID) updateActionDetail(actionID);

    return resetAction;
  }, [actionID]);

  return (
    <Panel
      onClose={() => {
        history.push(`/admin/actions${search}`);
      }}
      position="right"
      size={450}
      visible={!!actionID}
    >
      <LoadingComponent
        className={styles.container}
        loading={loadingActionDetail}
      >
        <NameAndAddress
          borderless
          showLinkToHome
          homeSnippet={selectedAction?.home_snippet}
        />
        <Divider style={{ marginBottom: "12px", marginTop: "0px" }} />
        <Box paddingTop={4} css={{ position: "relative" }}>
          {selectedAction?.project_snippet && (
            <Heading width="75%" fontWeight="bold" marginBottom={3}>
              {selectedAction?.project_snippet.name}
            </Heading>
          )}
          <Flex>
            <Flex width="50%">
              <SubSection
                title="Action created"
                flexDirection="column"
                marginTop={4}
              >
                {moment(selectedAction?.created_at).format("MM/DD")}
              </SubSection>
            </Flex>
          </Flex>
          <SubSection title="Description" flexDirection="column" marginTop={4}>
            {selectedAction?.project_snippet?.details}
          </SubSection>
          {selectedAction?.project_snippet && (
            <ActionLink
              handleOnClick={() =>
                window.open(
                  `/admin/projects/${selectedAction?.project_snippet?.uid}`
                )
              }
              text="View project"
              overridingStyles={{
                marginTop: 16,
              }}
            />
          )}
        </Box>
      </LoadingComponent>
    </Panel>
  );
};

export default ActionDetail;
