/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, FC } from "react";
import { Button, Checkbox, Form, message } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Heading, Text } from "rebass";
import theme from "theme/theme.main";

import { useUser } from "providers/UserProvider";
import { updateUser } from "api/users";

import Agreement from "./components/Agreement";

import styles from "./styles.module.scss";

const Terms: FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const loc = useLocation();
  const defaultFormValues = {
    termsOfService: false,
  };
  const [readTOS, setReadTOS] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const { user, setUser } = useUser();

  const handleTermsScroll = (event: any) => {
    const SCROLL_PADDING = 200;
    const readTermsOfService =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + SCROLL_PADDING;
    if (readTermsOfService) setReadTOS(true);
  };

  const handleSubmit = () => {
    if (user && acceptedTerms) {
      updateUser({ accepted_terms_of_use: true }, user.uid)
        .then((resp) => {
          setUser(resp);
          message.success("Accepted terms of service");
          if (!user.has_onboarded) {
            history.push(`/vendor/onboarding${loc.search}`);
          } else {
            history.push(`/vendor/success${loc.search}`);
          }
        })
        .catch(() => {
          message.error(
            "An error occurred, please check for errors and retry."
          );
        });
    }
  };

  return (
    <>
      <Heading
        fontSize={5}
        lineHeight="1.3"
        color={theme.colors.success}
        fontWeight={500}
        marginTop={6}
        marginLeft="auto"
        marginRight="auto"
      >
        Step 5 of 5
      </Heading>
      <div className={styles.terms}>
        <Heading
          fontSize="32px"
          lineHeight="1.3"
          color={theme.colors.success}
          fontWeight={500}
          mb={5}
        >
          Terms of Service
        </Heading>
        <Form
          form={form}
          initialValues={defaultFormValues}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            padding={4}
            bg="background"
            height="200px"
            mt={3}
            overflowY="auto"
            fontSize="10px"
            color="chamomile05"
            css={{
              border: "1px solid #EDEAE4",
            }}
            onScroll={handleTermsScroll}
          >
            <Agreement />
          </Box>
          <Text textAlign="center" mt={3} color="primary" fontWeight="bold">
            Please scroll to the bottom to accept Terms of Service
          </Text>
          <Form.Item
            name="termsOfService"
            style={{ marginTop: "8px", marginBottom: 0 }}
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("Accept Terms of Service")),
              },
            ]}
          >
            <Checkbox
              name="termsOfService"
              disabled={!readTOS}
              style={{
                color: `${
                  readTOS ? theme.colors.primary : theme.colors.chamomile05
                }`,
              }}
              onChange={(value) => {
                form.setFieldsValue({ termsOfService: value.target.checked });
                setAcceptedTerms(value.target.checked);
              }}
            >
              I accept and agree to the Service Provider Agreement
            </Checkbox>
          </Form.Item>
          <Button
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then(() => handleSubmit())
                .catch(() => message.error("Form could not submit"));
            }}
            style={{
              marginTop: "32px",
              width: "250px",
              alignSelf: "center",
            }}
          >
            Accept terms
          </Button>
        </Form>
      </div>
    </>
  );
};

export default Terms;
