import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { resetPasswordConfirm } from "api/auth";
import Label from "components/Label";
import Form from "components/Form";
import Button from "components/Button";
import { minLength, required, sameInput } from "utils/form";
import useAsync from "hooks/use-async";
import { getError } from "utils/errors";

import { toast } from "react-hot-toast";
import styles from "./styles.module.scss";

interface Props {
  nextStep(): void;
}

const SetPassword: FC<Props> = ({ nextStep }) => {
  const { uid, token } = useParams<{ [key: string]: string }>();

  const [submit, submitting] = useAsync(
    async ({ password, confirm_password: confirmPassword }) => {
      await resetPasswordConfirm(uid, token, password, confirmPassword)
        .then(async () => {
          toast.success("Correctly set new password!");
          nextStep();
        })
        .catch((error) =>
          toast.error(
            getError(error.response.data, "An unexpected error has occurred")
          )
        );
    }
  );

  return (
    <div className={styles.container}>
      <Label className={styles.title} type="h5-centered" color="primary">
        Welcome to Humming Homes
      </Label>
      <Label type="h5" color="primary">
        Set Password
      </Label>
      <Form
        className={styles.form}
        onSubmit={submit}
        name="password-reset"
        fields={[
          {
            type: "password",
            placeholder: "Password",
            id: "password",
            validate: [
              required("This field is required."),
              minLength(8, "Passwords must contain at least 8 characters"),
            ],
          },
          {
            type: "password",
            placeholder: "Confirm Password",
            id: "confirm_password",
            validate: [required("This field is required.")],
            dependencies: [
              {
                id: "password",
                validate: [sameInput("Passwords must match")],
              },
            ],
          },
        ]}
      >
        {({ handleSubmit, invalid }) => (
          <Button
            disabled={invalid}
            className={styles.submit}
            onClick={handleSubmit}
            loading={submitting}
          >
            Set Password
          </Button>
        )}
      </Form>
    </div>
  );
};

export default SetPassword;
