import React, { FC, useEffect } from "react";
import {
  Redirect,
  RouteComponentProps,
  useHistory,
  useLocation,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import Switch from "components/Switch";

import { useUser } from "providers/UserProvider";
import useAsyncAtom from "hooks/use-async-atom";
import { userFlagsState } from "recoil/users";
import LoadingComponent from "components/LoadingComponent";

import { isEmpty } from "utils/array";
import { fetchHomes } from "api/v2/homes";
import ErrorBoundaryFallback from "components/ErrorBoundaryFallback";
import Topbar from "./components/Topbar";
import Sidebar from "./components/Sidebar";
import UserOnboarding from "./views/Onboarding";
import Invoices from "./views/Invoices";
import Preferences from "./views/Preferences";
import Projects from "./views/Projects";
import styles from "./styles.module.scss";
import {
  PRIVATE_ROUTES,
  RESTRICTED_ROUTES,
  ALL_RESTRICTED_ROUTES,
  FULL_RESTRICTED_ROUTES,
} from "./constants";

const Portal: FC = () => {
  const { user } = useUser();
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    data: userFlags,
    loaded: userDataLoaded,
    inProgress: fetchingUserData,
    refresh: refreshFlags,
  } = useAsyncAtom(userFlagsState, async () => {
    const hasOnboarded = !!user?.has_onboarded;
    const hasAcceptedTerms = !!user?.accepted_terms_of_use;

    const homes = await fetchHomes().then(({ results }) => results);
    const waitingForOps = !isEmpty(homes);

    const hasBeenActivated =
      !isEmpty(homes) &&
      homes.some(({ has_onboarded: homeHasOnboarded }) => homeHasOnboarded);

    return {
      hasOnboarded,
      hasAcceptedTerms,
      hasBeenActivated,
      waitingForOps,
    };
  });

  const { hasBeenActivated, hasAcceptedTerms, hasOnboarded, waitingForOps } =
    userFlags;

  const getRoute = () =>
    hasBeenActivated
      ? hasAcceptedTerms && hasOnboarded
        ? ALL_RESTRICTED_ROUTES.some((route) => pathname.includes(route))
          ? pathname
          : RESTRICTED_ROUTES.invoices
        : PRIVATE_ROUTES.onboarding
      : waitingForOps
      ? PRIVATE_ROUTES.waitingForOps
      : PRIVATE_ROUTES.verify;

  useEffect(() => {
    refreshFlags();
  }, []);

  useEffect(() => {
    if (userDataLoaded && !fetchingUserData) {
      history.push(getRoute());
    }
  }, [userDataLoaded, fetchingUserData]);

  return (
    <LoadingComponent loading={fetchingUserData} className={styles.container}>
      <Sidebar />
      <div className={styles.content} id="humming-content">
        <Topbar />
        <Sentry.ErrorBoundary
          fallback={
            <ErrorBoundaryFallback message="Whoops! Something went wrong, we'll work on fixing it!" />
          }
        >
          <Switch
            routes={[
              {
                path: PRIVATE_ROUTES.onboarding,
                Component: UserOnboarding,
                checkFor: !hasAcceptedTerms || !hasOnboarded || waitingForOps,
              },
              {
                path: FULL_RESTRICTED_ROUTES.invoices,
                Component: Invoices,
                checkFor: hasBeenActivated,
              },
              {
                path: RESTRICTED_ROUTES.preferences,
                Component: Preferences,
                checkFor: hasBeenActivated,
              },
              {
                path: RESTRICTED_ROUTES.projects,
                Component: Projects,
                checkFor: hasBeenActivated,
              },
              {
                path: "*",
                render: ({ location }: RouteComponentProps) => (
                  <Redirect
                    to={{
                      pathname: RESTRICTED_ROUTES.invoices,
                      state: { from: location },
                    }}
                  />
                ),
              },
            ]}
          />
        </Sentry.ErrorBoundary>
      </div>
    </LoadingComponent>
  );
};

export default Portal;
