import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { globalParamsState } from "hooks/use-global-params/atoms";

const useCallbackOnURLChange = (
  config: {
    queryParamID: string;
    urlParamID: string;
  },
  currentValue: string | undefined,
  callback: (value: string) => void
) => {
  const { queryParamID, urlParamID } = config;
  const { search } = useLocation();
  const globalParams = useRecoilValue(globalParamsState);

  const queryId = new URLSearchParams(search).get(queryParamID);
  const paramId = globalParams[urlParamID];

  useEffect(() => {
    if (
      (queryId && queryId !== currentValue) ||
      (paramId && paramId !== currentValue)
    )
      callback(queryId || paramId);
  }, [queryId, paramId]);
};

export default useCallbackOnURLChange;
