/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { useRecoilState, RecoilState } from "recoil";
import { loadingState, loadedState } from "./atoms";

const useAsyncAtom = <T>(
  atom: RecoilState<T>,
  asyncRequest: (...params: any[]) => Promise<T>,
  config?: {
    failureCallback?: (error: string) => void;
  }
): {
  data: T;
  refresh: (...params: any[]) => void;
  loaded: boolean;
  inProgress: boolean;
  error: string;
  overrideState: (V: T) => void;
} => {
  const { failureCallback } = config || {};

  const [inProgress, setInProgress] = useRecoilState(loadingState(atom.key));
  const [loaded, setLoaded] = useRecoilState(loadedState(atom.key));
  const [error, setError] = useState<string>("");
  const [data, setData] = useRecoilState(atom);

  const refresh = (...params: any[]) => {
    setLoaded(true);
    setInProgress(true);
    asyncRequest(...params)
      .then((newData: T) => {
        setData(newData);
        setInProgress(false);
      })
      .catch((newError: string) => {
        setError(newError);
        failureCallback?.(error);
      })
      .finally(() => setInProgress(false));
  };

  return {
    data,
    refresh,
    loaded,
    inProgress,
    error,
    overrideState: setData,
  };
};

export default useAsyncAtom;
