import Button from "components/Button";
import Label from "components/Label";
import React, { FC, ReactNode } from "react";
import { MdOutlineAdd as AddIcon } from "react-icons/md";

import styles from "./styles.module.scss";

interface Props {
  title: string;
  handleAdd?(): void;
  tag?: ReactNode;
  className?: string;
}

const ItemHeader: FC<Props> = ({ title, handleAdd, tag, className }) => (
  <div className={`${styles.header} ${className}`}>
    <div className={styles.title}>
      {tag}
      <Label type="h7">{title}</Label>
    </div>
    <div className={styles.actions}>
      {handleAdd && (
        <Button
          className={styles.iconButton}
          variant="icon"
          stopPropagation
          onClick={handleAdd}
          Icon={<AddIcon className={styles.icon} />}
        />
      )}
    </div>
  </div>
);

ItemHeader.defaultProps = {
  className: undefined,
  handleAdd: undefined,
  tag: null,
};

export default ItemHeader;
