import { Button } from "antd";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Flex, Heading, Text } from "rebass";
import styled from "styled-components";

import { updateUser } from "api/users";
import { useUser } from "providers/UserProvider";
import Background from "svg/BackgroundEllipse";
import theme from "theme/theme.main";

const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 0;
  background-color: white;
`;

const Success = () => {
  const history = useHistory();
  const loc = useLocation();
  const { user, setUser } = useUser();
  const { vendorPaymentsV2 } = useFlags();
  const [loading, setLoading] = useState(false);
  const [successText, setSuccessText] = useState("");

  useEffect(() => {
    if (vendorPaymentsV2 && loc.search.includes("skipped_bank_info")) {
      setSuccessText(
        "You will be able to receive payment from Humming Homes via check. To accelerate payment using ACH, go to 'My Preferences' to add a bank account."
      );
    } else {
      setSuccessText(
        "Your bank account has been successfully linked and your Humming Homes sign-up is complete. Click below to see your invoices."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const updatedUser = user;

    if (updatedUser && updatedUser.uid) {
      updatedUser.has_onboarded = true;
      setUser(updatedUser);
      if (!vendorPaymentsV2)
        await updateUser({ has_onboarded: true }, updatedUser.uid);
      setLoading(false);
      history.push("/vendor");
    }
  };

  return (
    <>
      <BackgroundContainer>
        <Background />
      </BackgroundContainer>
      <Flex
        margin={["4rem 1rem 0", "4rem auto 0"]}
        padding={3}
        width={["auto", "65%"]}
        maxWidth="560px"
        flexDirection="column"
        alignItems="center"
        style={{ zIndex: 1 }}
      >
        <Heading
          fontSize="32px"
          lineHeight="1.3"
          color={theme.colors.success}
          fontWeight={500}
          textAlign="center"
          mb={2}
        >
          Success!
        </Heading>
        <Text fontSize="16px" textAlign="center" color="primary05">
          {successText}
        </Text>
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={loading}
          style={{
            marginTop: "36px",
            width: "250px",
            textTransform: "uppercase",
            alignSelf: "center",
          }}
        >
          {vendorPaymentsV2 ? "Go to Dashboard" : "Go to Invoices"}
        </Button>
      </Flex>
    </>
  );
};

export default Success;
