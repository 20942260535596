/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createLineItem,
  deleteLineItem,
  updateInvoice,
  updateLineItem,
} from "api/admin";
import Button from "components/Button";
import Form from "components/Form";
import { LineItem } from "components/LineItemsField/types";
import Modal from "components/Modal";
import useAsync from "hooks/use-async";
import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Invoice, InvoiceClient } from "types/models";
import { required } from "utils/form";

import Label from "components/Label";
import useProjects from "hooks/use-projects";
import { fetchTaxRates } from "api/taxes";
import useAsyncState from "hooks/use-async-state";
import styles from "./styles.module.scss";

type Props = {
  visible: boolean;
  handleClose(): void;
  invoice: Invoice;
};

const EditInvoice: FC<Props> = ({ visible, handleClose, invoice }) => {
  const { updateProjectJobs } = useProjects();
  const { projectID } = useParams<{ [key: string]: string }>();

  const {
    data: taxRates,
    inProgress: fetchingTaxes,
    refresh: getTaxes,
  } = useAsyncState([], () => fetchTaxRates());

  useEffect(() => {
    getTaxes();
  }, []);

  const formatInvoice = (invoiceToFormat: any): InvoiceClient => {
    const { vendor_invoice_number, vendors_invoice_reception_date, tax } =
      invoiceToFormat;

    return {
      ...invoiceToFormat,
      default_tax_rates: tax ? [tax] : [],
      metadata: {
        vendor_invoice_number,
        vendors_invoice_reception_date,
      },
    };
  };

  const [handleUpdateInvoice, updatingInvoice] = useAsync(
    async (newInvoice: InvoiceClient) => {
      const { lines, ...informationToUpdate } = newInvoice;

      await updateInvoiceItems(lines);

      await updateInvoice(invoice.id, formatInvoice(informationToUpdate)).then(
        () => updateProjectJobs({ projectUID: projectID })
      );
    }
  );

  const updateInvoiceItems = async (newLines: LineItem[]) => {
    const { invoiceitems } = invoice;

    const oldIds = invoiceitems.map(({ id }) => id);
    const newIds = newLines.map(({ id }) => id);

    const newInvoiceItems = newLines.filter(({ id }) => !oldIds.includes(id));
    const deletedInvoiceItems = invoiceitems.filter(
      ({ id }) => !newIds.includes(id)
    );

    const updatedInvoiceItems = newLines.filter((newLine) => {
      const oldLine = invoiceitems.find(({ id }) => newLine.id === id);

      return !!oldLine;
    });

    await Promise.all(
      updatedInvoiceItems.map((item) => updateLineItem(invoice.id, item))
    );

    await Promise.all(
      newInvoiceItems.map((item) => createLineItem(invoice.id, item))
    );

    await Promise.all(
      deletedInvoiceItems.map((item) => deleteLineItem(invoice.id, item.id))
    );
  };

  const loading = updatingInvoice || fetchingTaxes;

  return (
    <Modal
      contentClassName={styles.content}
      visible={visible}
      handleClose={() => {
        handleClose();
      }}
    >
      <Label type="h5-centered" color="primary">
        {`Editing invoice ${invoice.number}`}
      </Label>
      <Form
        disabled={loading}
        onSubmit={(newInvoice: InvoiceClient) => {
          handleUpdateInvoice(newInvoice);
        }}
        initialValues={{
          ...invoice,
          lines: invoice.invoiceitems,
          tax: invoice.default_tax_rates[0]?.id,
        }}
        fields={[
          {
            id: "invoice-detail",
            placeholder: "Invoice Detail",
            type: "label",
          },
          {
            id: "description",
            type: "area",
            placeholder: "Please describe the invoice",
            title: "Customer Memo",
            validate: [required("This field is required")],
            required: true,
          },
          {
            id: "vendor-invoice-detail-label",
            placeholder: "Vendor Invoice Detail",
            type: "label",
          },
          {
            title: "Invoice number",
            placeholder: "Input invoice number",
            type: "text",
            id: "vendor_invoice_number",
          },
          {
            title: "Received invoice on",
            type: "date",
            id: "vendors_invoice_reception_date",
            validate: [required("Field is required")],
            required: true,
            props: {
              futureDates: false,
              pastDates: true,
              format: "YYYY-MM-DD",
              valueFormat: "YYYY-MM-DD",
            },
          },
          {
            id: "invoice-charges-label",
            placeholder: "Invoice Charges",
            type: "label",
          },
          {
            id: "lines",
            type: "line-items",
            title: "Line items",
            validate: [required("This field is required!")],
          },
          {
            title: "Tax",
            type: "select",
            id: "tax",
            options: taxRates
              .filter(({ active }) => active)
              .map(({ description, id: stripeID }) => ({
                value: `${stripeID}`,
                label: description,
                key: `${stripeID}`,
              })),
          },
        ]}
        name="edit-invoice"
      >
        {({ invalid, handleSubmit }) => (
          <Button
            loading={loading}
            className={styles.submit}
            disabled={invalid}
            onClick={handleSubmit}
          >
            Save Invoice
          </Button>
        )}
      </Form>
    </Modal>
  );
};

export default EditInvoice;
