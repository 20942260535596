import Label from "components/Label";
import React, { FC } from "react";

import { Project } from "types/models";
import { displayTimeSince } from "utils/datetime";

import styles from "./styles.module.scss";

type Props = {
  project: Project;
};

const Header: FC<Props> = ({ project }) => {
  const createdAt = displayTimeSince(project.created_at);

  return (
    <div className={styles.container}>
      <Label ellipsis type="bold-italic">
        {project.name}
      </Label>
      <Label type="bold-italic">
        {createdAt ? `Created: ${createdAt}` : ""}
      </Label>
    </div>
  );
};

export default Header;
