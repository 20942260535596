import React, { FC, useEffect, useMemo } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import * as Sentry from "@sentry/react";

import Switch from "components/Switch";
import { fetchActiveUsers } from "api/users";
import useAsyncAtom from "hooks/use-async-atom";
import { potentialAssigneeList } from "recoil/users";
import LoadingComponent from "components/LoadingComponent";

import ErrorBoundaryFallback from "components/ErrorBoundaryFallback";
import { UserRole } from "types/models";
import {
  ActiveProjectStatus,
  AllProjectStatus,
  ArchivedProjectStatus,
  CompletedProjectStatus,
  RequestedProjectStatus,
} from "constants/statuses";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import Homes from "./views/Homes";
import ActionCenter from "./views/ActionCenter";
import Projects from "./views/Projects";
import styles from "./styles.module.scss";
import Manage from "./views/Manage";

const Admin: FC = () => {
  const routes = useMemo(
    () => [
      { path: "/admin/actions", Component: ActionCenter },
      { path: "/admin/manage", Component: Manage },
      {
        path: "/admin/all-projects",
        Component: Projects,
        statuses: AllProjectStatus,
        title: "All Projects",
      },
      {
        path: "/admin/requests",
        Component: Projects,
        statuses: RequestedProjectStatus,
        title: "Requested Projects",
      },
      {
        path: "/admin/projects",
        Component: Projects,
        statuses: ActiveProjectStatus,
        title: "Active Projects",
      },
      {
        path: "/admin/completed-projects",
        Component: Projects,
        statuses: CompletedProjectStatus,
        title: "Completed Projects",
      },
      {
        path: "/admin/archived-projects",
        Component: Projects,
        statuses: ArchivedProjectStatus,
        title: "Archived Projects",
      },
      { path: "/admin/homes", Component: Homes },
      {
        path: "*",
        render: ({ location }: RouteComponentProps) => (
          <Redirect
            to={{
              pathname: "/admin/projects",
              state: { from: location },
            }}
          />
        ),
      },
    ],
    []
  );

  const { refresh: getPotentialAssignees, inProgress: fetchingAssignees } =
    useAsyncAtom(potentialAssigneeList, () =>
      fetchActiveUsers({
        role: UserRole.HomeCoordinator,
        page_size: "100",
      }).then(({ results }) => results)
    );

  useEffect(() => {
    getPotentialAssignees();
  }, []);

  return (
    <div className={styles.container}>
      <Sidebar />
      <LoadingComponent
        loading={fetchingAssignees}
        className={styles.content}
        id="humming-content"
      >
        <Topbar />
        <Sentry.ErrorBoundary
          fallback={
            <ErrorBoundaryFallback message="Whoops! Something went wrong, we'll work on fixing it!" />
          }
        >
          <Switch routes={routes} />
        </Sentry.ErrorBoundary>
      </LoadingComponent>
    </div>
  );
};

export default Admin;
