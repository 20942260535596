const LIGHTEN_COLORS = {
  "lighten:10": "#ffffff10",
  "lighten:20": "#ffffff20",
  "lighten:50": "#ffffff50",
  "lighten:80": "#ffffff80",
};

const DARKEN_COLORS = {
  "darken:10": "#00000010",
  "darken:20": "#00000020",
  "darken:50": "#00000050",
  "darken:80": "#00000080",
};

const BACKGROUND_COLORS = {
  background: "#F9F7F5",
  panelBackground: "#F9F9F9",
  lightBlueBackground: "#EAEDF0",
  darkerPanelBackground: "#F0EDED",
  darkBlue: "#27355B",
};

const COMMON_COLORS = {
  white: "#FFF",
};

const colors = {
  transparent: "#00000000",
  black: "#484848",
  primary60: "#4C6587",
  primary40: "#9cabb5",
  primary20: "#ECF0F8",
  primary80: "#415674",
  primary: "#354760",
  primary05: "#232C37",
  primary04: "#354760",
  primary03: "#5D7BA4",
  primary02: "#8499B6",
  primary01: "#8C97A6",
  secondary: "#F1EDE8",
  chamomile01: "#F1EDE8",
  chamomile02: "#E9E4DE",
  chamomile03: "#CCC7C0",
  chamomile04: "#B8B5B0",
  chamomile05: "#91918F",
  chamomile06: "#53554D",
  tertiary: "#EDEAE4",
  error: "#974B13",
  errorRed: "#C22F28",
  sandstone03: "#974B13",
  sandstone02: "#BD5D17",
  sandstone01: "#E37A2D",

  semanticNegative: "#B93C3C",
  semanticCritical: "#DFBF19",
  semanticPositive: "#0D9944",
  semanticInactive: "#0D5BA7",

  pastel01: "#BAE1FF",
  pastel02: "#FFFFB5",
  pastel03: "#FFC8A2",
  pastel04: "#FF968A",
  pastel05: "#72E797",
  pastel06: "#ECD5E3",
  pastel07: "#FDDF7E",
  pastel08: "#CCFFCC",

  gray: "#626262",
  gray75: "#7a7a7a",
  gray50: "#DBDBDB",
  gray25: "#f0f0f0",

  warning: "#eba157",
  success: "#0B894A",
  text: "#1D1F23",
  heading: "#161D2E",
  inProgress: "#e8e887",
  "text:muted": "232C37", // opacity: 0.2
  "text:inverse": "#232C37",
  // BACKGROUND COLORS
  ...BACKGROUND_COLORS,
  // Opacity
  ...LIGHTEN_COLORS,
  ...DARKEN_COLORS,
  ...COMMON_COLORS,
};

const space = [0, 2, 4, 8, 16, 32, 64, 128, 256];
const sizes = {
  avatar: 36,
};
const radii = {
  default: 5,
  circle: 99999,
};
const breakpoints = ["40em", "52em", "64em"];
const fontSizes = [12, 14, 18, 20, 24, 32, 40, 48, 64];

const BASE_FONT = `"Noto Serif", -apple-system, BlinkMacSystemFont,
"Roboto", "Droid Sans", "Helvetica Neue", sans-serif;`;

const fonts = {
  body: BASE_FONT,
  heading: BASE_FONT,
};

const fontWeights = {
  light: 300,
  body: 400,
  semibold: 500,
  bold: 600,
  heading: 700,
};

const lineHeights = {
  body: 1.5,
  heading: 1.25,
};

const shadows = {
  elevation1:
    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  elevation2:
    "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  elevation3:
    "2px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
  elevation4:
    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  elevation5:
    "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
  elevation6:
    "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
  elevation7:
    "0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12)",
  elevation8:
    "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
  reverseElevation1:
    "2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 1px 0px rgba(0,0,0,0.14), 1px 0px 3px 0px rgba(0,0,0,0.12)",
  reverseElevation2:
    "3px 0px 1px -2px rgba(0,0,0,0.2), 2px 0px 2px 0px rgba(0,0,0,0.14), 1px 0px 5px 0px rgba(0,0,0,0.12)",
  reverseElevation3:
    "3px 2px 3px -2px rgba(0,0,0,0.2), 3px 0px 4px 0px rgba(0,0,0,0.14), 1px 0px 8px 0px rgba(0,0,0,0.12)",
  reverseElevation4:
    "2px 0px 4px -1px rgba(0,0,0,0.2), 4px 0px 5px 0px rgba(0,0,0,0.14), 1px 0px 10px 0px rgba(0,0,0,0.12)",
};

const InputBase = {
  fontSize: "2",
  p: "4",
  borderRadius: "default",
  transition: "all .1s",
};

const forms = {
  input: {
    ...InputBase,
  },
};

const links = {
  primary: {
    color: colors.secondary,
  },
};

const ButtonBase = {
  fontSize: "2",
  p: "4",
  borderRadius: "default",
  transition: "all .1s",
};
const buttons = {
  primary: {
    ...ButtonBase,
    color: colors.text,
    backgroundColor: colors.primary,
    border: `1px solid ${colors.primary}`,

    ":hover": {
      cursor: "pointer",
    },
    ":focus": {
      outline: "none",
    },
    ":active": {
      backgroundColor: "primary:darkened",
    },
  },

  outline: {
    ...ButtonBase,
    color: colors.primary,
    bg: "transparent",
    border: `1px solid ${colors.primary}`,

    ":hover": {
      bg: "primary",
      color: "white",
      transition: "all .1s",
      cursor: "pointer",
    },
    ":focus": {
      outline: "none",
    },
    ":active": {
      backgroundColor: "primary:darkened",
    },
  },

  disabled: {
    ...ButtonBase,
    bg: "transparent",
    border: `1px solid ${colors.tertiary}`,
    color: "tertiary",
    opacity: 0.5,
    ":hover": {
      cursor: "not-allowed",
    },
  },

  success: {
    ...ButtonBase,
    color: colors.success,
    backgroundColor: colors["lighten:10"],
    border: `1px solid ${colors.success}`,

    ":hover": {
      cursor: "pointer",
    },
    ":focus": {
      outline: "none",
    },
    ":active": {
      backgroundColor: colors["lighten:20"],
    },
  },
};

const borders = {
  thin: "1px solid #ffffff55",
  default: `1px solid ${colors.primary}`,
  thick: "4px solid",
};

const variants = {
  avatar: {
    width: "avatar",
    height: "avatar",
    borderRadius: "circle",
  },
  panel: {
    border: "thin",
    backgroundColor: "background",
    p: "3",
    boxShadow: "elevation1",
  },
  card: {
    p: 4,
    bg: "background",
    boxShadow: "elevation2",
    border: "default",
    borderRadius: "default",
  },
  unstyledList: {
    padding: 0,
    listStyle: "none",
  },
};
const text = {
  muted: {
    color: colors["text:muted"],
  },
  warning: {
    color: colors.warning,
  },
};

const topbar = {
  primary: {
    backgroundColor: colors.white,
    logoColor: colors.primary,
  },
  secondary: {
    backgroundColor: colors.background,
    logoColor: colors.primary,
  },
};

const button = {
  xxlargePadding: "10px 45px",
  xlargePadding: "10px 35px",
  largePadding: "10px 15px",
  mediumPadding: "5px 15px",
  smallPadding: "5px",
  iconPadding: "5px",
  primary: {
    backgroundColor: colors.primary,
    borderRadius: 5,
    color: colors.white,
    hover: {
      backgroundColor: colors.primary80,
    },
    disabled: {
      borderColor: colors.gray,
      backgroundColor: colors.gray25,
      color: colors.gray,
    },
  },
  text: {
    backgroundColor: colors.transparent,
    borderRadius: 5,
    color: colors.primary,
    hover: {
      backgroundColor: colors.transparent,
    },
    disabled: {
      backgroundColor: colors.transparent,
      color: colors.gray,
    },
  },
  secondary: {
    backgroundColor: colors.white,
    borderRadius: 5,
    color: colors.primary,
    hover: {
      backgroundColor: colors.gray25,
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray,
    },
  },
  icon: {
    backgroundColor: colors.transparent,
    borderRadius: 5,
    color: colors.primary,
    hover: {
      backgroundColor: colors.gray25,
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray,
    },
  },
  "secondary-outlined": {
    backgroundColor: colors.white,
    borderRadius: 5,
    borderColor: colors.primary,
    color: colors.primary,
    hover: {
      borderColor: colors.primary,
      backgroundColor: colors.gray25,
    },
    disabled: {
      borderColor: colors.gray,
      backgroundColor: colors.gray25,
      color: colors.gray,
    },
  },
  negative: {
    backgroundColor: colors.semanticNegative,
    borderRadius: 5,
    color: colors.white,
    hover: {
      borderColor: `${colors.semanticNegative}95`,
      backgroundColor: `${colors.semanticNegative}95`,
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray,
    },
  },
  "negative-outlined": {
    borderColor: colors.semanticNegative,
    backgroundColor: colors.white,
    borderRadius: 5,
    color: colors.semanticNegative,
    hover: {
      borderColor: `${colors.semanticNegative}95`,
      backgroundColor: `${colors.gray25}`,
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray,
    },
  },
  positive: {
    backgroundColor: colors.semanticPositive,
    borderRadius: 5,
    color: colors.white,
    hover: {
      borderColor: `${colors.semanticPositive}95`,
      backgroundColor: `${colors.semanticPositive}95`,
    },
    disabled: {
      backgroundColor: colors.gray25,
      color: colors.gray,
    },
  },
};

const label = {
  centered: {
    textAlign: "center",
  },
  uppercase: {
    textTransform: "uppercase",
    letterSpacing: "1px",
  },
  capitalized: {
    textTransform: "capitalize",
  },
  h1: {
    fontFamily: "Noto Serif",
    fontSize: "64px",
    fontWeight: fontWeights.light,
  },
  h2: {
    fontFamily: "Noto Serif",
    fontSize: "48px",
    fontWeight: fontWeights.light,
  },
  h3: {
    fontFamily: "Noto Serif",
    fontSize: "42px",
    fontWeight: fontWeights.body,
  },
  h3Responsive: {
    fontFamily: "Noto Serif",
    fontSize: "28px",
    fontWeight: fontWeights.body,
  },
  h4: {
    fontFamily: "Noto Serif",
    fontSize: "32px",
    fontWeight: fontWeights.body,
  },
  h5: {
    fontFamily: "Noto Serif",
    fontSize: "24px",
    fontWeight: fontWeights.body,
  },
  h6: {
    fontFamily: "Noto Serif",
    fontSize: "20px",
    fontWeight: fontWeights.body,
  },
  h7: {
    fontFamily: "Noto Serif",
    fontSize: "16px",
    fontWeight: fontWeights.body,
  },
  s1: {
    fontSize: "16px",
    fontWeight: fontWeights.body,
  },
  s2: {
    fontSize: "14px",
    fontWeight: fontWeights.body,
  },
  s3: {
    fontSize: "12px",
    fontWeight: fontWeights.body,
  },
  button: {
    fontSizes: "14px",
    fontWeight: fontWeights.bold,
  },
  colors: {
    primary: colors.primary,
    white: colors.white,
    transparent: colors.transparent,
    gray: colors.gray,
  },
  italic: {
    fontStyle: "italic",
  },
  light: {
    fontWeight: fontWeights.light,
  },
  bold: {
    fontWeight: fontWeights.bold,
  },
  semibold: {
    fontWeight: fontWeights.semibold,
  },
  negative: {
    color: colors.semanticNegative,
  },
  critical: {
    color: colors.semanticCritical,
  },
  positive: {
    color: colors.semanticPositive,
  },
  neutral: {
    color: colors.semanticInactive,
  },
};

const alert = {
  info: {
    backgroundColor: colors.primary03,
  },
  success: {
    backgroundColor: colors.success,
  },
  error: {
    backgroundColor: colors.errorRed,
  },
};

const theme = {
  alert,
  label,
  breakpoints,
  fontSizes,
  colors,
  space,
  sizes,
  radii,
  fonts,
  borders,
  fontWeights,
  lineHeights,
  shadows,
  variants,
  text,
  buttons,
  forms,
  links,
  button,
  topbar,
} as const;

export default theme;
