import React, { FC } from "react";
import Label from "components/Label";

import styles from "./styles.module.scss";

const PageNotFound: FC = () => (
  <div className={styles.container}>
    <Label color="primary" type="h4-centered">
      404 - Page not found
    </Label>
  </div>
);

export default PageNotFound;
