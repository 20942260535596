/* eslint-disable react/no-array-index-key */
import React, { FC } from "react";
import { Text } from "rebass";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import useProjects from "hooks/use-projects";
import { isEmpty } from "utils/array";
import LoadingComponent from "components/LoadingComponent";
import { useParams } from "react-router-dom";
import NewNote from "./components/NewNote";
import NoteDetail from "./components/NoteDetail";

import styles from "./styles.module.scss";

const NotesList: FC = () => {
  const { projectNotes, loadingProjectNotes } = useProjects();

  const { noteID } = useParams<{ [key: string]: string }>();

  return (
    <LoadingComponent
      loading={loadingProjectNotes}
      className={styles.container}
    >
      <SimpleBar className={styles.notes}>
        {!isEmpty(projectNotes) ? (
          projectNotes.map((note, index) => {
            return (
              <NoteDetail
                highlight={noteID === note.uid}
                note={note}
                key={`${index}-NOTE`}
              />
            );
          })
        ) : (
          <Text marginBottom={4}>No notes on project</Text>
        )}
      </SimpleBar>
      <NewNote />
    </LoadingComponent>
  );
};
export default NotesList;
