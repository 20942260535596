import { throttle } from "lodash";
import { useLayoutEffect, useState } from "react";

const useMousePosition = () => {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    const handleMouseReposition = throttle((event) => {
      setCoordinates({
        x: event.x,
        y: event.y,
      });
    }, 50);

    window.addEventListener("mousemove", handleMouseReposition);

    window.dispatchEvent(new Event("mousemove"));

    return () => {
      window.removeEventListener("mousemove", handleMouseReposition);
    };
  }, []);

  return coordinates;
};

export default useMousePosition;
