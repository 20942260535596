/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Label from "components/Label";
import React, { FC } from "react";
import { Project } from "types/models";
import { HiChevronRight as ChevronRight } from "react-icons/hi";

import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

type Props = {
  project: Project;
};

const ProjectItem: FC<Props> = ({ project }) => {
  const history = useHistory();

  const { name, details, uid } = project;

  return (
    <div
      onClick={() => history.push(`/admin/projects/${uid}`)}
      className={styles.container}
    >
      <div className={styles.information}>
        <Label color="white">{name}</Label>
        <Label color="white">{details}</Label>
      </div>
      <ChevronRight className={styles.chevron} />
    </div>
  );
};

export default ProjectItem;
