import React, { FC } from "react";

const Icon: FC<{
  width?: string | number;
  height?: string | number;
  color?: string;
}> = ({ width = "28px", height = "21px", color = "#000" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 200 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6"
        y="6"
        width="188"
        height="138"
        rx="10"
        stroke={color}
        strokeWidth="12"
        strokeLinecap="round"
      />
      <line x1="91" x2="91" y2="150" stroke={color} strokeWidth="12" />
      <line x1="24" y1="36" x2="69" y2="36" stroke={color} strokeWidth="8" />
      <line x1="24" y1="62" x2="69" y2="62" stroke={color} strokeWidth="8" />
      <line x1="24" y1="85" x2="52" y2="85" stroke={color} strokeWidth="8" />
    </svg>
  );
};

Icon.defaultProps = { width: "28px", height: "21px", color: "#000" };

export default Icon;
