import Button from "components/Button";
import React, { FC } from "react";

import useBackdrop from "hooks/use-backdrop";
import { RecoilState, useRecoilState } from "recoil";
import Form from "components/Form";
import { Values } from "components/FormConstructor/types";
import { isEmpty } from "utils/array";
import { FilterOption } from "../../../../types";
import styles from "./styles.module.scss";

type Props = {
  options: FilterOption[];
  name: string;
  onClose(): void;
  disabled: boolean;
  filtersState: RecoilState<{ [key: string]: string | undefined }>;
  width: number;
};

const SetFilter: FC<Props> = ({
  name,
  options,
  onClose,
  disabled,
  filtersState,
  width,
}) => {
  const [tableFilters, setTableFilters] = useRecoilState(filtersState);

  useBackdrop(name, onClose);

  return (
    <Form
      style={{ width }}
      disabled={disabled}
      className={styles.dropdown}
      id={name}
      initialValues={{ [name]: tableFilters[name]?.split(",") }}
      fields={[
        {
          placeholder: name,
          id: name,
          type: "select",
          options,
          props: { mode: "multiple", className: styles.select },
        },
      ]}
      onSubmit={(values: Values) => {
        if (!isEmpty(values[name]))
          setTableFilters({
            ...tableFilters,
            [name]: values[name]?.join(","),
            [`has_${name}`]: undefined,
            page: "1",
          });
        else
          setTableFilters({
            ...tableFilters,
            [name]: undefined,
            [`has_${name}`]: undefined,
            page: "1",
          });
      }}
    >
      {({ handleSubmit, clearForm }) => (
        <div className={styles.actions}>
          <Button size="small" onClick={handleSubmit}>
            Apply
          </Button>
          <Button
            size="small"
            onClick={() => clearForm()}
            variant="secondary-outlined"
          >
            Clear
          </Button>
        </div>
      )}
    </Form>
  );
};

export default SetFilter;
