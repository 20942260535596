import React, { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined as SpinIcon } from "@ant-design/icons";

import styles from "./styles.module.scss";

type Props = {
  className?: string;
  iconClassName?: string;
};

const Spinner: FC<Props> = ({ className, iconClassName }) => {
  return (
    <Spin
      className={`${className}`}
      indicator={
        <SpinIcon className={`${iconClassName} ${styles.iconClassName}`} spin />
      }
    />
  );
};

Spinner.defaultProps = {
  className: undefined,
  iconClassName: undefined,
};

export default Spinner;
