import React, { useEffect, useState, FC, RefObject } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import useComponentDimensions from "../../hooks/use-component-dimensions";
import styles from "./styles.module.scss";
import {
  DEFAULT_ITEM_PADDING,
  DEFAULT_OVERSCAN,
  DEFAULT_EFFECT,
  DEFAULT_ELEMENTS_PER_ROW,
} from "./constants";
import VirtualizedData from "./components/VirtualizedData";
import { VirtualizedListProps } from "./types";

const VirtualizedList: FC<VirtualizedListProps> = ({
  data = [],
  className,
  elementsPerRow = DEFAULT_ELEMENTS_PER_ROW,
  overscan = DEFAULT_OVERSCAN,
  itemRenderer,
  itemPadding = DEFAULT_ITEM_PADDING,
  NoDataComponent,
  effect = DEFAULT_EFFECT,
  reRenderOnChange = [],
  HeaderRenderer = () => null,
  FooterRenderer = () => null,
}) => {
  const {
    height: listViewportHeight,
    width: listViewportWidth,
    ref: listViewportRef,
  } = useComponentDimensions();

  const [reRender, setReRender] = useState(false);

  useEffect(() => {
    setReRender(!reRender);
  }, [...reRenderOnChange]);

  return (
    <div className={`${styles.container} ${className}`}>
      <SimpleBar
        autoHide={false}
        style={{
          height: "100%",
          paddingRight: 20,
          width: "100%",
        }}
        scrollableNodeProps={{
          ref: listViewportRef as unknown as RefObject<SimpleBar>,
        }}
      >
        {data.length === 0 ? (
          NoDataComponent ? (
            <NoDataComponent />
          ) : null
        ) : (
          <VirtualizedData
            HeaderRenderer={HeaderRenderer}
            FooterRenderer={FooterRenderer}
            reRenderOnChange={reRender}
            listViewportHeight={listViewportHeight}
            listViewportWidth={listViewportWidth}
            listViewportRef={listViewportRef}
            data={data}
            elementsPerRow={elementsPerRow}
            overscan={overscan}
            itemRenderer={itemRenderer}
            itemPadding={itemPadding}
            effect={effect}
          />
        )}
      </SimpleBar>
    </div>
  );
};

export default VirtualizedList;
