import Panel from "components/Panel";
import React, { FC, useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";

import Label from "components/Label";

import useHomes from "hooks/use-homes";
import LoadingComponent from "components/LoadingComponent";
import Button from "components/Button";
import { toast } from "react-hot-toast";
import Detail from "./components/Detail";
import HomeForm from "./components/HomeForm";
import styles from "./styles.module.scss";
import Notes from "../Notes";

type Props = {
  updateData(): void;
  loadingData: boolean;
  renderStatic?: boolean;
  className?: string;
};

const HomeDetailPanel: FC<Props> = ({
  updateData,
  loadingData,
  renderStatic,
  className,
}) => {
  const [mounted, setMounted] = useState(false);

  const { homeDetail: home, clearHomeDetail, loadingHomeDetail } = useHomes();

  const [openDetailPanel, setOpenDetailPanel] = useState(false);
  const [tab, setTab] = useState("detail");

  const Wrapper = renderStatic ? LoadingComponent : Panel;

  useEffect(() => {
    if (home) setOpenDetailPanel(true);
    else setOpenDetailPanel(false);
  }, [home]);

  useEffect(() => {
    if (!openDetailPanel) setTab("detail");
  }, [openDetailPanel]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted ? (
    <Wrapper
      loading={loadingHomeDetail || loadingData}
      unmountOnCollapse
      position="right"
      size={500}
      visible={openDetailPanel}
      onClose={() => setOpenDetailPanel(false)}
      onPanelClosed={clearHomeDetail}
      containerClassName={styles.container}
      className={`${styles.divContainer} ${className}`}
    >
      <div className={styles.header}>
        <Label type="h5" color="white">
          Home info
        </Label>
        {!home?.djstripe_subscription_id && (
          <Button
            variant="positive"
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_BROCHURE_BASE}/start/join?hid=${home?.uid}`
              );
              toast.success("Link copied to clipboard!");
            }}
          >
            Get Checkout Link
          </Button>
        )}
      </div>
      <div className={styles.content}>
        <Tabs
          className={styles.tabs}
          classes={{ indicator: styles.indicator }}
          value={tab}
          onChange={(_, newValue) => {
            setTab(newValue);
          }}
        >
          <Tab className={styles.tab} value="detail" label="Home detail" />
          <Tab className={styles.tab} value="notes" label="Home notes" />
          <Tab className={styles.tab} value="form" label="Edit home" />
        </Tabs>
        <div className={styles.tabContent}>
          {tab === "detail" && home && (
            <Detail home={home} loadingData={loadingData} />
          )}
          {tab === "form" && (
            <HomeForm
              updateData={updateData}
              onSubmit={() => {
                setTab("detail");
              }}
            />
          )}
          {tab === "notes" && home && <Notes homeID={home.uid} />}
        </div>
      </div>
    </Wrapper>
  ) : null;
};

HomeDetailPanel.defaultProps = {
  renderStatic: false,
  className: undefined,
};

export default HomeDetailPanel;
