import React, { FC } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { login, fetchStripeCustomer } from "api/auth";
import { useSessionValue } from "providers/SessionProvider";
import { useUser } from "providers/UserProvider";
import Label from "components/Label";
import Form from "components/Form";
import Button from "components/Button";

import { required } from "utils/form";
import useAsync from "hooks/use-async";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import { getError } from "utils/errors";
import Content from "components/Content";
import styles from "./styles.module.scss";

const Login: FC = () => {
  const history = useHistory();
  const notificationDispatcher = useNotificationDispatcher();

  const { setUser, setCustomer } = useUser();
  const { setSessionData } = useSessionValue();

  const loc = useLocation();
  const params = new URLSearchParams(loc.search);
  const redirectUrl = params.get("redirectUrl");

  const [submit, submitting] = useAsync(
    async ({ email, password }: { email: string; password: string }) => {
      await login(email, password)
        .then(async (resp) => {
          const customer = await fetchStripeCustomer();

          setUser(resp.user);
          setCustomer(customer);
          setSessionData(resp);

          if (redirectUrl) {
            history.push(redirectUrl);
          } else {
            history.push("/");
          }
        })
        .catch((requestError) => {
          notificationDispatcher({
            type: "error",
            description: getError(
              requestError.response.data,
              "Couldn't sign in"
            ),
            message: "Something went wrong!",
            duration: 10,
            key: "login-error-notification",
          });
        });
    }
  );

  return (
    <Content className={styles.container}>
      <Label id="title" color="primary" type="h5">
        Welcome back!
      </Label>
      <Form
        className={styles.form}
        fields={[
          {
            type: "text",
            id: "email",
            placeholder: "Email address",
            validate: [required("Please enter your email!")],
          },
          {
            type: "password",
            id: "password",
            validate: [required("Please enter your password!")],
            placeholder: "Password",
          },
        ]}
        name="login"
        onSubmit={submit}
      >
        {({ handleSubmit, invalid }) => (
          <div className={styles.submitActions}>
            <Button
              id="submit-login"
              loading={submitting}
              onClick={handleSubmit}
              disabled={invalid}
            >
              Log in
            </Button>
          </div>
        )}
      </Form>
      <Link className={styles.link} id="sign-up" to="/auth/sign_up">
        Sign up
      </Link>
      <Link className={styles.link} to="/auth/reset">
        Forgot password?
      </Link>
    </Content>
  );
};

export default Login;
