import Label from "components/Label";
import React, { FC } from "react";

import { tagVariants } from "./contants";
import styles from "./styles.module.scss";

interface Props {
  variant: "emergency" | "normal";
}

const EmergencyTag: FC<Props> = ({ variant }) => {
  const { label, labelProps } = tagVariants(variant);

  return (
    <div className={`${styles.container} ${styles[variant]}`}>
      <Label {...labelProps}>{label}</Label>
    </div>
  );
};

export default EmergencyTag;
