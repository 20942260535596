import { Select } from "antd";
import styled from "styled-components";

import theme from "theme/theme.main";

export const CreateNewSelect = styled(Select)`
  &.styled-dropdown #create-new {
    display: none;
  }
`;

export const AddressInfoSelect = styled(Select)`
  &.ant-select {
    .ant-select-selector {
      padding: 8px 18px;
      height: 40px;
      margin-bottom: 8px;
      padding-top: 1px;

      input.ant-select-selection-search-input {
        margin-top: 1px;
        border-bottom: 1px solid rgba(108, 114, 128, 0.2);
      }
    }

    .ant-select-selection-item {
      align-self: center;
      justify-content: center;
      padding-top: 8px;
    }

    .ant-select-selection-search {
      left: 12px;
      right: 12px;
    }

    .ant-select-selection-placeholder {
      opacity: 0.55;
      color: #1d1f23;
    }

    .ant-select-arrow {
      top: 42%;
    }
  }
`;

export const PaymentMethodSelect = styled(Select)`
  &.ant-select {
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 380px;
    }

    .ant-select-arrow {
      right: 22px;
    }
  }
`;

export const MarketSelect = styled(Select)`
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid ${theme.colors.chamomile03};
    border-radius: 4px;
  }
`;
