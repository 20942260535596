import React, { useEffect, useState, FC } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Notes from "views/Admin/components/Notes";
import Tabs from "components/Tabs";
import RoomList from "./components/RoomList";

import styles from "./styles.module.scss";
import ProjectsList from "./components/ProjectsList";

const chooseCurrentTab = (currentURL: string) => {
  if (currentURL.includes("home")) return "home";
  if (currentURL.includes("notes")) return "notes";
  return "home";
};

const HomeSpecifics: FC = () => {
  const { homeID } = useParams<{ [key: string]: string }>();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [currentTab, setCurrentTab] = useState("home");

  useEffect(() => {
    const fetchData = async () => {};

    fetchData();
    setCurrentTab(chooseCurrentTab(pathname));
  }, []);

  return (
    <div className={styles.container}>
      <Tabs
        className={styles.tabs}
        currentValue={currentTab}
        tabs={[
          { name: "Home", value: "home" },
          { name: "Projects", value: "projects" },
          { name: "Notes", value: "notes" },
        ]}
        onChange={(newTab) => {
          history.push(`/admin/homes/${homeID}/${newTab}${search}`);
          setCurrentTab(newTab);
        }}
      />
      <div className={styles.pane}>
        {currentTab === "home" && <RoomList />}
        {currentTab === "projects" && <ProjectsList />}
        {currentTab === "notes" && <Notes homeID={homeID} />}
      </div>
    </div>
  );
};

export default HomeSpecifics;
