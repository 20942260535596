import MarketVerification from "./components/MarketVerification";
import ScheduleAppointment from "./components/ScheduleAppointment";

export const steps = [
  {
    key: "market-verification",
    Component: MarketVerification,
    progressLabel: "Verify market",
  },
  {
    progressLabel: "Schedule appointment",
    key: "schedule-appointment",
    Component: ScheduleAppointment,
  },
];
