import Label from "components/Label";
import React, { FC } from "react";
import { AiOutlineDatabase as DataIcon } from "react-icons/ai";

import styles from "./styles.module.scss";

const NoData: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <DataIcon className={styles.icon} />
        <Label color="primary" type="bold">
          No data
        </Label>
      </div>
    </div>
  );
};

export default NoData;
