import React, { FC } from "react";
import { DatePicker } from "antd";
import moment from "moment";

import ErrorBar from "components/ErrorBar";

import styles from "./styles.module.scss";

const { RangePicker: RootRangePicker } = DatePicker;

type Props = {
  onChange(
    newValue: [string | undefined, string | undefined] | undefined
  ): void;
  id?: string;
  value: [string, string];
  disabled?: boolean;
  error?: string;
  format?: string;
  showPastDates?: boolean;
};

const RangePicker: FC<Props> = ({
  onChange,
  id,
  value,
  disabled,
  error,
  format = "MM/DD/YYYY HH:mm",
  showPastDates,
}) => {
  return (
    <div className={styles.container}>
      <RootRangePicker
        id={id}
        dropdownClassName={styles.dropdown}
        className={styles.picker}
        format={format}
        disabledDate={
          showPastDates
            ? () => false
            : (date: moment.Moment) => date.isBefore(moment())
        }
        disabled={disabled}
        value={value ? [moment(value[0]), moment(value[1])] : undefined}
        onChange={(currentValues) => {
          const [startDate, endDate] = currentValues || [];
          const formattedStartDate = startDate?.format();
          const formattedEndDate = endDate?.format();
          if (formattedEndDate && formattedStartDate)
            onChange([formattedStartDate, formattedEndDate]);
          else onChange(undefined);
        }}
        showTime
        minuteStep={15}
      />
      <ErrorBar id={id} error={error} />
    </div>
  );
};

RangePicker.defaultProps = {
  id: undefined,
  disabled: false,
  error: undefined,
  format: "MM/DD/YYYY HH:mm",
  showPastDates: false,
};

export default RangePicker;
