import React, { FC } from "react";
import Button from "components/Button";
import Form from "components/Form";
import Modal from "components/Modal";
import { maxLength, required } from "utils/form";
import { HomeNote } from "types/models";

import useAsync from "hooks/use-async";
import { addHomeNote, updateHomeNote } from "api/homes";
import styles from "./styles.module.scss";

type Props = {
  visible: boolean;
  handleClose(): void;
  note: HomeNote | null;
  homeID: string;
  updateNotes(): void;
};

type FormValues = {
  text: string;
  title: string;
};

const NoteForm: FC<Props> = ({
  visible,
  handleClose,
  note,
  homeID,
  updateNotes,
}) => {
  const [onAddNote, addingNote] = useAsync(async (values: FormValues) => {
    await addHomeNote(homeID, values);
    updateNotes();
    handleClose();
  });

  const [onUpdateNote, updatingNote] = useAsync(async (values: FormValues) => {
    if (note) {
      await updateHomeNote(note.uid, homeID, values);
      updateNotes();
      handleClose();
    }
  });

  const isEditing = !!note;

  return (
    <Modal
      title={isEditing ? "Edit note" : "Create note"}
      handleClose={handleClose}
      visible={visible}
    >
      <Form
        className={styles.form}
        initialValues={note ?? {}}
        onSubmit={isEditing ? onUpdateNote : onAddNote}
        fields={[
          {
            type: "text",
            id: "title",
            title: "Note title",
            validate: [
              required("This field is required"),
              maxLength(200, "This field cannot have more than 200 characters"),
            ],
          },
          {
            type: "area",
            id: "text",
            title: "Note description",
            validate: [required("This field is required")],
          },
        ]}
      >
        {({ handleSubmit, invalid }) => (
          <Button
            loading={isEditing ? updatingNote : addingNote}
            className={styles.submit}
            disabled={invalid}
            onClick={handleSubmit}
          >
            Save note
          </Button>
        )}
      </Form>
    </Modal>
  );
};

export default NoteForm;
