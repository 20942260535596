import React, { FC } from "react";
import { loadStripe, Source } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Heading } from "rebass";
import CreditCardForm from "./components/CreditCardForm";

const stripePromise = process.env.REACT_APP_STRIPE_PK
  ? loadStripe(process.env.REACT_APP_STRIPE_PK)
  : null;

type Props = {
  addCreditCard: (source: Source) => Promise<void>;
  handleClose(): void;
};

const AddCard: FC<Props> = ({ addCreditCard, handleClose }) => (
  <Elements stripe={stripePromise}>
    <Heading
      fontSize="4"
      color="primary"
      textAlign="center"
      width="100%"
      fontWeight="body"
      p="27px 0px"
    >
      Add a Credit/Debit Card
    </Heading>
    <CreditCardForm handleClose={handleClose} handleAddCard={addCreditCard} />
  </Elements>
);

export default AddCard;
