import React, { FC } from "react";
import { Button, Form, Input, message, Select } from "antd";
import { useFlags } from "launchdarkly-react-client-sdk";
import NumberFormat from "react-number-format";
import { Flex, Heading, Text } from "rebass";
import styled from "styled-components";
import { useRecoilState } from "recoil";

import { vendorUser } from "recoil/vendors";
import { updateUser } from "api/users";
import { createVendor, updateVendor } from "api/vendors";
import Loading from "components/Loading";
import { useUser } from "providers/UserProvider";
import theme from "theme/theme.main";
import { Event } from "types/components";
import { toast } from "react-hot-toast";
import { getError } from "utils/errors";
import { AxiosError } from "axios";

type Props = {
  nextStep(): void;
};

const StyledSelect = styled(Select)`
  &.ant-select {
    .ant-select-selector {
      padding: 0px 16px;
      height: 40px;
    }

    .ant-select-selection-placeholder {
      opacity: 0.55;
      color: #1d1f23;
    }

    .ant-select-selection-item {
      line-height: 40px;
    }

    .ant-select-arrow {
      top: 54%;
    }
  }
`;

const ContactInfo: FC<Props> = ({ nextStep }: Props) => {
  const [form] = Form.useForm();
  const { user, setUser } = useUser();
  const { vendorPaymentsV2 } = useFlags();
  const [vendor, setVendor] = useRecoilState(vendorUser);

  const handleInputChange = (event: Event) => {
    const { value, name: key } = event.target;

    setVendor({ uid: "", ...vendor, [key]: value });
  };

  const handleSubmit = () => {
    if (!vendor) return;

    if (vendor.uid) {
      updateVendor(vendor)
        .then((vendorResp) => {
          setVendor(vendorResp);
          message.success("Updated profile");
          nextStep();
        })
        .catch((error) => {
          toast.error(
            getError(
              error.response?.data,
              "An error occurred, please check for errors and retry."
            )
          );
        });
    } else {
      if (!user) return;
      createVendor(vendor)
        .then(async (vendorResp) => {
          setVendor(vendorResp);

          if (user && user.uid && !vendorPaymentsV2) {
            updateUser({ has_onboarded: true }, user.uid).then((respUser) => {
              setUser(respUser);
            });
          }
          nextStep();
        })
        .catch((error: AxiosError) => {
          toast.error(
            getError(
              error.response?.data,
              "An error occurred, please check for errors and retry."
            )
          );
        });
    }
  };

  if (!vendor) return <Loading />;

  return (
    <>
      <Heading
        fontSize="32px"
        lineHeight="1.3"
        color={theme.colors.success}
        fontWeight={500}
        marginTop={6}
      >
        Step 2 of 5
      </Heading>
      <Text fontSize="1rem" color="primary05" mb={3}>
        Please enter your contact information
      </Text>
      <Flex
        margin={["0 auto", "0 auto", "24px auto 0"]}
        backgroundColor="white"
        padding={[4, 5]}
        style={{
          borderRadius: "8px",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        }}
        flexDirection="column"
        width={["85%", "65%"]}
        maxWidth="500px"
      >
        <Form
          form={form}
          initialValues={{
            phone_number: vendor?.phone_number,
            phone_number_mobile: vendor?.phone_number_mobile,
            website: vendor?.website,
            contact_method: vendor?.contact_method || null,
          }}
        >
          <Form.Item
            name="phone_number"
            style={{ marginBottom: 0 }}
            rules={[
              { required: true, message: "Please add in a phone number" },
              {
                validator: (_rule, value) => {
                  const phoneNumber = /\+1[\d]{10}/;
                  if (!value || phoneNumber.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please put in a valid phone number")
                  );
                },
              },
            ]}
          >
            <NumberFormat
              type="tel"
              name="phone_number"
              placeholder="Business phone number"
              format="+1##########"
              onValueChange={({ formattedValue }) => {
                form.setFieldsValue({ phone_number: formattedValue });
                handleInputChange({
                  target: { name: "phone_number", value: formattedValue },
                });
              }}
              style={{
                padding: "16px",
                height: "40px",
                width: "100%",
                borderRight: 0,
                borderLeft: 0,
                borderTop: 0,
                borderBottom: "1px solid rgba(108, 114, 128, 0.2)",
              }}
            />
          </Form.Item>
          <Form.Item
            name="phone_number_mobile"
            style={{ marginBottom: 0 }}
            rules={[
              {
                validator: (_rule, value) => {
                  const phoneNumber = /\+1[\d]{10}/;
                  if (!value || phoneNumber.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please put in a valid phone number")
                  );
                },
              },
            ]}
          >
            <NumberFormat
              type="tel"
              name="phone_number_mobile"
              placeholder="Mobile phone number"
              format="+1##########"
              onValueChange={({ formattedValue }) => {
                form.setFieldsValue({ phone_number_mobile: formattedValue });
                handleInputChange({
                  target: {
                    name: "phone_number_mobile",
                    value: formattedValue,
                  },
                });
              }}
              style={{
                padding: "16px",
                height: "40px",
                marginTop: "8px",
                width: "100%",
                borderRight: 0,
                borderLeft: 0,
                borderTop: 0,
                borderBottom: "1px solid rgba(108, 114, 128, 0.2)",
              }}
            />
          </Form.Item>
          <Form.Item
            name="website"
            style={{ marginBottom: 0 }}
            rules={[
              {
                validator: (_rule, value) => {
                  const simpleWebsiteFormat =
                    /[w]{0,3}[.]{0,1}[\w\d]+\.[a-zA-Z]{3}/;
                  if (!value || simpleWebsiteFormat.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please put in a valid website url")
                  );
                },
              },
            ]}
          >
            <Input
              type="text"
              name="website"
              placeholder="Website"
              bordered={false}
              onChange={handleInputChange}
              style={{
                padding: "16px",
                height: "40px",
                marginTop: "8px",
                borderBottom: "1px solid rgba(108, 114, 128, 0.2)",
              }}
            />
          </Form.Item>
          <Form.Item
            name="contact_method"
            style={{
              marginBottom: 0,
              marginTop: "8px",
              borderBottom: "1px solid rgba(108, 114, 128, 0.2)",
            }}
            rules={[
              {
                required: true,
                message: "Please select preferred contact method",
              },
            ]}
          >
            <StyledSelect
              showSearch
              placeholder="Preferred contact method for new jobs"
              optionFilterProp="children"
              bordered={false}
              filterOption
              onChange={(value) => {
                form.setFieldsValue({ contact_method: value });
                handleInputChange({
                  target: { name: "contact_method", value },
                });
              }}
            >
              <Select.Option value="OFFICE_PHONE">Office Phone</Select.Option>
              <Select.Option value="CELL_TEXT_CALL">
                Cell Text / Call
              </Select.Option>
              <Select.Option value="COMPANY_EMAIL">Company Email</Select.Option>
            </StyledSelect>
          </Form.Item>
        </Form>
        <Button
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then(() => handleSubmit())
              .catch(() =>
                message.error(
                  "Oops! Something went wrong. Please ensure all fields are properly entered without error."
                )
              );
          }}
          style={{
            marginTop: "24px",
            width: "250px",
            alignSelf: "center",
          }}
        >
          Next
        </Button>
      </Flex>
    </>
  );
};

export default ContactInfo;
