/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

const useAsync = <T>(
  asyncRequest: (...params: any[]) => Promise<T>,
  failureCallback?: (error: string) => void
): [(...params: any[]) => void, boolean] => {
  const [inProgress, setInProgress] = useState(false);

  const fetch = (...params: any[]) => {
    setInProgress(true);
    asyncRequest(...params)
      .then((newData: T) => {
        return newData;
      })
      .catch((newError: string) => {
        failureCallback?.(newError);
      })
      .finally(() => setInProgress(false));
  };

  return [fetch, inProgress];
};

export default useAsync;
