import { atom } from "recoil";

import { PaginationInfo } from "types/client";
import { Vendor } from "types/models";

export const vendorsState = atom<Vendor[]>({
  key: "vendorsState",
  default: [],
});

export const vendorState = atom<Vendor | null>({
  key: "vendorState",
  default: null,
});

// pagination, sorting, filters
export const totalVendorsPagesState = atom<PaginationInfo>({
  key: "totalVendorsPagesState",
  default: {
    prevLink: null,
    nextLink: null,
    numOfItems: 1,
    currentPage: 1,
  },
});

// vendor that is logged in
export const vendorUser = atom<Vendor | null>({
  key: "vendorUser",
  default: null,
});
