const COL_WIDTH = {
  small: 120,
  medium: 150,
  large: 175,
  extraLarge: 200,
  xxl: 225,
  xxxl: 275,
};

const RESULTS_PAGE_SIZE = 15;

const ADMIN_PANEL = {
  add: "add",
  view: "view",
  edit: "edit",
};

const JOB_ACTIONS = {
  CREATE_ESTIMATE: "Create Service Estimate",
  CREATE_INVOICE: "Create Invoice",
  REQUEST_TIME: "Request Time",
  SCHEDULE_APPOINTMENT: "Schedule Appointment",
  FINALIZE_INVOICE: "Complete Invoice",
};

const JOB_DETAIL_TYPES = {
  ATTACHMENTS: "Attachments",
  INVOICES: "Invoices",
  ESTIMATES: "Estimates",
  APPOINTMENTS: "Appointments",
};

export const USER_ROLE_DISPLAY = {
  HOME_COORDINATOR: "Home Coordinator",
  ADMIN: "Admin",
  HOME_MEMBER: "Secondary Owner",
  HOME_OWNER: "Primary Owner",
  VENDOR: "Vendor",
  HUMMING_EXPERT: "Expert in Residence",
  HUMMING_WATCHER: "Home Watcher",
};

const STATE_ABBR = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const HEADER_HEIGHT = "62px";
const ADMIN_HEADER_HEIGHT = 225;

export {
  COL_WIDTH,
  JOB_ACTIONS,
  JOB_DETAIL_TYPES,
  RESULTS_PAGE_SIZE,
  ADMIN_PANEL,
  STATE_ABBR,
  HEADER_HEIGHT,
  ADMIN_HEADER_HEIGHT,
};

export const PASSWORD_MIN_LENGTH = 8;

export const NEW_JOB = "new-job";

export const PAUSE_TIME = "8:00 am";

export const PROJECT_PAGE_SIZE = "100";

export const CUS_INVOICE_PAGE_SIZE = "10";
export const VENDOR_INVOICE_PAGE_SIZE = "10";

export const ACTION_PAGE_SIZE = "8";
