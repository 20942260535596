import { MdFilter1, MdFilter2, MdFilterNone, MdFilter3 } from "react-icons/md";
import { mapper } from "utils/mapping";

export const FilterIcons = mapper(
  {
    0: MdFilterNone,
    1: MdFilter1,
    2: MdFilter2,
    3: MdFilter3,
  },
  MdFilterNone
);
