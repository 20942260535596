import Button from "components/Button";
import React, { FC, useEffect, useState } from "react";
import { Dropdown } from "antd";
import {
  AiOutlineCalendar as FilterIcon,
  AiFillCheckCircle as CheckIcon,
} from "react-icons/ai";

import { RecoilState, useRecoilValue } from "recoil";
import Filter from "./components/Filter";
import styles from "./styles.module.scss";

type Props = {
  name: string;
  disabled: boolean;
  filtersState: RecoilState<{ [key: string]: string | undefined }>;
};

const DateFilter: FC<Props> = ({ name, disabled, filtersState }) => {
  const [visible, setVisible] = useState(false);
  const tableFilters = useRecoilValue(filtersState);

  const applied = !!tableFilters[`${name}__gte`];

  useEffect(() => {
    if (disabled) setVisible(false);
  }, [disabled]);

  return (
    <Dropdown
      destroyPopupOnHide
      placement="bottomRight"
      visible={visible}
      overlay={
        <Filter filtersState={filtersState} disabled={disabled} name={name} />
      }
    >
      <div className={styles.container}>
        <Button
          disabled={disabled}
          stopPropagation
          variant="secondary"
          onClick={() => setVisible(!visible)}
          Icon={<FilterIcon />}
        />
        {applied && <CheckIcon className={styles.checkIcon} />}
      </div>
    </Dropdown>
  );
};

export default DateFilter;
