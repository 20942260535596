/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dropdown, Menu, message, Tooltip } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { deleteAction, pauseAction } from "api/actions";
import { ACTION_ISSUE_URLS, ACTION_MODAL_TYPES } from "constants/actions";
import { Action, ActionType } from "types/models";
import Modal from "components/Modal";
import Button from "components/Button";
import { AiOutlineMore as MoreIcon } from "react-icons/ai";

import useActions from "hooks/use-actions";
import useAsync from "hooks/use-async";

import { capitalize } from "lodash";
import Label from "components/Label";
import styles from "./styles.module.scss";

type ActionButtonProps = {
  action: Action;
};

const ActionButton = ({ action }: ActionButtonProps) => {
  const history = useHistory();
  const { search } = useLocation();
  const [actionButtonInfo, setInfo] = useState<ActionType>();

  const { updateActions } = useActions();

  const [modalVisible, setModalVisible] = useState(false);
  const [actionModalType, setActionModalType] = useState("");

  useEffect(() => {
    const actionInfo = ACTION_ISSUE_URLS[action.issue];
    setInfo(actionInfo);
  }, [action]);

  const handleClose = () => {
    setModalVisible(false);
  };

  const [handleDelete, deleting] = useAsync(async () => {
    await deleteAction(action.uid)
      .then(() => {
        message.success(`Action deleted!`);
        updateActions();
        history.push(`/admin/actions${search}`);
      })
      .catch(() => message.error(`Could not delete action`))
      .finally(() => {
        setModalVisible(false);
      });
  });

  const [handlePause, pausing] = useAsync(async () => {
    await pauseAction(action.uid)
      .then(() => {
        message.success(`Action paused!`);
        updateActions();
        history.push(`/admin/actions${search}`);
      })
      .catch(() => message.error(`Could not pause action`))
      .finally(() => {
        setModalVisible(false);
      });
  });

  if (!actionButtonInfo) return <>N/A</>;
  const { text, actionableURL } = actionButtonInfo;

  const overlay = (
    <Menu
      mode="horizontal"
      selectedKeys={["actionOptions"]}
      className={styles.menu}
    >
      <Menu.Item
        className={styles.item}
        key="delete-action"
        onClick={() => {
          setModalVisible(true);
          setActionModalType(ACTION_MODAL_TYPES.DELETE);
        }}
      >
        Delete Action
      </Menu.Item>
      <Menu.Item
        className={styles.item}
        key="pause-action"
        onClick={() => {
          setModalVisible(true);
          setActionModalType(ACTION_MODAL_TYPES.PAUSE);
        }}
      >
        <Tooltip title="Pauses action for 24 hours" placement="left">
          Pause Action
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className={styles.container}>
        <Button onClick={() => actionableURL(action)}>{text}</Button>
        <Dropdown placement="bottomRight" overlay={overlay}>
          <MoreIcon className={styles.menuIcon} />
        </Dropdown>
      </div>
      <Modal
        visible={modalVisible}
        handleClose={handleClose}
        title={`${capitalize(actionModalType)} action`}
        contentClassName={styles.content}
      >
        <Label type="bold-italic" className={styles.prompt}>
          Are you sure you want to {actionModalType} this action?
        </Label>
        <Button
          loading={
            actionModalType === ACTION_MODAL_TYPES.DELETE ? deleting : pausing
          }
          onClick={() => {
            if (actionModalType === ACTION_MODAL_TYPES.DELETE) {
              handleDelete();
            } else handlePause();
          }}
        >{`Yes, ${actionModalType}`}</Button>
      </Modal>
    </>
  );
};

export default ActionButton;
