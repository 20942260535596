/* eslint-disable react/no-array-index-key */
import React, { FC } from "react";
import { Checkbox } from "antd";

import ErrorBar from "components/ErrorBar";

import { isEmpty } from "utils/array";
import Label from "components/Label";
import styles from "./styles.module.scss";
import { Option } from "./types";

type Props = {
  options: Option[];
  disabled?: boolean;
  onChange(values: string[] | undefined): void;
  value: string[] | undefined;
  id?: string;
  error?: string;
};

const Checklist: FC<Props> = ({
  options,
  disabled,
  onChange,
  value,
  id,
  error,
}) => {
  return (
    <div id={id} className={styles.container}>
      <Checkbox
        onChange={() => {
          if (value?.length === options.length) {
            onChange(undefined);
          } else {
            onChange(options.map(({ value: optionValue }) => optionValue));
          }
        }}
      >
        <Label type="bold-italic">Select all</Label>
      </Checkbox>
      <Checkbox.Group
        className={styles.group}
        value={value}
        disabled={disabled}
        options={options as Option[]}
        onChange={(values) =>
          onChange(isEmpty(values) ? undefined : (values as string[]))
        }
      />
      <ErrorBar id={id} error={error} />
    </div>
  );
};

Checklist.defaultProps = {
  disabled: false,
  id: undefined,
  error: undefined,
};

export default Checklist;
