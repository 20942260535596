import React, { FC, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ReactDOM from "react-dom";

import { useTheme } from "theme/ThemeCreator";

import styles from "./styles.module.scss";
import { variants } from "./constants";

type Props = {
  visible: boolean;
  size: number | string;
  position: string;
  onClose(): void;
  containerClassName?: string;
  unmountOnCollapse?: boolean;
  initial?: boolean;
  id?: string;
  onPanelOpened?(): void;
  onPanelClosed?(): void;
};

const Panel: FC<Props> = ({
  visible,
  children,
  size,
  position,
  onClose,
  containerClassName,
  unmountOnCollapse,
  initial,
  id,
  onPanelClosed,
  onPanelOpened,
}) => {
  const bodyRef = useRef<HTMLElement | null>(null);

  const { animateOpen, animateClose, className } = variants(size)(position);

  const { colors } = useTheme();

  useEffect(() => {
    bodyRef.current = document.getElementById("root");
  }, []);

  const containerVarians = {
    visible: {
      backgroundColor: `${colors.black}30`,
      zIndex: 999,
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
    collapsed: {
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
  };

  const contentVariants = {
    visible: animateOpen,
    collapsed: animateClose,
  };

  return (
    bodyRef.current &&
    ReactDOM.createPortal(
      <motion.div
        id={id}
        onClick={onClose}
        className={`${styles.backdrop} ${visible && styles.blur}`}
        animate={visible ? "visible" : "collapsed"}
        variants={containerVarians}
        style={{ pointerEvents: visible ? "all" : "none" }}
      >
        <AnimatePresence initial={initial}>
          {(unmountOnCollapse ? visible : true) && (
            <motion.div
              className={`${className} ${containerClassName}`}
              onClick={(event) => event.stopPropagation()}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              onAnimationComplete={() => {
                if (visible) onPanelOpened?.();
                else onPanelClosed?.();
              }}
              {...(unmountOnCollapse
                ? {
                    exit: animateClose,
                    initial: animateClose,
                    animate: animateOpen,
                  }
                : { variants: contentVariants })}
            >
              <div className={styles.children}>{children}</div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>,
      bodyRef.current
    )
  );
};

Panel.defaultProps = {
  containerClassName: "",
  unmountOnCollapse: false,
  initial: false,
  id: undefined,
  onPanelClosed: () => {},
  onPanelOpened: () => {},
};

export default Panel;
