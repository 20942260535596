import React, { FC } from "react";
import NumberFormat from "react-number-format";

import ErrorBar from "components/ErrorBar";

import styles from "./styles.module.scss";

type Props = {
  onChange(newValue: string): void;
  disabled?: boolean;
  error?: string;
  value: string;
  id?: string;
  placeholder?: string;
};

const PercentageField: FC<Props> = ({
  onChange,
  disabled,
  error,
  value,
  id,
  placeholder,
}) => {
  return (
    <div className={styles.container}>
      <NumberFormat
        id={id}
        className={styles.field}
        placeholder={placeholder || "0.00%"}
        value={value}
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        disabled={disabled}
        suffix="%"
        onValueChange={({ value: newValue }) => {
          if (!newValue) onChange("0.00");
          else onChange(newValue);
        }}
      />
      <ErrorBar id={id} error={error} />
    </div>
  );
};

PercentageField.defaultProps = {
  disabled: false,
  error: undefined,
  id: undefined,
  placeholder: undefined,
};

export default PercentageField;
