import React from "react";
import ActiveTag from "components/ActiveTag";
import { ColumnDefinition } from "components/Table/types";
import { USER_ROLE_LABELS } from "constants/roles";
import { User, UserRole } from "types/models";

export const targetRoles = [
  UserRole.Admin,
  UserRole.HomeCoordinator,
  UserRole.HomeExpert,
  UserRole.HomeWatcher,
];

export interface RowData {
  name: string;
  role: string;
  id: string;
  is_active: boolean;
  email: string;
}

export const createData = (users: User[]) =>
  users.map(({ name, role, uid, email, is_active }) => ({
    is_active,
    email,
    name,
    role: USER_ROLE_LABELS[role],
    id: uid,
  }));

export const createColumns = (): ColumnDefinition<RowData>[] => [
  {
    Header: "Name",
    accessor: "name",
    width: 150,
  },
  {
    Header: "Role",
    accessor: "role",
    width: 200,
    filter: "set",
    options: targetRoles.map((role) => ({
      value: role,
      key: role,
      label: USER_ROLE_LABELS[role],
    })),
  },
  {
    Header: "Active",
    accessor: "is_active",
    width: 200,
    Cell: ({ value }: { value: boolean }) => (
      <ActiveTag variant={value ? "active" : "inactive"} />
    ),
  },
  {
    Header: "Email",
    accessor: "email",
    width: 200,
  },
];
