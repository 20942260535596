import { atom } from "recoil";

import {
  totalActionsState,
  actionSelectedState,
  actionListState,
} from "./actions";
import {
  applianceListState,
  homeSelectedState,
  homesState,
  selectedHomeFilter,
  roomListState,
} from "./homes";
import {
  adminInvoicesState,
  invoicePaginationInfo,
  invoiceSelectedState,
} from "./invoices";
import {
  jobSelectedState,
  jobsOnProjectState,
  jobsUpdatingState,
} from "./jobs";
import { listOfNotesState } from "./notes";
import {
  editingProjectState,
  projectListState,
  projectRequestorsState,
  projectSelectedState,
  requestListState,
  requestRequestorsState,
  requestSelectedState,
  totalProjectsPagesState,
  totalRequestsPagesState,
  totalRequestsState,
} from "./projects";
import { vendorsState, vendorState } from "./vendors";
import { serviceEstimateListState } from "./serviceEstimates";
import { potentialAssigneeList } from "./users";
import { pageTitleState } from "./pageTitle";

type BannerInfo = {
  page: string;
  bannerVisible: boolean;
  text: string;
  subtext?: string;
  color?: string;
};

const bannerInfoState = atom<BannerInfo>({
  key: "bannerInfoState",
  default: { page: "", bannerVisible: false, text: "" },
});

// Setting the global state with static values
export {
  actionListState,
  actionSelectedState,
  adminInvoicesState,
  applianceListState,
  bannerInfoState,
  selectedHomeFilter,
  homeSelectedState,
  homesState,
  invoicePaginationInfo,
  invoiceSelectedState,
  jobSelectedState,
  jobsOnProjectState,
  jobsUpdatingState,
  editingProjectState,
  listOfNotesState,
  projectListState,
  projectRequestorsState,
  projectSelectedState,
  requestListState,
  requestRequestorsState,
  requestSelectedState,
  roomListState,
  potentialAssigneeList,
  serviceEstimateListState,
  totalActionsState,
  totalProjectsPagesState,
  totalRequestsPagesState,
  totalRequestsState,
  vendorsState,
  pageTitleState,
  vendorState,
};
