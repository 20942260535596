import { RadioGroup, Radio } from "@mui/material";
import ErrorBar from "components/ErrorBar";
import Label from "components/Label";
import React, { FC } from "react";

import styles from "./styles.module.scss";

interface Props {
  options: { label: string; value: string }[];
  onChange: (newValue: string | undefined) => void;
  value: string;
  error?: string;
}

const RadioField: FC<Props> = ({ options, onChange, value, error }) => {
  return (
    <div className={styles.container}>
      <RadioGroup
        onChange={(event) => {
          const { value: newValue } = event.target;

          if (!newValue) onChange(undefined);
          else onChange(newValue);
        }}
      >
        {options.map(({ value: optValue, label }) => (
          <div className={styles.option}>
            <Radio
              checked={value === optValue}
              className={styles.radio}
              value={optValue}
            />
            <Label>{label}</Label>
          </div>
        ))}
      </RadioGroup>
      {error && <ErrorBar error={error} />}
    </div>
  );
};

RadioField.defaultProps = {
  error: undefined,
};

export default RadioField;
