import React, { FC } from "react";
import { IoAddCircleOutline as AddIcon } from "react-icons/io5";

import Button from "components/Button";

type AddButtonProps = {
  handleOnClick?: () => void;
  buttonID: string;
};

const AddButton: FC<AddButtonProps> = ({
  handleOnClick = () => {},
  buttonID,
}) => (
  <Button
    Icon={<AddIcon style={{ fontSize: "20px", marginRight: 5 }} />}
    id={buttonID}
    variant="text"
    onClick={handleOnClick}
  >
    Add
  </Button>
);

AddButton.defaultProps = {
  handleOnClick: () => {},
};

export default AddButton;
