import { updateServiceOffering } from "api/projects";
import Button from "components/Button";
import Form from "components/Form";
import Modal from "components/Modal";
import useAsync from "hooks/use-async";
import React, { FC } from "react";
import { ServiceOfferingSnippet } from "types/models";
import { required } from "utils/form";

import styles from "./styles.module.scss";

interface Props {
  visible: boolean;
  handleClose(): void;
  onModalClosed(): void;
  serviceOffering: ServiceOfferingSnippet;
  onSuccess(): void;
}

interface FormValues {
  name: string;
}

const EditServiceOffering: FC<Props> = ({
  visible,
  handleClose,
  onModalClosed,
  serviceOffering,
  onSuccess,
}) => {
  const [submit, submitting] = useAsync((formValues: FormValues) => {
    return updateServiceOffering(serviceOffering.id, formValues).then(() => {
      onSuccess();
      handleClose();
    });
  });

  return (
    <Modal
      onModalClosed={onModalClosed}
      handleClose={handleClose}
      visible={visible}
      title={`Editing: ${serviceOffering.full_name}`}
    >
      <Form
        className={styles.form}
        initialValues={{
          name: serviceOffering.name,
          active: serviceOffering.active,
        }}
        onSubmit={submit}
        fields={[
          {
            type: "text",
            title: "Name",
            id: "name",
            validate: [required("Name is required for creation")],
          },
          {
            id: "active",
            placeholder: "Active",
            type: "checkbox",
          },
        ]}
      >
        {({ handleSubmit, invalid, hasChanged }) => (
          <Button
            className={styles.submit}
            disabled={!hasChanged || invalid}
            loading={submitting}
            onClick={handleSubmit}
          >
            Edit
          </Button>
        )}
      </Form>
    </Modal>
  );
};

export default EditServiceOffering;
