import { AxiosError } from "axios";

import { Invoice } from "types/models";
import { InvoiceResponse } from "types/responses";

import { apiClient, ServerError } from ".";

const fetchInvoices = async (
  queryFilters: { [x: string]: string } = {},
  stickyParams?: string
) => {
  try {
    const queryParams = new URLSearchParams(queryFilters);
    const combinedQueryParams = [queryParams, stickyParams].join("&");
    const response = await apiClient.get<InvoiceResponse>(
      `/customers/invoices/?${combinedQueryParams}`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchInvoice = async (invoiceID: string) => {
  try {
    const response = await apiClient.get<Invoice>(
      `/customers/invoices/${invoiceID}/`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const fetchPayableInvoice = async (invoiceID: string) => {
  try {
    const response = await apiClient.get<Invoice>(
      `/customers/invoices/${invoiceID}/payable/`
    );
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

const payInvoice = async (invoiceID: string) => {
  try {
    const response = await apiClient.put<Invoice>(
      `/customers/invoices/${invoiceID}/pay/`
    );

    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    throw axiosError;
  }
};

export { fetchInvoices, fetchInvoice, payInvoice, fetchPayableInvoice };
