import React, { FC } from "react";
import ReactMarkdown from "react-markdown";

const Agreement: FC = () => (
  <ReactMarkdown>
    {`__Effective date: 5/25/2020__

Welcome to Humming Homes. Please read on to learn the rules and restrictions that govern your use of our website(s), products, services and applications (the “Services”). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at: hello@humminghomes.com.

These Terms of Use (the “Terms”) are a binding contract between you and __Halseen, Inc__. (“Humming Homes,” “we” and “us”). Your use of the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use the Services. These Terms include the provisions in this document as well as those in the [Privacy Policy](https://www.humminghomes.com/privacy).

**ARBITRATION NOTICE AND CLASS ACTION WAIVER:** EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.

‍**Will these Terms ever change?**
‍We reserve the right to change the Terms at any time, but if we do, we will notify you by some means. If you don’t agree with the new Terms, you are free to reject them, but will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes. Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.

**‍What about my privacy?**
‍Humming Homes takes the privacy of its users very seriously. For the current Humming Homes Privacy Policy, please click here.

‍**What is Humming Homes?**
‍Humming Homes is a technology driven property management platform modernizing homeownership. We are offering a complete home operations and maintenance service powered by technology. At the core of our offering are our home managers, who are equipped with technology tools to connect with homeowners and third-party service providers, proactively maintain homes, and make maintenance recommendations. Our software connects homeowners, our property managers, and third-party service providers to manage home activity, recommend proactive maintenance, Humming Homes will arrange for and obtain certain property management services (“Property Management Services”) through a homeowner’s preferred list of service providers or from a network of third party providers (“Providers”). Humming Homes is not itself in the business of providing any services, and is not involved in the actual performance of any services.

Descriptions, images, references, features, content, prices, and availability of any Property Management Services made available through the Humming Homes platform are subject to change without notice. The inclusion of any Property Management Services at a particular time does not imply or warrant that they will be available at any time. We reserve the right, with or without prior notice, to limit or discontinue any Property Management Services or to bar any user from ordering or arranging for Property Management Services.

Prices and fees for Property Management Services can be found on our Property Management Services page. Such prices and fees may not include additional Provider charges or taxes, which will be noted in the payment terms presented to you during the checkout process. You may find more information about returns and refunds by contacting us at hello@humminghomes.com. If you have any questions about a particular service, please contact us at hello@humminghomes.com.

By placing an order for or purchasing Property Management Services, you agree to grant to applicable Providers and their employees, agents, and personnel (collectively, “Provider Personnel”) the right to access, enter upon and use the home or property address you have designated and the contents thereof and the appurtenances thereto to provide the service. Further, you acknowledge and agree that Providers shall not be liable for any deficiency in performing the Property Management Services if such deficiency results from your failure to provide full and timely cooperation and assistance, or any refunds, rebates, credits, or other amounts relating to the foregoing.  

Providers and Provider Personnel are independent contractors and not employees, partners, agents, or joint venturers of Humming Homes. Humming Homes shall not be liable or responsible for any services provided by or on behalf of Providers, or any errors or misrepresentations made by any of them. By agreeing to have Property Management Services or other services performed by Providers, you agree to bear responsibility for receipt of such services. You hereby acknowledge that Humming Homes does not supervise, direct, control, or monitor a Provider’s provision of services. Any interactions or disputes between you and a Provider or Provider Personnel are solely between you and that Provider. Humming Homes and its licensors shall have no liability, obligation or responsibility for any interaction between you and any Provider or Provider Personnel.

Please see our [“Frequently Asked Questions” page](https://www.humminghomes.com/how-it-works/homeowners) for more information regarding Property Management Services.

‍**What are the basics of using Humming Homes?**
‍You may be required to sign up for an account, select a password and username (“Humming Homes User ID”), and provide us with certain information, such as your contact information and information about your home or property. You promise to provide us with accurate, complete, and updated information about yourself. You may not transfer your account to anyone else without our prior written permission.

You represent and warrant that you are of legal age to form a binding contract (or if not, you’ve received your parent’s or guardian’s permission to use the Services and have gotten them to agree to these Terms on your behalf). If you’re agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on that organization’s or entity’s behalf and bind them to these Terms (in which case, the references to “you” and “your” in these Terms, except for in this sentence, refer to that organization or entity).

‍**What about messaging?**
‍When signing up for the Services, you will receive a welcome message and instructions on how to stop receiving messages. BY SIGNING UP FOR THE SERVICES, YOU AGREE TO RECEIVE COMMUNICATIONS FROM Humming Homes, AND YOU REPRESENT AND WARRANT THAT EACH PERSON YOU REGISTER FOR THE SERVICES OR FOR WHOM YOU PROVIDE A WIRELESS PHONE NUMBER HAS CONSENTED TO RECEIVE COMMUNICATIONS FROM Humming Homes. You agree to indemnify and hold Humming Homes harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to your breach of the foregoing.

**‍Are there restrictions in how I can use the Services?**
‍You represent, warrant, and agree that you will not contribute any Content or User Submission (each of those terms is defined below) or otherwise use the Services or interact with the Services in a manner that: (a) violates any law or regulation; (b) is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, or otherwise objectionable; or (c) jeopardizes the security of your account or anyone else’s (such as allowing someone else to log in to the Services as you). A violation of any of the foregoing is grounds for termination of your right to use or access the Services.

‍**What are my rights in the Services?**
‍The content and materials available on or through the Services, including, but not limited to, text, graphics, images, and so forth (all of the foregoing, the “Content”) are protected by copyright and/or other intellectual property laws. You promise to abide by all notices, rules, and restrictions contained in any Content, and you won’t use or exploit any Content in a way that violates someone else’s (including our) rights.

You understand that Humming Homes owns the Services. Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the Services. Any other use is prohibited without prior written permission from us.

Any information you communicate (orally or in writing), share, submit, or otherwise provide through the Services (whether through our websites or mobile applications, by email, or otherwise) is your “User Submission.” For all User Submissions, you hereby do and shall grant us a license to use, share, and otherwise exploit such User Submissions to enable us to operate, test, and improve the Services. If you share any feedback relating to the Services, then you grant us the licenses above with respect to such User Submissions, as well as any and all other rights necessary to use and exercise all rights in them in connection with the Services and/or Humming Homes’s business.

You agree that the foregoing licenses granted by you are royalty-free, perpetual, sublicensable, transferable, irrevocable, and worldwide, subject to our Privacy Policy to the extent they relate to personally identifiable information. You represent and warrant that you have all rights to grant the foregoing licenses without infringement or violation of any laws, regulations, or any third party rights.  

‍**Who is responsible for the Property Management Services?**
‍Your interactions with organizations and/or individuals found on or through the Services, including with respect to payment and performance of Property Management Services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You agree that Humming Homes shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.

If there is a dispute between you and any third party, including without limitation any Provider, Provider Personnel, or other user, you agree that Humming Homes is under no obligation to become involved. In the event that you have a dispute with any Provider or other third party in connection with the Services, you release Humming Homes, its directors, officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. You shall and hereby do waive California Civil Code Section 1542 or any similar law of any jurisdiction, which says in substance: “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.”

**‍Will Humming Homes ever change the Services, or the Products it offers through the Services?**
‍We’re always trying to improve our Services, so they may change over time. We may suspend or discontinue any part of the Services or restrict access to parts or all of the Services, without notice.

‍**Do the Services cost anything?**
‍We don’t currently charge a fee for using the Services, though we may at some time in the future. Note that if you elect to receive text messages through the Services, data and message charges may apply, and are your sole responsibility.

‍**What if I want to stop using the Services?**
‍You’re free to do that at any time by contacting us at hello@humminghomes.com; please refer to our Privacy Policy to understand how we treat information you provide to us after you have stopped using our Services. Humming Homes is also free to terminate or suspend your use of the Services for any reason in our discretion, including for breach of these Terms. Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration agreement.

‍**What else do I need to know?**
‍Warranty Disclaimer. Humming Homes and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of their respective officers, directors, members, employees, consultants, contract employees, representatives and agents, and each of their respective successors and assigns (Humming Homes and all such parties together, the “Humming Homes Parties”) make no representations or warranties concerning the Services, and the Humming Homes Parties will not be responsible or liable for any of the foregoing or any claims, actions, suits procedures, costs, expenses, damages or liabilities arising out of use of, or in any way related to your participation in, the Services.

THE SERVICES, ALL PROPERTY MANAGEMENT SERVICES, AND ANY OTHER SERVICES OR PRODUCTS PURCHASED OR OFFERED (WHETHER OR NOT FOLLOWING SUCH RECOMMENDATIONS AND SUGGESTIONS) THROUGH THE SERVICES ARE PROVIDED “AS-IS” AND WITHOUT ANY WARRANTY OF ANY KIND FROM THE HUMMING HOMES PARTIES OR OTHERS, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. YOU WAIVE AND RELEASE US FROM ANY AND ALL INJURIES, DAMAGES, CLAIMS, LIABILITIES, AND COSTS ARISING FROM OR RELATED TO ANY ACT OR OMISSION OF ANY PROVIDER, PROVIDER PERSONNEL, OR ANY OTHER THIRD PARTY. WE ARE NOT RESPONSIBLE FOR EXAMINING OR EVALUATING THE OFFERINGS OF ANY THIRD PARTY OR ANY THIRD PARTY SERVICES OR PRODUCTS. YOUR PURCHASE AND USE OF PROPERTY MANAGEMENT SERVICES OR ANY OTHER SERVICES OR PRODUCTS IS SOLELY AT YOUR OWN RISK.

‍*Limitation of Liability.* TO THE FULLEST EXTENT ALLOWED BY LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE HUMMING HOMES PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100) DOLLARS OR (II) AMOUNTS PAID OR PAYABLE BY YOU TO HUMMING HOMES IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THE CLAIM OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.

‍*Indemnity.* You agree to indemnify and hold the Humming Homes Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any claims relating to (a) your use of the Services (including any actions taken by a third party using your account) or any products or services offered or obtained in connection with the Services, (b) your interactions with any Provider, Provider Personnel, or other third parties in connection with the Services, and (c) your violation of these Terms. In the event of such a claim, suit, or action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have for your account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification obligations hereunder).

‍*Assignment.* You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without Humming Homes’s prior written consent. We may do the foregoing without consent.

‍*Choice of Law.* These Terms are governed by and will be construed under the Federal Arbitration Act, applicable federal law, and the laws of New York, without regard to conflicts of laws provisions.

‍*Arbitration Agreement.* Both you and Humming Homes acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject matter of these Terms, Humming Homes’s officers, directors, employees and independent contractors (“Personnel”) are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.

Any dispute arising out of or relating to the subject matter of these Terms shall be finally settled by binding arbitration in New York County, New York, in accordance with the Streamlined Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc. ("JAMS") then in effect. The Rules will govern payment of all arbitration fees. Humming Homes will pay all arbitration fees for claims less than seventy-five thousand ($75,000) dollars. Either you or Humming Homes may assert claims, if they qualify, in small claims court in any United States county where you live or work. Furthermore, notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue injunctive or equitable relief at any time, from any court of competent jurisdiction, to prevent the infringement, misappropriation or violation of intellectual property rights.

YOU AND HUMMING HOMES WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. YOU AND HUMMING HOMES ARE INSTEAD CHOOSING TO HAVE CLAIMS AND DISPUTES RESOLVED BY ARBITRATION. IN ANY LITIGATION BETWEEN YOU AND HUMMING HOMES OVER WHETHER TO VACATE OR ENFORCE AN ARBITRATION AWARD, YOU AND HUMMING HOMES WAIVE ALL RIGHTS TO A JURY TRIAL, AND ELECT INSTEAD TO HAVE THE DISPUTE BE RESOLVED BY A JUDGE.

ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable, then instead all claims and disputes will be resolved in a court as set forth in the paragraph below.

‍You have the right to opt out of the provisions of this Section by sending written notice of your decision to opt out to the following address: 520 Broadway, Floor 4, New York, NY 10012 postmarked within thirty (30) days of first accepting these Terms. You must include (i) your name and address, (ii) the email address associated with your account, and (iii) a clear statement that you want to opt out of these Terms’ arbitration agreement. If you send such an opt-out notice, and/or where the foregoing arbitration agreement permits either you or Humming Homes to litigate any dispute, then the foregoing arbitration agreement will not apply to either party, and both you and Humming Homes agree that any judicial proceeding (other than small claims actions) will be brought in the state or federal courts located in New York County, New York. If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration Agreement section will be null and void. This arbitration agreement will survive the termination of your relationship with Humming Homes.

‍*Miscellaneous.* You will be responsible for paying, withholding, filing, and reporting all taxes and assessments associated with your activity in connection with the Services, provided that Humming Homes may, in its sole discretion, do so on your behalf as it sees fit. Failure to exercise any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect and enforceable. You and Humming Homes agree that these Terms are the complete and exclusive statement of the mutual understanding between you and Humming Homes, and that these Terms supersede and cancel all previous agreements and understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venturer of Humming Homes, and you do not have any authority of any kind to bind Humming Homes in any respect whatsoever. Except as expressly set forth in the section above regarding arbitration, you and Humming Homes agree there are no third-party beneficiaries intended under these Terms.`}
  </ReactMarkdown>
);

export default Agreement;
