import React, { FC } from "react";

import Button from "components/Button";

import styles from "./styles.module.scss";

type Props = {
  nextStep(): void;
  skipStep(): void;
};

const ManagePaymentOptions: FC<Props> = ({ skipStep, nextStep }) => (
  <div className={styles.container}>
    <Button onClick={() => nextStep()} labelVariant="uppercase">
      Connect payment methods
    </Button>
    <Button
      className={styles.skipButton}
      variant="text"
      onClick={() => skipStep()}
    >
      Skip for now
    </Button>
  </div>
);

export default ManagePaymentOptions;
