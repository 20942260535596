import React, { FC } from "react";
import { useRecoilValue } from "recoil";

import Form from "components/Form";
import Button from "components/Button";
import { STATE_ABBR } from "constants/site";
import { Vendor } from "types/models";

import {
  onlyNumbers,
  phoneNumberInput,
  required,
  validateWebsite,
} from "utils/form";
import Label from "components/Label";
import { SharedFlowState } from "components/Flow/types";
import { createVendor } from "api/vendors";
import useAsync from "hooks/use-async";
import useNotificationDispatcher from "hooks/use-notification-dispatcher";
import { getError } from "utils/errors";
import { AxiosError } from "axios";
import styles from "./styles.module.scss";

type Props = {
  sharedFlowState: SharedFlowState;
  onSuccess(): void;
};

type FormValues = {
  vendor_name: string;
  address_line_1: string;
  address_line_2?: string;
  city: string;
  zip_code: string;
  state: string;
  phone_number: string;
  phone_number_mobile?: string;
  website?: string;
  contact_method: string;
};

const AddressInfo: FC<Props> = ({ sharedFlowState, onSuccess }) => {
  const notificationDispatcher = useNotificationDispatcher();

  const { vendorUID } = useRecoilValue(sharedFlowState);

  const [submit, submitting] = useAsync(async (values: FormValues) => {
    await createVendor(values as Vendor, vendorUID)
      .then(() => {
        notificationDispatcher({
          type: "success",
          description: "Vendor created correctly",
        });
        onSuccess();
      })
      .catch((error: AxiosError) => {
        notificationDispatcher({
          type: "error",
          description: getError(error.response?.data, "Something went wrong!"),
        });
      });
  });

  return (
    <>
      <Label className={styles.title} type="h6-centered" color="primary">
        Please enter address information
      </Label>
      <Form
        className={styles.form}
        onSubmit={submit}
        fields={[
          {
            placeholder: "Business name",
            id: "vendor_name",
            type: "text",
            validate: [required("This field is required")],
          },
          {
            placeholder: "Address line 1",
            id: "address_line_1",
            type: "text",
            validate: [required("Please enter an address")],
          },
          {
            placeholder: "Address line 2",
            id: "address_line_2",
            type: "text",
          },
          {
            placeholder: "City",
            type: "text",
            id: "city",
            validate: [required("This field is required")],
          },
          {
            id: "state",
            placeholder: "ST",
            type: "select",
            options: STATE_ABBR.map((state) => ({
              value: state,
              key: state,
              label: state,
            })),
            validate: [required("This field is required")],
          },
          {
            placeholder: "Zip code",
            id: "zip_code",
            type: "text",
            validate: [required("This field is required")],
            normalize: [onlyNumbers],
          },
          {
            placeholder: "Phone number",
            id: "phone_number",
            type: "text",
            normalize: [phoneNumberInput],
            validate: [required("Please enter a phone number")],
          },
          {
            placeholder: "Mobile phone number",
            id: "phone_number_mobile",
            type: "text",
            normalize: [phoneNumberInput],
          },
          {
            placeholder: "Website",
            id: "website",
            type: "text",
            validate: [validateWebsite("Please enter a valid website")],
          },
          {
            id: "contact_method",
            type: "select",
            placeholder: "Preferred contact method",
            validate: [required("This field is required")],
            options: [
              { value: "OFFICE_PHONE", label: "Office phone" },
              { value: "CELL_TEXT_CALL", label: "Cell text/call" },
              { value: "COMPANY_EMAIL", label: "Company email" },
            ],
          },
        ]}
      >
        {({ invalid, handleSubmit }) => (
          <Button
            loading={submitting}
            className={styles.submit}
            onClick={handleSubmit}
            disabled={invalid}
          >
            Next
          </Button>
        )}
      </Form>
    </>
  );
};

export default AddressInfo;
