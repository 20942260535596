import React, { FC } from "react";
import { Switch } from "antd";

type Props = {
  disabled?: boolean;
  onChange(values: boolean): void;
  value: boolean;
  id?: string;
};

const Toggle: FC<Props> = ({ disabled, onChange, id, value }) => {
  return (
    <Switch id={id} onChange={onChange} disabled={disabled} checked={value} />
  );
};

Toggle.defaultProps = {
  disabled: false,
  id: undefined,
};

export default Toggle;
