/* eslint-disable @typescript-eslint/no-explicit-any */

interface MapperOptions {
  [key: string]: any;
}

export const mapper =
  (options: MapperOptions, defaultValue: any) =>
  (selectedOption: string | undefined) =>
    (selectedOption && options[selectedOption]) || defaultValue;
