import React, { useEffect, useState, FC } from "react";
import { Link } from "react-router-dom";

import { FormatAddress } from "components/FormattedAddress";
import { Home, HomeSnippetOnAction } from "types/models";

import Label from "components/Label";

import styles from "./styles.module.scss";

type Props = {
  showName?: boolean;
  showLinkToHome?: boolean;
  home?: Home | null;
  homeSnippet?: HomeSnippetOnAction | null;
  borderless?: boolean;
};

const NameAndAddress: FC<Props> = ({
  showName = true,
  showLinkToHome = false,
  home,
  homeSnippet,
  borderless,
}) => {
  const [name, setName] = useState("");
  const image = home?.cover_image || homeSnippet?.cover_image;

  useEffect(() => {
    const homeowner = home?.users?.find((user) => user.role === "HOME_OWNER");
    if (homeowner) setName(homeowner.user.name);
  }, [home]);

  const homeAddress = home?.home_address || homeSnippet?.home_address;

  if (!homeAddress) return null;

  return (
    <div className={`${styles.container} ${borderless && styles.borderless}`}>
      <div className={styles.information}>
        {showName && <Label type="s2-italic-bold">{name}</Label>}
        <FormatAddress home={homeAddress} />
        {showLinkToHome && (
          <Link to={`/admin/homes/${home?.uid || homeSnippet?.uid}`}>
            View home
          </Link>
        )}
      </div>
      {image && (
        <div className={styles.imageContainer}>
          <img src={image} alt="" className={styles.image} />
        </div>
      )}
    </div>
  );
};

NameAndAddress.defaultProps = {
  showName: true,
  showLinkToHome: false,
  home: null,
  homeSnippet: null,
  borderless: false,
};

export default NameAndAddress;
