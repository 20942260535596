import React, { useState, useEffect, FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Tabs } from "antd";

import { InvoiceStatus } from "types/models";
import { CustomerInvoiceTabs } from "elements/tabs";
import Content from "components/Content";
import { useSidebarInteraction } from "components/Sidebar";
import useAsyncState from "hooks/use-async-state";
import { fetchInvoices } from "api/invoices";
import { updateStickyFilters } from "utils/projects";
import InvoiceList from "./components/InvoiceList";
import InvoiceDetail from "./components/InvoiceDetail";

const Invoices: FC = () => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState("all_invoices");
  const { showSidebar } = useSidebarInteraction();

  useEffect(() => {
    showSidebar();
  }, []);

  const loc = useLocation();

  const {
    data: invoiceResponse,
    refresh: refreshInvoices,
    inProgress: fetchingInvoices,
  } = useAsyncState(
    { count: 0, results: [], next: null, previous: null },
    (stickyParams) => fetchInvoices({ page_size: "10" }, stickyParams)
  );

  useEffect(() => {
    refreshInvoices(updateStickyFilters(loc.search));
  }, [loc.search]);

  return (
    <Content withSidebar>
      <CustomerInvoiceTabs
        size="large"
        activeKey={currentTab}
        onChange={(newTab) => {
          history.push(
            `/portal/invoices${
              newTab === "all_invoices" ? "" : `/?status=${newTab}`
            }`
          );
          setCurrentTab(newTab || "all_invoices");
        }}
        style={{
          width: "100%",
        }}
        tabBarStyle={{
          marginBottom: 0,
          overflow: "auto",
          marginLeft: "1rem",
        }}
      >
        <Tabs.TabPane tab="All Invoices" key="all_invoices">
          <InvoiceList
            invoiceResponse={invoiceResponse}
            fetchingInvoices={fetchingInvoices}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Unpaid" key="open">
          <InvoiceList
            fetchingInvoices={fetchingInvoices}
            invoiceResponse={{
              ...invoiceResponse,
              results: invoiceResponse.results?.filter(
                ({ status }) => status === InvoiceStatus.Unpaid
              ),
            }}
          />
        </Tabs.TabPane>
      </CustomerInvoiceTabs>
      <InvoiceDetail
        onPay={() => refreshInvoices(updateStickyFilters(loc.search))}
      />
    </Content>
  );
};

export default Invoices;
