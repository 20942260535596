import Button from "components/Button";
import React, { FC } from "react";

import styles from "./styles.module.scss";

interface Tab {
  name: string;
  value: string;
  disabled?: boolean;
}

interface Props {
  tabs: Tab[];
  onChange(newTab: string): void;
  currentValue: string;
  className?: string;
}

const Tabs: FC<Props> = ({ tabs, onChange, currentValue, className }) => {
  return (
    <div className={className}>
      <div className={styles.tabs}>
        {tabs.map(({ name, value, disabled }) => (
          <Button
            key={name}
            className={`${styles.tab} ${
              currentValue === value && styles.selectedTab
            }`}
            onClick={() => onChange(value)}
            variant="text"
            disabled={disabled}
          >
            {name}
          </Button>
        ))}
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  className: undefined,
};

export default Tabs;
