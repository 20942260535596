import React, { FC, useState } from "react";
import { Button } from "antd";
import SimpleBar from "simplebar-react";

import Label from "components/Label";

import { Appliance as ApplianceType } from "types/models";

import styles from "./styles.module.scss";
import NewAppliancePanel from "./components/NewAppliancePanel";
import Appliance from "./components/Appliance";

type Props = {
  name: string;
  appliances: ApplianceType[];
  id: string;
};

const Room: FC<Props> = ({ name, appliances, id }) => {
  const [addNewAppliance, setAddNewAppliance] = useState(false);

  return (
    <>
      <NewAppliancePanel
        id={id}
        visible={addNewAppliance}
        onClose={() => setAddNewAppliance(false)}
      />
      <div className={styles.roomWrapper}>
        <div className={styles.room}>
          <div className={styles.roomContent}>
            <div className={styles.roomHeader}>
              <Label
                className={styles.roomName}
                ellipsis
                color="white"
                type="italic-bold"
              >
                {name}
              </Label>
            </div>
            <SimpleBar className={styles.appliances}>
              {appliances.map((appliance) => (
                <Appliance key={appliance.id} appliance={appliance} />
              ))}
            </SimpleBar>
          </div>
          <div className={styles.roomActions}>
            <Button
              onClick={() => setAddNewAppliance(true)}
              className={styles.addAppliance}
            >
              <Label type="button" color="primary">
                Add appliance
              </Label>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Room;
