import { UploadFile } from "antd/lib/upload/interface";
import { IMAGE_TYPES } from "constants/fileTypes";

export const handleDownloadAttachment = ({
  file,
  name,
}: {
  file: string;
  name: string;
}) => {
  const element = document.createElement("a");
  element.href = file;
  element.download = name || "image.png";
  element.target = "_blank";
  element.click();
};

export const handleDownload = (file: UploadFile) => {
  if (file.url === undefined) return;

  const element = document.createElement("a");
  element.href = file.url;
  element.download = file.name || "image.png";
  element.target = "_blank";
  element.click();
};

export const handleBulkDownload = (fileList: UploadFile[]) => {
  fileList.map((file) => {
    if (file.url === undefined) return null;
    const element = document.createElement("a");
    element.href = file.url;
    element.target = "_blank";
    element.download = file.name || "image.png";
    return element.click();
  });
};

export const isFileImage = (file: UploadFile) => {
  return IMAGE_TYPES.some((imageType) => file.url?.includes(imageType));
};
