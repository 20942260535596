import React, { useEffect, useState, FC } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Tabs from "components/Tabs";
import NotesList from "./components/NotesList";

import ActivityList from "./components/ActivityList";
import JobsList from "./components/JobsList";

import styles from "./styles.module.scss";

const chooseCurrentTab = (currentURL: string) => {
  if (currentURL.includes("activity-log")) return "activity-log";
  if (currentURL.includes("notes")) return "notes";
  return "jobs";
};

const ProjectSpecifics: FC = () => {
  const { projectID } = useParams<{ [key: string]: string }>();
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [currentTab, setCurrentTab] = useState(chooseCurrentTab(pathname));

  useEffect(() => {
    setCurrentTab(chooseCurrentTab(pathname));
  }, [pathname]);

  return (
    <div className={styles.container}>
      <Tabs
        className={styles.tabs}
        tabs={[
          { name: "Notes", value: "notes" },
          { name: "Jobs", value: "jobs" },
          { name: "Activity log", value: "activity-log" },
        ]}
        currentValue={currentTab}
        onChange={(newTab) => {
          history.push(`/admin/projects/${projectID}/${newTab}${search}`);
          setCurrentTab(newTab);
        }}
      />
      <div className={styles.pane}>
        {currentTab === "notes" && <NotesList />}
        {currentTab === "jobs" && <JobsList />}
        {currentTab === "activity-log" && <ActivityList />}
      </div>
    </div>
  );
};

export default ProjectSpecifics;
